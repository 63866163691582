.mattress-recommend-video__wrapper {
    display: block;
    @include spaceXL(padding, 0);

    background: map-get($mau, color-primary);
    // min-height: 636px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .container{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .mattress-recommend-video {
        display: flex;
        // justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        
        .left-recommend-video {
            width: 40%;
            @media (max-width: 900px) {
                width: 100%;
            }
            margin-left: auto;
            @media (max-width: 1800px) {
                width: 45%;
            }
            @media (max-width: 1600px) {
                width: 48%;
            }
            @media (max-width: 1440px){
                width: 50%;
            }

            .product-infor {
                // padding: 0 170px 0 0;
                @include spaceL(padding-right);
                & > * {
                    color: map-get($mau, gray-1);
                }
                span {
                    text-transform: uppercase;
                    font-weight: 400;
                    // font-size: 20px;
                }
                // .product-infor__title {
                //     // margin: 10px 0;
                //     // @include spaceM(margin-top);
                // }
                .product-infor__content{
                    margin-top: 32px;
                    &>*:not(:first-child){
                        margin-top: 18px;
                    }
                }

            }
        }
    }
    .right-recommend-video {
        // position: absolute;
        // top: 50%;
        // transform: translateY(-50%);
        right: 0;
        width: 50%;
        // height: auto;
        

        
        .video-section{
            height: 100%;
            &__img{
                min-height: 380px;
            }
            img{
                object-fit: contain;
                object-position: center;
                width: 100%;
                height: 100%;
                padding: 0 24px;
            }
            overflow: hidden;
        }
    }
    
    
    @media (max-width: map-get($media, tl)) {
        // .mattress-recommend-video__wrapper{
            //     min-height: 520px;
            // }
            .mattress-recommend-video__wrapper .mattress-recommend-video .left-recommend-video {
                width: 100%;
            }
            .mattress-recommend-video__wrapper {
                flex-direction: column;
                // padding: 36px 0;
            }
            .mattress-recommend-video__wrapper .right-recommend-video {
                transform: none;
                position: static;
                top: initial;
                right: initial;
                width: 96%;
            }
            .mattress-recommend-video__wrapper .mattress-recommend-video .left-recommend-video .product-infor {
                padding: 0;
            }
        }
    
        @media (max-width: map-get($media, tl)) {
            .mattress-recommend-video__wrapper .mattress-recommend-video{
                padding: 24px 0;
            }
        }
}

@media (max-width: map-get($media, tl)) {
    // .mattress-recommend-video__wrapper{
        //     min-height: 520px;
        // }
        .mattress-recommend-video__wrapper .mattress-recommend-video .left-recommend-video {
            width: 100%;
        }
        .mattress-recommend-video__wrapper {
            flex-direction: column;
            // padding: 36px 0;
        }
        .mattress-recommend-video__wrapper .right-recommend-video {
            transform: none;
            position: static;
            top: initial;
            right: initial;
            width: 96%;
        }
        .mattress-recommend-video__wrapper .mattress-recommend-video .left-recommend-video .product-infor {
            padding: 0;
        }
    }

    @media (max-width: map-get($media, tl)) {
        .mattress-recommend-video__wrapper .mattress-recommend-video{
            padding: 24px 0;
        }
    }
// .video{
//     &-section{
//     position: relative;
//         &-content{
//             position: absolute;
//             width: 685px;
//             top: 30%;
//             left: 10%;
//             .product-infor {
//                 // padding: 0 170px 0 0;
//                 @include spaceL(padding-right);
//                 & > * {
//                     color: map-get($mau, gray-1);
//                 }
//                 .product-infor__title {
//                     font-size: 70px;
//                 }
//                 .product-infor__content{
//                     font-size: 25px;
//                     margin-top: 32px;
//                     &>*:not(:first-child){
//                         margin-top: 18px;
//                     }
//                 }

//             }
//         }
//     }
// }
// @media(max-width:1660px){
//     .video{
//         &-section{
//             &-content{
//                 width: 685px;
//                 .product-infor{
//                     .product-infor__title {
//                         font-size: 60px;
//                     }
//                     .product-infor__content{
//                         font-size: 25px;

//                     }
//                 }
//             }
//         }
//     }
// }
// @media(max-width:1440px){
//     .video{
//         &-section{
//             &-content{
//                 width: 570px;
//                 .product-infor{
//                     .product-infor__title {
//                         font-size: 50px;
//                     }
//                     .product-infor__content{
//                         font-size: 20px;

//                     }
//                 }
//             }
//         }
//     }
// }
// @media(max-width:1200px){
//     .video{
//         &-section{
//             &-content{
//                 width: 490px;
//                 .product-infor{
//                     .product-infor__title {
//                         font-size: 40px;
//                     }
//                     .product-infor__content{
//                         font-size: 18px;

//                     }
//                 }
//             }
//         }
//     }
// }
// @media(max-width:1024px){
//     .video{
//         display: none;
//         &-section{
//             &-content{
//                 width: 380px;
//                 .product-infor{
//                     .product-infor__title {
//                         font-size: 35px;
//                     }
//                     .product-infor__content{
//                         font-size: 14px;

//                     }
//                 }
//             }
//         }
//     }
// }
// @media(max-width:768px){
//     .video{
//         &-section{
//             &-content{
//                 width: 300px;
//                 .product-infor{
//                     .product-infor__title {
//                         font-size: 22px;
//                     }
//                     .product-infor__content{
//                         font-size: 14px;

//                     }
//                 }
//             }
//         }
//     }
// }
// @media(max-width:650px){
//     .video{
//         &-section{
//             &-content{
//                 width: 290px;
//                 left: 7%;

//                 .product-infor{
//                     .product-infor__title {
//                         font-size: 22px;
//                     }
//                     .product-infor__content{
//                         font-size: 10px;
//                         margin: 12px 0;
//                         &>*:not(:first-child){
//                             margin-top: 8px;
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }
// @media(max-width:425px){
//     .video{
//         &-section{
//             &-content{
//                 width: 180px;
//                 left: 7%;

//                 .product-infor{
//                     .product-infor__title {
//                         font-size: 13px;
//                     }
//                     .product-infor__content{
//                         font-size: 7px;
//                         margin: 12px 0;
//                         &>*:not(:first-child){
//                             margin-top: 8px;
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }
// @media(max-width:380px){
//     .video{
//         &-section{
//             &-content{
//                 width: 150px;
//                 left: 7%;

//                 .product-infor{
//                     .product-infor__title {
//                         font-size: 10px;
//                     }
//                     .product-infor__content{
//                         font-size: 6px;
//                         margin: 12px 0;
//                         &>*:not(:first-child){
//                             margin-top: 8px;
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }