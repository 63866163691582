.dealer-navigation-digital__wrapper {
    .dealer-navigation-digital {
        display: flex;
        align-items: center;
        // justify-content: center;
        // margin: 80px 0;
        flex-wrap: wrap;

        @include spaceL(margin, 0);
        .items {
            // text-align: center;
            display: flex;
            justify-content: center;
            position: relative;
            & {
                &::before {
                    position: absolute;
                    content: "";
                    top: 50%;
                    right: 0;
                    transform: translate(-50%, -50%);
                    height: 50%;
                    width: 2px;
                    background: map-get($mau, color-primary);
                }
            }
            &:nth-child(3n) {
                margin-left: unset;
                &::before {
                    display: none;
                }
            }
            .nav-items {
                height: 100%;
                padding: 20px 55px 20px 30px;
                display: flex;
                flex-wrap: wrap;
                p {
                    width: 100%;
                }

                cursor: pointer;
                position: relative;
                font-size: 27px;
                // color: map-get($mau, color-primary );
                color: #000;
                background-color: transparent;

                // white-space: nowrap;
                min-width: 20%;

                @media (max-width: 425px) {
                    font-size: 20px;
                }
                img {
                    display: none;
                }
                &.active {
                    padding: 20px 30px 20px 30px;
                    // border: solid 2px map-get($mau,color-primary );
                    color: #fff;
                    background-color: map-get($mau, color-primary);
                    border-radius: 6px;
                    position: relative;

                    &::after {
                        margin-left: 3px;
                        width: 2rem;
                        height: 2rem;
                        position: absolute;
                        top: 50%;
                        right: 8px;
                        transform: translateY(-50%);
                        content: "";
                        // background-image: url("../assets/images/dealer-digital/arrow.png");
                        // background-image:  url('../assets/images/about/pagination_next.svg');

                        background-repeat: no-repeat;
                        background-position: center;
                    }
                }
            }
        }
    }
}

@media (max-width: 960px) {
    .dealer-navigation-digital__wrapper {
        .dealer-navigation-digital {
            flex-direction: column;
            .items {
                flex: 0 0 100%;
                width: 100%;
                max-width: 100%;
                & {
                    &::before {
                        display: none;
                    }
                }
            }
        }
    }
    @media (max-width: map-get($media, tl)) {
        .dealer-navigation-digital__wrapper .dealer-navigation-digital .nav-items {
            padding: 10px 40px 10px 10px;
            margin-right: 0;

            margin-top: 12px;
        }
    }
    // @media (max-width: map-get($media, ml)){
}
