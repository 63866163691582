.title-purchase-type {
    // margin-bottom: 95px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & > * {
        text-align: center;
    }
    @include spaceL(padding-bottom);
}

.purchase-section {
    @include spaceL(padding-bottom);
    .purchase-type {
        // margin-top: 80px;
        // margin-bottom: 100px;
        @include spaceL(padding, 0);
    }

    .purchase-products {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        margin-top: -20px;
        margin-left: -10px;
        margin-right: -10px;

        & > li {
            flex: 0 0 100%;
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 20px;

            @include rpmin(ml) {
                flex: 0 0 50%;
            }

            @include rpmin(xl) {
                flex: 0 0 33.3333%;
            }
        }
        &-item {
            height: 100%;
            background-color: #fff;
            box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
            border: 1px solid #e6e6e6;
            border-radius: 0 0 4px 4px;

            display: flex;
            flex-direction: column;

            .thumbnail {
                position: relative;
                width: 100%;
                padding-top: 100%;

                & img,
                & > a {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                &-promo {
                    z-index: 2;
                    position: absolute;
                    top: 16px;
                    left: 16px;
                    padding: 3px 8px;
                    color: #fff;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 150%;
                    @include font2;
                    background-color: #e80826;
                    border-radius: 4px;
                    span {
                        font-weight: 600;
                    }
                }
            }

            .content {
                flex: 1 1 auto;
                padding: 24px 16px;
                display: flex;
                flex-direction: column;
                &-name {
                    flex: 1 1 auto;
                    & > * {
                        @include font2;
                        // font-size: 32px;
                        @include size32px;
                        font-weight: 600;
                        color: #000;
                    }
                }

                &-rating {
                    margin-top: 6px;

                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;

                    font-size: 12px;
                    color: #a32732;
                    font-weight: 500;

                    @include font2;
                    .icon {
                        width: 16px;
                        height: 16px;
                        & > * {
                            width: 100%;
                            max-height: 100%;
                        }
                    }
                }

                &-prices {
                    margin-top: 12px;
                    &-listed {
                        @include font2;
                        color: #a32732;
                        // font-size: 24px;
                        @include size24px;
                        font-weight: 600;
                    }
                    &-pre {
                        margin-top: 4px;
                        @include font2;
                        color: #707070;
                        font-size: 14px;
                        line-height: 24px;
                        text-decoration: line-through;
                    }
                }

                &-desc {
                    margin-top: 10px;
                    @include font2;
                    font-size: 14px;
                    color: #707070;
                    font-size: 500;
                }
            }
        }
    }
}

.purchase-service {
    // @include spaceXL(margin-bottom);

    &-wrapper {
        position: relative;
        color: #fff;

        // @include spaceL(padding);
        @include spaceL(padding-top);
        @include spaceL(padding-bottom);
        // padding-left: 80px;
        // padding-right: 80px;

        background-color: #a32732;

        .bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            max-height: 100%;
            object-fit: cover;
            object-position: center;
            // mix-blend-mode: multiply;
            z-index: 1;
        }

        & > * {
            position: relative;
            z-index: 2;
        }
        .title-purchase-type {
            & > * {
                color: #fff;
            }
        }

        .services {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            // margin-top: -40px;
            // --side: 30px;

            // margin-left: calc(#{var(--side)} * (-1));
            // margin-right: calc(#{var(--side)} * (-1));

            & > li {
                flex: 0 0 100%;
                padding-left: var(--side, 0);
                padding-right: var(--side, 0);
                @include space(padding-top, 40px);

                @include rpmin(ml) {
                    flex: 0 0 50%;
                }

                @include rpmin(xl) {
                    flex: 0 0 33.3333%;
                }
            }

            &-item {
                text-align: center;
                .thumbnail {
                    width: 48%;
                    padding-top: 48%;
                    margin: 0 auto;
                    position: relative;
                    & > * {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        display: block;
                        object-fit: contain;
                    }
                }
                .content {
                    margin-top: 20px;

                    &-name {
                        & > * {
                            @include font2;
                            // font-size: 24px;
                            @include size24px;
                            font-weight: 600;
                        }
                    }

                    &-desc {
                        margin-top: 6px;

                        @include font2;
                        font-size: 14px;
                        font-weight: 500;
                    }
                }
            }
            padding-left: 100px;
            padding-right: 100px;

            position: relative;

            .swiper-container {
                position: static;
            }

            .swiper-button-prev,
            .swiper-button-next {
                display: flex;
                justify-content: center;
                align-items: center;

                width: 40px;
                height: 40px;
                border: 1px solid #fff;
                border-radius: 100%;

                background-color: rgba(255, 255, 255, 0.2);

                &::after {
                    content: "";
                    width: 100%;
                    height: 100%;

                    background-image: url('data:image/svg+xml,%3Csvg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.09128 7L0 12.4447L1.45436 14L8 7L1.45436 0L0 1.55531L5.09128 7Z" fill="white"/%3E%3C/svg%3E');
                    background-repeat: no-repeat;
                    background-position: center;
                }
            }

            .swiper-button-prev {
                left: 30px;
                transform: rotate(180deg);
            }
            .swiper-button-next {
                right: 30px;
            }
        }
    }
}
