.advertise {
    margin-top: 80px;
    @media (max-width: 768px) {
        margin-top: 64px;
    }
    .advertise-content {
        position: relative;
        text-align: center;
        width: 100%;

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.3);
        }
    }
    .pic {
        height: 100%;
        width: 100%;
    }

    .advertise-pic {
        display: block !important;

        a {
            display: block;
        }
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            min-height: 560px;
        }
    }

    .advertise-text {
        @include spaceContainer;
        color: white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;

        display: inline-flex;
        flex-direction: column;
        align-items: center;
    }
    .header {
        @include spaceM(margin-bottom);

        width: 100%;
        text-align: center;

        & > * {
            font-weight: 400;
            text-transform: uppercase;
            letter-spacing: 7px;
            font-size: 80px;
            @media (max-width: 1600px) {
                font-size: 64px;
            }
            @media (max-width: 1280px) {
                font-size: 54px;
            }
            @media (max-width: 768px) {
                font-size: 40px;
            }
            @media (max-width: 530px) {
                font-size: 32px;
            }
        }
    }
    .content {
        @include p-line-clamp(3);
        @include spaceM(margin-bottom, 0);
        @include font-heading(4);
        font-weight: bold;
        text-align: center;
        width: 70%;
        letter-spacing: 1px;
        // margin: 70px 0 41px 0;

        text-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
    }
    .readmore {
        a {
            color: white;
            font-weight: bold;
            padding: 15px 25px;
            border-radius: 8px;
            border: 4px solid white;
            display: inline-block;
            font-size: 28px;
        }
        a:hover {
            // background-color: #041e42;
            background-color: #a32732;
            color: white;
        }
    }
}

@media (max-width: map-get($media, ps)) {
    .advertise .header {
        width: 90%;
    }
    .advertise .content {
        width: 80%;
    }
}
@media (max-width: map-get($media, tl)) {
    .advertise .readmore a {
        padding: 12px 20px;
        font-size: 21px;

        // border: 3px solid #041e42;
    }
}

@media (max-width: map-get($media, ml)) {
    .advertise .advertise-text {
        padding: 0;
    }

    .advertise .header {
        width: 100%;
    }
    .advertise .content {
        width: 100%;
    }
    .advertise .readmore a {
        padding: 8px 16px;
        font-size: 20px;
    }

    .advertise {
        .advertise-pic {
            img {
                min-height: 420px;
            }
        }
    }
}
