.our-tech {
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    @include spaceXL(padding, 0);

    background-color: rgba(0, 0, 0, 0.02);
    .title-our-technology {
        .title {
            @include font-heading(2);
            // margin: 70px;
            // @include spaceL(margin-bottom);
            margin-bottom: 16px;
        }
        .desc {
            @include font-heading(5);
            width: 56%;
            margin: 0 auto;
        }
    }

    .tech-list {
        display: flex;
        justify-content: center;

        flex-wrap: wrap;
        // padding-top: 90px;
        @include space(margin-top, 80px);

        @media (max-width: 768px) {
            padding-top: 72px;
            flex-direction: column;
        }

        & > * {
            flex: 0 0 33.3333%;

            @include rpmax(xl) {
                flex: 0 0 50%;
            }

            @include rpmax(ml) {
                flex: 0 0 100%;
            }
        }

        margin-top: -30px;
        margin-left: -12px;
        margin-right: -12px;

        @media (max-width: 768px) {
            margin-top: -12px;
            margin-left: -8px;
            margin-right: -8px;
        }

        &-item {
            padding: 30px 12px;
            @media (max-width: 768px) {
                padding: 12px 8px;
            }

            display: flex;
            flex-direction: column;

            &__title {
                // flex: 1 1 auto;
                // margin-top: 50px;
                // @media (max-width: 768px) {
                //     margin-top: 24px;
                // }
                @include space(margin-top, 40px);

                & > * {
                    // @include font-heading(3);
                    @include font1;
                    @include size30px;
                    @include semi-bold;
                    color: #000;
                }
            }
            &__desc {
                margin-top: 16px;
                & > * {
                    // @include font-heading(5);
                    @include size20px;
                    @include medium;

                    color: #707070;
                }
                @media (max-width: 768px) {
                    margin-top: 12px;
                }
            }

            &__img {
                display: block;
                height: 120px;

                img {
                    height: 100%;
                }
            }
        }
    }
}

.sf {
    background-color: rgba(0, 0, 0, 0.1);
    &-content {
        width: 40%;
        padding: 160px 0;
        @media (max-width: 1900px) {
            padding: 120px 0;
        }
        @media (max-width: 1660px) {
            padding: 60px 0;
        }
        @media (max-width: 1440px) {
            padding: 30px 0;
        }
        @media (max-width: 1365px) {
            padding: 64px 0;
            width: 100%;
        }

        &__title {
            & > * {
                @include font-heading(3);
                @media (max-width: 425px) {
                    font-size: 25px;
                }
            }
        }
        &__desc {
            padding-right: 50px;
            margin-top: 40px;
            & > * {
                // @include font-heading(4);
                // color: #707070;
                // @media (max-width: 425px) {
                //     font-size: 20px;
                //     color: #707070;
                // }
                @include font2;
                font-weight: 500;
                @include size20px;
            }
        }
    }

    position: relative;
    &-img {
        position: absolute;
        top: 50%;
        height: 100%;
        transform: translateY(-50%);
        right: 0;

        @media (max-width: 1336px) {
            position: initial;
            top: initial;
            right: initial;
            transform: none;
        }
        // height: 100%;

        img,
        video,
        iframe {
            width: 1062px;
            height: 100%;
            object-fit: cover;
            @media (max-width: 1820px) {
                width: 920px;
            }
            @media (max-width: 1640px) {
                width: 840px;
            }

            @media (max-width: 1440px) {
                width: 740px;
            }

            @media (max-width: 1336px) {
                width: 100%;
                max-height: 50vh;
            }
            @media (max-width: 768px) {
                width: 100%;
                max-height: 50vh;
            }
        }
    }
}

.lifetime {
    // background-color: rgba(0, 0, 0, 0.02);
    margin-top: 80px;
    @media (max-width: 768px) {
        margin-top: 64px;
    }
    &--container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        @media (max-width: 1280px) {
            flex-direction: column;
        }
    }

    &__img {
        align-self: center;
        width: 41.6667%;

        @media (max-width: 1820px) {
            width: 49.6667%;
        }
        @media (max-width: 1280px) {
            width: 100%;

            img {
                width: 100%;
            }
        }
        @media (max-width: 768px) {
            padding-bottom: 0;
        }
    }

    &-content {
        width: calc(50% + 24px);
        margin-left: auto;
        padding-top: 70px;
        @media (max-width: 1820px) {
            padding-top: 60px;
            width: calc(42% + 24px);
        }
        @media (max-width: 1280px) {
            padding-top: 24px;
            width: 100%;
        }
        padding-bottom: 48px;
        &__title {
            & > * {
                @include font-heading(3);
                @media (max-width: 425px) {
                    font-size: 25px;
                }
            }
        }

        &__desc {
            & > * {
                // @include font-heading(4);
                // color: rgba(0, 0, 0, 0.75);
                // @media (max-width: 425px) {
                //     @include font-heading(5);
                // }

                @include font2;
                font-weight: 500;
                @include size20px;
            }
        }

        &__button {
            & > * {
                // color: #041E42;
                @include font2;
                color: #000;
                font-weight: 700;
            }
        }

        & > *:not(:first-child) {
            margin-top: 32px;
            @media (max-width: 768px) {
                margin-top: 24px;
            }
        }
    }
}
