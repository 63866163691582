/*!
* source: https://getbootstrap.com/
* Bootstrap Grid v5.0.2 (https://getbootstrap.com/)
* Copyright 2011-2021 The Bootstrap Authors
* Copyright 2011-2021 Twitter, Inc.
* Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
*/
.container, .wholesale-factory__content2 .right .content,
.container-left,
.container-right {
  width: calc(100vw - 32px);
  margin-left: auto;
  margin-right: auto; }

.container2 {
  margin-left: auto;
  margin-right: auto;
  width: calc(100vw - 32px); }

.container-left,
.container-right {
  width: calc( (100vw - var(--wContainer))/2  + var(--wContainer)); }

.container-right {
  margin-right: initial;
  margin-left: auto; }

.container-left {
  margin-right: auto;
  margin-left: initial; }

@media (min-width: 576px) {
  .container2 {
    width: 96vw; } }

@media (min-width: 640px) {
  .container, .wholesale-factory__content2 .right .content {
    width: 96vw; } }

@media (min-width: 1280px) {
  .container, .wholesale-factory__content2 .right .content {
    width: 1172px; }
  .container2 {
    width: 1074px; } }

@media (min-width: 1440px) {
  .container, .wholesale-factory__content2 .right .content {
    width: 1366px; }
  .container2 {
    width: 1366px; } }

@media (min-width: 1920px) {
  .container, .wholesale-factory__content2 .right .content {
    width: 1640px; }
  .container2 {
    width: 1640px; } }

.col {
  display: block;
  flex: 1 0 0%; }

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0px;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -.5);
  margin-left: calc(var(--bs-gutter-x) * -.5);
  width: calc(100% + (var(--bs-gutter-x) * 0.5 * 2)); }
  .row > * {
    box-sizing: border-box;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y); }
  .row-cols-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%; }
  .row-cols-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%; }

@media (min-width: 360px) {
  .col-ss {
    flex: 1 0 0%;
    display: block; }
  .row-cols-ss-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-1-ss > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-2-ss > * {
    flex: 0 0 auto;
    width: 91.66667%; }
  .row-cols-3-ss > * {
    flex: 0 0 auto;
    width: 83.33333%; }
  .row-cols-4-ss > * {
    flex: 0 0 auto;
    width: 75%; }
  .row-cols-5-ss > * {
    flex: 0 0 auto;
    width: 66.66667%; }
  .row-cols-6-ss > * {
    flex: 0 0 auto;
    width: 58.33333%; }
  .row-cols-7-ss > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-8-ss > * {
    flex: 0 0 auto;
    width: 41.66667%; }
  .row-cols-9-ss > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-10-ss > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-11-ss > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .row-cols-12-ss > * {
    flex: 0 0 auto;
    width: 8.33333%; } }

@media (min-width: 420px) {
  .col-sx {
    flex: 1 0 0%;
    display: block; }
  .row-cols-sx-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-1-sx > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-2-sx > * {
    flex: 0 0 auto;
    width: 91.66667%; }
  .row-cols-3-sx > * {
    flex: 0 0 auto;
    width: 83.33333%; }
  .row-cols-4-sx > * {
    flex: 0 0 auto;
    width: 75%; }
  .row-cols-5-sx > * {
    flex: 0 0 auto;
    width: 66.66667%; }
  .row-cols-6-sx > * {
    flex: 0 0 auto;
    width: 58.33333%; }
  .row-cols-7-sx > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-8-sx > * {
    flex: 0 0 auto;
    width: 41.66667%; }
  .row-cols-9-sx > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-10-sx > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-11-sx > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .row-cols-12-sx > * {
    flex: 0 0 auto;
    width: 8.33333%; } }

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
    display: block; }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-1-sm > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-2-sm > * {
    flex: 0 0 auto;
    width: 91.66667%; }
  .row-cols-3-sm > * {
    flex: 0 0 auto;
    width: 83.33333%; }
  .row-cols-4-sm > * {
    flex: 0 0 auto;
    width: 75%; }
  .row-cols-5-sm > * {
    flex: 0 0 auto;
    width: 66.66667%; }
  .row-cols-6-sm > * {
    flex: 0 0 auto;
    width: 58.33333%; }
  .row-cols-7-sm > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-8-sm > * {
    flex: 0 0 auto;
    width: 41.66667%; }
  .row-cols-9-sm > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-10-sm > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-11-sm > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .row-cols-12-sm > * {
    flex: 0 0 auto;
    width: 8.33333%; } }

@media (min-width: 640px) {
  .col-md {
    flex: 1 0 0%;
    display: block; }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-1-md > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-2-md > * {
    flex: 0 0 auto;
    width: 91.66667%; }
  .row-cols-3-md > * {
    flex: 0 0 auto;
    width: 83.33333%; }
  .row-cols-4-md > * {
    flex: 0 0 auto;
    width: 75%; }
  .row-cols-5-md > * {
    flex: 0 0 auto;
    width: 66.66667%; }
  .row-cols-6-md > * {
    flex: 0 0 auto;
    width: 58.33333%; }
  .row-cols-7-md > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-8-md > * {
    flex: 0 0 auto;
    width: 41.66667%; }
  .row-cols-9-md > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-10-md > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-11-md > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .row-cols-12-md > * {
    flex: 0 0 auto;
    width: 8.33333%; } }

@media (min-width: 768px) {
  .col-ml {
    flex: 1 0 0%;
    display: block; }
  .row-cols-ml-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-1-ml > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-2-ml > * {
    flex: 0 0 auto;
    width: 91.66667%; }
  .row-cols-3-ml > * {
    flex: 0 0 auto;
    width: 83.33333%; }
  .row-cols-4-ml > * {
    flex: 0 0 auto;
    width: 75%; }
  .row-cols-5-ml > * {
    flex: 0 0 auto;
    width: 66.66667%; }
  .row-cols-6-ml > * {
    flex: 0 0 auto;
    width: 58.33333%; }
  .row-cols-7-ml > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-8-ml > * {
    flex: 0 0 auto;
    width: 41.66667%; }
  .row-cols-9-ml > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-10-ml > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-11-ml > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .row-cols-12-ml > * {
    flex: 0 0 auto;
    width: 8.33333%; } }

@media (min-width: 900px) {
  .col-ls {
    flex: 1 0 0%;
    display: block; }
  .row-cols-ls-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-1-ls > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-2-ls > * {
    flex: 0 0 auto;
    width: 91.66667%; }
  .row-cols-3-ls > * {
    flex: 0 0 auto;
    width: 83.33333%; }
  .row-cols-4-ls > * {
    flex: 0 0 auto;
    width: 75%; }
  .row-cols-5-ls > * {
    flex: 0 0 auto;
    width: 66.66667%; }
  .row-cols-6-ls > * {
    flex: 0 0 auto;
    width: 58.33333%; }
  .row-cols-7-ls > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-8-ls > * {
    flex: 0 0 auto;
    width: 41.66667%; }
  .row-cols-9-ls > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-10-ls > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-11-ls > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .row-cols-12-ls > * {
    flex: 0 0 auto;
    width: 8.33333%; } }

@media (min-width: 1024px) {
  .col-lg {
    flex: 1 0 0%;
    display: block; }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-1-lg > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-2-lg > * {
    flex: 0 0 auto;
    width: 91.66667%; }
  .row-cols-3-lg > * {
    flex: 0 0 auto;
    width: 83.33333%; }
  .row-cols-4-lg > * {
    flex: 0 0 auto;
    width: 75%; }
  .row-cols-5-lg > * {
    flex: 0 0 auto;
    width: 66.66667%; }
  .row-cols-6-lg > * {
    flex: 0 0 auto;
    width: 58.33333%; }
  .row-cols-7-lg > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-8-lg > * {
    flex: 0 0 auto;
    width: 41.66667%; }
  .row-cols-9-lg > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-10-lg > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-11-lg > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .row-cols-12-lg > * {
    flex: 0 0 auto;
    width: 8.33333%; } }

@media (min-width: 1280px) {
  .col-xl {
    flex: 1 0 0%;
    display: block; }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-1-xl > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-2-xl > * {
    flex: 0 0 auto;
    width: 91.66667%; }
  .row-cols-3-xl > * {
    flex: 0 0 auto;
    width: 83.33333%; }
  .row-cols-4-xl > * {
    flex: 0 0 auto;
    width: 75%; }
  .row-cols-5-xl > * {
    flex: 0 0 auto;
    width: 66.66667%; }
  .row-cols-6-xl > * {
    flex: 0 0 auto;
    width: 58.33333%; }
  .row-cols-7-xl > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-8-xl > * {
    flex: 0 0 auto;
    width: 41.66667%; }
  .row-cols-9-xl > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-10-xl > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-11-xl > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .row-cols-12-xl > * {
    flex: 0 0 auto;
    width: 8.33333%; } }

@media (min-width: 1440px) {
  .col-yl {
    flex: 1 0 0%;
    display: block; }
  .row-cols-yl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-1-yl > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-2-yl > * {
    flex: 0 0 auto;
    width: 91.66667%; }
  .row-cols-3-yl > * {
    flex: 0 0 auto;
    width: 83.33333%; }
  .row-cols-4-yl > * {
    flex: 0 0 auto;
    width: 75%; }
  .row-cols-5-yl > * {
    flex: 0 0 auto;
    width: 66.66667%; }
  .row-cols-6-yl > * {
    flex: 0 0 auto;
    width: 58.33333%; }
  .row-cols-7-yl > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-8-yl > * {
    flex: 0 0 auto;
    width: 41.66667%; }
  .row-cols-9-yl > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-10-yl > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-11-yl > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .row-cols-12-yl > * {
    flex: 0 0 auto;
    width: 8.33333%; } }

@media (min-width: 1920px) {
  .col-pl {
    flex: 1 0 0%;
    display: block; }
  .row-cols-pl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-1-pl > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-2-pl > * {
    flex: 0 0 auto;
    width: 91.66667%; }
  .row-cols-3-pl > * {
    flex: 0 0 auto;
    width: 83.33333%; }
  .row-cols-4-pl > * {
    flex: 0 0 auto;
    width: 75%; }
  .row-cols-5-pl > * {
    flex: 0 0 auto;
    width: 66.66667%; }
  .row-cols-6-pl > * {
    flex: 0 0 auto;
    width: 58.33333%; }
  .row-cols-7-pl > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-8-pl > * {
    flex: 0 0 auto;
    width: 41.66667%; }
  .row-cols-9-pl > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-10-pl > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-11-pl > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .row-cols-12-pl > * {
    flex: 0 0 auto;
    width: 8.33333%; } }

.col-f1 {
  flex: 1;
  display: block; }

.col-auto {
  display: block;
  flex: 0 0 auto;
  width: auto; }

.col-none {
  width: 0;
  flex: 0 0 0;
  display: none; }

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%;
  display: block; }

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%;
  display: block; }

.col-3 {
  flex: 0 0 auto;
  width: 25%;
  display: block; }

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%;
  display: block; }

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%;
  display: block; }

.col-6 {
  flex: 0 0 auto;
  width: 50%;
  display: block; }

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%;
  display: block; }

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%;
  display: block; }

.col-9 {
  flex: 0 0 auto;
  width: 75%;
  display: block; }

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%;
  display: block; }

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%;
  display: block; }

.col-12 {
  flex: 0 0 auto;
  width: 100%;
  display: block; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

@media (min-width: 360px) {
  .order-1-ss {
    order: 1; }
  .order-2-ss {
    order: 2; }
  .order-3-ss {
    order: 3; }
  .order-4-ss {
    order: 4; }
  .order-5-ss {
    order: 5; }
  .order-6-ss {
    order: 6; }
  .order-7-ss {
    order: 7; }
  .order-8-ss {
    order: 8; }
  .order-9-ss {
    order: 9; }
  .order-10-ss {
    order: 10; }
  .col-f1-ss {
    display: block;
    flex: 1; } }

@media (min-width: 420px) {
  .order-1-sx {
    order: 1; }
  .order-2-sx {
    order: 2; }
  .order-3-sx {
    order: 3; }
  .order-4-sx {
    order: 4; }
  .order-5-sx {
    order: 5; }
  .order-6-sx {
    order: 6; }
  .order-7-sx {
    order: 7; }
  .order-8-sx {
    order: 8; }
  .order-9-sx {
    order: 9; }
  .order-10-sx {
    order: 10; }
  .col-f1-sx {
    display: block;
    flex: 1; } }

@media (min-width: 576px) {
  .order-1-sm {
    order: 1; }
  .order-2-sm {
    order: 2; }
  .order-3-sm {
    order: 3; }
  .order-4-sm {
    order: 4; }
  .order-5-sm {
    order: 5; }
  .order-6-sm {
    order: 6; }
  .order-7-sm {
    order: 7; }
  .order-8-sm {
    order: 8; }
  .order-9-sm {
    order: 9; }
  .order-10-sm {
    order: 10; }
  .col-f1-sm {
    display: block;
    flex: 1; } }

@media (min-width: 640px) {
  .order-1-md {
    order: 1; }
  .order-2-md {
    order: 2; }
  .order-3-md {
    order: 3; }
  .order-4-md {
    order: 4; }
  .order-5-md {
    order: 5; }
  .order-6-md {
    order: 6; }
  .order-7-md {
    order: 7; }
  .order-8-md {
    order: 8; }
  .order-9-md {
    order: 9; }
  .order-10-md {
    order: 10; }
  .col-f1-md {
    display: block;
    flex: 1; } }

@media (min-width: 768px) {
  .order-1-ml {
    order: 1; }
  .order-2-ml {
    order: 2; }
  .order-3-ml {
    order: 3; }
  .order-4-ml {
    order: 4; }
  .order-5-ml {
    order: 5; }
  .order-6-ml {
    order: 6; }
  .order-7-ml {
    order: 7; }
  .order-8-ml {
    order: 8; }
  .order-9-ml {
    order: 9; }
  .order-10-ml {
    order: 10; }
  .col-f1-ml {
    display: block;
    flex: 1; } }

@media (min-width: 900px) {
  .order-1-ls {
    order: 1; }
  .order-2-ls {
    order: 2; }
  .order-3-ls {
    order: 3; }
  .order-4-ls {
    order: 4; }
  .order-5-ls {
    order: 5; }
  .order-6-ls {
    order: 6; }
  .order-7-ls {
    order: 7; }
  .order-8-ls {
    order: 8; }
  .order-9-ls {
    order: 9; }
  .order-10-ls {
    order: 10; }
  .col-f1-ls {
    display: block;
    flex: 1; } }

@media (min-width: 1024px) {
  .order-1-lg {
    order: 1; }
  .order-2-lg {
    order: 2; }
  .order-3-lg {
    order: 3; }
  .order-4-lg {
    order: 4; }
  .order-5-lg {
    order: 5; }
  .order-6-lg {
    order: 6; }
  .order-7-lg {
    order: 7; }
  .order-8-lg {
    order: 8; }
  .order-9-lg {
    order: 9; }
  .order-10-lg {
    order: 10; }
  .col-f1-lg {
    display: block;
    flex: 1; } }

@media (min-width: 1280px) {
  .order-1-xl {
    order: 1; }
  .order-2-xl {
    order: 2; }
  .order-3-xl {
    order: 3; }
  .order-4-xl {
    order: 4; }
  .order-5-xl {
    order: 5; }
  .order-6-xl {
    order: 6; }
  .order-7-xl {
    order: 7; }
  .order-8-xl {
    order: 8; }
  .order-9-xl {
    order: 9; }
  .order-10-xl {
    order: 10; }
  .col-f1-xl {
    display: block;
    flex: 1; } }

@media (min-width: 1440px) {
  .order-1-yl {
    order: 1; }
  .order-2-yl {
    order: 2; }
  .order-3-yl {
    order: 3; }
  .order-4-yl {
    order: 4; }
  .order-5-yl {
    order: 5; }
  .order-6-yl {
    order: 6; }
  .order-7-yl {
    order: 7; }
  .order-8-yl {
    order: 8; }
  .order-9-yl {
    order: 9; }
  .order-10-yl {
    order: 10; }
  .col-f1-yl {
    display: block;
    flex: 1; } }

@media (min-width: 1920px) {
  .order-1-pl {
    order: 1; }
  .order-2-pl {
    order: 2; }
  .order-3-pl {
    order: 3; }
  .order-4-pl {
    order: 4; }
  .order-5-pl {
    order: 5; }
  .order-6-pl {
    order: 6; }
  .order-7-pl {
    order: 7; }
  .order-8-pl {
    order: 8; }
  .order-9-pl {
    order: 9; }
  .order-10-pl {
    order: 10; }
  .col-f1-pl {
    display: block;
    flex: 1; } }

.offset-0 {
  margin-left: 0%; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

.offset-l-0 {
  margin-left: 0%; }

.offset-l-1 {
  margin-left: 8.33333%; }

.offset-l-2 {
  margin-left: 16.66667%; }

.offset-l-3 {
  margin-left: 25%; }

.offset-l-4 {
  margin-left: 33.33333%; }

.offset-l-5 {
  margin-left: 41.66667%; }

.offset-l-6 {
  margin-left: 50%; }

.offset-l-7 {
  margin-left: 58.33333%; }

.offset-l-8 {
  margin-left: 66.66667%; }

.offset-l-9 {
  margin-left: 75%; }

.offset-l-10 {
  margin-left: 83.33333%; }

.offset-l-11 {
  margin-left: 91.66667%; }

.offset-r-0 {
  margin-right: 0%; }

.offset-r-1 {
  margin-right: 8.33333%; }

.offset-r-2 {
  margin-right: 16.66667%; }

.offset-r-3 {
  margin-right: 25%; }

.offset-r-4 {
  margin-right: 33.33333%; }

.offset-r-5 {
  margin-right: 41.66667%; }

.offset-r-6 {
  margin-right: 50%; }

.offset-r-7 {
  margin-right: 58.33333%; }

.offset-r-8 {
  margin-right: 66.66667%; }

.offset-r-9 {
  margin-right: 75%; }

.offset-r-10 {
  margin-right: 83.33333%; }

.offset-r-11 {
  margin-right: 91.66667%; }

.gx-0 {
  --bs-gutter-x: 0px; }

.gy-0 {
  --bs-gutter-y: 0px; }

.gx-1 {
  --bs-gutter-x: 1px; }

.gy-1 {
  --bs-gutter-y: 1px; }

.gx-2 {
  --bs-gutter-x: 2px; }

.gy-2 {
  --bs-gutter-y: 2px; }

.gx-10 {
  --bs-gutter-x: 10px; }

.gy-10 {
  --bs-gutter-y: 10px; }

.gx-12 {
  --bs-gutter-x: 12px; }

.gy-12 {
  --bs-gutter-y: 12px; }

.gx-16 {
  --bs-gutter-x: 16px; }

.gy-16 {
  --bs-gutter-y: 16px; }

.gx-20 {
  --bs-gutter-x: 20px; }

.gy-20 {
  --bs-gutter-y: 20px; }

.gx-22 {
  --bs-gutter-x: 22px; }

.gy-22 {
  --bs-gutter-y: 22px; }

.gx-24 {
  --bs-gutter-x: 24px; }

.gy-24 {
  --bs-gutter-y: 24px; }

.gx-30 {
  --bs-gutter-x: 30px; }

.gy-30 {
  --bs-gutter-y: 30px; }

.gx-32 {
  --bs-gutter-x: 32px; }

.gy-32 {
  --bs-gutter-y: 32px; }

.gx-34 {
  --bs-gutter-x: 34px; }

.gy-34 {
  --bs-gutter-y: 34px; }

.gx-36 {
  --bs-gutter-x: 36px; }

.gy-36 {
  --bs-gutter-y: 36px; }

.gx-40 {
  --bs-gutter-x: 40px; }

.gy-40 {
  --bs-gutter-y: 40px; }

.gx-42 {
  --bs-gutter-x: 42px; }

.gy-42 {
  --bs-gutter-y: 42px; }

.gx-46 {
  --bs-gutter-x: 46px; }

.gy-46 {
  --bs-gutter-y: 46px; }

.gx-48 {
  --bs-gutter-x: 48px; }

.gy-48 {
  --bs-gutter-y: 48px; }

.gx-50 {
  --bs-gutter-x: 50px; }

.gy-50 {
  --bs-gutter-y: 50px; }

.gx-54 {
  --bs-gutter-x: 54px; }

.gy-54 {
  --bs-gutter-y: 54px; }

.gx-56 {
  --bs-gutter-x: 56px; }

.gy-56 {
  --bs-gutter-y: 56px; }

.gx-60 {
  --bs-gutter-x: 60px; }

.gy-60 {
  --bs-gutter-y: 60px; }

.gx-64 {
  --bs-gutter-x: 64px; }

.gy-64 {
  --bs-gutter-y: 64px; }

.gx-70 {
  --bs-gutter-x: 70px; }

.gy-70 {
  --bs-gutter-y: 70px; }

.gx-75 {
  --bs-gutter-x: 75px; }

.gy-75 {
  --bs-gutter-y: 75px; }

.gx-80 {
  --bs-gutter-x: 80px; }

.gy-80 {
  --bs-gutter-y: 80px; }

.gx-90 {
  --bs-gutter-x: 90px; }

.gy-90 {
  --bs-gutter-y: 90px; }

.gx-100 {
  --bs-gutter-x: 100px; }

.gy-100 {
  --bs-gutter-y: 100px; }

@media (min-width: 360px) {
  .col-auto-ss {
    flex: 0 0 auto;
    width: auto;
    display: block; }
  .col-none-ss {
    flex: 0 0 0;
    width: 0;
    display: none; }
  .col-1-ss {
    flex: 0 0 auto;
    width: 8.33333%;
    display: block; }
  .col-2-ss {
    flex: 0 0 auto;
    width: 16.66667%;
    display: block; }
  .col-3-ss {
    flex: 0 0 auto;
    width: 25%;
    display: block; }
  .col-4-ss {
    flex: 0 0 auto;
    width: 33.33333%;
    display: block; }
  .col-5-ss {
    flex: 0 0 auto;
    width: 41.66667%;
    display: block; }
  .col-6-ss {
    flex: 0 0 auto;
    width: 50%;
    display: block; }
  .col-7-ss {
    flex: 0 0 auto;
    width: 58.33333%;
    display: block; }
  .col-8-ss {
    flex: 0 0 auto;
    width: 66.66667%;
    display: block; }
  .col-9-ss {
    flex: 0 0 auto;
    width: 75%;
    display: block; }
  .col-10-ss {
    flex: 0 0 auto;
    width: 83.33333%;
    display: block; }
  .col-11-ss {
    flex: 0 0 auto;
    width: 91.66667%;
    display: block; }
  .col-12-ss {
    flex: 0 0 auto;
    width: 100%;
    display: block; }
  .offset-0-ss > * {
    margin-left: 0%; }
  .offset-1-ss > * {
    margin-left: 8.33333%; }
  .offset-2-ss > * {
    margin-left: 16.66667%; }
  .offset-3-ss > * {
    margin-left: 25%; }
  .offset-4-ss > * {
    margin-left: 33.33333%; }
  .offset-5-ss > * {
    margin-left: 41.66667%; }
  .offset-6-ss > * {
    margin-left: 50%; }
  .offset-7-ss > * {
    margin-left: 58.33333%; }
  .offset-8-ss > * {
    margin-left: 66.66667%; }
  .offset-9-ss > * {
    margin-left: 75%; }
  .offset-10-ss > * {
    margin-left: 83.33333%; }
  .offset-11-ss > * {
    margin-left: 91.66667%; }
  .gx-0-ss {
    --bs-gutter-x: 0px; }
  .gy-0-ss {
    --bs-gutter-y: 0px; }
  .gx-1-ss {
    --bs-gutter-x: 1px; }
  .gy-1-ss {
    --bs-gutter-y: 1px; }
  .gx-2-ss {
    --bs-gutter-x: 2px; }
  .gy-2-ss {
    --bs-gutter-y: 2px; }
  .gx-10-ss {
    --bs-gutter-x: 10px; }
  .gy-10-ss {
    --bs-gutter-y: 10px; }
  .gx-12-ss {
    --bs-gutter-x: 12px; }
  .gy-12-ss {
    --bs-gutter-y: 12px; }
  .gx-16-ss {
    --bs-gutter-x: 16px; }
  .gy-16-ss {
    --bs-gutter-y: 16px; }
  .gx-20-ss {
    --bs-gutter-x: 20px; }
  .gy-20-ss {
    --bs-gutter-y: 20px; }
  .gx-22-ss {
    --bs-gutter-x: 22px; }
  .gy-22-ss {
    --bs-gutter-y: 22px; }
  .gx-24-ss {
    --bs-gutter-x: 24px; }
  .gy-24-ss {
    --bs-gutter-y: 24px; }
  .gx-30-ss {
    --bs-gutter-x: 30px; }
  .gy-30-ss {
    --bs-gutter-y: 30px; }
  .gx-32-ss {
    --bs-gutter-x: 32px; }
  .gy-32-ss {
    --bs-gutter-y: 32px; }
  .gx-34-ss {
    --bs-gutter-x: 34px; }
  .gy-34-ss {
    --bs-gutter-y: 34px; }
  .gx-36-ss {
    --bs-gutter-x: 36px; }
  .gy-36-ss {
    --bs-gutter-y: 36px; }
  .gx-40-ss {
    --bs-gutter-x: 40px; }
  .gy-40-ss {
    --bs-gutter-y: 40px; }
  .gx-42-ss {
    --bs-gutter-x: 42px; }
  .gy-42-ss {
    --bs-gutter-y: 42px; }
  .gx-46-ss {
    --bs-gutter-x: 46px; }
  .gy-46-ss {
    --bs-gutter-y: 46px; }
  .gx-48-ss {
    --bs-gutter-x: 48px; }
  .gy-48-ss {
    --bs-gutter-y: 48px; }
  .gx-50-ss {
    --bs-gutter-x: 50px; }
  .gy-50-ss {
    --bs-gutter-y: 50px; }
  .gx-54-ss {
    --bs-gutter-x: 54px; }
  .gy-54-ss {
    --bs-gutter-y: 54px; }
  .gx-56-ss {
    --bs-gutter-x: 56px; }
  .gy-56-ss {
    --bs-gutter-y: 56px; }
  .gx-60-ss {
    --bs-gutter-x: 60px; }
  .gy-60-ss {
    --bs-gutter-y: 60px; }
  .gx-64-ss {
    --bs-gutter-x: 64px; }
  .gy-64-ss {
    --bs-gutter-y: 64px; }
  .gx-70-ss {
    --bs-gutter-x: 70px; }
  .gy-70-ss {
    --bs-gutter-y: 70px; }
  .gx-75-ss {
    --bs-gutter-x: 75px; }
  .gy-75-ss {
    --bs-gutter-y: 75px; }
  .gx-80-ss {
    --bs-gutter-x: 80px; }
  .gy-80-ss {
    --bs-gutter-y: 80px; }
  .gx-90-ss {
    --bs-gutter-x: 90px; }
  .gy-90-ss {
    --bs-gutter-y: 90px; }
  .gx-100-ss {
    --bs-gutter-x: 100px; }
  .gy-100-ss {
    --bs-gutter-y: 100px; } }

@media (min-width: 420px) {
  .col-auto-sx {
    flex: 0 0 auto;
    width: auto;
    display: block; }
  .col-none-sx {
    flex: 0 0 0;
    width: 0;
    display: none; }
  .col-1-sx {
    flex: 0 0 auto;
    width: 8.33333%;
    display: block; }
  .col-2-sx {
    flex: 0 0 auto;
    width: 16.66667%;
    display: block; }
  .col-3-sx {
    flex: 0 0 auto;
    width: 25%;
    display: block; }
  .col-4-sx {
    flex: 0 0 auto;
    width: 33.33333%;
    display: block; }
  .col-5-sx {
    flex: 0 0 auto;
    width: 41.66667%;
    display: block; }
  .col-6-sx {
    flex: 0 0 auto;
    width: 50%;
    display: block; }
  .col-7-sx {
    flex: 0 0 auto;
    width: 58.33333%;
    display: block; }
  .col-8-sx {
    flex: 0 0 auto;
    width: 66.66667%;
    display: block; }
  .col-9-sx {
    flex: 0 0 auto;
    width: 75%;
    display: block; }
  .col-10-sx {
    flex: 0 0 auto;
    width: 83.33333%;
    display: block; }
  .col-11-sx {
    flex: 0 0 auto;
    width: 91.66667%;
    display: block; }
  .col-12-sx {
    flex: 0 0 auto;
    width: 100%;
    display: block; }
  .offset-0-sx > * {
    margin-left: 0%; }
  .offset-1-sx > * {
    margin-left: 8.33333%; }
  .offset-2-sx > * {
    margin-left: 16.66667%; }
  .offset-3-sx > * {
    margin-left: 25%; }
  .offset-4-sx > * {
    margin-left: 33.33333%; }
  .offset-5-sx > * {
    margin-left: 41.66667%; }
  .offset-6-sx > * {
    margin-left: 50%; }
  .offset-7-sx > * {
    margin-left: 58.33333%; }
  .offset-8-sx > * {
    margin-left: 66.66667%; }
  .offset-9-sx > * {
    margin-left: 75%; }
  .offset-10-sx > * {
    margin-left: 83.33333%; }
  .offset-11-sx > * {
    margin-left: 91.66667%; }
  .gx-0-sx {
    --bs-gutter-x: 0px; }
  .gy-0-sx {
    --bs-gutter-y: 0px; }
  .gx-1-sx {
    --bs-gutter-x: 1px; }
  .gy-1-sx {
    --bs-gutter-y: 1px; }
  .gx-2-sx {
    --bs-gutter-x: 2px; }
  .gy-2-sx {
    --bs-gutter-y: 2px; }
  .gx-10-sx {
    --bs-gutter-x: 10px; }
  .gy-10-sx {
    --bs-gutter-y: 10px; }
  .gx-12-sx {
    --bs-gutter-x: 12px; }
  .gy-12-sx {
    --bs-gutter-y: 12px; }
  .gx-16-sx {
    --bs-gutter-x: 16px; }
  .gy-16-sx {
    --bs-gutter-y: 16px; }
  .gx-20-sx {
    --bs-gutter-x: 20px; }
  .gy-20-sx {
    --bs-gutter-y: 20px; }
  .gx-22-sx {
    --bs-gutter-x: 22px; }
  .gy-22-sx {
    --bs-gutter-y: 22px; }
  .gx-24-sx {
    --bs-gutter-x: 24px; }
  .gy-24-sx {
    --bs-gutter-y: 24px; }
  .gx-30-sx {
    --bs-gutter-x: 30px; }
  .gy-30-sx {
    --bs-gutter-y: 30px; }
  .gx-32-sx {
    --bs-gutter-x: 32px; }
  .gy-32-sx {
    --bs-gutter-y: 32px; }
  .gx-34-sx {
    --bs-gutter-x: 34px; }
  .gy-34-sx {
    --bs-gutter-y: 34px; }
  .gx-36-sx {
    --bs-gutter-x: 36px; }
  .gy-36-sx {
    --bs-gutter-y: 36px; }
  .gx-40-sx {
    --bs-gutter-x: 40px; }
  .gy-40-sx {
    --bs-gutter-y: 40px; }
  .gx-42-sx {
    --bs-gutter-x: 42px; }
  .gy-42-sx {
    --bs-gutter-y: 42px; }
  .gx-46-sx {
    --bs-gutter-x: 46px; }
  .gy-46-sx {
    --bs-gutter-y: 46px; }
  .gx-48-sx {
    --bs-gutter-x: 48px; }
  .gy-48-sx {
    --bs-gutter-y: 48px; }
  .gx-50-sx {
    --bs-gutter-x: 50px; }
  .gy-50-sx {
    --bs-gutter-y: 50px; }
  .gx-54-sx {
    --bs-gutter-x: 54px; }
  .gy-54-sx {
    --bs-gutter-y: 54px; }
  .gx-56-sx {
    --bs-gutter-x: 56px; }
  .gy-56-sx {
    --bs-gutter-y: 56px; }
  .gx-60-sx {
    --bs-gutter-x: 60px; }
  .gy-60-sx {
    --bs-gutter-y: 60px; }
  .gx-64-sx {
    --bs-gutter-x: 64px; }
  .gy-64-sx {
    --bs-gutter-y: 64px; }
  .gx-70-sx {
    --bs-gutter-x: 70px; }
  .gy-70-sx {
    --bs-gutter-y: 70px; }
  .gx-75-sx {
    --bs-gutter-x: 75px; }
  .gy-75-sx {
    --bs-gutter-y: 75px; }
  .gx-80-sx {
    --bs-gutter-x: 80px; }
  .gy-80-sx {
    --bs-gutter-y: 80px; }
  .gx-90-sx {
    --bs-gutter-x: 90px; }
  .gy-90-sx {
    --bs-gutter-y: 90px; }
  .gx-100-sx {
    --bs-gutter-x: 100px; }
  .gy-100-sx {
    --bs-gutter-y: 100px; } }

@media (min-width: 576px) {
  .col-auto-sm {
    flex: 0 0 auto;
    width: auto;
    display: block; }
  .col-none-sm {
    flex: 0 0 0;
    width: 0;
    display: none; }
  .col-1-sm {
    flex: 0 0 auto;
    width: 8.33333%;
    display: block; }
  .col-2-sm {
    flex: 0 0 auto;
    width: 16.66667%;
    display: block; }
  .col-3-sm {
    flex: 0 0 auto;
    width: 25%;
    display: block; }
  .col-4-sm {
    flex: 0 0 auto;
    width: 33.33333%;
    display: block; }
  .col-5-sm {
    flex: 0 0 auto;
    width: 41.66667%;
    display: block; }
  .col-6-sm {
    flex: 0 0 auto;
    width: 50%;
    display: block; }
  .col-7-sm {
    flex: 0 0 auto;
    width: 58.33333%;
    display: block; }
  .col-8-sm {
    flex: 0 0 auto;
    width: 66.66667%;
    display: block; }
  .col-9-sm {
    flex: 0 0 auto;
    width: 75%;
    display: block; }
  .col-10-sm {
    flex: 0 0 auto;
    width: 83.33333%;
    display: block; }
  .col-11-sm {
    flex: 0 0 auto;
    width: 91.66667%;
    display: block; }
  .col-12-sm {
    flex: 0 0 auto;
    width: 100%;
    display: block; }
  .offset-0-sm > * {
    margin-left: 0%; }
  .offset-1-sm > * {
    margin-left: 8.33333%; }
  .offset-2-sm > * {
    margin-left: 16.66667%; }
  .offset-3-sm > * {
    margin-left: 25%; }
  .offset-4-sm > * {
    margin-left: 33.33333%; }
  .offset-5-sm > * {
    margin-left: 41.66667%; }
  .offset-6-sm > * {
    margin-left: 50%; }
  .offset-7-sm > * {
    margin-left: 58.33333%; }
  .offset-8-sm > * {
    margin-left: 66.66667%; }
  .offset-9-sm > * {
    margin-left: 75%; }
  .offset-10-sm > * {
    margin-left: 83.33333%; }
  .offset-11-sm > * {
    margin-left: 91.66667%; }
  .gx-0-sm {
    --bs-gutter-x: 0px; }
  .gy-0-sm {
    --bs-gutter-y: 0px; }
  .gx-1-sm {
    --bs-gutter-x: 1px; }
  .gy-1-sm {
    --bs-gutter-y: 1px; }
  .gx-2-sm {
    --bs-gutter-x: 2px; }
  .gy-2-sm {
    --bs-gutter-y: 2px; }
  .gx-10-sm {
    --bs-gutter-x: 10px; }
  .gy-10-sm {
    --bs-gutter-y: 10px; }
  .gx-12-sm {
    --bs-gutter-x: 12px; }
  .gy-12-sm {
    --bs-gutter-y: 12px; }
  .gx-16-sm {
    --bs-gutter-x: 16px; }
  .gy-16-sm {
    --bs-gutter-y: 16px; }
  .gx-20-sm {
    --bs-gutter-x: 20px; }
  .gy-20-sm {
    --bs-gutter-y: 20px; }
  .gx-22-sm {
    --bs-gutter-x: 22px; }
  .gy-22-sm {
    --bs-gutter-y: 22px; }
  .gx-24-sm {
    --bs-gutter-x: 24px; }
  .gy-24-sm {
    --bs-gutter-y: 24px; }
  .gx-30-sm {
    --bs-gutter-x: 30px; }
  .gy-30-sm {
    --bs-gutter-y: 30px; }
  .gx-32-sm {
    --bs-gutter-x: 32px; }
  .gy-32-sm {
    --bs-gutter-y: 32px; }
  .gx-34-sm {
    --bs-gutter-x: 34px; }
  .gy-34-sm {
    --bs-gutter-y: 34px; }
  .gx-36-sm {
    --bs-gutter-x: 36px; }
  .gy-36-sm {
    --bs-gutter-y: 36px; }
  .gx-40-sm {
    --bs-gutter-x: 40px; }
  .gy-40-sm {
    --bs-gutter-y: 40px; }
  .gx-42-sm {
    --bs-gutter-x: 42px; }
  .gy-42-sm {
    --bs-gutter-y: 42px; }
  .gx-46-sm {
    --bs-gutter-x: 46px; }
  .gy-46-sm {
    --bs-gutter-y: 46px; }
  .gx-48-sm {
    --bs-gutter-x: 48px; }
  .gy-48-sm {
    --bs-gutter-y: 48px; }
  .gx-50-sm {
    --bs-gutter-x: 50px; }
  .gy-50-sm {
    --bs-gutter-y: 50px; }
  .gx-54-sm {
    --bs-gutter-x: 54px; }
  .gy-54-sm {
    --bs-gutter-y: 54px; }
  .gx-56-sm {
    --bs-gutter-x: 56px; }
  .gy-56-sm {
    --bs-gutter-y: 56px; }
  .gx-60-sm {
    --bs-gutter-x: 60px; }
  .gy-60-sm {
    --bs-gutter-y: 60px; }
  .gx-64-sm {
    --bs-gutter-x: 64px; }
  .gy-64-sm {
    --bs-gutter-y: 64px; }
  .gx-70-sm {
    --bs-gutter-x: 70px; }
  .gy-70-sm {
    --bs-gutter-y: 70px; }
  .gx-75-sm {
    --bs-gutter-x: 75px; }
  .gy-75-sm {
    --bs-gutter-y: 75px; }
  .gx-80-sm {
    --bs-gutter-x: 80px; }
  .gy-80-sm {
    --bs-gutter-y: 80px; }
  .gx-90-sm {
    --bs-gutter-x: 90px; }
  .gy-90-sm {
    --bs-gutter-y: 90px; }
  .gx-100-sm {
    --bs-gutter-x: 100px; }
  .gy-100-sm {
    --bs-gutter-y: 100px; } }

@media (min-width: 640px) {
  .col-auto-md {
    flex: 0 0 auto;
    width: auto;
    display: block; }
  .col-none-md {
    flex: 0 0 0;
    width: 0;
    display: none; }
  .col-1-md {
    flex: 0 0 auto;
    width: 8.33333%;
    display: block; }
  .col-2-md {
    flex: 0 0 auto;
    width: 16.66667%;
    display: block; }
  .col-3-md {
    flex: 0 0 auto;
    width: 25%;
    display: block; }
  .col-4-md {
    flex: 0 0 auto;
    width: 33.33333%;
    display: block; }
  .col-5-md {
    flex: 0 0 auto;
    width: 41.66667%;
    display: block; }
  .col-6-md {
    flex: 0 0 auto;
    width: 50%;
    display: block; }
  .col-7-md {
    flex: 0 0 auto;
    width: 58.33333%;
    display: block; }
  .col-8-md {
    flex: 0 0 auto;
    width: 66.66667%;
    display: block; }
  .col-9-md {
    flex: 0 0 auto;
    width: 75%;
    display: block; }
  .col-10-md {
    flex: 0 0 auto;
    width: 83.33333%;
    display: block; }
  .col-11-md {
    flex: 0 0 auto;
    width: 91.66667%;
    display: block; }
  .col-12-md {
    flex: 0 0 auto;
    width: 100%;
    display: block; }
  .offset-0-md > * {
    margin-left: 0%; }
  .offset-1-md > * {
    margin-left: 8.33333%; }
  .offset-2-md > * {
    margin-left: 16.66667%; }
  .offset-3-md > * {
    margin-left: 25%; }
  .offset-4-md > * {
    margin-left: 33.33333%; }
  .offset-5-md > * {
    margin-left: 41.66667%; }
  .offset-6-md > * {
    margin-left: 50%; }
  .offset-7-md > * {
    margin-left: 58.33333%; }
  .offset-8-md > * {
    margin-left: 66.66667%; }
  .offset-9-md > * {
    margin-left: 75%; }
  .offset-10-md > * {
    margin-left: 83.33333%; }
  .offset-11-md > * {
    margin-left: 91.66667%; }
  .gx-0-md {
    --bs-gutter-x: 0px; }
  .gy-0-md {
    --bs-gutter-y: 0px; }
  .gx-1-md {
    --bs-gutter-x: 1px; }
  .gy-1-md {
    --bs-gutter-y: 1px; }
  .gx-2-md {
    --bs-gutter-x: 2px; }
  .gy-2-md {
    --bs-gutter-y: 2px; }
  .gx-10-md {
    --bs-gutter-x: 10px; }
  .gy-10-md {
    --bs-gutter-y: 10px; }
  .gx-12-md {
    --bs-gutter-x: 12px; }
  .gy-12-md {
    --bs-gutter-y: 12px; }
  .gx-16-md {
    --bs-gutter-x: 16px; }
  .gy-16-md {
    --bs-gutter-y: 16px; }
  .gx-20-md {
    --bs-gutter-x: 20px; }
  .gy-20-md {
    --bs-gutter-y: 20px; }
  .gx-22-md {
    --bs-gutter-x: 22px; }
  .gy-22-md {
    --bs-gutter-y: 22px; }
  .gx-24-md {
    --bs-gutter-x: 24px; }
  .gy-24-md {
    --bs-gutter-y: 24px; }
  .gx-30-md {
    --bs-gutter-x: 30px; }
  .gy-30-md {
    --bs-gutter-y: 30px; }
  .gx-32-md {
    --bs-gutter-x: 32px; }
  .gy-32-md {
    --bs-gutter-y: 32px; }
  .gx-34-md {
    --bs-gutter-x: 34px; }
  .gy-34-md {
    --bs-gutter-y: 34px; }
  .gx-36-md {
    --bs-gutter-x: 36px; }
  .gy-36-md {
    --bs-gutter-y: 36px; }
  .gx-40-md {
    --bs-gutter-x: 40px; }
  .gy-40-md {
    --bs-gutter-y: 40px; }
  .gx-42-md {
    --bs-gutter-x: 42px; }
  .gy-42-md {
    --bs-gutter-y: 42px; }
  .gx-46-md {
    --bs-gutter-x: 46px; }
  .gy-46-md {
    --bs-gutter-y: 46px; }
  .gx-48-md {
    --bs-gutter-x: 48px; }
  .gy-48-md {
    --bs-gutter-y: 48px; }
  .gx-50-md {
    --bs-gutter-x: 50px; }
  .gy-50-md {
    --bs-gutter-y: 50px; }
  .gx-54-md {
    --bs-gutter-x: 54px; }
  .gy-54-md {
    --bs-gutter-y: 54px; }
  .gx-56-md {
    --bs-gutter-x: 56px; }
  .gy-56-md {
    --bs-gutter-y: 56px; }
  .gx-60-md {
    --bs-gutter-x: 60px; }
  .gy-60-md {
    --bs-gutter-y: 60px; }
  .gx-64-md {
    --bs-gutter-x: 64px; }
  .gy-64-md {
    --bs-gutter-y: 64px; }
  .gx-70-md {
    --bs-gutter-x: 70px; }
  .gy-70-md {
    --bs-gutter-y: 70px; }
  .gx-75-md {
    --bs-gutter-x: 75px; }
  .gy-75-md {
    --bs-gutter-y: 75px; }
  .gx-80-md {
    --bs-gutter-x: 80px; }
  .gy-80-md {
    --bs-gutter-y: 80px; }
  .gx-90-md {
    --bs-gutter-x: 90px; }
  .gy-90-md {
    --bs-gutter-y: 90px; }
  .gx-100-md {
    --bs-gutter-x: 100px; }
  .gy-100-md {
    --bs-gutter-y: 100px; } }

@media (min-width: 768px) {
  .col-auto-ml {
    flex: 0 0 auto;
    width: auto;
    display: block; }
  .col-none-ml {
    flex: 0 0 0;
    width: 0;
    display: none; }
  .col-1-ml {
    flex: 0 0 auto;
    width: 8.33333%;
    display: block; }
  .col-2-ml {
    flex: 0 0 auto;
    width: 16.66667%;
    display: block; }
  .col-3-ml {
    flex: 0 0 auto;
    width: 25%;
    display: block; }
  .col-4-ml {
    flex: 0 0 auto;
    width: 33.33333%;
    display: block; }
  .col-5-ml {
    flex: 0 0 auto;
    width: 41.66667%;
    display: block; }
  .col-6-ml {
    flex: 0 0 auto;
    width: 50%;
    display: block; }
  .col-7-ml {
    flex: 0 0 auto;
    width: 58.33333%;
    display: block; }
  .col-8-ml {
    flex: 0 0 auto;
    width: 66.66667%;
    display: block; }
  .col-9-ml {
    flex: 0 0 auto;
    width: 75%;
    display: block; }
  .col-10-ml {
    flex: 0 0 auto;
    width: 83.33333%;
    display: block; }
  .col-11-ml {
    flex: 0 0 auto;
    width: 91.66667%;
    display: block; }
  .col-12-ml {
    flex: 0 0 auto;
    width: 100%;
    display: block; }
  .offset-0-ml > * {
    margin-left: 0%; }
  .offset-1-ml > * {
    margin-left: 8.33333%; }
  .offset-2-ml > * {
    margin-left: 16.66667%; }
  .offset-3-ml > * {
    margin-left: 25%; }
  .offset-4-ml > * {
    margin-left: 33.33333%; }
  .offset-5-ml > * {
    margin-left: 41.66667%; }
  .offset-6-ml > * {
    margin-left: 50%; }
  .offset-7-ml > * {
    margin-left: 58.33333%; }
  .offset-8-ml > * {
    margin-left: 66.66667%; }
  .offset-9-ml > * {
    margin-left: 75%; }
  .offset-10-ml > * {
    margin-left: 83.33333%; }
  .offset-11-ml > * {
    margin-left: 91.66667%; }
  .gx-0-ml {
    --bs-gutter-x: 0px; }
  .gy-0-ml {
    --bs-gutter-y: 0px; }
  .gx-1-ml {
    --bs-gutter-x: 1px; }
  .gy-1-ml {
    --bs-gutter-y: 1px; }
  .gx-2-ml {
    --bs-gutter-x: 2px; }
  .gy-2-ml {
    --bs-gutter-y: 2px; }
  .gx-10-ml {
    --bs-gutter-x: 10px; }
  .gy-10-ml {
    --bs-gutter-y: 10px; }
  .gx-12-ml {
    --bs-gutter-x: 12px; }
  .gy-12-ml {
    --bs-gutter-y: 12px; }
  .gx-16-ml {
    --bs-gutter-x: 16px; }
  .gy-16-ml {
    --bs-gutter-y: 16px; }
  .gx-20-ml {
    --bs-gutter-x: 20px; }
  .gy-20-ml {
    --bs-gutter-y: 20px; }
  .gx-22-ml {
    --bs-gutter-x: 22px; }
  .gy-22-ml {
    --bs-gutter-y: 22px; }
  .gx-24-ml {
    --bs-gutter-x: 24px; }
  .gy-24-ml {
    --bs-gutter-y: 24px; }
  .gx-30-ml {
    --bs-gutter-x: 30px; }
  .gy-30-ml {
    --bs-gutter-y: 30px; }
  .gx-32-ml {
    --bs-gutter-x: 32px; }
  .gy-32-ml {
    --bs-gutter-y: 32px; }
  .gx-34-ml {
    --bs-gutter-x: 34px; }
  .gy-34-ml {
    --bs-gutter-y: 34px; }
  .gx-36-ml {
    --bs-gutter-x: 36px; }
  .gy-36-ml {
    --bs-gutter-y: 36px; }
  .gx-40-ml {
    --bs-gutter-x: 40px; }
  .gy-40-ml {
    --bs-gutter-y: 40px; }
  .gx-42-ml {
    --bs-gutter-x: 42px; }
  .gy-42-ml {
    --bs-gutter-y: 42px; }
  .gx-46-ml {
    --bs-gutter-x: 46px; }
  .gy-46-ml {
    --bs-gutter-y: 46px; }
  .gx-48-ml {
    --bs-gutter-x: 48px; }
  .gy-48-ml {
    --bs-gutter-y: 48px; }
  .gx-50-ml {
    --bs-gutter-x: 50px; }
  .gy-50-ml {
    --bs-gutter-y: 50px; }
  .gx-54-ml {
    --bs-gutter-x: 54px; }
  .gy-54-ml {
    --bs-gutter-y: 54px; }
  .gx-56-ml {
    --bs-gutter-x: 56px; }
  .gy-56-ml {
    --bs-gutter-y: 56px; }
  .gx-60-ml {
    --bs-gutter-x: 60px; }
  .gy-60-ml {
    --bs-gutter-y: 60px; }
  .gx-64-ml {
    --bs-gutter-x: 64px; }
  .gy-64-ml {
    --bs-gutter-y: 64px; }
  .gx-70-ml {
    --bs-gutter-x: 70px; }
  .gy-70-ml {
    --bs-gutter-y: 70px; }
  .gx-75-ml {
    --bs-gutter-x: 75px; }
  .gy-75-ml {
    --bs-gutter-y: 75px; }
  .gx-80-ml {
    --bs-gutter-x: 80px; }
  .gy-80-ml {
    --bs-gutter-y: 80px; }
  .gx-90-ml {
    --bs-gutter-x: 90px; }
  .gy-90-ml {
    --bs-gutter-y: 90px; }
  .gx-100-ml {
    --bs-gutter-x: 100px; }
  .gy-100-ml {
    --bs-gutter-y: 100px; } }

@media (min-width: 900px) {
  .col-auto-ls {
    flex: 0 0 auto;
    width: auto;
    display: block; }
  .col-none-ls {
    flex: 0 0 0;
    width: 0;
    display: none; }
  .col-1-ls {
    flex: 0 0 auto;
    width: 8.33333%;
    display: block; }
  .col-2-ls {
    flex: 0 0 auto;
    width: 16.66667%;
    display: block; }
  .col-3-ls {
    flex: 0 0 auto;
    width: 25%;
    display: block; }
  .col-4-ls {
    flex: 0 0 auto;
    width: 33.33333%;
    display: block; }
  .col-5-ls {
    flex: 0 0 auto;
    width: 41.66667%;
    display: block; }
  .col-6-ls {
    flex: 0 0 auto;
    width: 50%;
    display: block; }
  .col-7-ls {
    flex: 0 0 auto;
    width: 58.33333%;
    display: block; }
  .col-8-ls {
    flex: 0 0 auto;
    width: 66.66667%;
    display: block; }
  .col-9-ls {
    flex: 0 0 auto;
    width: 75%;
    display: block; }
  .col-10-ls {
    flex: 0 0 auto;
    width: 83.33333%;
    display: block; }
  .col-11-ls {
    flex: 0 0 auto;
    width: 91.66667%;
    display: block; }
  .col-12-ls {
    flex: 0 0 auto;
    width: 100%;
    display: block; }
  .offset-0-ls > * {
    margin-left: 0%; }
  .offset-1-ls > * {
    margin-left: 8.33333%; }
  .offset-2-ls > * {
    margin-left: 16.66667%; }
  .offset-3-ls > * {
    margin-left: 25%; }
  .offset-4-ls > * {
    margin-left: 33.33333%; }
  .offset-5-ls > * {
    margin-left: 41.66667%; }
  .offset-6-ls > * {
    margin-left: 50%; }
  .offset-7-ls > * {
    margin-left: 58.33333%; }
  .offset-8-ls > * {
    margin-left: 66.66667%; }
  .offset-9-ls > * {
    margin-left: 75%; }
  .offset-10-ls > * {
    margin-left: 83.33333%; }
  .offset-11-ls > * {
    margin-left: 91.66667%; }
  .gx-0-ls {
    --bs-gutter-x: 0px; }
  .gy-0-ls {
    --bs-gutter-y: 0px; }
  .gx-1-ls {
    --bs-gutter-x: 1px; }
  .gy-1-ls {
    --bs-gutter-y: 1px; }
  .gx-2-ls {
    --bs-gutter-x: 2px; }
  .gy-2-ls {
    --bs-gutter-y: 2px; }
  .gx-10-ls {
    --bs-gutter-x: 10px; }
  .gy-10-ls {
    --bs-gutter-y: 10px; }
  .gx-12-ls {
    --bs-gutter-x: 12px; }
  .gy-12-ls {
    --bs-gutter-y: 12px; }
  .gx-16-ls {
    --bs-gutter-x: 16px; }
  .gy-16-ls {
    --bs-gutter-y: 16px; }
  .gx-20-ls {
    --bs-gutter-x: 20px; }
  .gy-20-ls {
    --bs-gutter-y: 20px; }
  .gx-22-ls {
    --bs-gutter-x: 22px; }
  .gy-22-ls {
    --bs-gutter-y: 22px; }
  .gx-24-ls {
    --bs-gutter-x: 24px; }
  .gy-24-ls {
    --bs-gutter-y: 24px; }
  .gx-30-ls {
    --bs-gutter-x: 30px; }
  .gy-30-ls {
    --bs-gutter-y: 30px; }
  .gx-32-ls {
    --bs-gutter-x: 32px; }
  .gy-32-ls {
    --bs-gutter-y: 32px; }
  .gx-34-ls {
    --bs-gutter-x: 34px; }
  .gy-34-ls {
    --bs-gutter-y: 34px; }
  .gx-36-ls {
    --bs-gutter-x: 36px; }
  .gy-36-ls {
    --bs-gutter-y: 36px; }
  .gx-40-ls {
    --bs-gutter-x: 40px; }
  .gy-40-ls {
    --bs-gutter-y: 40px; }
  .gx-42-ls {
    --bs-gutter-x: 42px; }
  .gy-42-ls {
    --bs-gutter-y: 42px; }
  .gx-46-ls {
    --bs-gutter-x: 46px; }
  .gy-46-ls {
    --bs-gutter-y: 46px; }
  .gx-48-ls {
    --bs-gutter-x: 48px; }
  .gy-48-ls {
    --bs-gutter-y: 48px; }
  .gx-50-ls {
    --bs-gutter-x: 50px; }
  .gy-50-ls {
    --bs-gutter-y: 50px; }
  .gx-54-ls {
    --bs-gutter-x: 54px; }
  .gy-54-ls {
    --bs-gutter-y: 54px; }
  .gx-56-ls {
    --bs-gutter-x: 56px; }
  .gy-56-ls {
    --bs-gutter-y: 56px; }
  .gx-60-ls {
    --bs-gutter-x: 60px; }
  .gy-60-ls {
    --bs-gutter-y: 60px; }
  .gx-64-ls {
    --bs-gutter-x: 64px; }
  .gy-64-ls {
    --bs-gutter-y: 64px; }
  .gx-70-ls {
    --bs-gutter-x: 70px; }
  .gy-70-ls {
    --bs-gutter-y: 70px; }
  .gx-75-ls {
    --bs-gutter-x: 75px; }
  .gy-75-ls {
    --bs-gutter-y: 75px; }
  .gx-80-ls {
    --bs-gutter-x: 80px; }
  .gy-80-ls {
    --bs-gutter-y: 80px; }
  .gx-90-ls {
    --bs-gutter-x: 90px; }
  .gy-90-ls {
    --bs-gutter-y: 90px; }
  .gx-100-ls {
    --bs-gutter-x: 100px; }
  .gy-100-ls {
    --bs-gutter-y: 100px; } }

@media (min-width: 1024px) {
  .col-auto-lg {
    flex: 0 0 auto;
    width: auto;
    display: block; }
  .col-none-lg {
    flex: 0 0 0;
    width: 0;
    display: none; }
  .col-1-lg {
    flex: 0 0 auto;
    width: 8.33333%;
    display: block; }
  .col-2-lg {
    flex: 0 0 auto;
    width: 16.66667%;
    display: block; }
  .col-3-lg {
    flex: 0 0 auto;
    width: 25%;
    display: block; }
  .col-4-lg {
    flex: 0 0 auto;
    width: 33.33333%;
    display: block; }
  .col-5-lg {
    flex: 0 0 auto;
    width: 41.66667%;
    display: block; }
  .col-6-lg {
    flex: 0 0 auto;
    width: 50%;
    display: block; }
  .col-7-lg {
    flex: 0 0 auto;
    width: 58.33333%;
    display: block; }
  .col-8-lg {
    flex: 0 0 auto;
    width: 66.66667%;
    display: block; }
  .col-9-lg {
    flex: 0 0 auto;
    width: 75%;
    display: block; }
  .col-10-lg {
    flex: 0 0 auto;
    width: 83.33333%;
    display: block; }
  .col-11-lg {
    flex: 0 0 auto;
    width: 91.66667%;
    display: block; }
  .col-12-lg {
    flex: 0 0 auto;
    width: 100%;
    display: block; }
  .offset-0-lg > * {
    margin-left: 0%; }
  .offset-1-lg > * {
    margin-left: 8.33333%; }
  .offset-2-lg > * {
    margin-left: 16.66667%; }
  .offset-3-lg > * {
    margin-left: 25%; }
  .offset-4-lg > * {
    margin-left: 33.33333%; }
  .offset-5-lg > * {
    margin-left: 41.66667%; }
  .offset-6-lg > * {
    margin-left: 50%; }
  .offset-7-lg > * {
    margin-left: 58.33333%; }
  .offset-8-lg > * {
    margin-left: 66.66667%; }
  .offset-9-lg > * {
    margin-left: 75%; }
  .offset-10-lg > * {
    margin-left: 83.33333%; }
  .offset-11-lg > * {
    margin-left: 91.66667%; }
  .gx-0-lg {
    --bs-gutter-x: 0px; }
  .gy-0-lg {
    --bs-gutter-y: 0px; }
  .gx-1-lg {
    --bs-gutter-x: 1px; }
  .gy-1-lg {
    --bs-gutter-y: 1px; }
  .gx-2-lg {
    --bs-gutter-x: 2px; }
  .gy-2-lg {
    --bs-gutter-y: 2px; }
  .gx-10-lg {
    --bs-gutter-x: 10px; }
  .gy-10-lg {
    --bs-gutter-y: 10px; }
  .gx-12-lg {
    --bs-gutter-x: 12px; }
  .gy-12-lg {
    --bs-gutter-y: 12px; }
  .gx-16-lg {
    --bs-gutter-x: 16px; }
  .gy-16-lg {
    --bs-gutter-y: 16px; }
  .gx-20-lg {
    --bs-gutter-x: 20px; }
  .gy-20-lg {
    --bs-gutter-y: 20px; }
  .gx-22-lg {
    --bs-gutter-x: 22px; }
  .gy-22-lg {
    --bs-gutter-y: 22px; }
  .gx-24-lg {
    --bs-gutter-x: 24px; }
  .gy-24-lg {
    --bs-gutter-y: 24px; }
  .gx-30-lg {
    --bs-gutter-x: 30px; }
  .gy-30-lg {
    --bs-gutter-y: 30px; }
  .gx-32-lg {
    --bs-gutter-x: 32px; }
  .gy-32-lg {
    --bs-gutter-y: 32px; }
  .gx-34-lg {
    --bs-gutter-x: 34px; }
  .gy-34-lg {
    --bs-gutter-y: 34px; }
  .gx-36-lg {
    --bs-gutter-x: 36px; }
  .gy-36-lg {
    --bs-gutter-y: 36px; }
  .gx-40-lg {
    --bs-gutter-x: 40px; }
  .gy-40-lg {
    --bs-gutter-y: 40px; }
  .gx-42-lg {
    --bs-gutter-x: 42px; }
  .gy-42-lg {
    --bs-gutter-y: 42px; }
  .gx-46-lg {
    --bs-gutter-x: 46px; }
  .gy-46-lg {
    --bs-gutter-y: 46px; }
  .gx-48-lg {
    --bs-gutter-x: 48px; }
  .gy-48-lg {
    --bs-gutter-y: 48px; }
  .gx-50-lg {
    --bs-gutter-x: 50px; }
  .gy-50-lg {
    --bs-gutter-y: 50px; }
  .gx-54-lg {
    --bs-gutter-x: 54px; }
  .gy-54-lg {
    --bs-gutter-y: 54px; }
  .gx-56-lg {
    --bs-gutter-x: 56px; }
  .gy-56-lg {
    --bs-gutter-y: 56px; }
  .gx-60-lg {
    --bs-gutter-x: 60px; }
  .gy-60-lg {
    --bs-gutter-y: 60px; }
  .gx-64-lg {
    --bs-gutter-x: 64px; }
  .gy-64-lg {
    --bs-gutter-y: 64px; }
  .gx-70-lg {
    --bs-gutter-x: 70px; }
  .gy-70-lg {
    --bs-gutter-y: 70px; }
  .gx-75-lg {
    --bs-gutter-x: 75px; }
  .gy-75-lg {
    --bs-gutter-y: 75px; }
  .gx-80-lg {
    --bs-gutter-x: 80px; }
  .gy-80-lg {
    --bs-gutter-y: 80px; }
  .gx-90-lg {
    --bs-gutter-x: 90px; }
  .gy-90-lg {
    --bs-gutter-y: 90px; }
  .gx-100-lg {
    --bs-gutter-x: 100px; }
  .gy-100-lg {
    --bs-gutter-y: 100px; } }

@media (min-width: 1280px) {
  .col-auto-xl {
    flex: 0 0 auto;
    width: auto;
    display: block; }
  .col-none-xl {
    flex: 0 0 0;
    width: 0;
    display: none; }
  .col-1-xl {
    flex: 0 0 auto;
    width: 8.33333%;
    display: block; }
  .col-2-xl {
    flex: 0 0 auto;
    width: 16.66667%;
    display: block; }
  .col-3-xl {
    flex: 0 0 auto;
    width: 25%;
    display: block; }
  .col-4-xl {
    flex: 0 0 auto;
    width: 33.33333%;
    display: block; }
  .col-5-xl {
    flex: 0 0 auto;
    width: 41.66667%;
    display: block; }
  .col-6-xl {
    flex: 0 0 auto;
    width: 50%;
    display: block; }
  .col-7-xl {
    flex: 0 0 auto;
    width: 58.33333%;
    display: block; }
  .col-8-xl {
    flex: 0 0 auto;
    width: 66.66667%;
    display: block; }
  .col-9-xl {
    flex: 0 0 auto;
    width: 75%;
    display: block; }
  .col-10-xl {
    flex: 0 0 auto;
    width: 83.33333%;
    display: block; }
  .col-11-xl {
    flex: 0 0 auto;
    width: 91.66667%;
    display: block; }
  .col-12-xl {
    flex: 0 0 auto;
    width: 100%;
    display: block; }
  .offset-0-xl > * {
    margin-left: 0%; }
  .offset-1-xl > * {
    margin-left: 8.33333%; }
  .offset-2-xl > * {
    margin-left: 16.66667%; }
  .offset-3-xl > * {
    margin-left: 25%; }
  .offset-4-xl > * {
    margin-left: 33.33333%; }
  .offset-5-xl > * {
    margin-left: 41.66667%; }
  .offset-6-xl > * {
    margin-left: 50%; }
  .offset-7-xl > * {
    margin-left: 58.33333%; }
  .offset-8-xl > * {
    margin-left: 66.66667%; }
  .offset-9-xl > * {
    margin-left: 75%; }
  .offset-10-xl > * {
    margin-left: 83.33333%; }
  .offset-11-xl > * {
    margin-left: 91.66667%; }
  .gx-0-xl {
    --bs-gutter-x: 0px; }
  .gy-0-xl {
    --bs-gutter-y: 0px; }
  .gx-1-xl {
    --bs-gutter-x: 1px; }
  .gy-1-xl {
    --bs-gutter-y: 1px; }
  .gx-2-xl {
    --bs-gutter-x: 2px; }
  .gy-2-xl {
    --bs-gutter-y: 2px; }
  .gx-10-xl {
    --bs-gutter-x: 10px; }
  .gy-10-xl {
    --bs-gutter-y: 10px; }
  .gx-12-xl {
    --bs-gutter-x: 12px; }
  .gy-12-xl {
    --bs-gutter-y: 12px; }
  .gx-16-xl {
    --bs-gutter-x: 16px; }
  .gy-16-xl {
    --bs-gutter-y: 16px; }
  .gx-20-xl {
    --bs-gutter-x: 20px; }
  .gy-20-xl {
    --bs-gutter-y: 20px; }
  .gx-22-xl {
    --bs-gutter-x: 22px; }
  .gy-22-xl {
    --bs-gutter-y: 22px; }
  .gx-24-xl {
    --bs-gutter-x: 24px; }
  .gy-24-xl {
    --bs-gutter-y: 24px; }
  .gx-30-xl {
    --bs-gutter-x: 30px; }
  .gy-30-xl {
    --bs-gutter-y: 30px; }
  .gx-32-xl {
    --bs-gutter-x: 32px; }
  .gy-32-xl {
    --bs-gutter-y: 32px; }
  .gx-34-xl {
    --bs-gutter-x: 34px; }
  .gy-34-xl {
    --bs-gutter-y: 34px; }
  .gx-36-xl {
    --bs-gutter-x: 36px; }
  .gy-36-xl {
    --bs-gutter-y: 36px; }
  .gx-40-xl {
    --bs-gutter-x: 40px; }
  .gy-40-xl {
    --bs-gutter-y: 40px; }
  .gx-42-xl {
    --bs-gutter-x: 42px; }
  .gy-42-xl {
    --bs-gutter-y: 42px; }
  .gx-46-xl {
    --bs-gutter-x: 46px; }
  .gy-46-xl {
    --bs-gutter-y: 46px; }
  .gx-48-xl {
    --bs-gutter-x: 48px; }
  .gy-48-xl {
    --bs-gutter-y: 48px; }
  .gx-50-xl {
    --bs-gutter-x: 50px; }
  .gy-50-xl {
    --bs-gutter-y: 50px; }
  .gx-54-xl {
    --bs-gutter-x: 54px; }
  .gy-54-xl {
    --bs-gutter-y: 54px; }
  .gx-56-xl {
    --bs-gutter-x: 56px; }
  .gy-56-xl {
    --bs-gutter-y: 56px; }
  .gx-60-xl {
    --bs-gutter-x: 60px; }
  .gy-60-xl {
    --bs-gutter-y: 60px; }
  .gx-64-xl {
    --bs-gutter-x: 64px; }
  .gy-64-xl {
    --bs-gutter-y: 64px; }
  .gx-70-xl {
    --bs-gutter-x: 70px; }
  .gy-70-xl {
    --bs-gutter-y: 70px; }
  .gx-75-xl {
    --bs-gutter-x: 75px; }
  .gy-75-xl {
    --bs-gutter-y: 75px; }
  .gx-80-xl {
    --bs-gutter-x: 80px; }
  .gy-80-xl {
    --bs-gutter-y: 80px; }
  .gx-90-xl {
    --bs-gutter-x: 90px; }
  .gy-90-xl {
    --bs-gutter-y: 90px; }
  .gx-100-xl {
    --bs-gutter-x: 100px; }
  .gy-100-xl {
    --bs-gutter-y: 100px; } }

@media (min-width: 1440px) {
  .col-auto-yl {
    flex: 0 0 auto;
    width: auto;
    display: block; }
  .col-none-yl {
    flex: 0 0 0;
    width: 0;
    display: none; }
  .col-1-yl {
    flex: 0 0 auto;
    width: 8.33333%;
    display: block; }
  .col-2-yl {
    flex: 0 0 auto;
    width: 16.66667%;
    display: block; }
  .col-3-yl {
    flex: 0 0 auto;
    width: 25%;
    display: block; }
  .col-4-yl {
    flex: 0 0 auto;
    width: 33.33333%;
    display: block; }
  .col-5-yl {
    flex: 0 0 auto;
    width: 41.66667%;
    display: block; }
  .col-6-yl {
    flex: 0 0 auto;
    width: 50%;
    display: block; }
  .col-7-yl {
    flex: 0 0 auto;
    width: 58.33333%;
    display: block; }
  .col-8-yl {
    flex: 0 0 auto;
    width: 66.66667%;
    display: block; }
  .col-9-yl {
    flex: 0 0 auto;
    width: 75%;
    display: block; }
  .col-10-yl {
    flex: 0 0 auto;
    width: 83.33333%;
    display: block; }
  .col-11-yl {
    flex: 0 0 auto;
    width: 91.66667%;
    display: block; }
  .col-12-yl {
    flex: 0 0 auto;
    width: 100%;
    display: block; }
  .offset-0-yl > * {
    margin-left: 0%; }
  .offset-1-yl > * {
    margin-left: 8.33333%; }
  .offset-2-yl > * {
    margin-left: 16.66667%; }
  .offset-3-yl > * {
    margin-left: 25%; }
  .offset-4-yl > * {
    margin-left: 33.33333%; }
  .offset-5-yl > * {
    margin-left: 41.66667%; }
  .offset-6-yl > * {
    margin-left: 50%; }
  .offset-7-yl > * {
    margin-left: 58.33333%; }
  .offset-8-yl > * {
    margin-left: 66.66667%; }
  .offset-9-yl > * {
    margin-left: 75%; }
  .offset-10-yl > * {
    margin-left: 83.33333%; }
  .offset-11-yl > * {
    margin-left: 91.66667%; }
  .gx-0-yl {
    --bs-gutter-x: 0px; }
  .gy-0-yl {
    --bs-gutter-y: 0px; }
  .gx-1-yl {
    --bs-gutter-x: 1px; }
  .gy-1-yl {
    --bs-gutter-y: 1px; }
  .gx-2-yl {
    --bs-gutter-x: 2px; }
  .gy-2-yl {
    --bs-gutter-y: 2px; }
  .gx-10-yl {
    --bs-gutter-x: 10px; }
  .gy-10-yl {
    --bs-gutter-y: 10px; }
  .gx-12-yl {
    --bs-gutter-x: 12px; }
  .gy-12-yl {
    --bs-gutter-y: 12px; }
  .gx-16-yl {
    --bs-gutter-x: 16px; }
  .gy-16-yl {
    --bs-gutter-y: 16px; }
  .gx-20-yl {
    --bs-gutter-x: 20px; }
  .gy-20-yl {
    --bs-gutter-y: 20px; }
  .gx-22-yl {
    --bs-gutter-x: 22px; }
  .gy-22-yl {
    --bs-gutter-y: 22px; }
  .gx-24-yl {
    --bs-gutter-x: 24px; }
  .gy-24-yl {
    --bs-gutter-y: 24px; }
  .gx-30-yl {
    --bs-gutter-x: 30px; }
  .gy-30-yl {
    --bs-gutter-y: 30px; }
  .gx-32-yl {
    --bs-gutter-x: 32px; }
  .gy-32-yl {
    --bs-gutter-y: 32px; }
  .gx-34-yl {
    --bs-gutter-x: 34px; }
  .gy-34-yl {
    --bs-gutter-y: 34px; }
  .gx-36-yl {
    --bs-gutter-x: 36px; }
  .gy-36-yl {
    --bs-gutter-y: 36px; }
  .gx-40-yl {
    --bs-gutter-x: 40px; }
  .gy-40-yl {
    --bs-gutter-y: 40px; }
  .gx-42-yl {
    --bs-gutter-x: 42px; }
  .gy-42-yl {
    --bs-gutter-y: 42px; }
  .gx-46-yl {
    --bs-gutter-x: 46px; }
  .gy-46-yl {
    --bs-gutter-y: 46px; }
  .gx-48-yl {
    --bs-gutter-x: 48px; }
  .gy-48-yl {
    --bs-gutter-y: 48px; }
  .gx-50-yl {
    --bs-gutter-x: 50px; }
  .gy-50-yl {
    --bs-gutter-y: 50px; }
  .gx-54-yl {
    --bs-gutter-x: 54px; }
  .gy-54-yl {
    --bs-gutter-y: 54px; }
  .gx-56-yl {
    --bs-gutter-x: 56px; }
  .gy-56-yl {
    --bs-gutter-y: 56px; }
  .gx-60-yl {
    --bs-gutter-x: 60px; }
  .gy-60-yl {
    --bs-gutter-y: 60px; }
  .gx-64-yl {
    --bs-gutter-x: 64px; }
  .gy-64-yl {
    --bs-gutter-y: 64px; }
  .gx-70-yl {
    --bs-gutter-x: 70px; }
  .gy-70-yl {
    --bs-gutter-y: 70px; }
  .gx-75-yl {
    --bs-gutter-x: 75px; }
  .gy-75-yl {
    --bs-gutter-y: 75px; }
  .gx-80-yl {
    --bs-gutter-x: 80px; }
  .gy-80-yl {
    --bs-gutter-y: 80px; }
  .gx-90-yl {
    --bs-gutter-x: 90px; }
  .gy-90-yl {
    --bs-gutter-y: 90px; }
  .gx-100-yl {
    --bs-gutter-x: 100px; }
  .gy-100-yl {
    --bs-gutter-y: 100px; } }

@media (min-width: 1920px) {
  .col-auto-pl {
    flex: 0 0 auto;
    width: auto;
    display: block; }
  .col-none-pl {
    flex: 0 0 0;
    width: 0;
    display: none; }
  .col-1-pl {
    flex: 0 0 auto;
    width: 8.33333%;
    display: block; }
  .col-2-pl {
    flex: 0 0 auto;
    width: 16.66667%;
    display: block; }
  .col-3-pl {
    flex: 0 0 auto;
    width: 25%;
    display: block; }
  .col-4-pl {
    flex: 0 0 auto;
    width: 33.33333%;
    display: block; }
  .col-5-pl {
    flex: 0 0 auto;
    width: 41.66667%;
    display: block; }
  .col-6-pl {
    flex: 0 0 auto;
    width: 50%;
    display: block; }
  .col-7-pl {
    flex: 0 0 auto;
    width: 58.33333%;
    display: block; }
  .col-8-pl {
    flex: 0 0 auto;
    width: 66.66667%;
    display: block; }
  .col-9-pl {
    flex: 0 0 auto;
    width: 75%;
    display: block; }
  .col-10-pl {
    flex: 0 0 auto;
    width: 83.33333%;
    display: block; }
  .col-11-pl {
    flex: 0 0 auto;
    width: 91.66667%;
    display: block; }
  .col-12-pl {
    flex: 0 0 auto;
    width: 100%;
    display: block; }
  .offset-0-pl > * {
    margin-left: 0%; }
  .offset-1-pl > * {
    margin-left: 8.33333%; }
  .offset-2-pl > * {
    margin-left: 16.66667%; }
  .offset-3-pl > * {
    margin-left: 25%; }
  .offset-4-pl > * {
    margin-left: 33.33333%; }
  .offset-5-pl > * {
    margin-left: 41.66667%; }
  .offset-6-pl > * {
    margin-left: 50%; }
  .offset-7-pl > * {
    margin-left: 58.33333%; }
  .offset-8-pl > * {
    margin-left: 66.66667%; }
  .offset-9-pl > * {
    margin-left: 75%; }
  .offset-10-pl > * {
    margin-left: 83.33333%; }
  .offset-11-pl > * {
    margin-left: 91.66667%; }
  .gx-0-pl {
    --bs-gutter-x: 0px; }
  .gy-0-pl {
    --bs-gutter-y: 0px; }
  .gx-1-pl {
    --bs-gutter-x: 1px; }
  .gy-1-pl {
    --bs-gutter-y: 1px; }
  .gx-2-pl {
    --bs-gutter-x: 2px; }
  .gy-2-pl {
    --bs-gutter-y: 2px; }
  .gx-10-pl {
    --bs-gutter-x: 10px; }
  .gy-10-pl {
    --bs-gutter-y: 10px; }
  .gx-12-pl {
    --bs-gutter-x: 12px; }
  .gy-12-pl {
    --bs-gutter-y: 12px; }
  .gx-16-pl {
    --bs-gutter-x: 16px; }
  .gy-16-pl {
    --bs-gutter-y: 16px; }
  .gx-20-pl {
    --bs-gutter-x: 20px; }
  .gy-20-pl {
    --bs-gutter-y: 20px; }
  .gx-22-pl {
    --bs-gutter-x: 22px; }
  .gy-22-pl {
    --bs-gutter-y: 22px; }
  .gx-24-pl {
    --bs-gutter-x: 24px; }
  .gy-24-pl {
    --bs-gutter-y: 24px; }
  .gx-30-pl {
    --bs-gutter-x: 30px; }
  .gy-30-pl {
    --bs-gutter-y: 30px; }
  .gx-32-pl {
    --bs-gutter-x: 32px; }
  .gy-32-pl {
    --bs-gutter-y: 32px; }
  .gx-34-pl {
    --bs-gutter-x: 34px; }
  .gy-34-pl {
    --bs-gutter-y: 34px; }
  .gx-36-pl {
    --bs-gutter-x: 36px; }
  .gy-36-pl {
    --bs-gutter-y: 36px; }
  .gx-40-pl {
    --bs-gutter-x: 40px; }
  .gy-40-pl {
    --bs-gutter-y: 40px; }
  .gx-42-pl {
    --bs-gutter-x: 42px; }
  .gy-42-pl {
    --bs-gutter-y: 42px; }
  .gx-46-pl {
    --bs-gutter-x: 46px; }
  .gy-46-pl {
    --bs-gutter-y: 46px; }
  .gx-48-pl {
    --bs-gutter-x: 48px; }
  .gy-48-pl {
    --bs-gutter-y: 48px; }
  .gx-50-pl {
    --bs-gutter-x: 50px; }
  .gy-50-pl {
    --bs-gutter-y: 50px; }
  .gx-54-pl {
    --bs-gutter-x: 54px; }
  .gy-54-pl {
    --bs-gutter-y: 54px; }
  .gx-56-pl {
    --bs-gutter-x: 56px; }
  .gy-56-pl {
    --bs-gutter-y: 56px; }
  .gx-60-pl {
    --bs-gutter-x: 60px; }
  .gy-60-pl {
    --bs-gutter-y: 60px; }
  .gx-64-pl {
    --bs-gutter-x: 64px; }
  .gy-64-pl {
    --bs-gutter-y: 64px; }
  .gx-70-pl {
    --bs-gutter-x: 70px; }
  .gy-70-pl {
    --bs-gutter-y: 70px; }
  .gx-75-pl {
    --bs-gutter-x: 75px; }
  .gy-75-pl {
    --bs-gutter-y: 75px; }
  .gx-80-pl {
    --bs-gutter-x: 80px; }
  .gy-80-pl {
    --bs-gutter-y: 80px; }
  .gx-90-pl {
    --bs-gutter-x: 90px; }
  .gy-90-pl {
    --bs-gutter-y: 90px; }
  .gx-100-pl {
    --bs-gutter-x: 100px; }
  .gy-100-pl {
    --bs-gutter-y: 100px; } }

/*# sourceMappingURL=bootstrap-grid.css.map */
@keyframes show-hamberger {
  0% {
    display: none;
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0); }
  100% {
    display: block;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%); } }

@keyframes show-popup-block {
  0% {
    display: none;
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0); }
  100% {
    display: block;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%); } }

@keyframes showFlexRL {
  0% {
    display: none;
    clip-path: inset(0 0 0 100%); }
  100% {
    display: flex;
    clip-path: inset(0 0 0 0); } }

@keyframes widthRight {
  0% {
    width: 0%; }
  100% {
    width: 80%; } }

*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0; }

html {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 52.5%;
  scroll-behavior: smooth;
  top: -100px; }
  @media (min-width: 768px) {
    html {
      font-size: 57.5%; } }
  @media (min-width: 1200px) {
    html {
      font-size: 62.5%; } }

body {
  margin: 0;
  line-height: 1.2;
  color: #707070;
  background-color: #ffffff;
  font-family: "SVN-AvenirNext", sans-serif;
  font-size: 18px;
  font-weight: 500; }
  @media screen and (min-width: 1024px) {
    body {
      font-size: 20px; } }

video {
  object-fit: cover; }

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0; }

p {
  margin-top: 0;
  margin-bottom: 0; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 0;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 0;
  list-style-type: none; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: 0;
  margin-left: 0; }

blockquote {
  margin: 0; }

b,
strong {
  font-weight: bolder;
  font-weight: 700; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

a {
  text-decoration: none;
  background-color: transparent;
  margin: 0; }

pre,
code,
kbd,
samp {
  font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 0;
  overflow: auto; }

figure {
  margin: 0; }

img {
  vertical-align: middle;
  border-style: none;
  max-width: 100%; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0;
  padding-bottom: 0;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0; }

button {
  border-radius: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

*:focus,
*:active {
  outline: none; }

::-webkit-input-placeholder {
  /* Edge */
  color: rgba(163, 39, 50, 0.7);
  font-style: italic; }

:-ms-input-placeholder {
  /* Internet Explorer */
  color: rgba(163, 39, 50, 0.7);
  font-style: italic; }

::placeholder {
  color: rgba(163, 39, 50, 0.7);
  font-style: italic; }

@font-face {
  font-family: UTM-NEO-Sans-Intel;
  src: url("../fonts/00137-UTM-Neo-Sans-Intel.ttf") format("truetype"); }

@font-face {
  font-family: SVN-AvenirNext;
  src: url("../fonts/SVN-AvenirNext/OTF/SVN-AvenirNext-Thin.otf") format("opentype"), url("../fonts/SVN-AvenirNext/TTF/SVN-AvenirNext-Thin.ttf") format("truetype");
  font-weight: 100; }

@font-face {
  font-family: SVN-AvenirNext;
  src: url("../fonts/SVN-AvenirNext/OTF/SVN-AvenirNext-ThinItalic.otf") format("opentype"), url("../fonts/SVN-AvenirNext/TTF/SVN-AvenirNext-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic; }

@font-face {
  font-family: SVN-AvenirNext;
  src: url("../fonts/SVN-AvenirNext/OTF/SVN-AvenirNext-UltraLight.otf") format("opentype"), url("../fonts/SVN-AvenirNext/TTF/SVN-AvenirNext-UltraLight.ttf") format("truetype");
  font-weight: 200; }

@font-face {
  font-family: SVN-AvenirNext;
  src: url("../fonts/SVN-AvenirNext/OTF/SVN-AvenirNext-UltraLightItalic.otf") format("opentype"), url("../fonts/SVN-AvenirNext/TTF/SVN-AvenirNext-UltraLightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic; }

@font-face {
  font-family: SVN-AvenirNext;
  src: url("../fonts/SVN-AvenirNext/OTF/SVN-AvenirNext-Light.otf") format("opentype"), url("../fonts/SVN-AvenirNext/TTF/SVN-AvenirNext-Light.ttf") format("truetype");
  font-weight: 300; }

@font-face {
  font-family: SVN-AvenirNext;
  src: url("../fonts/SVN-AvenirNext/OTF/SVN-AvenirNext-LightItalic.otf") format("opentype"), url("../fonts/SVN-AvenirNext/TTF/SVN-AvenirNext-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: SVN-AvenirNext;
  src: url("../fonts/SVN-AvenirNext/OTF/SVN-AvenirNext-Regular.otf") format("opentype"), url("../fonts/SVN-AvenirNext/TTF/SVN-AvenirNext-Regular.ttf") format("truetype");
  font-weight: 400; }

@font-face {
  font-family: SVN-AvenirNext;
  src: url("../fonts/SVN-AvenirNext/OTF/SVN-AvenirNext-Italic.otf") format("opentype"), url("../fonts/SVN-AvenirNext/TTF/SVN-AvenirNext-Italic.ttf") format("truetype");
  font-style: italic; }

@font-face {
  font-family: SVN-AvenirNext;
  src: url("../fonts/SVN-AvenirNext/OTF/SVN-AvenirNext-Medium.otf") format("opentype"), url("../fonts/SVN-AvenirNext/TTF/SVN-AvenirNext-Medium.ttf") format("truetype");
  font-weight: 500; }

@font-face {
  font-family: SVN-AvenirNext;
  src: url("../fonts/SVN-AvenirNext/OTF/SVN-AvenirNext-MediumItalic.otf") format("opentype"), url("../fonts/SVN-AvenirNext/TTF/SVN-AvenirNext-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic; }

@font-face {
  font-family: SVN-AvenirNext;
  src: url("../fonts/SVN-AvenirNext/OTF/SVN-AvenirNext-Demi.otf") format("opentype"), url("../fonts/SVN-AvenirNext/TTF/SVN-AvenirNext-Demi.ttf") format("truetype");
  font-weight: 600; }

@font-face {
  font-family: SVN-AvenirNext;
  src: url("../fonts/SVN-AvenirNext/OTF/SVN-AvenirNext-DemiItalic.otf") format("opentype"), url("../fonts/SVN-AvenirNext/TTF/SVN-AvenirNext-DemiItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic; }

@font-face {
  font-family: SVN-AvenirNext;
  src: url("../fonts/SVN-AvenirNext/OTF/SVN-AvenirNext-Bold.otf") format("opentype"), url("../fonts/SVN-AvenirNext/TTF/SVN-AvenirNext-Bold.ttf") format("truetype");
  font-weight: 700; }

@font-face {
  font-family: SVN-AvenirNext;
  src: url("../fonts/SVN-AvenirNext/OTF/SVN-AvenirNext-BoldItalic.otf") format("opentype"), url("../fonts/SVN-AvenirNext/TTF/SVN-AvenirNext-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic; }

@font-face {
  font-family: SVN-AvenirNext;
  src: url("../fonts/SVN-AvenirNext/OTF/SVN-AvenirNext-ExtraBold.otf") format("opentype"), url("../fonts/SVN-AvenirNext/TTF/SVN-AvenirNext-ExtraBold.ttf") format("truetype");
  font-weight: 800; }

@font-face {
  font-family: SVN-AvenirNext;
  src: url("../fonts/SVN-AvenirNext/OTF/SVN-AvenirNext-ExtraBoldItalic.otf") format("opentype"), url("../fonts/SVN-AvenirNext/TTF/SVN-AvenirNext-ExtraBoldItalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic; }

@font-face {
  font-family: SVN-AvenirNext;
  src: url("../fonts/SVN-AvenirNext/OTF/SVN-AvenirNext-Heavy.otf") format("opentype"), url("../fonts/SVN-AvenirNext/TTF/SVN-AvenirNext-Heavy.ttf") format("truetype");
  font-weight: 900; }

@font-face {
  font-family: SVN-AvenirNext;
  src: url("../fonts/SVN-AvenirNext/OTF/SVN-AvenirNext-HeavyItalic.otf") format("opentype"), url("../fonts/SVN-AvenirNext/TTF/SVN-AvenirNext-HeavyItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic; }

.clearfix:after {
  content: "";
  display: table;
  clear: both; }

.uppercase {
  text-transform: uppercase; }

.fullsize {
  width: 100%;
  height: 100%; }

.object-cover {
  object-fit: contain;
  object-position: center;
  background-size: cover; }

.object-contain {
  object-fit: contain;
  object-position: center;
  background-size: contain; }

.blur-up {
  filter: blur(10px);
  transition: filter 1s; }
  .blur-up.lazyloaded {
    filter: blur(0); }

.img-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center; }

.flex-center, .nav-bar-center, .dropdown-content .nav-bar-section {
  display: flex;
  justify-content: center;
  align-items: center; }

header {
  overflow: hidden; }

main {
  overflow: hidden; }

footer {
  overflow: hidden; }

.product-infor .product-infor__title {
  font-family: "SVN-AvenirNext", sans-serif;
  color: #000;
  font-size: 50px;
  font-weight: 500; }
  @media (max-width: 1024px) {
    .product-infor .product-infor__title {
      font-size: 40px; } }
  @media (max-width: 640px) {
    .product-infor .product-infor__title {
      font-size: 32px; } }

.product-infor .product-infor__content {
  margin: 40px 0;
  color: #707070;
  display: -webkit-box;
  -webkit-line-clamp: 9;
  -webkit-box-orient: vertical;
  overflow: hidden; }
  @media (max-width: 1280px) {
    .product-infor .product-infor__content {
      margin: 32px 0; } }
  @media (max-width: 640px) {
    .product-infor .product-infor__content {
      margin: 12px 0; } }

.product-infor .product-infor__more {
  color: #a32732;
  font-weight: bold;
  padding-top: 24px;
  border-top: 1px solid #707070; }
  .product-infor .product-infor__more + .product-infor__more {
    margin-top: 12px;
    padding-top: 0;
    border: none; }
  .product-infor .product-infor__more span {
    color: #707070; }

.more {
  display: flex;
  width: fit-content;
  align-items: center;
  padding: 5px 10px;
  border: solid 2px #a32732;
  border-radius: 5px;
  margin: 40px 0;
  cursor: pointer; }
  .more p {
    text-transform: uppercase;
    white-space: nowrap;
    color: #a32732;
    font-weight: bold; }

.btn--download p {
  font-weight: 600; }

.btn a {
  background-color: white;
  border: 1px solid #a32732;
  color: #a32732;
  padding: 0px 12px;
  border-radius: 3px;
  font-weight: bold;
  position: relative;
  line-height: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.3s all ease; }
  .btn a:hover {
    background-color: #a32732;
    color: white; }
  .btn a svg {
    margin-left: 4px; }
    .btn a svg path {
      stroke: #a32732; }

.btn .btn-arrow.btn-arrow--white {
  background-color: white;
  border: 1px solid #a32732;
  color: #a32732;
  transition: 0.2s all ease-in-out; }
  .btn .btn-arrow.btn-arrow--white:hover {
    background-color: #a32732;
    color: white; }
    .btn .btn-arrow.btn-arrow--white:hover svg path {
      stroke: white; }
  .btn .btn-arrow.btn-arrow--white svg path {
    stroke: #a32732; }

.btn .btn-arrow.btn-arrow--transparent {
  background-color: transparent;
  border: 1px solid #a32732;
  color: #a32732; }
  .btn .btn-arrow.btn-arrow--transparent:hover {
    background-color: #a32732;
    color: white; }
    .btn .btn-arrow.btn-arrow--transparent:hover svg path {
      stroke: white; }
  .btn .btn-arrow.btn-arrow--transparent svg path {
    stroke: #a32732; }

.btn .btn-arrow.btn-arrow--primary {
  background-color: #a32732;
  border: 1px solid white;
  color: white; }
  .btn .btn-arrow.btn-arrow--primary:hover {
    background-color: white;
    color: #a32732; }
    .btn .btn-arrow.btn-arrow--primary:hover svg path {
      stroke: #a32732; }
  .btn .btn-arrow.btn-arrow--primary svg path {
    stroke: white; }

.btn .btn-bg {
  padding: 12px 13px;
  line-height: 24px;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  font-family: "SVN-AvenirNext", sans-serif;
  background-color: #520007; }
  @media screen and (min-width: 1024px) {
    .btn .btn-bg {
      font-size: 20px; } }

.swiper-btn__nav {
  position: relative; }
  .swiper-btn__nav .swiper-button-prev,
  .swiper-btn__nav .swiper-button-next {
    position: absolute;
    top: 50%;
    user-select: none; }
    .swiper-btn__nav .swiper-button-prev::after,
    .swiper-btn__nav .swiper-button-next::after {
      display: none; }

.font-heading-1 {
  font-family: "SVN-AvenirNext", sans-serif;
  color: #000;
  font-size: 80px;
  font-weight: 500; }

@media (max-width: 1024px) {
  .font-heading-1 {
    font-size: 56px; } }

@media (max-width: 640px) {
  .font-heading-1 {
    font-size: 30px; } }

.font-heading-2 {
  font-family: "SVN-AvenirNext", sans-serif;
  color: #000;
  font-size: 65px;
  font-weight: 500; }

@media (max-width: 1024px) {
  .font-heading-2 {
    font-size: 50px; } }

@media (max-width: 640px) {
  .font-heading-2 {
    font-size: 45px; } }

.font-heading-3 {
  font-family: "SVN-AvenirNext", sans-serif;
  color: #000;
  font-size: 50px;
  font-weight: 500; }

@media (max-width: 1024px) {
  .font-heading-3 {
    font-size: 40px; } }

@media (max-width: 640px) {
  .font-heading-3 {
    font-size: 32px; } }

.font-heading-4 {
  font-family: "SVN-AvenirNext", sans-serif;
  color: #000;
  font-size: 32px;
  font-weight: 500; }

@media (max-width: 1024px) {
  .font-heading-4 {
    font-size: 26px; } }

@media (max-width: 640px) {
  .font-heading-4 {
    font-size: 22px; } }

.font-heading-5 {
  font-family: "SVN-AvenirNext", sans-serif;
  color: #000;
  font-size: 20px;
  font-weight: 500; }

@media (max-width: 1024px) {
  .font-heading-5 {
    font-size: 19px; } }

@media (max-width: 640px) {
  .font-heading-5 {
    font-size: 18px; } }

.font-heading-6 {
  font-family: "SVN-AvenirNext", sans-serif;
  color: #000;
  font-size: 17px;
  font-weight: 500; }

@media (max-width: 1024px) {
  .font-heading-6 {
    font-size: 16px; } }

@media (max-width: 640px) {
  .font-heading-6 {
    font-size: 15px; } }

.font-p {
  font-family: "UTM-NEO-Sans-Intel", sans-serif;
  color: #707070;
  font-size: 20px;
  font-weight: 400; }

@media (max-width: 1024px) {
  .font-p {
    font-size: 18px; } }

@media (max-width: 640px) {
  .font-p {
    font-size: 16px; } }

.font-p-small {
  font-family: "SVN-AvenirNext", sans-serif;
  color: #707070;
  font-size: 16px;
  font-weight: 500; }

@media (max-width: 1024px) {
  .font-p-small {
    font-size: 15px; } }

.p-line-clamp--1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden; }

.p-line-clamp--2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden; }

.p-line-clamp--3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden; }

.p-line-clamp--4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden; }

.p-line-clamp--5 {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden; }

.overlay-black {
  position: relative;
  display: block;
  width: 100%;
  height: 100%; }
  .overlay-black::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background: rgba(0, 0, 0, 0.1);
    z-index: 1; }

.span--date, .about-media-releases__wrapper .about-media-releases__content .releases-box-item__date, .about-story-blog__wrapper .about-story-blog--left .blog-item__content-date, .about-story-blog__wrapper .about-story-blog--right .blog-item__content-date, .blog-detail__wrapper .blog-detail--right .blog-item__content-date {
  color: #707070;
  font-family: "UTM-NEO-Sans-Intel", sans-serif;
  font-size: 16px;
  font-weight: 400;
  display: block;
  font-style: italic;
  margin-top: 32px;
  color: #a32732;
  text-transform: uppercase; }
  @media (max-width: 1024px) {
    .span--date, .about-media-releases__wrapper .about-media-releases__content .releases-box-item__date, .about-story-blog__wrapper .about-story-blog--left .blog-item__content-date, .about-story-blog__wrapper .about-story-blog--right .blog-item__content-date, .blog-detail__wrapper .blog-detail--right .blog-item__content-date {
      font-size: 15px; } }

::-webkit-scrollbar {
  width: 8px;
  height: 8px; }

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px #c0c0c0;
  border-radius: 5px; }

/* Handle */
::-webkit-scrollbar-thumb {
  background: #a32732;
  border-radius: 5px; }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(112, 112, 112, 0.7); }

[hidden],
.hidden,
.d-none {
  display: none !important; }

@media (max-width: 1024px) {
  .desktop {
    display: none !important; } }

@media (min-width: 1025px) {
  .mobile {
    display: none !important; } }

.d-block {
  display: block; }

.d-inline-block {
  display: inline-block; }

.d-flex {
  display: flex; }

.d-inline-flex {
  display: inline-flex; }

.flex-wrap {
  flex-wrap: wrap; }

.align-center {
  align-items: center; }

.align-end {
  align-items: flex-end; }

.justify-center {
  justify-content: center; }

.justify-end {
  justify-content: flex-end; }

.justify-space-between {
  justify-content: space-between; }

.flex-center, .nav-bar-center, .dropdown-content .nav-bar-section {
  justify-content: center;
  align-items: center; }

.list-style-none {
  list-style-type: none; }

.ml-auto {
  margin-left: auto; }

.mr-auto {
  margin-right: auto; }

.mx-auto {
  margin-right: auto;
  margin-left: auto; }

.overflow-hidden {
  overflow: hidden; }

.font-italic {
  font-style: italic; }

.text-uppercase {
  text-transform: uppercase; }

.text-capitalize {
  text-transform: capitalize; }

.text-center {
  text-align: center; }

.field-validation-error {
  display: inline-block;
  margin: 0.5rem 0;
  font-size: 12px;
  color: red; }

.container-fluid {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto; }

.container, .wholesale-factory__content2 .right .content {
  width: 96%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto;
  position: relative; }

@media (min-width: 576px) {
  .container, .wholesale-factory__content2 .right .content {
    max-width: 95%; } }

@media (min-width: 1025px) {
  .container, .wholesale-factory__content2 .right .content {
    max-width: 900px; } }

@media (min-width: 1280px) {
  .container, .wholesale-factory__content2 .right .content {
    max-width: 1200px;
    padding-right: 3%;
    padding-left: 3%; } }

@media (min-width: 1440px) {
  .container, .wholesale-factory__content2 .right .content {
    max-width: 1280px;
    padding-right: 3%;
    padding-left: 3%; } }

@media (min-width: 1600px) {
  .container, .wholesale-factory__content2 .right .content {
    max-width: 1366px;
    padding-right: 3%;
    padding-left: 3%; } }

@media (min-width: 1800px) {
  .container, .wholesale-factory__content2 .right .content {
    max-width: 1640px;
    padding-right: 5%;
    padding-left: 5%; } }

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%; }

.col-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%; }

.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%; }

.col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%; }

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%; }

.col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%; }

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%; }

.col-11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%; }

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%; }

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-sm-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-sm-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-md-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-md-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 1025px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-lg-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-lg-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-xl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-xl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-xl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 1360px) {
  .col-2xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-2xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-2xl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-2xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-2xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-2xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-2xl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-2xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-2xl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-2xl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-2xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-2xl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-2xl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-2xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

.col,
[class*="col-"] {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.header_wapper {
  position: fixed;
  line-height: 92px;
  z-index: 1000;
  background-color: white;
  box-shadow: 0px 7px 10px #00000036;
  top: 0;
  left: 0;
  right: 0; }
  .header_wapper .container, .header_wapper .wholesale-factory__content2 .right .content, .wholesale-factory__content2 .right .header_wapper .content {
    position: static;
    display: flex;
    justify-content: space-between;
    padding: 0; }

.hidden-none {
  display: none; }

.header-top-section-left {
  justify-content: center;
  align-items: center;
  width: 260px; }
  .header-top-section-left .as-logo {
    height: 100%;
    width: 260px;
    display: inline-block;
    transition: 0.3s; }
    .header-top-section-left .as-logo:hover, .header-top-section-left .as-logo:active, .header-top-section-left .as-logo:focus {
      opacity: 0.7; }

.btn-menu {
  display: none; }

.nav-bar-center {
  flex-direction: row;
  white-space: nowrap; }
  .nav-bar-center .navbar-item:not(:first-child) {
    margin-left: 48px; }
  .nav-bar-center .navbar-item .nav-bar-link {
    display: block;
    font-family: "SVN-AvenirNext", sans-serif;
    font-weight: 600;
    color: #000;
    font-size: 16px;
    text-transform: uppercase; }
  .nav-bar-center .navbar-item .nav-bar-link:hover,
  .nav-bar-center .navbar-item .nav-bar-link:active,
  .nav-bar-center .navbar-item .nav-bar-link:focus {
    width: 100%;
    height: 100%;
    font-weight: bold; }
  @media (max-width: 1280px) {
    .nav-bar-center .navbar-item {
      font-size: 16px; } }

.header-top-section-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; }
  .header-top-section-right__list {
    height: 35px;
    display: flex;
    flex-direction: row;
    align-items: center;
    vertical-align: middle; }
    .header-top-section-right__list li {
      align-items: center;
      list-style: none;
      line-height: normal; }
      .header-top-section-right__list li > a {
        padding: 0 7px;
        color: #a32732;
        transition: 0.3s; }
        .header-top-section-right__list li > a:hover, .header-top-section-right__list li > a:active, .header-top-section-right__list li > a:focus {
          opacity: 0.7; }
    .header-top-section-right__list .user {
      border-left: none;
      position: relative;
      user-select: none; }
      .header-top-section-right__list .user .user-box {
        position: absolute;
        top: 100%;
        left: 50%;
        background-color: white;
        transform: translatex(-50%);
        display: none; }
        .header-top-section-right__list .user .user-box__content {
          border-top: 2px solid #a32732;
          margin-top: 16px;
          padding: 5px 12px; }
        .header-top-section-right__list .user .user-box__item {
          display: block;
          position: relative;
          cursor: pointer;
          white-space: nowrap; }
          .header-top-section-right__list .user .user-box__item::before {
            content: "\2022";
            position: absolute;
            top: 50%;
            left: -2%;
            transform: translateY(-50%); }
    .header-top-section-right__list .shop-cart {
      position: fixed;
      top: 212px;
      right: 0;
      z-index: 100;
      color: #707070;
      font-family: "UTM-NEO-Sans-Intel", sans-serif;
      font-size: 16px;
      font-weight: 400; }
      @media (max-width: 1024px) {
        .header-top-section-right__list .shop-cart {
          font-size: 15px; } }
      .header-top-section-right__list .shop-cart .shop-cart__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 62px;
        height: 64px;
        background-color: #a32732;
        border-radius: 10px 0 0 10px; }
        .header-top-section-right__list .shop-cart .shop-cart__icon.active ~ .shop-cart-box {
          animation: show-popup-block 0.1s linear forwards;
          display: block; }
        .header-top-section-right__list .shop-cart .shop-cart__icon.after--hidden::after {
          display: none; }
        .header-top-section-right__list .shop-cart .shop-cart__icon::after {
          content: attr(data-number);
          position: absolute;
          top: 12px;
          right: 5px;
          padding: 0 4px;
          height: 18px;
          min-width: 18px;
          border-radius: 50%;
          border: 1px solid #000;
          color: #fff;
          font-size: 10px;
          font-weight: 400;
          font-family: "SVN-AvenirNext", sans-serif;
          line-height: 18px;
          white-space: nowrap;
          text-align: center;
          background-color: #4f0007; }
        .header-top-section-right__list .shop-cart .shop-cart__icon img {
          pointer-events: none; }
      .header-top-section-right__list .shop-cart-box {
        display: none;
        position: absolute;
        top: 100%;
        right: 0%;
        margin-top: 12px;
        width: 500px;
        max-height: 450px;
        overflow-y: scroll;
        background-color: white;
        box-shadow: 0 0 5px rgba(112, 112, 112, 0.3);
        clip-path: circle(14.6% at 50% 0); }
        .header-top-section-right__list .shop-cart-box--close {
          position: absolute;
          top: 0;
          right: 0;
          width: 36px;
          height: 36px;
          cursor: pointer; }
          .header-top-section-right__list .shop-cart-box--close:hover {
            background-color: #c0c0c0; }
          .header-top-section-right__list .shop-cart-box--close::before, .header-top-section-right__list .shop-cart-box--close::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 70%;
            height: 2px;
            background-color: #707070;
            border-radius: 1px; }
          .header-top-section-right__list .shop-cart-box--close::before {
            transform: translate(-50%, -50%) rotate(45deg); }
          .header-top-section-right__list .shop-cart-box--close::after {
            transform: translate(-50%, -50%) rotate(-45deg); }
        .header-top-section-right__list .shop-cart-box--margin {
          position: relative;
          margin: 16px; }
        .header-top-section-right__list .shop-cart-box__title {
          font-weight: bold;
          letter-spacing: 1.5px;
          line-height: 24px;
          margin-bottom: 32px;
          display: block; }
        .header-top-section-right__list .shop-cart-box__button {
          outline: none;
          background-color: #a32732;
          color: #fff;
          width: 100%;
          line-height: 46px;
          text-align: center;
          font-weight: bold;
          border: 0;
          display: block; }
          .header-top-section-right__list .shop-cart-box__button:hover, .header-top-section-right__list .shop-cart-box__button:active {
            box-shadow: 0 0 8px rgba(112, 112, 112, 0.3); }
        .header-top-section-right__list .shop-cart-box__list {
          margin: 24px 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          max-height: 156px;
          overflow-y: scroll;
          box-shadow: 0 0 4px rgba(112, 112, 112, 0.1); }
        .header-top-section-right__list .shop-cart-box__item {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          padding-right: 12px;
          border: 0; }
          .header-top-section-right__list .shop-cart-box__item:not(:first-child) {
            margin: 12px 0; }
          .header-top-section-right__list .shop-cart-box__item--img {
            background-color: #c0c0c0;
            width: 70px;
            height: 55px;
            object-fit: cover;
            object-position: center; }
          .header-top-section-right__list .shop-cart-box__item--right {
            flex-basis: 30%;
            width: 30%;
            display: flex;
            justify-content: space-between;
            align-items: center; }
          .header-top-section-right__list .shop-cart-box__item--content {
            flex-basis: 50%;
            width: 50%; }
          .header-top-section-right__list .shop-cart-box__item--amount {
            width: 46px;
            line-height: 46px;
            white-space: pre-wrap;
            font-weight: bold;
            background-color: #c0c0c0;
            text-align: center; }
          .header-top-section-right__list .shop-cart-box__item--x {
            width: 36px;
            height: 36px;
            position: relative; }
            .header-top-section-right__list .shop-cart-box__item--x::before, .header-top-section-right__list .shop-cart-box__item--x::after {
              content: "";
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 70%;
              height: 2px;
              background-color: #707070;
              border-radius: 1px; }
            .header-top-section-right__list .shop-cart-box__item--x::before {
              transform: translate(-50%, -50%) rotate(45deg); }
            .header-top-section-right__list .shop-cart-box__item--x::after {
              transform: translate(-50%, -50%) rotate(-45deg); }
          .header-top-section-right__list .shop-cart-box__item--price {
            font-weight: bold;
            color: #707070;
            font-family: "UTM-NEO-Sans-Intel", sans-serif;
            font-size: 20px;
            font-weight: 400; }
            @media (max-width: 1024px) {
              .header-top-section-right__list .shop-cart-box__item--price {
                font-size: 18px; } }
            @media (max-width: 640px) {
              .header-top-section-right__list .shop-cart-box__item--price {
                font-size: 16px; } }
        .header-top-section-right__list .shop-cart-box__total {
          width: 100%;
          height: 56px;
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
          padding-right: 20%;
          background-color: #c0c0c0; }
          .header-top-section-right__list .shop-cart-box__total--desc {
            font-size: 18px; }
            @media screen and (min-width: 1024px) {
              .header-top-section-right__list .shop-cart-box__total--desc {
                font-size: 20px; } }
          .header-top-section-right__list .shop-cart-box__total--unit {
            font-weight: bold; }
          .header-top-section-right__list .shop-cart-box__total--price {
            font-weight: bold; }
    .header-top-section-right__list .find {
      position: relative; }
      .header-top-section-right__list .find__img.active ~ .find-box {
        display: flex; }
      .header-top-section-right__list .find-box {
        position: absolute;
        top: calc(100% + 38px);
        right: -10px;
        padding: 8px 12px;
        background: white;
        box-shadow: 0 0 5px rgba(112, 112, 112, 0.7);
        display: none;
        justify-content: center;
        align-items: center; }
        .header-top-section-right__list .find-box input[type="search"] {
          padding: 5px 40px 5px 12px;
          height: 36px;
          width: 320px;
          max-width: 100%;
          border-radius: 5px;
          border: 1px solid rgba(163, 39, 50, 0.3); }
          .header-top-section-right__list .find-box input[type="search"]:focus {
            border: 2px solid rgba(163, 39, 50, 0.7); }
        .header-top-section-right__list .find-box--submit {
          width: 40px;
          line-height: 36px;
          background: transparent;
          border: none;
          position: absolute;
          top: 50%;
          right: 12px;
          transform: translateY(-50%);
          background: transparent;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px; }
          .header-top-section-right__list .find-box--submit:hover {
            background-color: rgba(112, 112, 112, 0.4); }
    .header-top-section-right__list .languages {
      display: flex;
      margin-right: 12px; }
      .header-top-section-right__list .languages-item:not(:first-child) {
        margin-left: 6px; }
      .header-top-section-right__list .languages-item > a {
        display: block;
        width: 28px;
        height: 20px;
        position: relative; }
        .header-top-section-right__list .languages-item > a::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: #2f2f2f;
          opacity: 0.8;
          user-select: none;
          pointer-events: none;
          transition: 0.2s all ease-in-out; }
        .header-top-section-right__list .languages-item > a img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: contain; }
        .header-top-section-right__list .languages-item > a:hover::after {
          opacity: 0; }
      .header-top-section-right__list .languages-item.is-actived > *::after {
        content: none; }
  .header-top-section-right__ham {
    margin-left: 12px;
    height: 45px;
    width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none; }
    .header-top-section-right__ham .hamberger__img {
      cursor: pointer;
      height: 19px;
      width: 23px;
      position: relative; }
      .header-top-section-right__ham .hamberger__img::after, .header-top-section-right__ham .hamberger__img::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 0;
        border-top: 2px solid #000;
        border-radius: 4px;
        transition: 0.2s all ease-in-out; }
      .header-top-section-right__ham .hamberger__img::before {
        top: calc(50% - 7px); }
      .header-top-section-right__ham .hamberger__img::before {
        box-shadow: 0 7px 0 #000; }
      .header-top-section-right__ham .hamberger__img::after {
        top: calc(50% + 7px); }
      .header-top-section-right__ham .hamberger__img.active::before {
        top: 50%;
        box-shadow: 0 0 0 #000;
        transform: translate(-50%, -50%) rotate(45deg); }
      .header-top-section-right__ham .hamberger__img.active::after {
        top: 50%;
        transform: translate(-50%, -50%) rotate(-45deg); }
      .header-top-section-right__ham .hamberger__img.active ~ .hamberger-box {
        animation: show-hamberger 0.1s linear forwards;
        display: block; }
    .header-top-section-right__ham:hover .hamberger__img {
      opacity: 0.8; }
  .header-top-section-right .hamberger {
    position: absolute;
    top: 103px;
    right: 0;
    width: 100%;
    height: calc(100vh - 103px);
    background-color: rgba(112, 112, 112, 0.1);
    display: none; }
  .header-top-section-right .hamberger-box {
    display: none;
    z-index: 111;
    position: absolute;
    top: 103px;
    right: 0;
    width: 100%;
    max-width: 450px;
    max-height: calc(100vh - 103px);
    overflow-y: scroll;
    background-color: #c0c0c0;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3); }
    .header-top-section-right .hamberger-box__list {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start; }
    .header-top-section-right .hamberger-box__item {
      width: 100%;
      transition: max-height 2s linear; }
      .header-top-section-right .hamberger-box__item:not(:first-child) .hamberger-box__link {
        border-top: 1px solid #707070; }
      .header-top-section-right .hamberger-box__item:hover {
        background-color: #a32732;
        opacity: 0.8; }
        .header-top-section-right .hamberger-box__item:hover .hamberger-box__link {
          color: white; }
      .header-top-section-right .hamberger-box__item.hamberger-box__item-nav {
        display: none;
        visibility: hidden;
        opacity: 0; }
      .header-top-section-right .hamberger-box__item.active {
        background-color: #a32732; }
        .header-top-section-right .hamberger-box__item.active .hamberger-box__link {
          color: white; }
        .header-top-section-right .hamberger-box__item.active .box-sub {
          max-height: initial;
          background-color: #c0c0c0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start; }
          .header-top-section-right .hamberger-box__item.active .box-sub__item {
            width: 100%; }
            .header-top-section-right .hamberger-box__item.active .box-sub__item:hover {
              background-color: rgba(163, 39, 50, 0.7);
              opacity: 0.8; }
              .header-top-section-right .hamberger-box__item.active .box-sub__item:hover .box-sub__link {
                color: white; }
            .header-top-section-right .hamberger-box__item.active .box-sub__item:not(:first-child) .box-sub__link {
              border-top: 1px solid rgba(112, 112, 112, 0.7); }
          .header-top-section-right .hamberger-box__item.active .box-sub__link {
            display: block;
            width: 60%;
            margin: 0 auto;
            color: #a32732;
            text-transform: uppercase;
            font-size: 16px;
            line-height: 45px; }
        .header-top-section-right .hamberger-box__item.active .hamberger-box__link.box-flus::after {
          content: "\268A"; }
      .header-top-section-right .hamberger-box__item .box-sub {
        max-height: 0;
        display: none;
        transition: max-height 2s linear; }
    .header-top-section-right .hamberger-box__link {
      display: block;
      width: 80%;
      margin: 0 auto;
      line-height: 64px;
      color: #a32732;
      text-transform: uppercase; }
      .header-top-section-right .hamberger-box__link.box-flus {
        position: relative; }
        .header-top-section-right .hamberger-box__link.box-flus::after {
          position: absolute;
          top: 0;
          right: 0;
          width: 20px;
          height: 20px;
          content: "\271A"; }

@media (max-width: 1280px) {
  .header-top-section-right .hamberger-box__item.hamberger-box__item-nav {
    display: block;
    opacity: 1;
    visibility: visible; } }

.dropdown {
  display: inline-block;
  width: 100%;
  left: 0; }
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #a32732;
    line-height: 103px;
    z-index: 1;
    width: 100%;
    left: 0; }
    .dropdown-content a.nav-bar-link {
      text-decoration: none; }
    .dropdown-content .nav-bar-section {
      flex-direction: row;
      height: 103px; }
      .dropdown-content .nav-bar-section .nav {
        display: flex;
        text-align: center;
        justify-content: center;
        align-self: center;
        padding: 0 26px; }
        .dropdown-content .nav-bar-section .nav a {
          line-height: normal;
          transition: 0.2s all ease-in-out; }
          .dropdown-content .nav-bar-section .nav a:hover {
            transform: scale(1.2); }
          .dropdown-content .nav-bar-section .nav a p {
            color: white;
            line-height: normal; }

.dropdown:hover .dropdown-content {
  display: block; }

.dropdown:hover .nav-bar-link {
  font-weight: bold; }

.btn-menu:hover {
  cursor: pointer; }

@media (max-width: 1280px) {
  .header_wapper {
    line-height: 86px; }
  .header-top-section-right__list .find-box {
    top: calc(100% + 30px); }
  .header-top-section-right .hamberger-box {
    top: 86px;
    max-height: calc(100vh - 86px); }
  .header-top-section-right .header-top-section-right__list .shop-cart-box {
    margin: 0; } }

@media (max-width: 1280px) {
  .nav-bar-center {
    display: none; } }

@media (max-width: 768px) {
  .header_wapper {
    line-height: 78px; }
    .header_wapper .header-top-section-right .hamberger-box {
      top: 78px;
      max-height: calc(100vh - 78px); }
  .header-top-section-right__list .find-box {
    top: calc(100% + 27px); } }

@media (max-width: 640px) {
  .header-top-section-right .hamberger-box {
    max-width: 100%; }
  .header-top-section-right .shop-cart-box {
    max-height: calc(100vh - 78px);
    max-width: 100%; }
  .header-top-section-left {
    width: 150px; }
    .header-top-section-left .as-logo {
      width: 100%; } }

@media (max-width: 425px) {
  .header-top-section-right__list li.phone {
    display: none; }
  .header-top-section-right__list li + li {
    border-left: 0; }
  .header-top-section-right__list .find {
    position: static; }
  .header-top-section-right__list .find-box {
    top: 100%;
    right: 0;
    width: 100%; }
  .header-top-section-right__list .find-box input[type="search"] {
    width: 100%; } }

@keyframes popup {
  0% {
    opacity: 0;
    visibility: hidden;
    clip-path: inset(0 0 0 100%); }
  100% {
    opacity: 1;
    visibility: visible;
    clip-path: inset(0 0 0 0); } }

@keyframes closePopup {
  0% {
    opacity: 1;
    visibility: visible;
    clip-path: inset(0 0 0 0); }
  100% {
    opacity: 0;
    visibility: hidden;
    clip-path: inset(0 0 0 100%); } }

#form-forgot__wrapper,
#form-login__wrapper,
#form-user__wrapper,
#form-dealer__wrapper,
#form-custom__wrapper {
  display: none; }
  #form-forgot__wrapper.active,
  #form-login__wrapper.active,
  #form-user__wrapper.active,
  #form-dealer__wrapper.active,
  #form-custom__wrapper.active {
    display: flex;
    animation: popup 0.2s ease-in-out forwards; }

.loginform-content__wrapper {
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100vh;
  background-color: white; }
  .loginform-content__wrapper .loginform__close {
    position: absolute;
    top: 0;
    right: 0;
    background: #a32732;
    color: #ffffff;
    width: 50px;
    height: 50px;
    justify-content: center;
    border-bottom-left-radius: 20px;
    border: none; }
    .loginform-content__wrapper .loginform__close::after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #ffffff;
      content: "\2715";
      font-size: 22px;
      font-weight: 700; }
  .loginform-content__wrapper .loginform-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80vh; }
    .loginform-content__wrapper .loginform-content .register {
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #a32732; }
      .loginform-content__wrapper .loginform-content .register-heading {
        margin-top: 50px;
        color: #c0c0c0; }
      .loginform-content__wrapper .loginform-content .register-customer {
        width: 90%;
        display: flex;
        justify-content: center;
        align-items: center; }
        .loginform-content__wrapper .loginform-content .register-customer__namelist {
          font-size: 18px;
          color: #c0c0c0;
          margin: 10px 20px;
          width: 60%; }
        .loginform-content__wrapper .loginform-content .register-customer__checklist {
          margin-right: auto;
          width: 24px;
          height: 24px;
          border-radius: 20px; }
      .loginform-content__wrapper .loginform-content .register-sendmess {
        width: 70%;
        display: flex;
        justify-content: space-between; }
        .loginform-content__wrapper .loginform-content .register-sendmess__textlist {
          padding-left: 10px;
          margin: 15px 0px;
          width: 49%;
          height: 50px;
          border-radius: 10px; }
      .loginform-content__wrapper .loginform-content .register-enduser {
        padding: 0px 0px;
        width: 70%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .loginform-content__wrapper .loginform-content .register-enduser__btn {
          width: 120px;
          height: 40px;
          margin: 20px 75px;
          border: solid 3px #ffffff;
          background: #a32732;
          border-radius: 20px;
          justify-content: center;
          font-family: "SVN-AvenirNext", sans-serif;
          font-size: 16px;
          color: #c0c0c0; }
        .loginform-content__wrapper .loginform-content .register-enduser__textlist {
          padding-left: 10px;
          width: 100%;
          height: 50px;
          margin: 15px 0px;
          border-radius: 10px; }
        .loginform-content__wrapper .loginform-content .register-enduser__forgot {
          text-decoration: none;
          color: #c0c0c0; }
    .loginform-content__wrapper .loginform-content .login {
      margin-left: 40px;
      width: 50%;
      height: 80%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #a32732; }
      .loginform-content__wrapper .loginform-content .login-heading {
        margin: 20px;
        color: #c0c0c0; }
      .loginform-content__wrapper .loginform-content .login-customer {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center; }
        .loginform-content__wrapper .loginform-content .login-customer__namelist {
          font-size: 18px;
          color: #c0c0c0;
          margin: 10px 20px;
          width: 60%; }
        .loginform-content__wrapper .loginform-content .login-customer__checklist {
          margin-right: auto;
          width: 24px;
          height: 24px;
          border-radius: 20px; }
      .loginform-content__wrapper .loginform-content .login-sendmess {
        width: 80%;
        display: flex;
        justify-content: space-between; }
        .loginform-content__wrapper .loginform-content .login-sendmess__textlist {
          padding-left: 10px;
          margin: 15px 0px;
          width: 49%;
          height: 50px;
          border-radius: 10px; }
      .loginform-content__wrapper .loginform-content .login-enduser {
        padding: 0px 0px;
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .loginform-content__wrapper .loginform-content .login-enduser__btn {
          width: 120px;
          height: 40px;
          margin: 20px 75px;
          border: solid 3px #ffffff;
          background: #a32732;
          border-radius: 20px;
          justify-content: center;
          font-family: "SVN-AvenirNext", sans-serif;
          font-size: 16px;
          color: #c0c0c0; }
        .loginform-content__wrapper .loginform-content .login-enduser__textlist {
          padding-left: 10px;
          width: 100%;
          height: 50px;
          margin: 20px 0px;
          border-radius: 10px; }
        .loginform-content__wrapper .loginform-content .login-enduser__forgot {
          text-decoration: none;
          color: #c0c0c0; }
  .loginform-content__wrapper .login-right {
    width: 50%;
    height: 100%; }
    .loginform-content__wrapper .login-right .cart-section {
      padding: 0px 50px;
      width: 100%;
      position: relative; }
      .loginform-content__wrapper .login-right .cart-section::after {
        position: absolute;
        content: "";
        top: 50%;
        right: 47%;
        height: 1px;
        width: 100%;
        transform: rotate(90deg);
        background: #a32732;
        opacity: 0.2; }
      .loginform-content__wrapper .login-right .cart-section .cart-content__title {
        margin: 40px 0px;
        display: flex;
        justify-content: center; }
      .loginform-content__wrapper .login-right .cart-section .cart-header {
        border: solid #a32732 1px;
        margin: 15px 0px;
        padding: 0; }
      .loginform-content__wrapper .login-right .cart-section .cart-content {
        margin: 40px 0px;
        position: relative; }
        .loginform-content__wrapper .login-right .cart-section .cart-content::after {
          position: absolute;
          content: "";
          top: 120%;
          right: 0%;
          height: 1px;
          width: 100%;
          background: #a32732;
          opacity: 0.2; }
        .loginform-content__wrapper .login-right .cart-section .cart-content .cart-items__textview {
          width: 200px;
          height: 50px; }
        .loginform-content__wrapper .login-right .cart-section .cart-content .cart-items__symbolprice {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 85%;
          height: 50px;
          margin-left: 20px; }
        .loginform-content__wrapper .login-right .cart-section .cart-content .cart-items__symboltotal {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 85%;
          height: 50px;
          margin-left: 20px;
          background: #c0c0c0;
          position: relative; }
          .loginform-content__wrapper .login-right .cart-section .cart-content .cart-items__symboltotal::after {
            position: absolute;
            top: 50%;
            left: 90%;
            transform: translate(-50%, -50%);
            color: #ffffff;
            content: "\2715";
            font-size: 22px;
            font-weight: 700; }
        .loginform-content__wrapper .login-right .cart-section .cart-content .cart-items__quanlity {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 55%;
          height: 50px;
          margin-left: 25px;
          background: #c0c0c0; }
      .loginform-content__wrapper .login-right .cart-section .cart-footer {
        width: 100%; }
        .loginform-content__wrapper .login-right .cart-section .cart-footer__click {
          justify-content: space-between;
          border: none;
          width: 180px;
          height: 50px; }

.form__wrapper {
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white; }
  .form__wrapper .loginform__close {
    position: absolute;
    top: 0;
    right: 0;
    background: #a32732;
    color: #ffffff;
    width: 50px;
    height: 50px;
    justify-content: center;
    border-bottom-left-radius: 20px;
    border: none; }
    .form__wrapper .loginform__close::after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #ffffff;
      content: "\2715";
      font-size: 22px;
      font-weight: 700; }
  .form__wrapper .form__title {
    color: #a32732;
    margin: 42px 0;
    text-align: center; }
  .form__wrapper .form-content {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.7);
    padding: 0 24px 24px 24px;
    width: 560px;
    max-width: 100%;
    max-height: 90%;
    overflow-y: scroll;
    background: #a32732; }
    .form__wrapper .form-content__dealer {
      width: 720px; }
    .form__wrapper .form-content__title {
      color: #c0c0c0;
      font-weight: bold;
      padding: 64px 0;
      text-align: center;
      text-transform: uppercase; }
    .form__wrapper .form-content--group {
      width: 100%; }
    .form__wrapper .form-content .input-group {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center; }
      .form__wrapper .form-content .input-group:not(:first-child) {
        margin-top: 24px; }
      .form__wrapper .form-content .input-group--checkbox {
        flex: 1;
        text-align: left;
        display: flex;
        justify-content: flex-start;
        align-items: center; }
        .form__wrapper .form-content .input-group--checkbox label {
          white-space: nowrap;
          text-align: left;
          margin-left: 8px; }
        .form__wrapper .form-content .input-group--checkbox:not(:first-child) {
          margin-left: 2%; }
      .form__wrapper .form-content .input-group label {
        width: 50%;
        justify-self: center; }
      .form__wrapper .form-content .input-group input[type="checkbox"] {
        width: 20px;
        height: 20px; }
      .form__wrapper .form-content .input-group input + input {
        margin-left: 2%; }
      .form__wrapper .form-content .input-group input,
      .form__wrapper .form-content .input-group textarea,
      .form__wrapper .form-content .input-group select {
        border-radius: 10px;
        padding: 5px 16px;
        height: 56px;
        line-height: 36px;
        background-color: #ffffff;
        letter-spacing: 1px;
        font-size: 18px; }
        @media screen and (min-width: 1024px) {
          .form__wrapper .form-content .input-group input,
          .form__wrapper .form-content .input-group textarea,
          .form__wrapper .form-content .input-group select {
            font-size: 20px; } }
        .form__wrapper .form-content .input-group input.error,
        .form__wrapper .form-content .input-group textarea.error,
        .form__wrapper .form-content .input-group select.error {
          border: 2px solid rgba(255, 0, 0, 0.658) !important; }
        .form__wrapper .form-content .input-group input.success,
        .form__wrapper .form-content .input-group textarea.success,
        .form__wrapper .form-content .input-group select.success {
          border: 2px solid #4bb543 !important; }
      .form__wrapper .form-content .input-group input[type="text"],
      .form__wrapper .form-content .input-group input[type="email"],
      .form__wrapper .form-content .input-group input[type="password"],
      .form__wrapper .form-content .input-group input[type="tel"],
      .form__wrapper .form-content .input-group input[type="date"],
      .form__wrapper .form-content .input-group textarea {
        width: 100%; }
      .form__wrapper .form-content .input-group textarea {
        height: 120px;
        resize: none; }
      .form__wrapper .form-content .input-group input[type="submit"] {
        width: 240px;
        max-width: 100%;
        border-radius: 56px;
        border: 3px solid #c0c0c0;
        background-color: transparent;
        color: #c0c0c0; }
        .form__wrapper .form-content .input-group input[type="submit"]:hover {
          background-color: #c0c0c0;
          color: #a32732; }
      .form__wrapper .form-content .input-group input:focus {
        box-shadow: 0 0 5px rgba(112, 112, 112, 0.3); }
    .form__wrapper .form-content--link {
      display: inline-block;
      color: #c0c0c0;
      margin: 24px 0;
      font-size: 16px;
      font-style: italic; }
      .form__wrapper .form-content--link:hover, .form__wrapper .form-content--link:active {
        font-weight: bold;
        text-decoration: underline; }
    .form__wrapper .form-content-register {
      margin-top: 24px;
      border-top: 1px solid #c0c0c0;
      display: flex;
      justify-content: flex-start;
      flex-direction: column; }
      .form__wrapper .form-content-register .form-content--link {
        margin: 12px 0; }
  .form__wrapper .form-flex {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: white;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-left: 2%;
    min-height: 70%; }
    .form__wrapper .form-flex--left {
      width: 30%;
      min-width: 360px; }
    .form__wrapper .form-flex--right {
      width: 60%;
      min-width: 660px;
      margin-left: 2%;
      border-left: 1px solid #c0c0c0;
      align-self: stretch; }
      .form__wrapper .form-flex--right .form-cart {
        padding: 24px;
        width: 100%;
        max-width: 100%;
        height: 100%;
        background-color: #ffffff;
        color: #707070;
        display: flex;
        flex-direction: column; }
        .form__wrapper .form-flex--right .form-cart__title {
          color: #a32732;
          margin: 24px 0;
          text-align: center;
          font-weight: bold;
          text-transform: uppercase; }
        .form__wrapper .form-flex--right .form-cart-table {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          flex-direction: column;
          flex-grow: 1; }
          .form__wrapper .form-flex--right .form-cart-table__product {
            margin: 24px 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            height: 250px;
            max-height: 250px;
            overflow-y: scroll;
            box-shadow: 0 0 4px rgba(112, 112, 112, 0.1); }
            .form__wrapper .form-flex--right .form-cart-table__product--item:first-child {
              position: sticky;
              top: 0;
              z-index: 1;
              border: 1px solid #a32732;
              box-shadow: 0 5px 10px rgba(112, 112, 112, 0.3);
              background-color: white; }
            .form__wrapper .form-flex--right .form-cart-table__product--item {
              display: flex;
              flex-direction: row;
              width: 100%; }
            .form__wrapper .form-flex--right .form-cart-table__product .product__header {
              line-height: 46px; }
            .form__wrapper .form-flex--right .form-cart-table__product .product__item {
              min-height: 120px; }
              .form__wrapper .form-flex--right .form-cart-table__product .product__item:not(:first-child) {
                margin: 12px 0; }
              .form__wrapper .form-flex--right .form-cart-table__product .product__item--center {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center; }
              .form__wrapper .form-flex--right .form-cart-table__product .product__item--span {
                display: block;
                min-width: 72px;
                min-height: 64px;
                padding: 0px 20px;
                line-height: 64px;
                border-radius: 3px;
                background-color: #c0c0c0;
                font-weight: bold;
                color: #a32732;
                position: relative;
                user-select: none; }
              .form__wrapper .form-flex--right .form-cart-table__product .product__item--plus, .form__wrapper .form-flex--right .form-cart-table__product .product__item--minus {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 12px;
                height: 100%;
                opacity: 0.3; }
                .form__wrapper .form-flex--right .form-cart-table__product .product__item--plus:hover, .form__wrapper .form-flex--right .form-cart-table__product .product__item--minus:hover {
                  opacity: 0.8; }
              .form__wrapper .form-flex--right .form-cart-table__product .product__item--plus {
                left: auto;
                right: 4px; }
                .form__wrapper .form-flex--right .form-cart-table__product .product__item--plus::after {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  content: "\271A";
                  pointer-events: none; }
              .form__wrapper .form-flex--right .form-cart-table__product .product__item--minus {
                left: 4px;
                right: auto; }
                .form__wrapper .form-flex--right .form-cart-table__product .product__item--minus::after {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  content: "\268A";
                  pointer-events: none; }
              .form__wrapper .form-flex--right .form-cart-table__product .product__item--product {
                padding-left: 12px;
                flex-basis: 40%; }
                .form__wrapper .form-flex--right .form-cart-table__product .product__item--product-img {
                  width: 84px;
                  height: 64px;
                  max-height: 100%;
                  margin-right: 2%; }
                .form__wrapper .form-flex--right .form-cart-table__product .product__item--product-content {
                  padding-right: 8px;
                  flex: 1; }
              .form__wrapper .form-flex--right .form-cart-table__product .product__item--price {
                flex-basis: 20%;
                text-align: center; }
                .form__wrapper .form-flex--right .form-cart-table__product .product__item--price span {
                  background-color: transparent; }
              .form__wrapper .form-flex--right .form-cart-table__product .product__item--quanlity {
                flex-basis: 10%;
                text-align: center; }
              .form__wrapper .form-flex--right .form-cart-table__product .product__item--total {
                flex-basis: 20%;
                text-align: center; }
              .form__wrapper .form-flex--right .form-cart-table__product .product__item--close {
                cursor: pointer;
                flex-basis: 10%;
                text-align: center; }
                .form__wrapper .form-flex--right .form-cart-table__product .product__item--close span {
                  min-width: 36px;
                  min-height: 36px;
                  line-height: 36px;
                  position: relative;
                  background-color: transparent;
                  transition: 0.3s all linear; }
                  .form__wrapper .form-flex--right .form-cart-table__product .product__item--close span:hover {
                    background-color: #c0c0c0; }
                  .form__wrapper .form-flex--right .form-cart-table__product .product__item--close span img {
                    pointer-events: none; }
          .form__wrapper .form-flex--right .form-cart-table__footer {
            flex-grow: 1;
            display: flex;
            padding-top: 24px;
            border-top: 1px solid #c0c0c0;
            position: relative;
            max-height: 220px; }
            .form__wrapper .form-flex--right .form-cart-table__footer .footer__note {
              flex-basis: 40%;
              resize: none;
              height: calc(100% - 4px);
              padding: 12px;
              margin: 4px 12px; }
            .form__wrapper .form-flex--right .form-cart-table__footer .footer-subtotal {
              position: absolute;
              top: 24px;
              right: 10%; }
              .form__wrapper .form-flex--right .form-cart-table__footer .footer-subtotal p {
                color: #a32732; }
                .form__wrapper .form-flex--right .form-cart-table__footer .footer-subtotal p span {
                  font-weight: bold; }
            .form__wrapper .form-flex--right .form-cart-table__footer .footer-submit {
              flex-grow: 1;
              display: flex;
              justify-content: center;
              align-items: flex-end;
              flex-direction: row; }
              .form__wrapper .form-flex--right .form-cart-table__footer .footer-submit input {
                border-radius: 8px;
                border: 0;
                background-color: transparent;
                min-width: 180px;
                min-height: 48px;
                padding: 5px 8px;
                background-color: #ececec;
                margin: 0 12px;
                color: #a32732;
                transition: 0.3s ease-in-out; }
                .form__wrapper .form-flex--right .form-cart-table__footer .footer-submit input:focus, .form__wrapper .form-flex--right .form-cart-table__footer .footer-submit input:hover {
                  font-size: 22px;
                  font-weight: bold; }
              .form__wrapper .form-flex--right .form-cart-table__footer .footer-submit input[type="submit"] {
                background-color: #a32732;
                color: white; }
    .form__wrapper .form-flex .form-content {
      overflow-y: auto; }

@media (max-width: 768px) {
  .form__wrapper .form-content {
    width: 96%; } }

@media (max-width: 1280px) {
  #form-custom__wrapper .form-flex {
    flex-direction: column;
    justify-content: flex-start; }
  #form-custom__wrapper.form__wrapper .form-flex {
    margin: 0; }
    #form-custom__wrapper.form__wrapper .form-flex--left {
      min-width: auto;
      width: 90%; }
    #form-custom__wrapper.form__wrapper .form-flex--right {
      border: 0;
      width: 90%;
      min-width: auto;
      margin-left: 0;
      align-self: center;
      margin-bottom: 24px; }
      #form-custom__wrapper.form__wrapper .form-flex--right .form-cart-table__product {
        max-height: none;
        height: auto;
        overflow-x: scroll;
        overflow-y: hidden;
        align-items: flex-start;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); }
        #form-custom__wrapper.form__wrapper .form-flex--right .form-cart-table__product--item {
          min-width: 650px; }
      #form-custom__wrapper.form__wrapper .form-flex--right .form-cart-table__footer {
        flex-direction: column;
        padding: 64px 0 0 0; }
        #form-custom__wrapper.form__wrapper .form-flex--right .form-cart-table__footer .footer__note {
          width: 100%;
          height: 160px;
          flex-basis: initial;
          margin-bottom: 24px;
          border: 1px solid #a32732;
          outline: none;
          transition: 0.3s ease-in-out; }
          #form-custom__wrapper.form__wrapper .form-flex--right .form-cart-table__footer .footer__note:focus {
            padding-left: 24px; }
    #form-custom__wrapper.form__wrapper .form-flex .form-content {
      overflow-y: visible;
      width: 100%; }
  #form-custom__wrapper.form__wrapper .form-content {
    max-height: none; }
  #form-custom__wrapper.form__wrapper .form-flex--right .form-cart-table__footer {
    max-height: 320px; } }

@media (max-width: 640px) {
  #form-custom__wrapper.form__wrapper .form-flex--left {
    width: 98%; }
  #form-custom__wrapper.form__wrapper .form-flex--right {
    width: 98%; }
    #form-custom__wrapper.form__wrapper .form-flex--right .form-cart-table__footer .footer-submit {
      flex-direction: column; }
      #form-custom__wrapper.form__wrapper .form-flex--right .form-cart-table__footer .footer-submit > * {
        margin: 0; }
      #form-custom__wrapper.form__wrapper .form-flex--right .form-cart-table__footer .footer-submit > *:not(:first-child) {
        margin-top: 12px; } }

.footer {
  margin-top: 80px;
  background-color: #a32732;
  color: #fff; }
  @media screen and (min-width: 640px) {
    .footer {
      margin-top: 100px; } }
  @media screen and (min-width: 1024px) {
    .footer {
      margin-top: 128px; } }
  @media screen and (min-width: 1280px) {
    .footer {
      margin-top: 128px; } }
  .footer a {
    color: inherit; }
  .footer-wrapper {
    padding: 48px 0 24px 0;
    display: flex;
    flex-wrap: wrap; }
    .footer-wrapper__logo {
      display: block;
      width: 100%;
      margin-right: 0px;
      margin-bottom: 40px; }
      @media screen and (min-width: 768px) {
        .footer-wrapper__logo {
          width: 203px;
          margin-right: 100px; } }
      .footer-wrapper__logo > * {
        width: 100%;
        height: 60px;
        object-fit: contain;
        object-position: left; }
    .footer-wrapper-nav {
      width: calc(100% + 40px);
      flex: 1;
      margin-left: -40px;
      margin-top: -40px;
      display: flex;
      flex-wrap: wrap;
      font-family: "SVN-AvenirNext", sans-serif;
      justify-content: space-between; }
      .footer-wrapper-nav .nav-item {
        padding-top: 40px;
        padding-left: 40px;
        max-width: 50%;
        width: 50%; }
        @media screen and (min-width: 1280px) {
          .footer-wrapper-nav .nav-item {
            width: auto;
            max-width: 30%; } }
        .footer-wrapper-nav .nav-item > ul {
          margin-top: 16px;
          display: flex;
          flex-direction: column; }
          .footer-wrapper-nav .nav-item > ul > li + li {
            margin-top: 8px; }
          .footer-wrapper-nav .nav-item > ul > li > a {
            font-family: "SVN-AvenirNext", sans-serif;
            white-space: wrap;
            font-size: 15px;
            font-weight: 500; }
        .footer-wrapper-nav .nav-item--addr > ul > li + li {
          margin-top: 22px; }
        .footer-wrapper-nav .nav-item--addr > ul > li > a {
          font-size: 14px; }
  .footer-copyright {
    padding: 24px;
    border-top: 2px solid #fff;
    font-family: "SVN-AvenirNext", sans-serif;
    text-align: center;
    font-size: 15px;
    font-weight: 500; }

.pagination,
.pagination > ul {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center; }

.pagination {
  flex: 0 0 100%;
  max-width: 100%;
  --vh: 20px;
  --vw: 20px;
  --sText: 14px;
  --swArrow: 6px;
  --shArrow: 12px;
  --bgBtn: transparent;
  --text: #525252;
  --bgBtnHover: #a32732;
  --textHover: #fff;
  --bgArrow: transparent;
  --textArrow: #525252;
  --bgArrowHover: transparent;
  --textArrowHover: #a32732;
  list-style-type: none; }
  @media screen and (min-width: 1024px) {
    .pagination {
      --vh: 24px;
      --vw: 24px;
      --wh: 24px;
      --sText: 14px;
      --swArrow: 6px;
      --shArrow: 12x; } }
  .pagination > ul {
    width: calc(100% + 16px);
    list-style-type: none;
    display: flex;
    align-items: center; }
    .pagination > ul li {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      user-select: none; }
      .pagination > ul li:not(.is-actived):hover > a {
        color: var(--textHover);
        background-color: var(--bgBtnHover); }
      .pagination > ul li > a {
        display: inline-flex;
        font-size: var(--sText);
        font-weight: 500;
        justify-content: center;
        align-items: center;
        min-width: var(--vw);
        height: var(--vh);
        padding: 0 4px;
        color: var(--text);
        background-color: var(--bgBtn);
        transition: 0.3s ease-in-out;
        border-radius: 2px; }
      .pagination > ul li svg {
        width: var(--swArrow);
        height: var(--shArrow); }
      .pagination > ul li.is-actived > a {
        cursor: initial;
        color: var(--textHover) !important;
        background-color: var(--bgBtnHover) !important; }
    .pagination > ul li + li {
      margin-left: 10px; }
      @media screen and (min-width: 1024px) {
        .pagination > ul li + li {
          margin-left: 23px; } }
    .pagination > ul li.next,
    .pagination > ul li.prev {
      background-color: var(--bgArrow);
      transition: 0.3s ease-in-out;
      display: flex;
      justify-content: center;
      align-items: center; }
      .pagination > ul li.next > a,
      .pagination > ul li.prev > a {
        display: flex;
        justify-content: center;
        align-items: center; }
      .pagination > ul li.next svg,
      .pagination > ul li.prev svg {
        width: auto;
        height: auto; }
      .pagination > ul li.next path[fill],
      .pagination > ul li.prev path[fill] {
        fill: var(--textArrow); }
      .pagination > ul li.next path[stroke],
      .pagination > ul li.prev path[stroke] {
        stroke: var(--textArrow); }
      .pagination > ul li.next:not(.btn-hidden):hover,
      .pagination > ul li.prev:not(.btn-hidden):hover {
        background-color: var(--bgArrowHover); }
        .pagination > ul li.next:not(.btn-hidden):hover path[fill],
        .pagination > ul li.prev:not(.btn-hidden):hover path[fill] {
          fill: var(--textArrowHover); }
        .pagination > ul li.next:not(.btn-hidden):hover path[stroke],
        .pagination > ul li.prev:not(.btn-hidden):hover path[stroke] {
          stroke: var(--textArrowHover); }
      .pagination > ul li.next.btn-hidden,
      .pagination > ul li.prev.btn-hidden {
        display: none;
        cursor: initial;
        opacity: 0.3; }
    .pagination > ul li.prev {
      display: flex;
      align-items: center;
      justify-content: center; }
      .pagination > ul li.prev a {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 40px; }
      .pagination > ul li.prev svg {
        transform: rotate(180deg); }
    .pagination > ul li.next {
      display: flex;
      align-items: center;
      justify-content: center; }
      .pagination > ul li.next a {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 40px; }
    .pagination > ul li.dot {
      user-select: none;
      pointer-events: none;
      background-color: var(--bgBtn); }

.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 0;
  appearance: none; }

.tab-container .list-tab .item {
  cursor: pointer; }

.tab-container .tab-content .content {
  display: none;
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.2, 0.5, 0.5, 0.8); }
  .tab-container .tab-content .content.show {
    opacity: 1; }

#loading-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;
  transition: all 0.3s ease-in-out;
  background-color: #ffffff; }
  #loading-container .loading-wrapper {
    position: absolute;
    padding-bottom: 75px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 450px;
    padding: 0 20px; }
  #loading-container .progress {
    height: 15px;
    background-color: #e9ecef;
    box-shadow: 0px 0px 0px 2px white, 0px 0px 0px 3px black;
    border-radius: 8px;
    overflow: hidden; }
  #loading-container .progress-status {
    margin-top: 15px;
    text-align: center;
    font-size: 16px;
    letter-spacing: 3px;
    font-weight: 600; }
  #loading-container #progress-bar {
    max-width: 100%;
    width: 0%;
    height: 100%;
    background-color: #ff9e0c;
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.3) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0.3) 75%, transparent 75%, transparent);
    background-size: 1rem 1rem;
    background-repeat: repeat;
    animation: background 10s linear infinite forwards; }
  #loading-container #loading-logo {
    text-align: center;
    padding: 20px 0; }
    #loading-container #loading-logo img,
    #loading-container #loading-logo svg {
      max-width: 200px;
      width: 100%; }

@keyframes slideDown {
  0% {
    transform: translate3d(0, 100%, 0); }
  100% {
    transform: translate3d(0, 0%, 0); } }

@keyframes slideUp {
  0% {
    transform: translate3d(0, 0%, 0); }
  100% {
    transform: translate3d(0, 100%, 0); } }

.fp-container {
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: hidden; }

.fp-wrapper {
  width: 100%;
  height: 100%;
  position: relative; }

.fp-slide {
  width: 100%;
  height: 100%;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: #e6e6e6;
  box-shadow: 0 0 10px black;
  left: 0;
  top: 0;
  z-index: 1; }
  .fp-slide.active {
    z-index: 2; }
  .fp-slide.changing {
    z-index: 3; }

.fp-dots {
  position: fixed;
  z-index: 1001;
  right: 30px;
  bottom: 50px;
  width: 20px; }
  .fp-dots .fp-dot {
    width: 8px;
    height: 8px;
    border: 1px solid white;
    margin: 5px auto;
    border-radius: 4px;
    background-color: white;
    cursor: pointer;
    position: relative;
    transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1); }
    .fp-dots .fp-dot .fp-number {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;
      color: white;
      display: none; }
    .fp-dots .fp-dot .fp-title {
      position: absolute;
      right: 100%;
      top: 50%;
      transform: translateY(-50%);
      margin-right: -15px;
      padding-right: 10px;
      white-space: nowrap;
      opacity: 0;
      transition: all 0.5s ease;
      display: none; }
    .fp-dots .fp-dot.active {
      background-color: transparent;
      height: 16px; }
    @media (min-width: 1025px) {
      .fp-dots .fp-dot:hover .fp-title {
        margin-right: 0;
        opacity: 1; } }

.breadcrumb-wrapper {
  display: flex;
  align-items: center;
  padding-top: 36px;
  font-family: "SVN-AvenirNext", sans-serif;
  max-width: 100%;
  overflow-x: auto;
  /* Track */
  /* Handle */
  /* Handle on hover */ }
  .breadcrumb-wrapper .breadcrumb-items {
    font-size: 16px;
    color: #707070;
    text-transform: uppercase;
    padding-right: 20px;
    position: relative;
    white-space: nowrap;
    line-height: 20px; }
    .breadcrumb-wrapper .breadcrumb-items::after {
      content: ">";
      position: absolute;
      top: 50%;
      right: 0px;
      transform: translate(-50%, -50%);
      line-height: 20px;
      font-size: inherit; }
  .breadcrumb-wrapper .active {
    font-weight: 700; }
    .breadcrumb-wrapper .active::after {
      content: ""; }
  .breadcrumb-wrapper::-webkit-scrollbar {
    width: 0px;
    height: 2px; }
  .breadcrumb-wrapper::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0px #c0c0c0;
    border-radius: 10px; }
  .breadcrumb-wrapper::-webkit-scrollbar-thumb {
    border-radius: 10px; }
  .breadcrumb-wrapper::-webkit-scrollbar-thumb:hover {
    background: rgba(112, 112, 112, 0.7); }

@media (max-width: 1024px) {
  .breadcrumb-wrapper {
    margin: 0; } }

.dropdown-value .dropdown-item {
  margin-right: 10px;
  padding: 5px 10px;
  border: solid 2px #a32732;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  background: none;
  color: #707070; }
  .dropdown-value .dropdown-item.error {
    border: solid 2px rgba(255, 0, 0, 0.658); }
  .dropdown-value .dropdown-item.success {
    border: solid 2px #4bb543; }
  .dropdown-value .dropdown-item .dropdown-caret {
    height: 100%;
    margin-left: auto; }
    .dropdown-value .dropdown-item .dropdown-caret img {
      max-width: none;
      margin-left: 10px; }

.form-info__popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 8888;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s ease-in-out;
  z-index: 10000;
  min-height: 100%; }
  .form-info__popup .contact-1__section--form {
    padding: 0;
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: 0.4s ease-in-out;
    min-height: 100%;
    z-index: 10001; }
  .form-info__popup .btn-close {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 42px;
    height: 42px;
    border-radius: 0 0 0 16px;
    background: #a32732;
    cursor: pointer; }
    .form-info__popup .btn-close::after {
      position: absolute;
      width: 100%;
      height: 100%;
      transform: scale(0);
      top: 0;
      left: 0;
      content: "";
      background: white;
      border-radius: 0 0 0 16px;
      z-index: 2;
      transition: 0.3s ease-in-out;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); }
    .form-info__popup .btn-close:hover .line-1,
    .form-info__popup .btn-close:hover .line-2 {
      background: #a32732; }
    .form-info__popup .btn-close:hover::after {
      transform: scale(1); }
    .form-info__popup .btn-close .line-1,
    .form-info__popup .btn-close .line-2 {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 3px;
      height: 20px;
      border-radius: 20px;
      background: white;
      z-index: 3; }
    .form-info__popup .btn-close .line-1 {
      transform: translate(-50%, -50%) rotate(45deg); }
    .form-info__popup .btn-close .line-2 {
      transform: translate(-50%, -50%) rotate(135deg); }
  .form-info__popup .form-alert__popup--wrapper {
    background: #ffffff;
    max-width: 852px;
    width: 96%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: 0.4s ease-in-out;
    min-height: 100%;
    z-index: 10001;
    padding: 80px 45px;
    display: flex;
    flex-direction: column; }
    .form-info__popup .form-alert__popup--wrapper-alert {
      text-align: center;
      margin-bottom: 38px; }
      .form-info__popup .form-alert__popup--wrapper-alert > * {
        line-height: 32px;
        font-size: 32px;
        font-family: inherit;
        color: #000000;
        letter-spacing: 1.2px;
        font-weight: 400; }
    .form-info__popup .form-alert__popup--wrapper-btn {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center; }
      .form-info__popup .form-alert__popup--wrapper-btn .btn--link {
        width: 100%;
        max-width: 320px;
        padding: 16px 20px;
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border: 3px solid #a32732;
        border-radius: 6px;
        cursor: pointer;
        transition: 0.2s ease-in-out; }
        @media (max-width: 640px) {
          .form-info__popup .form-alert__popup--wrapper-btn .btn--link {
            max-width: 100%; } }
        .form-info__popup .form-alert__popup--wrapper-btn .btn--link > * {
          text-transform: uppercase;
          color: #a32732;
          font-family: "Segoe UI";
          font-weight: bold;
          font-size: 18px; }
          @media screen and (min-width: 1024px) {
            .form-info__popup .form-alert__popup--wrapper-btn .btn--link > * {
              font-size: 20px; } }
        .form-info__popup .form-alert__popup--wrapper-btn .btn--link:hover {
          background-color: #a32732 !important;
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); }
          .form-info__popup .form-alert__popup--wrapper-btn .btn--link:hover > * {
            color: #fff !important; }

.form-info__popup.active {
  visibility: visible;
  opacity: 1; }
  .form-info__popup.active .form-alert__popup--wrapper {
    transform: translate(-50%, -50%) scale(1); }
  .form-info__popup.active .contact-1__section--form {
    transform: translate(-50%, -50%) scale(1); }

.web4g {
  position: fixed;
  width: 100%;
  z-index: 10; }

.animated,
.fadein {
  animation-duration: 2s; }

body {
  position: relative; }

.index-intro .intro-pic-txt {
  position: relative;
  width: 100%;
  height: 100vh; }
  @media (max-width: 1024px) {
    .index-intro .intro-pic-txt {
      max-height: 720px; } }
  @media (max-width: 640px) {
    .index-intro .intro-pic-txt {
      max-height: 520px; } }

.index-intro .intro-pic {
  width: 100%;
  height: 100%; }
  .index-intro .intro-pic img.intro__background {
    height: 100% !important;
    width: 100% !important;
    object-fit: cover;
    object-position: center; }
  .index-intro .intro-pic iframe {
    height: 100% !important;
    width: 100% !important; }
  .index-intro .intro-pic .ytp-large-play-button {
    top: 70% !important; }

.index-intro .intro-txt {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 500;
  text-align: center;
  padding: 12px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .index-intro .intro-txt img,
  .index-intro .intro-txt svg {
    display: block;
    max-width: 100%;
    object-fit: contain;
    margin-bottom: 20px; }
  .index-intro .intro-txt .intro__heading {
    width: 96%;
    padding-right: 10px;
    padding-left: 10px;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    text-transform: uppercase;
    display: inline-block;
    color: white;
    margin-bottom: 40px; }
    @media (min-width: 576px) {
      .index-intro .intro-txt .intro__heading {
        max-width: 95%; } }
    @media (min-width: 1025px) {
      .index-intro .intro-txt .intro__heading {
        max-width: 900px; } }
    @media (min-width: 1280px) {
      .index-intro .intro-txt .intro__heading {
        max-width: 1200px;
        padding-right: 3%;
        padding-left: 3%; } }
    @media (min-width: 1440px) {
      .index-intro .intro-txt .intro__heading {
        max-width: 1280px;
        padding-right: 3%;
        padding-left: 3%; } }
    @media (min-width: 1600px) {
      .index-intro .intro-txt .intro__heading {
        max-width: 1366px;
        padding-right: 3%;
        padding-left: 3%; } }
    @media (min-width: 1800px) {
      .index-intro .intro-txt .intro__heading {
        max-width: 1640px;
        padding-right: 5%;
        padding-left: 5%; } }
    @media (max-width: 1380px) {
      .index-intro .intro-txt .intro__heading {
        font-size: 48px; } }
    @media (max-width: 1024px) {
      .index-intro .intro-txt .intro__heading {
        font-size: 40px; } }
    @media (max-width: 768px) {
      .index-intro .intro-txt .intro__heading {
        font-size: 32px; } }
    @media (max-width: 500px) {
      .index-intro .intro-txt .intro__heading {
        font-size: 28px;
        letter-spacing: 2px; } }
    @media (max-width: 425px) {
      .index-intro .intro-txt .intro__heading {
        font-size: 38px;
        letter-spacing: 1px; } }
    @media (max-width: 320px) {
      .index-intro .intro-txt .intro__heading {
        font-size: 32px;
        letter-spacing: 1px; } }
  .index-intro .intro-txt .intro__desc {
    text-transform: uppercase;
    color: white;
    letter-spacing: 5px; }
    @media (max-width: 500px) {
      .index-intro .intro-txt .intro__desc {
        font-size: 20px;
        letter-spacing: 2px; } }
    @media (max-width: 425px) {
      .index-intro .intro-txt .intro__desc {
        font-size: 20px;
        letter-spacing: 1px; } }
    @media (max-width: 320px) {
      .index-intro .intro-txt .intro__desc {
        font-size: 16px;
        letter-spacing: 1px; } }

.index-2 .slogan {
  background-color: #a32732;
  padding: 24px 0;
  text-align: center;
  position: relative; }
  .index-2 .slogan .scroll {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  .index-2 .slogan a {
    color: white;
    font-size: 18px;
    font-weight: 500; }
    @media screen and (min-width: 1024px) {
      .index-2 .slogan a {
        font-size: 20px; } }
  .index-2 .slogan span {
    font-weight: 700;
    font-size: 22px; }
    @media screen and (min-width: 640px) {
      .index-2 .slogan span {
        font-size: 26px; } }
    @media screen and (min-width: 1024px) {
      .index-2 .slogan span {
        font-size: 30px; } }

.index-2 .featured {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: rgba(163, 39, 50, 0.1); }
  .index-2 .featured-left {
    flex: 0 0 62%;
    height: 626px; }
    @media (max-width: 1440px) {
      .index-2 .featured-left {
        height: 580px; } }
    @media (max-width: 1024px) {
      .index-2 .featured-left {
        height: 100%; } }
    @media (max-width: 425px) {
      .index-2 .featured-left {
        height: 100%; } }
    .index-2 .featured-left img,
    .index-2 .featured-left iframe,
    .index-2 .featured-left video {
      height: 100%;
      object-fit: cover; }
      @media (max-width: 425px) {
        .index-2 .featured-left img,
        .index-2 .featured-left iframe,
        .index-2 .featured-left video {
          height: auto; } }
    @media (max-width: 1440px) {
      .index-2 .featured-left {
        flex: 0 0 58%; } }
    @media (max-width: 1144px) {
      .index-2 .featured-left {
        flex: 0 0 100%; } }
  .index-2 .featured-right {
    flex: 1;
    padding-left: 3%;
    padding-right: 6%;
    padding-top: 84px; }
    @media (max-width: 1280px) {
      .index-2 .featured-right {
        padding-right: 3%; } }
    @media (max-width: 1440px) {
      .index-2 .featured-right {
        padding-top: 64px; } }
    @media (max-width: 1336px) {
      .index-2 .featured-right {
        padding-top: 48px; } }
    @media (max-width: 1144px) {
      .index-2 .featured-right {
        padding-bottom: 48px; } }
    .index-2 .featured-right__subtitle {
      margin-top: 12px; }
      .index-2 .featured-right__subtitle > * {
        font-family: "UTM-NEO-Sans-Intel", sans-serif;
        font-size: 30px;
        font-weight: 500;
        color: #a32732; }
    .index-2 .featured-right__desc {
      margin-top: 32px; }
      @media (max-width: 1336px) {
        .index-2 .featured-right__desc {
          margin-top: 12px; } }
      .index-2 .featured-right__desc > * {
        font-family: "Segoe UI", sans-serif; }
    .index-2 .featured-right__button {
      margin-top: 32px; }
      @media (max-width: 1336px) {
        .index-2 .featured-right__button {
          margin-top: 12px; } }

.mattress-type {
  padding: 32px 0; }
  @media screen and (min-width: 640px) {
    .mattress-type {
      padding: 40px 0; } }
  @media screen and (min-width: 1024px) {
    .mattress-type {
      padding: 64px 0; } }
  @media screen and (min-width: 1280px) {
    .mattress-type {
      padding: 64px 0; } }

.title-mattress-type {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .title-mattress-type .title {
    margin-bottom: 32px; }
    @media screen and (min-width: 640px) {
      .title-mattress-type .title {
        margin-bottom: 40px; } }
    @media screen and (min-width: 1024px) {
      .title-mattress-type .title {
        margin-bottom: 64px; } }
    @media screen and (min-width: 1280px) {
      .title-mattress-type .title {
        margin-bottom: 64px; } }
    .title-mattress-type .title h3 {
      text-align: center; }
  .title-mattress-type .menu {
    display: flex;
    text-align: center;
    justify-content: space-evenly;
    align-self: center;
    width: 100%;
    flex-wrap: wrap;
    margin-top: -12px;
    margin-left: -12px;
    margin-right: -12px; }
    .title-mattress-type .menu__item {
      height: auto;
      padding-top: 12px;
      padding-left: 12px;
      padding-right: 12px;
      display: flex;
      flex-direction: column;
      align-items: center; }
      @media screen and (max-width: 639px) {
        .title-mattress-type .menu__item {
          width: 100%; } }
    .title-mattress-type .menu p {
      color: #a32732;
      font-weight: 700;
      text-transform: uppercase; }
    .title-mattress-type .menu img {
      width: 54px;
      height: 54px; }
    @media screen and (max-width: null) {
      .title-mattress-type .menu {
        width: 100%;
        justify-content: space-evenly; } }
    @media screen and (max-width: 899px) {
      .title-mattress-type .menu {
        width: 100%;
        justify-content: space-between; } }

.mattress-type .collection-item {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 48px; }
  @media screen and (min-width: 640px) {
    .mattress-type .collection-item {
      margin-top: 60px; } }
  @media screen and (min-width: 1024px) {
    .mattress-type .collection-item {
      margin-top: 80px; } }
  @media screen and (min-width: 1280px) {
    .mattress-type .collection-item {
      margin-top: 80px; } }
  .mattress-type .collection-item:nth-of-type(2n) {
    flex-direction: row-reverse; }
    @media screen and (max-width: 767px) {
      .mattress-type .collection-item:nth-of-type(2n) {
        flex-direction: row; } }
    .mattress-type .collection-item:nth-of-type(2n) .collection-content {
      text-align: left; }

.mattress-type .collection__left {
  padding: 76px 54px;
  flex: 0 0 58.3333%;
  box-shadow: 0 0px 3px rgba(0, 0, 0, 0.16);
  display: flex;
  justify-content: center;
  align-items: center; }
  @media (max-width: 640px) {
    .mattress-type .collection__left {
      padding: 45px 24px; } }
  @media screen and (max-width: 767px) {
    .mattress-type .collection__left {
      flex: 0 0 100%; } }

.mattress-type .collection-right {
  padding: 24px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0px 3px rgba(0, 0, 0, 0.16);
  flex: 1;
  background-color: rgba(0, 0, 0, 0.02); }
  @media screen and (min-width: 1280px) {
    .mattress-type .collection-right {
      padding: 32px 60px; } }
  @media screen and (min-width: 1440px) {
    .mattress-type .collection-right {
      padding: 40px 90px; } }

.mattress-type .collection-content {
  text-align: right; }
  .mattress-type .collection-content__title > * {
    text-transform: uppercase;
    font-family: "SVN-AvenirNext", sans-serif;
    color: #000;
    font-size: 32px;
    font-weight: 500;
    font-family: "SVN-AvenirNext", sans-serif;
    font-weight: 500; }
    @media (max-width: 1024px) {
      .mattress-type .collection-content__title > * {
        font-size: 26px; } }
    @media (max-width: 640px) {
      .mattress-type .collection-content__title > * {
        font-size: 22px; } }
  .mattress-type .collection-content__desc {
    margin-top: 24px; }
    .mattress-type .collection-content__desc > * {
      color: #707070; }
  .mattress-type .collection-content__button {
    margin-top: 32px; }

.advertise {
  margin-top: 80px; }
  @media (max-width: 768px) {
    .advertise {
      margin-top: 64px; } }
  .advertise .advertise-content {
    position: relative;
    text-align: center;
    width: 100%; }
    .advertise .advertise-content::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3); }
  .advertise .pic {
    height: 100%;
    width: 100%; }
  .advertise .advertise-pic {
    display: block !important; }
    .advertise .advertise-pic a {
      display: block; }
    .advertise .advertise-pic img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      min-height: 560px; }
  .advertise .advertise-text {
    width: 96%;
    padding-right: 10px;
    padding-left: 10px;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    display: inline-flex;
    flex-direction: column;
    align-items: center; }
    @media (min-width: 576px) {
      .advertise .advertise-text {
        max-width: 95%; } }
    @media (min-width: 1025px) {
      .advertise .advertise-text {
        max-width: 900px; } }
    @media (min-width: 1280px) {
      .advertise .advertise-text {
        max-width: 1200px;
        padding-right: 3%;
        padding-left: 3%; } }
    @media (min-width: 1440px) {
      .advertise .advertise-text {
        max-width: 1280px;
        padding-right: 3%;
        padding-left: 3%; } }
    @media (min-width: 1600px) {
      .advertise .advertise-text {
        max-width: 1366px;
        padding-right: 3%;
        padding-left: 3%; } }
    @media (min-width: 1800px) {
      .advertise .advertise-text {
        max-width: 1640px;
        padding-right: 5%;
        padding-left: 5%; } }
  .advertise .header {
    margin-bottom: 40px;
    width: 100%;
    text-align: center; }
    @media (max-width: 1280px) {
      .advertise .header {
        margin-bottom: 32px; } }
    @media (max-width: 640px) {
      .advertise .header {
        margin-bottom: 12px; } }
    .advertise .header > * {
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 7px;
      font-size: 80px; }
      @media (max-width: 1600px) {
        .advertise .header > * {
          font-size: 64px; } }
      @media (max-width: 1280px) {
        .advertise .header > * {
          font-size: 54px; } }
      @media (max-width: 768px) {
        .advertise .header > * {
          font-size: 40px; } }
      @media (max-width: 530px) {
        .advertise .header > * {
          font-size: 32px; } }
  .advertise .content {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 40px 0;
    font-family: "SVN-AvenirNext", sans-serif;
    color: #000;
    font-size: 32px;
    font-weight: 500;
    font-weight: bold;
    text-align: center;
    width: 70%;
    letter-spacing: 1px;
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.3); }
    @media (max-width: 1280px) {
      .advertise .content {
        margin-bottom: 32px 0; } }
    @media (max-width: 640px) {
      .advertise .content {
        margin-bottom: 12px 0; } }
    @media (max-width: 1024px) {
      .advertise .content {
        font-size: 26px; } }
    @media (max-width: 640px) {
      .advertise .content {
        font-size: 22px; } }
  .advertise .readmore a {
    color: white;
    font-weight: bold;
    padding: 15px 25px;
    border-radius: 8px;
    border: 4px solid white;
    display: inline-block;
    font-size: 28px; }
  .advertise .readmore a:hover {
    background-color: #a32732;
    color: white; }

@media (max-width: 1280px) {
  .advertise .header {
    width: 90%; }
  .advertise .content {
    width: 80%; } }

@media (max-width: 1024px) {
  .advertise .readmore a {
    padding: 12px 20px;
    font-size: 21px; } }

@media (max-width: 640px) {
  .advertise .advertise-text {
    padding: 0; }
  .advertise .header {
    width: 100%; }
  .advertise .content {
    width: 100%; }
  .advertise .readmore a {
    padding: 8px 16px;
    font-size: 20px; }
  .advertise .advertise-pic img {
    min-height: 420px; } }

.our-tech {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 80px 0;
  background-color: rgba(0, 0, 0, 0.02); }
  @media screen and (min-width: 640px) {
    .our-tech {
      padding: 100px 0; } }
  @media screen and (min-width: 1024px) {
    .our-tech {
      padding: 128px 0; } }
  @media screen and (min-width: 1280px) {
    .our-tech {
      padding: 128px 0; } }
  .our-tech .title-our-technology .title {
    font-family: "SVN-AvenirNext", sans-serif;
    color: #000;
    font-size: 65px;
    font-weight: 500;
    margin-bottom: 16px; }
    @media (max-width: 1024px) {
      .our-tech .title-our-technology .title {
        font-size: 50px; } }
    @media (max-width: 640px) {
      .our-tech .title-our-technology .title {
        font-size: 45px; } }
  .our-tech .title-our-technology .desc {
    font-family: "SVN-AvenirNext", sans-serif;
    color: #000;
    font-size: 20px;
    font-weight: 500;
    width: 56%;
    margin: 0 auto; }
    @media (max-width: 1024px) {
      .our-tech .title-our-technology .desc {
        font-size: 19px; } }
    @media (max-width: 640px) {
      .our-tech .title-our-technology .desc {
        font-size: 18px; } }
  .our-tech .tech-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 48px;
    margin-top: -30px;
    margin-left: -12px;
    margin-right: -12px; }
    @media screen and (min-width: 640px) {
      .our-tech .tech-list {
        margin-top: 60px; } }
    @media screen and (min-width: 1024px) {
      .our-tech .tech-list {
        margin-top: 80px; } }
    @media screen and (min-width: 1280px) {
      .our-tech .tech-list {
        margin-top: 80px; } }
    @media (max-width: 768px) {
      .our-tech .tech-list {
        padding-top: 72px;
        flex-direction: column; } }
    .our-tech .tech-list > * {
      flex: 0 0 33.3333%; }
      @media screen and (max-width: 1279px) {
        .our-tech .tech-list > * {
          flex: 0 0 50%; } }
      @media screen and (max-width: 767px) {
        .our-tech .tech-list > * {
          flex: 0 0 100%; } }
    @media (max-width: 768px) {
      .our-tech .tech-list {
        margin-top: -12px;
        margin-left: -8px;
        margin-right: -8px; } }
    .our-tech .tech-list-item {
      padding: 30px 12px;
      display: flex;
      flex-direction: column; }
      @media (max-width: 768px) {
        .our-tech .tech-list-item {
          padding: 12px 8px; } }
      .our-tech .tech-list-item__title {
        margin-top: 20px; }
        @media screen and (min-width: 640px) {
          .our-tech .tech-list-item__title {
            margin-top: 32px; } }
        @media screen and (min-width: 1024px) {
          .our-tech .tech-list-item__title {
            margin-top: 40px; } }
        @media screen and (min-width: 1280px) {
          .our-tech .tech-list-item__title {
            margin-top: 40px; } }
        .our-tech .tech-list-item__title > * {
          font-family: "UTM-NEO-Sans-Intel", sans-serif;
          font-size: 22px;
          font-weight: 600;
          color: #000; }
          @media screen and (min-width: 640px) {
            .our-tech .tech-list-item__title > * {
              font-size: 26px; } }
          @media screen and (min-width: 1024px) {
            .our-tech .tech-list-item__title > * {
              font-size: 30px; } }
      .our-tech .tech-list-item__desc {
        margin-top: 16px; }
        .our-tech .tech-list-item__desc > * {
          font-size: 18px;
          font-weight: 500;
          color: #707070; }
          @media screen and (min-width: 1024px) {
            .our-tech .tech-list-item__desc > * {
              font-size: 20px; } }
        @media (max-width: 768px) {
          .our-tech .tech-list-item__desc {
            margin-top: 12px; } }
      .our-tech .tech-list-item__img {
        display: block;
        height: 120px; }
        .our-tech .tech-list-item__img img {
          height: 100%; }

.sf {
  background-color: rgba(0, 0, 0, 0.1);
  position: relative; }
  .sf-content {
    width: 40%;
    padding: 160px 0; }
    @media (max-width: 1900px) {
      .sf-content {
        padding: 120px 0; } }
    @media (max-width: 1660px) {
      .sf-content {
        padding: 60px 0; } }
    @media (max-width: 1440px) {
      .sf-content {
        padding: 30px 0; } }
    @media (max-width: 1365px) {
      .sf-content {
        padding: 64px 0;
        width: 100%; } }
    .sf-content__title > * {
      font-family: "SVN-AvenirNext", sans-serif;
      color: #000;
      font-size: 50px;
      font-weight: 500; }
      @media (max-width: 1024px) {
        .sf-content__title > * {
          font-size: 40px; } }
      @media (max-width: 640px) {
        .sf-content__title > * {
          font-size: 32px; } }
      @media (max-width: 425px) {
        .sf-content__title > * {
          font-size: 25px; } }
    .sf-content__desc {
      padding-right: 50px;
      margin-top: 40px; }
      .sf-content__desc > * {
        font-family: "SVN-AvenirNext", sans-serif;
        font-weight: 500;
        font-size: 18px; }
        @media screen and (min-width: 1024px) {
          .sf-content__desc > * {
            font-size: 20px; } }
  .sf-img {
    position: absolute;
    top: 50%;
    height: 100%;
    transform: translateY(-50%);
    right: 0; }
    @media (max-width: 1336px) {
      .sf-img {
        position: initial;
        top: initial;
        right: initial;
        transform: none; } }
    .sf-img img,
    .sf-img video,
    .sf-img iframe {
      width: 1062px;
      height: 100%;
      object-fit: cover; }
      @media (max-width: 1820px) {
        .sf-img img,
        .sf-img video,
        .sf-img iframe {
          width: 920px; } }
      @media (max-width: 1640px) {
        .sf-img img,
        .sf-img video,
        .sf-img iframe {
          width: 840px; } }
      @media (max-width: 1440px) {
        .sf-img img,
        .sf-img video,
        .sf-img iframe {
          width: 740px; } }
      @media (max-width: 1336px) {
        .sf-img img,
        .sf-img video,
        .sf-img iframe {
          width: 100%;
          max-height: 50vh; } }
      @media (max-width: 768px) {
        .sf-img img,
        .sf-img video,
        .sf-img iframe {
          width: 100%;
          max-height: 50vh; } }

.lifetime {
  margin-top: 80px; }
  @media (max-width: 768px) {
    .lifetime {
      margin-top: 64px; } }
  .lifetime--container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
    @media (max-width: 1280px) {
      .lifetime--container {
        flex-direction: column; } }
  .lifetime__img {
    align-self: center;
    width: 41.6667%; }
    @media (max-width: 1820px) {
      .lifetime__img {
        width: 49.6667%; } }
    @media (max-width: 1280px) {
      .lifetime__img {
        width: 100%; }
        .lifetime__img img {
          width: 100%; } }
    @media (max-width: 768px) {
      .lifetime__img {
        padding-bottom: 0; } }
  .lifetime-content {
    width: calc(50% + 24px);
    margin-left: auto;
    padding-top: 70px;
    padding-bottom: 48px; }
    @media (max-width: 1820px) {
      .lifetime-content {
        padding-top: 60px;
        width: calc(42% + 24px); } }
    @media (max-width: 1280px) {
      .lifetime-content {
        padding-top: 24px;
        width: 100%; } }
    .lifetime-content__title > * {
      font-family: "SVN-AvenirNext", sans-serif;
      color: #000;
      font-size: 50px;
      font-weight: 500; }
      @media (max-width: 1024px) {
        .lifetime-content__title > * {
          font-size: 40px; } }
      @media (max-width: 640px) {
        .lifetime-content__title > * {
          font-size: 32px; } }
      @media (max-width: 425px) {
        .lifetime-content__title > * {
          font-size: 25px; } }
    .lifetime-content__desc > * {
      font-family: "SVN-AvenirNext", sans-serif;
      font-weight: 500;
      font-size: 18px; }
      @media screen and (min-width: 1024px) {
        .lifetime-content__desc > * {
          font-size: 20px; } }
    .lifetime-content__button > * {
      font-family: "SVN-AvenirNext", sans-serif;
      color: #000;
      font-weight: 700; }
    .lifetime-content > *:not(:first-child) {
      margin-top: 32px; }
      @media (max-width: 768px) {
        .lifetime-content > *:not(:first-child) {
          margin-top: 24px; } }

.AS-container {
  margin-top: 80px;
  background-color: #a32732;
  color: #c0c0c0; }
  @media (max-width: 768px) {
    .AS-container {
      margin-top: 64px; } }

.AS-header {
  padding-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center; }
  @media (max-width: 768px) {
    .AS-header {
      padding-top: 64px; } }
  .AS-header > * {
    font-family: "SVN-AvenirNext", sans-serif;
    color: #000;
    font-size: 50px;
    font-weight: 500;
    color: white; }
    @media (max-width: 1024px) {
      .AS-header > * {
        font-size: 40px; } }
    @media (max-width: 640px) {
      .AS-header > * {
        font-size: 32px; } }

.AS-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  letter-spacing: 1px;
  padding-bottom: 88px; }
  @media (max-width: 768px) {
    .AS-content {
      padding-bottom: 64px; } }
  .AS-content-1 {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: center;
    padding: 0 12px; }
    @media (max-width: 1280px) {
      .AS-content-1 {
        margin-top: 32px; } }
    @media (max-width: 640px) {
      .AS-content-1 {
        margin-top: 12px; } }
    @media (max-width: 425px) {
      .AS-content-1 {
        padding: 0; } }
    .AS-content-1 > * {
      font-weight: 600;
      font-family: "SVN-AvenirNext", sans-serif;
      color: #000;
      font-size: 32px;
      font-weight: 500;
      color: #c0c0c0;
      font-family: "Segoe UI", sans-serif; }
      @media (max-width: 1024px) {
        .AS-content-1 > * {
          font-size: 26px; } }
      @media (max-width: 640px) {
        .AS-content-1 > * {
          font-size: 22px; } }
    .AS-content-1 .before,
    .AS-content-1 .after {
      height: fit-content;
      width: 64px;
      height: 43px;
      object-fit: contain;
      padding-right: 12px; }
      @media (max-width: 1280px) {
        .AS-content-1 .before,
        .AS-content-1 .after {
          width: 48px; } }
      @media (max-width: 425px) {
        .AS-content-1 .before,
        .AS-content-1 .after {
          width: 35px; } }
    .AS-content-1 .before {
      align-items: flex-start;
      margin-right: 32px; }
      @media (max-width: 1280px) {
        .AS-content-1 .before {
          margin-right: 8px; } }
    .AS-content-1 .after {
      transform: rotate(180deg) translateY(-16px);
      align-self: flex-end;
      margin-left: 32px; }
      @media (max-width: 1280px) {
        .AS-content-1 .after {
          margin-left: 8px; } }
    .AS-content-1 p {
      padding: 0 16px; }
      @media (max-width: 768px) {
        .AS-content-1 p {
          padding: 0 8px; } }
  .AS-content-2 {
    margin-top: 40px; }
    @media (max-width: 1280px) {
      .AS-content-2 {
        margin-top: 32px; } }
    @media (max-width: 640px) {
      .AS-content-2 {
        margin-top: 12px; } }
    .AS-content-2 p {
      font-family: "SVN-AvenirNext", sans-serif;
      color: #000;
      font-size: 32px;
      font-weight: 500;
      color: #c0c0c0;
      font-weight: 300;
      font-style: italic;
      font-family: "Segoe UI", sans-serif;
      letter-spacing: 2px; }
      @media (max-width: 1024px) {
        .AS-content-2 p {
          font-size: 26px; } }
      @media (max-width: 640px) {
        .AS-content-2 p {
          font-size: 22px; } }
  .AS-content-3 {
    margin-top: 40px; }
    @media (max-width: 1280px) {
      .AS-content-3 {
        margin-top: 32px; } }
    @media (max-width: 640px) {
      .AS-content-3 {
        margin-top: 12px; } }
    .AS-content-3 a {
      text-transform: uppercase;
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      border: 3px solid white;
      border-radius: 6px;
      padding: 16px 4px;
      min-width: 186px;
      color: white;
      font-weight: bold; }
      .AS-content-3 a:hover {
        color: #a32732;
        border: 3px solid #a32732;
        background-color: #c0c0c0; }

.index-logo .container, .index-logo .wholesale-factory__content2 .right .content, .wholesale-factory__content2 .right .index-logo .content {
  display: flex;
  flex-direction: row;
  justify-content: space-between; }

.index-page .footer {
  margin-top: 0px; }

.mattress-collections__wrapper {
  background: #ececec; }
  .mattress-collections__wrapper .container, .mattress-collections__wrapper .wholesale-factory__content2 .right .content, .wholesale-factory__content2 .right .mattress-collections__wrapper .content {
    text-align: center; }
    .mattress-collections__wrapper .container .mattress-collections, .mattress-collections__wrapper .wholesale-factory__content2 .right .content .mattress-collections, .wholesale-factory__content2 .right .mattress-collections__wrapper .content .mattress-collections {
      padding: 32px 0; }
      @media screen and (min-width: 640px) {
        .mattress-collections__wrapper .container .mattress-collections, .mattress-collections__wrapper .wholesale-factory__content2 .right .content .mattress-collections, .wholesale-factory__content2 .right .mattress-collections__wrapper .content .mattress-collections {
          padding: 40px 0; } }
      @media screen and (min-width: 1024px) {
        .mattress-collections__wrapper .container .mattress-collections, .mattress-collections__wrapper .wholesale-factory__content2 .right .content .mattress-collections, .wholesale-factory__content2 .right .mattress-collections__wrapper .content .mattress-collections {
          padding: 64px 0; } }
      @media screen and (min-width: 1280px) {
        .mattress-collections__wrapper .container .mattress-collections, .mattress-collections__wrapper .wholesale-factory__content2 .right .content .mattress-collections, .wholesale-factory__content2 .right .mattress-collections__wrapper .content .mattress-collections {
          padding: 64px 0; } }
      .mattress-collections__wrapper .container .mattress-collections .collections-section, .mattress-collections__wrapper .wholesale-factory__content2 .right .content .mattress-collections .collections-section, .wholesale-factory__content2 .right .mattress-collections__wrapper .content .mattress-collections .collections-section {
        margin: 32px 0; }
        @media screen and (min-width: 640px) {
          .mattress-collections__wrapper .container .mattress-collections .collections-section, .mattress-collections__wrapper .wholesale-factory__content2 .right .content .mattress-collections .collections-section, .wholesale-factory__content2 .right .mattress-collections__wrapper .content .mattress-collections .collections-section {
            margin: 40px 0; } }
        @media screen and (min-width: 1024px) {
          .mattress-collections__wrapper .container .mattress-collections .collections-section, .mattress-collections__wrapper .wholesale-factory__content2 .right .content .mattress-collections .collections-section, .wholesale-factory__content2 .right .mattress-collections__wrapper .content .mattress-collections .collections-section {
            margin: 64px 0; } }
        @media screen and (min-width: 1280px) {
          .mattress-collections__wrapper .container .mattress-collections .collections-section, .mattress-collections__wrapper .wholesale-factory__content2 .right .content .mattress-collections .collections-section, .wholesale-factory__content2 .right .mattress-collections__wrapper .content .mattress-collections .collections-section {
            margin: 64px 0; } }
        .mattress-collections__wrapper .container .mattress-collections .collections-section .options, .mattress-collections__wrapper .wholesale-factory__content2 .right .content .mattress-collections .collections-section .options, .wholesale-factory__content2 .right .mattress-collections__wrapper .content .mattress-collections .collections-section .options {
          font-family: "SVN-AvenirNext", sans-serif;
          color: #000;
          font-size: 32px;
          font-weight: 500;
          color: #000;
          padding: 10px 20px;
          cursor: pointer;
          display: inline-block; }
          @media (max-width: 1024px) {
            .mattress-collections__wrapper .container .mattress-collections .collections-section .options, .mattress-collections__wrapper .wholesale-factory__content2 .right .content .mattress-collections .collections-section .options, .wholesale-factory__content2 .right .mattress-collections__wrapper .content .mattress-collections .collections-section .options {
              font-size: 26px; } }
          @media (max-width: 640px) {
            .mattress-collections__wrapper .container .mattress-collections .collections-section .options, .mattress-collections__wrapper .wholesale-factory__content2 .right .content .mattress-collections .collections-section .options, .wholesale-factory__content2 .right .mattress-collections__wrapper .content .mattress-collections .collections-section .options {
              font-size: 22px; } }
          .mattress-collections__wrapper .container .mattress-collections .collections-section .options + .options, .mattress-collections__wrapper .wholesale-factory__content2 .right .content .mattress-collections .collections-section .options + .options, .wholesale-factory__content2 .right .mattress-collections__wrapper .content .mattress-collections .collections-section .options + .options {
            margin-left: 120px;
            position: relative; }
            @media (max-width: 425px) {
              .mattress-collections__wrapper .container .mattress-collections .collections-section .options + .options, .mattress-collections__wrapper .wholesale-factory__content2 .right .content .mattress-collections .collections-section .options + .options, .wholesale-factory__content2 .right .mattress-collections__wrapper .content .mattress-collections .collections-section .options + .options {
                margin-left: 70px; } }
            .mattress-collections__wrapper .container .mattress-collections .collections-section .options + .options::after, .mattress-collections__wrapper .wholesale-factory__content2 .right .content .mattress-collections .collections-section .options + .options::after, .wholesale-factory__content2 .right .mattress-collections__wrapper .content .mattress-collections .collections-section .options + .options::after {
              position: absolute;
              content: "";
              top: 50%;
              left: -60px;
              height: 60%;
              transform: translate(-50%, -50%);
              width: 2px;
              background-color: #000; }
              @media (max-width: 425px) {
                .mattress-collections__wrapper .container .mattress-collections .collections-section .options + .options::after, .mattress-collections__wrapper .wholesale-factory__content2 .right .content .mattress-collections .collections-section .options + .options::after, .wholesale-factory__content2 .right .mattress-collections__wrapper .content .mattress-collections .collections-section .options + .options::after {
                  left: -35px; } }
        .mattress-collections__wrapper .container .mattress-collections .collections-section .active, .mattress-collections__wrapper .wholesale-factory__content2 .right .content .mattress-collections .collections-section .active, .wholesale-factory__content2 .right .mattress-collections__wrapper .content .mattress-collections .collections-section .active {
          font-family: "SVN-AvenirNext", sans-serif;
          color: #000;
          font-size: 32px;
          font-weight: 500;
          border: solid 2px #a32732;
          border-radius: 6px; }
          @media (max-width: 1024px) {
            .mattress-collections__wrapper .container .mattress-collections .collections-section .active, .mattress-collections__wrapper .wholesale-factory__content2 .right .content .mattress-collections .collections-section .active, .wholesale-factory__content2 .right .mattress-collections__wrapper .content .mattress-collections .collections-section .active {
              font-size: 28px; } }
          @media (max-width: 640px) {
            .mattress-collections__wrapper .container .mattress-collections .collections-section .active, .mattress-collections__wrapper .wholesale-factory__content2 .right .content .mattress-collections .collections-section .active, .wholesale-factory__content2 .right .mattress-collections__wrapper .content .mattress-collections .collections-section .active {
              font-size: 24px; } }
          @media (max-width: 425px) {
            .mattress-collections__wrapper .container .mattress-collections .collections-section .active, .mattress-collections__wrapper .wholesale-factory__content2 .right .content .mattress-collections .collections-section .active, .wholesale-factory__content2 .right .mattress-collections__wrapper .content .mattress-collections .collections-section .active {
              font-size: 18px; } }
        .mattress-collections__wrapper .container .mattress-collections .collections-section .collections__products, .mattress-collections__wrapper .wholesale-factory__content2 .right .content .mattress-collections .collections-section .collections__products, .wholesale-factory__content2 .right .mattress-collections__wrapper .content .mattress-collections .collections-section .collections__products {
          padding: 32px 0;
          display: flex;
          align-items: center; }
          .mattress-collections__wrapper .container .mattress-collections .collections-section .collections__products img, .mattress-collections__wrapper .wholesale-factory__content2 .right .content .mattress-collections .collections-section .collections__products img, .wholesale-factory__content2 .right .mattress-collections__wrapper .content .mattress-collections .collections-section .collections__products img {
            width: 57%; }
          @media screen and (min-width: 640px) {
            .mattress-collections__wrapper .container .mattress-collections .collections-section .collections__products, .mattress-collections__wrapper .wholesale-factory__content2 .right .content .mattress-collections .collections-section .collections__products, .wholesale-factory__content2 .right .mattress-collections__wrapper .content .mattress-collections .collections-section .collections__products {
              padding: 40px 0; } }
          @media screen and (min-width: 1024px) {
            .mattress-collections__wrapper .container .mattress-collections .collections-section .collections__products, .mattress-collections__wrapper .wholesale-factory__content2 .right .content .mattress-collections .collections-section .collections__products, .wholesale-factory__content2 .right .mattress-collections__wrapper .content .mattress-collections .collections-section .collections__products {
              padding: 64px 0; } }
          @media screen and (min-width: 1280px) {
            .mattress-collections__wrapper .container .mattress-collections .collections-section .collections__products, .mattress-collections__wrapper .wholesale-factory__content2 .right .content .mattress-collections .collections-section .collections__products, .wholesale-factory__content2 .right .mattress-collections__wrapper .content .mattress-collections .collections-section .collections__products {
              padding: 64px 0; } }
          .mattress-collections__wrapper .container .mattress-collections .collections-section .collections__products .product-infor, .mattress-collections__wrapper .wholesale-factory__content2 .right .content .mattress-collections .collections-section .collections__products .product-infor, .wholesale-factory__content2 .right .mattress-collections__wrapper .content .mattress-collections .collections-section .collections__products .product-infor {
            width: 43%;
            text-align: start;
            padding: 0 20px;
            font-size: inherit; }
      .mattress-collections__wrapper .container .mattress-collections .collections-scroll, .mattress-collections__wrapper .wholesale-factory__content2 .right .content .mattress-collections .collections-scroll, .wholesale-factory__content2 .right .mattress-collections__wrapper .content .mattress-collections .collections-scroll {
        display: flex;
        align-items: center;
        justify-content: center;
        transform: rotate(270deg);
        text-transform: uppercase;
        font-size: 16px;
        font-family: SEGUISB;
        color: #707070;
        opacity: 0.7;
        user-select: none;
        -moz-user-select: none;
        margin-bottom: 10px; }
        .mattress-collections__wrapper .container .mattress-collections .collections-scroll img, .mattress-collections__wrapper .wholesale-factory__content2 .right .content .mattress-collections .collections-scroll img, .wholesale-factory__content2 .right .mattress-collections__wrapper .content .mattress-collections .collections-scroll img {
          transform: rotate(90deg);
          margin-right: 20px;
          margin-top: 3px; }
        .mattress-collections__wrapper .container .mattress-collections .collections-scroll .text-scroll, .mattress-collections__wrapper .wholesale-factory__content2 .right .content .mattress-collections .collections-scroll .text-scroll, .wholesale-factory__content2 .right .mattress-collections__wrapper .content .mattress-collections .collections-scroll .text-scroll {
          background-size: 200%;
          background-image: linear-gradient(to right, rgba(255, 255, 255, 0.1), #a32732);
          background-clip: text;
          -webkit-text-fill-color: transparent;
          animation: gradient 1.3s;
          animation-iteration-count: infinite; }

@keyframes gradient {
  100% {
    background-position: 200%; } }

@media (max-width: 768px) {
  .collections__products {
    flex-direction: column; }
  .mattress-collections__wrapper .container .mattress-collections .collections-section .collections__products img, .mattress-collections__wrapper .wholesale-factory__content2 .right .content .mattress-collections .collections-section .collections__products img, .wholesale-factory__content2 .right .mattress-collections__wrapper .content .mattress-collections .collections-section .collections__products img {
    width: 100%; }
  .mattress-collections__wrapper .container .mattress-collections .collections-section .collections__products .product-infor, .mattress-collections__wrapper .wholesale-factory__content2 .right .content .mattress-collections .collections-section .collections__products .product-infor, .wholesale-factory__content2 .right .mattress-collections__wrapper .content .mattress-collections .collections-section .collections__products .product-infor {
    width: 100%;
    margin-top: 12px; }
  .mattress-collections__wrapper .container .mattress-collections .collections-scroll, .mattress-collections__wrapper .wholesale-factory__content2 .right .content .mattress-collections .collections-scroll, .wholesale-factory__content2 .right .mattress-collections__wrapper .content .mattress-collections .collections-scroll {
    display: inline-flex; } }

.mattress-recommend__wrapper {
  padding: 80px 0; }
  @media screen and (min-width: 640px) {
    .mattress-recommend__wrapper {
      padding: 100px 0; } }
  @media screen and (min-width: 1024px) {
    .mattress-recommend__wrapper {
      padding: 128px 0; } }
  @media screen and (min-width: 1280px) {
    .mattress-recommend__wrapper {
      padding: 128px 0; } }
  .mattress-recommend__wrapper .options-recommend {
    display: flex;
    align-items: center;
    margin-bottom: 40px; }
    @media (max-width: 1280px) {
      .mattress-recommend__wrapper .options-recommend {
        margin-bottom: 32px; } }
    @media (max-width: 640px) {
      .mattress-recommend__wrapper .options-recommend {
        margin-bottom: 12px; } }
    .mattress-recommend__wrapper .options-recommend__link {
      padding: 5px 25px;
      font-weight: 700;
      cursor: pointer;
      background: #ececec;
      color: #a32732; }
      .mattress-recommend__wrapper .options-recommend__link:first-child {
        border-radius: 5px 0px 0px 5px; }
      .mattress-recommend__wrapper .options-recommend__link:last-child {
        border-radius: 0px 5px 5px 0px; }
    .mattress-recommend__wrapper .options-recommend .active {
      background: #a32732;
      color: #ffffff; }
  @media (max-width: 1024px) {
    .mattress-recommend__wrapper .mattress-recommend {
      flex-direction: column;
      margin-right: 0;
      margin-left: 0; } }
  .mattress-recommend__wrapper .mattress-recommend .left-recommend {
    padding: 0;
    padding-right: 32px; }
    @media screen and (min-width: 640px) {
      .mattress-recommend__wrapper .mattress-recommend .left-recommend {
        padding-right: 40px; } }
    @media screen and (min-width: 1024px) {
      .mattress-recommend__wrapper .mattress-recommend .left-recommend {
        padding-right: 64px; } }
    @media screen and (min-width: 1280px) {
      .mattress-recommend__wrapper .mattress-recommend .left-recommend {
        padding-right: 64px; } }
    @media (max-width: 1024px) {
      .mattress-recommend__wrapper .mattress-recommend .left-recommend {
        padding-right: 0;
        max-width: 100%;
        flex: 0 0 100%; } }
    .mattress-recommend__wrapper .mattress-recommend .left-recommend .product-infor .product-infor__more {
      padding-top: 0;
      border-top: 0; }
      .mattress-recommend__wrapper .mattress-recommend .left-recommend .product-infor .product-infor__more span {
        font-weight: 400; }
    .mattress-recommend__wrapper .mattress-recommend .left-recommend .more-wrapper .btn > * {
      border-radius: 5px;
      border: 2px solid;
      text-transform: uppercase; }
  @media (max-width: 1024px) {
    .mattress-recommend__wrapper .mattress-recommend .right-recommend {
      margin-top: 30px;
      max-width: 100%;
      flex: 0 0 100%;
      padding-right: 0;
      padding-left: 0; } }
  .mattress-recommend__wrapper .mattress-recommend .right-recommend .swiper-btn__nav {
    height: 100%; }
    .mattress-recommend__wrapper .mattress-recommend .right-recommend .swiper-btn__nav .slide {
      height: 100%; }
      .mattress-recommend__wrapper .mattress-recommend .right-recommend .swiper-btn__nav .slide img {
        height: 410px;
        width: 100%;
        object-fit: contain;
        object-position: top; }
        @media (max-width: 425px) {
          .mattress-recommend__wrapper .mattress-recommend .right-recommend .swiper-btn__nav .slide img {
            height: auto; } }

@media (max-width: 768px) {
  .mattress-recommend__wrapper .mattress-recommend .left-recommend .more-wrapper {
    padding: 40px 0; } }
  @media (max-width: 768px) and (max-width: 1280px) {
    .mattress-recommend__wrapper .mattress-recommend .left-recommend .more-wrapper {
      padding: 32px 0; } }
  @media (max-width: 768px) and (max-width: 640px) {
    .mattress-recommend__wrapper .mattress-recommend .left-recommend .more-wrapper {
      padding: 12px 0; } }

.mattress-recommend-video__wrapper {
  display: block;
  padding: 80px 0;
  background: #a32732;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; }
  @media screen and (min-width: 640px) {
    .mattress-recommend-video__wrapper {
      padding: 100px 0; } }
  @media screen and (min-width: 1024px) {
    .mattress-recommend-video__wrapper {
      padding: 128px 0; } }
  @media screen and (min-width: 1280px) {
    .mattress-recommend-video__wrapper {
      padding: 128px 0; } }
  .mattress-recommend-video__wrapper .container, .mattress-recommend-video__wrapper .wholesale-factory__content2 .right .content, .wholesale-factory__content2 .right .mattress-recommend-video__wrapper .content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
  .mattress-recommend-video__wrapper .mattress-recommend-video {
    display: flex;
    align-items: center;
    flex-wrap: wrap; }
    .mattress-recommend-video__wrapper .mattress-recommend-video .left-recommend-video {
      width: 40%;
      margin-left: auto; }
      @media (max-width: 900px) {
        .mattress-recommend-video__wrapper .mattress-recommend-video .left-recommend-video {
          width: 100%; } }
      @media (max-width: 1800px) {
        .mattress-recommend-video__wrapper .mattress-recommend-video .left-recommend-video {
          width: 45%; } }
      @media (max-width: 1600px) {
        .mattress-recommend-video__wrapper .mattress-recommend-video .left-recommend-video {
          width: 48%; } }
      @media (max-width: 1440px) {
        .mattress-recommend-video__wrapper .mattress-recommend-video .left-recommend-video {
          width: 50%; } }
      .mattress-recommend-video__wrapper .mattress-recommend-video .left-recommend-video .product-infor {
        padding-right: 32px; }
        @media screen and (min-width: 640px) {
          .mattress-recommend-video__wrapper .mattress-recommend-video .left-recommend-video .product-infor {
            padding-right: 40px; } }
        @media screen and (min-width: 1024px) {
          .mattress-recommend-video__wrapper .mattress-recommend-video .left-recommend-video .product-infor {
            padding-right: 64px; } }
        @media screen and (min-width: 1280px) {
          .mattress-recommend-video__wrapper .mattress-recommend-video .left-recommend-video .product-infor {
            padding-right: 64px; } }
        .mattress-recommend-video__wrapper .mattress-recommend-video .left-recommend-video .product-infor > * {
          color: #c0c0c0; }
        .mattress-recommend-video__wrapper .mattress-recommend-video .left-recommend-video .product-infor span {
          text-transform: uppercase;
          font-weight: 400; }
        .mattress-recommend-video__wrapper .mattress-recommend-video .left-recommend-video .product-infor .product-infor__content {
          margin-top: 32px; }
          .mattress-recommend-video__wrapper .mattress-recommend-video .left-recommend-video .product-infor .product-infor__content > *:not(:first-child) {
            margin-top: 18px; }
  .mattress-recommend-video__wrapper .right-recommend-video {
    right: 0;
    width: 50%; }
    .mattress-recommend-video__wrapper .right-recommend-video .video-section {
      height: 100%;
      overflow: hidden; }
      .mattress-recommend-video__wrapper .right-recommend-video .video-section__img {
        min-height: 380px; }
      .mattress-recommend-video__wrapper .right-recommend-video .video-section img {
        object-fit: contain;
        object-position: center;
        width: 100%;
        height: 100%;
        padding: 0 24px; }
  @media (max-width: 1024px) {
    .mattress-recommend-video__wrapper .mattress-recommend-video__wrapper .mattress-recommend-video .left-recommend-video {
      width: 100%; }
    .mattress-recommend-video__wrapper .mattress-recommend-video__wrapper {
      flex-direction: column; }
    .mattress-recommend-video__wrapper .mattress-recommend-video__wrapper .right-recommend-video {
      transform: none;
      position: static;
      top: initial;
      right: initial;
      width: 96%; }
    .mattress-recommend-video__wrapper .mattress-recommend-video__wrapper .mattress-recommend-video .left-recommend-video .product-infor {
      padding: 0; } }
  @media (max-width: 1024px) {
    .mattress-recommend-video__wrapper .mattress-recommend-video__wrapper .mattress-recommend-video {
      padding: 24px 0; } }

@media (max-width: 1024px) {
  .mattress-recommend-video__wrapper .mattress-recommend-video .left-recommend-video {
    width: 100%; }
  .mattress-recommend-video__wrapper {
    flex-direction: column; }
  .mattress-recommend-video__wrapper .right-recommend-video {
    transform: none;
    position: static;
    top: initial;
    right: initial;
    width: 96%; }
  .mattress-recommend-video__wrapper .mattress-recommend-video .left-recommend-video .product-infor {
    padding: 0; } }

@media (max-width: 1024px) {
  .mattress-recommend-video__wrapper .mattress-recommend-video {
    padding: 24px 0; } }

.about-story-down__wrapper .btn a {
  margin-top: 40px;
  padding: 8px 16px 8px 5px;
  border: solid 2px #041e42;
  border-radius: 5px;
  cursor: pointer; }
  @media (max-width: 1280px) {
    .about-story-down__wrapper .btn a {
      margin-top: 32px; } }
  @media (max-width: 640px) {
    .about-story-down__wrapper .btn a {
      margin-top: 12px; } }

.about-story-down__wrapper .our-story-down {
  margin: 32px 0;
  display: flex;
  flex-direction: column;
  align-items: center; }
  @media screen and (min-width: 640px) {
    .about-story-down__wrapper .our-story-down {
      margin: 40px 0; } }
  @media screen and (min-width: 1024px) {
    .about-story-down__wrapper .our-story-down {
      margin: 64px 0; } }
  @media screen and (min-width: 1280px) {
    .about-story-down__wrapper .our-story-down {
      margin: 64px 0; } }
  .about-story-down__wrapper .our-story-down__title {
    flex: 0 0 100%;
    width: 100%;
    margin-bottom: 40px; }
    @media (max-width: 1280px) {
      .about-story-down__wrapper .our-story-down__title {
        margin-bottom: 32px; } }
    @media (max-width: 640px) {
      .about-story-down__wrapper .our-story-down__title {
        margin-bottom: 12px; } }
    .about-story-down__wrapper .our-story-down__title--heading {
      text-align: left; }
  .about-story-down__wrapper .our-story-down__content {
    flex: 0 0 86%;
    width: 86%; }
    @media screen and (max-width: 1279px) {
      .about-story-down__wrapper .our-story-down__content {
        flex: 0 0 100%;
        width: 100%; } }
    .about-story-down__wrapper .our-story-down__content .btn {
      display: flex;
      justify-content: flex-start; }
      .about-story-down__wrapper .our-story-down__content .btn .btn-arrow.btn-arrow--white {
        border-radius: 12px;
        padding-left: 12px;
        border: 2px solid #a32732;
        box-shadow: 0 0 5px rgba(163, 39, 50, 0.3); }

@media (max-width: 1024px) {
  .about-story-down__wrapper .our-story-down {
    flex-direction: column; }
    .about-story-down__wrapper .our-story-down__paragraph {
      margin: 18px 0; }
      .about-story-down__wrapper .our-story-down__paragraph::before {
        width: 36px;
        height: 36px;
        top: calc(100% - 18px); }
    .about-story-down__wrapper .our-story-down__content--padding {
      padding-right: 36px; } }

@media (max-width: 425px) {
  .about-story-down__wrapper .our-story-down__title--heading {
    padding-right: initial;
    text-align: center; }
  .about-story-down__wrapper .our-story-down__paragraph {
    margin: 18px 0; }
  .about-story-down__wrapper .our-story-down__content--padding {
    padding-right: 36px; }
  .about-story-down__wrapper .btn .btn-arrow.btn-arrow--white {
    font-size: 14px;
    padding: 8px 8px; } }
  @media (max-width: 425px) and (max-width: 425px) {
    .about-story-down__wrapper .btn .btn-arrow.btn-arrow--white {
      font-size: 13px; } }

.about-story-business__wrapper {
  background-color: #ececec; }
  .about-story-business__wrapper .our-story-business {
    padding: 32px 0; }
    @media screen and (min-width: 640px) {
      .about-story-business__wrapper .our-story-business {
        padding: 40px 0; } }
    @media screen and (min-width: 1024px) {
      .about-story-business__wrapper .our-story-business {
        padding: 64px 0; } }
    @media screen and (min-width: 1280px) {
      .about-story-business__wrapper .our-story-business {
        padding: 64px 0; } }
    .about-story-business__wrapper .our-story-business__list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      margin-left: -4px;
      margin-right: -4px; }
    .about-story-business__wrapper .our-story-business__item {
      width: 20%;
      padding-left: 8px;
      padding-right: 8px;
      display: flex;
      flex-direction: column;
      margin-top: 32px; }
      @media screen and (min-width: 640px) {
        .about-story-business__wrapper .our-story-business__item {
          margin-top: 40px; } }
      @media screen and (min-width: 1024px) {
        .about-story-business__wrapper .our-story-business__item {
          margin-top: 64px; } }
      @media screen and (min-width: 1280px) {
        .about-story-business__wrapper .our-story-business__item {
          margin-top: 64px; } }
      .about-story-business__wrapper .our-story-business__item-img {
        width: 65px;
        height: 65px;
        object-fit: cover;
        object-position: center;
        margin-bottom: 40px; }
        @media (max-width: 1280px) {
          .about-story-business__wrapper .our-story-business__item-img {
            margin-bottom: 32px; } }
        @media (max-width: 640px) {
          .about-story-business__wrapper .our-story-business__item-img {
            margin-bottom: 12px; } }
      .about-story-business__wrapper .our-story-business__item-title {
        display: block;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        min-height: 71px; }
        @media (max-width: 1024px) {
          .about-story-business__wrapper .our-story-business__item-title {
            min-height: 65px; } }
        @media (max-width: 640px) {
          .about-story-business__wrapper .our-story-business__item-title {
            min-height: 55px; } }
      .about-story-business__wrapper .our-story-business__item-desc {
        margin-top: 8px;
        color: #707070;
        display: block;
        -webkit-line-clamp: initial;
        -webkit-box-orient: initial;
        overflow: initial; }
        @media screen and (max-width: 1023px) {
          .about-story-business__wrapper .our-story-business__item-desc {
            width: 80%; } }
        @media screen and (max-width: 767px) {
          .about-story-business__wrapper .our-story-business__item-desc {
            width: 100%; } }

@media (max-width: 1280px) {
  .about-story-business__wrapper .our-story-business__list {
    justify-content: flex-start; }
  .about-story-business__wrapper .our-story-business__item {
    flex-basis: 33.3333%; } }

@media (max-width: 1024px) {
  .about-story-business__wrapper .our-story-business__item {
    flex-basis: 50%; }
    .about-story-business__wrapper .our-story-business__item-img {
      margin-bottom: 32px; }
  .about-story-business__wrapper .our-story-business {
    padding: 36px 0; } }

@media (max-width: 425px) {
  .about-story-business__wrapper .our-story-business__title--heading {
    text-align: center; }
  .about-story-business__wrapper .our-story-business__item {
    flex-basis: 100%;
    justify-content: center;
    align-items: center; }
    .about-story-business__wrapper .our-story-business__item-title {
      text-align: center; }
    .about-story-business__wrapper .our-story-business__item-desc {
      text-align: center; } }

.about-story-our__wrapper {
  margin: 32px 0; }
  @media screen and (min-width: 640px) {
    .about-story-our__wrapper {
      margin: 40px 0; } }
  @media screen and (min-width: 1024px) {
    .about-story-our__wrapper {
      margin: 64px 0; } }
  @media screen and (min-width: 1280px) {
    .about-story-our__wrapper {
      margin: 64px 0; } }
  .about-story-our__wrapper .about-story-our__list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
  .about-story-our__wrapper .about-story-our__item {
    width: 100%; }
    .about-story-our__wrapper .about-story-our__item .our-item {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center; }
      .about-story-our__wrapper .about-story-our__item .our-item__img {
        height: 383px;
        flex-basis: 50%;
        width: 50%;
        object-fit: cover;
        object-position: center; }
      .about-story-our__wrapper .about-story-our__item .our-item__content {
        flex-basis: 50%; }
        .about-story-our__wrapper .about-story-our__item .our-item__content-title {
          font-weight: 500; }
        .about-story-our__wrapper .about-story-our__item .our-item__content-desc {
          margin: 40px 0; }
          @media (max-width: 1280px) {
            .about-story-our__wrapper .about-story-our__item .our-item__content-desc {
              margin: 32px 0; } }
          @media (max-width: 640px) {
            .about-story-our__wrapper .about-story-our__item .our-item__content-desc {
              margin: 12px 0; } }
        .about-story-our__wrapper .about-story-our__item .our-item__content-caption {
          font-style: italic;
          font-weight: 500;
          color: #707070; }
    .about-story-our__wrapper .about-story-our__item:nth-child(2n) .our-item {
      flex-direction: row-reverse; }
      .about-story-our__wrapper .about-story-our__item:nth-child(2n) .our-item__content {
        padding-right: 64px; }
    .about-story-our__wrapper .about-story-our__item:not(:nth-child(2n)) .our-item__content {
      padding-left: 64px; }
    .about-story-our__wrapper .about-story-our__item:not(:first-child) {
      margin-top: 40px; }

@media (max-width: 1024px) {
  .about-story-our__wrapper .about-story-our__item:nth-child(2n) .our-item__content {
    padding-right: 40px; }
  .about-story-our__wrapper .about-story-our__item:not(:nth-child(2n)) .our-item__content {
    padding-left: 40px; } }

@media (max-width: 768px) {
  .about-story-our__wrapper .about-story-our__item:nth-child(2n) .our-item__content {
    padding: 32px 12px; }
  .about-story-our__wrapper .about-story-our__item:not(:nth-child(2n)) .our-item__content {
    padding: 32px 12px; }
  .about-story-our__wrapper .about-story-our__item .our-item {
    background-color: #ececec; }
    .about-story-our__wrapper .about-story-our__item .our-item__content {
      flex-basis: 100%;
      width: 100%; }
    .about-story-our__wrapper .about-story-our__item .our-item__img {
      flex-basis: 100%;
      width: 100%; } }

.about-story-mg__wrapper {
  background-color: #ececec; }
  .about-story-mg__wrapper .about-story-global {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: auto; }
    .about-story-mg__wrapper .about-story-global__title {
      width: 56%; }
    .about-story-mg__wrapper .about-story-global__paragraph {
      width: 56%; }
    .about-story-mg__wrapper .about-story-global__img {
      margin: 80px 0;
      position: relative; }
      @media screen and (min-width: 640px) {
        .about-story-mg__wrapper .about-story-global__img {
          margin: 100px 0; } }
      @media screen and (min-width: 1024px) {
        .about-story-mg__wrapper .about-story-global__img {
          margin: 128px 0; } }
      @media screen and (min-width: 1280px) {
        .about-story-mg__wrapper .about-story-global__img {
          margin: 128px 0; } }
      .about-story-mg__wrapper .about-story-global__img--bg {
        min-width: 1200px;
        width: 1200px;
        max-width: 1200px; }
      .about-story-mg__wrapper .about-story-global__img-list {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; }
      .about-story-mg__wrapper .about-story-global__img--icon {
        position: absolute;
        width: 160px; }
      .about-story-mg__wrapper .about-story-global__img--icon:nth-of-type(1) {
        top: 12%;
        left: 8%; }
      .about-story-mg__wrapper .about-story-global__img--icon:nth-of-type(2) {
        top: 58%;
        left: 26%; }
      .about-story-mg__wrapper .about-story-global__img--icon:nth-of-type(3) {
        top: 34%;
        left: 45%; }
      .about-story-mg__wrapper .about-story-global__img--icon:nth-of-type(4) {
        top: 3%;
        left: 73%; }
      .about-story-mg__wrapper .about-story-global__img--icon:nth-of-type(5) {
        top: 63%;
        left: 80%; }
  .about-story-mg__wrapper .about-story-mg__title {
    padding-top: 80px;
    padding-bottom: 32px;
    text-align: center; }
    @media screen and (min-width: 640px) {
      .about-story-mg__wrapper .about-story-mg__title {
        padding-top: 100px; } }
    @media screen and (min-width: 1024px) {
      .about-story-mg__wrapper .about-story-mg__title {
        padding-top: 128px; } }
    @media screen and (min-width: 1280px) {
      .about-story-mg__wrapper .about-story-mg__title {
        padding-top: 128px; } }
    @media screen and (min-width: 640px) {
      .about-story-mg__wrapper .about-story-mg__title {
        padding-bottom: 40px; } }
    @media screen and (min-width: 1024px) {
      .about-story-mg__wrapper .about-story-mg__title {
        padding-bottom: 64px; } }
    @media screen and (min-width: 1280px) {
      .about-story-mg__wrapper .about-story-mg__title {
        padding-bottom: 64px; } }

@media (max-width: 1440px) {
  .about-story-mg__wrapper .about-story-mg__title,
  .about-story-mg__wrapper .about-story-global__paragraph {
    width: 72%; }
  .about-story-mg__wrapper .about-story-global__img--bg {
    min-width: 900px;
    width: 900px;
    max-width: 900px; }
  .about-story-mg__wrapper .about-story-global__img--icon {
    width: 156px; }
  .about-story-mg__wrapper .about-story-global__img--icon:nth-of-type(1) {
    top: 0%;
    left: 7%; }
  .about-story-mg__wrapper .about-story-global__img--icon:nth-of-type(2) {
    top: 38%;
    left: 29.5%; }
  .about-story-mg__wrapper .about-story-global__img--icon:nth-of-type(3) {
    top: 15%;
    left: 47%; }
  .about-story-mg__wrapper .about-story-global__img--icon:nth-of-type(4) {
    top: -10%;
    left: 75%; }
  .about-story-mg__wrapper .about-story-global__img--icon:nth-of-type(5) {
    top: 52%;
    left: 87%; } }

@media (max-width: 1024px) {
  .about-story-mg__wrapper .about-story-global__img--bg {
    min-width: 720px;
    width: 720px;
    max-width: 720px; }
  .about-story-mg__wrapper .about-story-global__img--icon {
    width: 120px; }
  .about-story-mg__wrapper .about-story-global__img--icon:nth-of-type(1) {
    top: 1.5%;
    left: 7%; }
  .about-story-mg__wrapper .about-story-global__img--icon:nth-of-type(2) {
    top: 38%;
    left: 29.5%; }
  .about-story-mg__wrapper .about-story-global__img--icon:nth-of-type(3) {
    top: 15%;
    left: 47%; }
  .about-story-mg__wrapper .about-story-global__img--icon:nth-of-type(4) {
    top: -10%;
    left: 75%; }
  .about-story-mg__wrapper .about-story-global__img--icon:nth-of-type(5) {
    top: 52%;
    left: 87%; } }

@media (max-width: 768px) {
  .about-story-mg__wrapper .about-story-global__img--bg {
    min-width: 540px;
    width: 540px;
    max-width: 540px; }
  .about-story-mg__wrapper .about-story-global__img--icon {
    width: 96px; }
  .about-story-mg__wrapper .about-story-global__img--icon:nth-of-type(1) {
    top: 0%;
    left: 7%; }
  .about-story-mg__wrapper .about-story-global__img--icon:nth-of-type(2) {
    top: 38%;
    left: 29.5%; }
  .about-story-mg__wrapper .about-story-global__img--icon:nth-of-type(3) {
    top: 15%;
    left: 47%; }
  .about-story-mg__wrapper .about-story-global__img--icon:nth-of-type(4) {
    top: -10%;
    left: 75%; }
  .about-story-mg__wrapper .about-story-global__img--icon:nth-of-type(5) {
    top: 52%;
    left: 87%; } }

@media (max-width: 640px) {
  .about-story-mg__wrapper .about-story-global__img--bg {
    min-width: 360px;
    width: 360px;
    max-width: 360px; }
  .about-story-mg__wrapper .about-story-global__img--icon {
    width: 64px; }
  .about-story-mg__wrapper .about-story-global__img--icon:nth-of-type(1) {
    top: 0%;
    left: 7%; }
  .about-story-mg__wrapper .about-story-global__img--icon:nth-of-type(2) {
    top: 38%;
    left: 29.5%; }
  .about-story-mg__wrapper .about-story-global__img--icon:nth-of-type(3) {
    top: 15%;
    left: 47%; }
  .about-story-mg__wrapper .about-story-global__img--icon:nth-of-type(4) {
    top: -10%;
    left: 75%; }
  .about-story-mg__wrapper .about-story-global__img--icon:nth-of-type(5) {
    top: 52%;
    left: 87%; } }

@media (max-width: 425px) {
  .about-story-mg__wrapper .about-story-global__img--bg {
    min-width: 300px;
    width: 300px;
    max-width: 300px; }
  .about-story-mg__wrapper .about-story-global__img--icon {
    width: 52px; }
  .about-story-mg__wrapper .about-story-global__img--icon:nth-of-type(1) {
    top: 7%;
    left: 8%; }
  .about-story-mg__wrapper .about-story-global__img--icon:nth-of-type(2) {
    top: 38%;
    left: 29.5%; }
  .about-story-mg__wrapper .about-story-global__img--icon:nth-of-type(3) {
    top: 15%;
    left: 47%; }
  .about-story-mg__wrapper .about-story-global__img--icon:nth-of-type(4) {
    top: -10%;
    left: 75%; }
  .about-story-mg__wrapper .about-story-global__img--icon:nth-of-type(5) {
    top: 52%;
    left: 87%; } }

@media (max-width: 1280px) {
  .about-story-mg__wrapper .about-story-mg__title,
  .about-story-mg__wrapper .about-story-global__paragraph {
    width: 88%;
    -webkit-line-clamp: 5; } }

.process-year {
  display: flex;
  flex-direction: row;
  height: 590px;
  align-items: center; }
  .process-year-item {
    flex: 1;
    position: relative; }
    .process-year-item.active .process-year-item__number::before {
      background-color: #a32732; }
    .process-year-item.active .process-year-item__number--year {
      color: white; }
    .process-year-item:not(:first-child) .process-year-item--dash {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 0;
      transform: translate(-100%, -50%);
      border-top: 2px dashed #a32732;
      z-index: 1; }
    .process-year-item--center {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      transition: 0.3s all linear; }
      .process-year-item--center:hover {
        z-index: 11111;
        transform: scale(1.05); }
        .process-year-item--center:hover .process-year-item__number::before {
          background-color: #a32732; }
        .process-year-item--center:hover .process-year-item__number--year {
          color: white; }
    .process-year-item__content {
      position: absolute;
      top: -320%;
      left: 50%;
      width: 150%;
      transform: translate(-50%, -100%);
      color: #a32732;
      text-align: center; }
      .process-year-item__content--title {
        width: 100%;
        margin-bottom: 24px;
        font-family: "SVN-AvenirNext", sans-serif;
        color: #000;
        font-size: 20px;
        font-weight: 500;
        font-family: inherit;
        -webkit-line-clamp: 1;
        font-weight: 700;
        text-transform: uppercase; }
        @media (max-width: 1024px) {
          .process-year-item__content--title {
            font-size: 19px; } }
        @media (max-width: 640px) {
          .process-year-item__content--title {
            font-size: 18px; } }
      .process-year-item__content--desc {
        width: 100%;
        font-family: inherit;
        -webkit-line-clamp: 2; }
    .process-year-item:nth-of-type(2n) .process-year-item__content {
      position: absolute;
      top: 420%;
      left: 50%;
      transform: translate(-50%, 0%);
      width: 150%; }
    .process-year-item:nth-of-type(2n) .process-year-item__icon {
      top: -60px; }
    .process-year-item:nth-of-type(2n) .process-year-item__number--solid {
      top: initial;
      bottom: 0;
      left: 50%;
      transform: rotate(90deg); }
    .process-year-item__number {
      position: relative;
      z-index: 2;
      padding: 8px 12px;
      cursor: pointer;
      transition: color 0.3s linear; }
      .process-year-item__number--year {
        font-family: "SVN-AvenirNext", sans-serif;
        color: #000;
        font-size: 20px;
        font-weight: 500;
        font-family: inherit;
        font-weight: bold;
        text-align: center;
        color: #a32732; }
        @media (max-width: 1024px) {
          .process-year-item__number--year {
            font-size: 19px; } }
        @media (max-width: 640px) {
          .process-year-item__number--year {
            font-size: 18px; } }
      .process-year-item__number::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        transform: skew(20deg);
        border-top: 2px solid #a32732;
        border-bottom: 2px solid #a32732;
        border-left: 2px solid #a32732;
        border-right: 2px solid #a32732;
        background-color: #ececec;
        transition: background 0.3s linear; }
      .process-year-item__number--solid {
        position: absolute;
        background-color: #a32732;
        transform-origin: left;
        width: 150%;
        height: 1px;
        top: 0;
        left: 50%;
        transform: rotate(-90deg); }
        .process-year-item__number--solid::before {
          content: "";
          position: absolute;
          top: 0;
          left: 100%;
          width: 8px;
          height: 8px;
          transform: translate(0%, -50%);
          border-radius: 50%;
          background-color: #041e42; }
    .process-year-item__icon {
      position: absolute;
      top: 60px;
      left: 50%;
      transform: translateX(-50%);
      width: 35px;
      height: 35px;
      object-fit: cover; }

@media (min-width: 1800px) {
  .process-year-item__content {
    width: 135%; }
  .process-year-item:nth-of-type(2n) .process-year-item__content {
    width: 135%; } }

@media (max-width: 1024px) {
  .process-year-item__number {
    padding: 5px 8px; }
  .process-year {
    margin-top: 40px;
    flex-direction: column;
    min-height: 100vh; }
    .process-year-item {
      width: 100%; }
      .process-year-item__content {
        position: absolute;
        top: 50%;
        left: calc(50% - 100px);
        width: 30%;
        transform: translate(-100%, -50%); }
        .process-year-item__content--title {
          display: none; }
        .process-year-item__content--desc {
          -webkit-line-clamp: 2;
          text-align: center;
          font-size: 16px; }
      .process-year-item__icon {
        top: 50%;
        left: calc(50% + 100px);
        transform: translateY(-50%); }
      .process-year-item__number--solid {
        position: absolute;
        top: 50%;
        left: 0;
        width: 50px;
        height: 2px;
        transform: rotate(180deg);
        transform-origin: left;
        background-color: #041e42; }
      .process-year-item:not(:first-child) .process-year-item--dash {
        width: 0;
        height: 100%;
        transform: translate(-50%, -150%);
        border-right: 3px dashed #a32732;
        border-top: 0; }
      .process-year-item:nth-of-type(2n) .process-year-item__content {
        top: 50%;
        left: calc(50% + 100px);
        width: 30%;
        transform: translate(0%, -50%); }
        .process-year-item:nth-of-type(2n) .process-year-item__content--title {
          -webkit-line-clamp: 5;
          text-align: left; }
      .process-year-item:nth-of-type(2n) .process-year-item__icon {
        top: 50%;
        left: calc(50% - 180px);
        transform: translateY(-50%); }
      .process-year-item:nth-of-type(2n) .process-year-item__number--solid {
        top: 50%;
        bottom: initial;
        left: 100%;
        right: initial;
        transform: rotate(0deg) translate(0%, -50%); }
  .process-year-item__content--title {
    margin-bottom: 0; } }

@media (max-width: 425px) {
  .process-year-item__number--solid {
    width: 20px; }
  .process-year-item__content {
    left: calc(50% - 70px); }
    .process-year-item__content--desc {
      font-size: 9px; }
  .process-year-item:nth-of-type(2n) .process-year-item__content {
    left: calc(50% + 70px); }
  .process-year-item__icon {
    left: calc(50% + 70px); }
  .process-year-item:nth-of-type(2n) .process-year-item__icon {
    left: calc(50% - 160px); } }

.about-story-partner__wrapper {
  margin: 32px 0; }
  @media screen and (min-width: 640px) {
    .about-story-partner__wrapper {
      margin: 40px 0; } }
  @media screen and (min-width: 1024px) {
    .about-story-partner__wrapper {
      margin: 64px 0; } }
  @media screen and (min-width: 1280px) {
    .about-story-partner__wrapper {
      margin: 64px 0; } }
  .about-story-partner__wrapper .swiper-container {
    padding: 0 80px; }
    @media (max-width: 1439px) {
      .about-story-partner__wrapper .swiper-container {
        padding: 0 60px; } }
    @media (max-width: 1023px) {
      .about-story-partner__wrapper .swiper-container {
        padding: 0 45px; } }
  .about-story-partner__wrapper .about-story-partner {
    position: relative; }
    .about-story-partner__wrapper .about-story-partner__title {
      padding-bottom: 32px;
      text-align: center; }
      @media screen and (min-width: 640px) {
        .about-story-partner__wrapper .about-story-partner__title {
          padding-bottom: 40px; } }
      @media screen and (min-width: 1024px) {
        .about-story-partner__wrapper .about-story-partner__title {
          padding-bottom: 64px; } }
      @media screen and (min-width: 1280px) {
        .about-story-partner__wrapper .about-story-partner__title {
          padding-bottom: 64px; } }
    .about-story-partner__wrapper .about-story-partner #about-story-partner--next, .about-story-partner__wrapper .about-story-partner #about-story-partner--prev {
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat; }
      .about-story-partner__wrapper .about-story-partner #about-story-partner--next::after, .about-story-partner__wrapper .about-story-partner #about-story-partner--prev::after {
        content: ""; }
    .about-story-partner__wrapper .about-story-partner #about-story-partner--next {
      background-image: url("../assets/images/about/next.png"); }
    .about-story-partner__wrapper .about-story-partner #about-story-partner--prev {
      background-image: url("../assets/images/about/prev.png"); }

@media (max-width: 1024px) {
  .about-story-partner__wrapper {
    margin: 36px 0; }
  .about-story-partner__wrapper .about-story-partner__title {
    padding-bottom: 36px; }
  .about-story-partner__wrapper .about-story-partner {
    margin-bottom: 12px; }
  .about-story-mg__wrapper .about-story-mg__title {
    padding: 36px 0; } }

.about-story-become__wrapper {
  background-color: #a32732; }
  .about-story-become__wrapper .about-story-become {
    height: 284px;
    display: flex;
    justify-content: center;
    align-items: center; }
    .about-story-become__wrapper .about-story-become .btn a {
      padding: 18px 24px;
      font-size: 18px;
      border-radius: 12px;
      border: 2px solid white; }

@media (max-width: 1024px) {
  .about-story-become__wrapper .about-story-become .btn a {
    padding: 16px 20px;
    font-size: 16px; }
  .about-story-become__wrapper .about-story-become {
    height: 250px; } }

@media (max-width: 640px) {
  .about-story-become__wrapper .about-story-become .btn a {
    padding: 8px 12px; }
  .about-story-become__wrapper .about-story-become {
    height: 200px; } }

.about-media-press__wrapper {
  transition: .3s; }
  .about-media-press__wrapper .about-media-press {
    margin-top: 32px;
    height: 427px;
    background-image: url("../assets/images/about/press.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center; }
    @media screen and (min-width: 640px) {
      .about-media-press__wrapper .about-media-press {
        margin-top: 40px; } }
    @media screen and (min-width: 1024px) {
      .about-media-press__wrapper .about-media-press {
        margin-top: 64px; } }
    @media screen and (min-width: 1280px) {
      .about-media-press__wrapper .about-media-press {
        margin-top: 64px; } }

@media (max-width: 900px) {
  .about-media-press__wrapper .about-media-press {
    height: 360px; } }

@media (max-width: 425px) {
  .about-media-press__wrapper .about-media-press {
    height: 300px;
    display: flex;
    text-align: center; } }

#logo .index-logo {
  padding-bottom: 40px; }
  @media (max-width: 1280px) {
    #logo .index-logo {
      padding-bottom: 32px; } }
  @media (max-width: 640px) {
    #logo .index-logo {
      padding-bottom: 12px; } }
  #logo .index-logo .container, #logo .index-logo .wholesale-factory__content2 .right .content, .wholesale-factory__content2 .right #logo .index-logo .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap; }
    #logo .index-logo .container .about-media-logo__item, #logo .index-logo .wholesale-factory__content2 .right .content .about-media-logo__item, .wholesale-factory__content2 .right #logo .index-logo .content .about-media-logo__item {
      width: 33.3333%;
      padding-top: 40px; }
      @media (max-width: 1280px) {
        #logo .index-logo .container .about-media-logo__item, #logo .index-logo .wholesale-factory__content2 .right .content .about-media-logo__item, .wholesale-factory__content2 .right #logo .index-logo .content .about-media-logo__item {
          padding-top: 32px; } }
      @media (max-width: 640px) {
        #logo .index-logo .container .about-media-logo__item, #logo .index-logo .wholesale-factory__content2 .right .content .about-media-logo__item, .wholesale-factory__content2 .right #logo .index-logo .content .about-media-logo__item {
          padding-top: 12px; } }
      #logo .index-logo .container .about-media-logo__item img, #logo .index-logo .wholesale-factory__content2 .right .content .about-media-logo__item img, .wholesale-factory__content2 .right #logo .index-logo .content .about-media-logo__item img {
        padding: 0 6px; }
    #logo .index-logo .container .index-logo__item, #logo .index-logo .wholesale-factory__content2 .right .content .index-logo__item, .wholesale-factory__content2 .right #logo .index-logo .content .index-logo__item {
      width: 33.3333%;
      padding-top: 40px; }
      @media (max-width: 1280px) {
        #logo .index-logo .container .index-logo__item, #logo .index-logo .wholesale-factory__content2 .right .content .index-logo__item, .wholesale-factory__content2 .right #logo .index-logo .content .index-logo__item {
          padding-top: 32px; } }
      @media (max-width: 640px) {
        #logo .index-logo .container .index-logo__item, #logo .index-logo .wholesale-factory__content2 .right .content .index-logo__item, .wholesale-factory__content2 .right #logo .index-logo .content .index-logo__item {
          padding-top: 12px; } }
      #logo .index-logo .container .index-logo__item img, #logo .index-logo .wholesale-factory__content2 .right .content .index-logo__item img, .wholesale-factory__content2 .right #logo .index-logo .content .index-logo__item img {
        padding: 0 6px; }

.about-media-logo {
  padding-bottom: 40px; }
  @media (max-width: 1280px) {
    .about-media-logo {
      padding-bottom: 32px; } }
  @media (max-width: 640px) {
    .about-media-logo {
      padding-bottom: 12px; } }
  .about-media-logo .container, .about-media-logo .wholesale-factory__content2 .right .content, .wholesale-factory__content2 .right .about-media-logo .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap; }
  .about-media-logo__item {
    width: 33.3333%;
    padding-top: 40px; }
    @media (max-width: 1280px) {
      .about-media-logo__item {
        padding-top: 32px; } }
    @media (max-width: 640px) {
      .about-media-logo__item {
        padding-top: 12px; } }
    .about-media-logo__item img {
      padding: 0 6px; }

.about-media-releases__wrapper .about-media-releases {
  padding-bottom: 32px;
  margin-bottom: 32px;
  background-color: #ececec; }
  @media screen and (min-width: 640px) {
    .about-media-releases__wrapper .about-media-releases {
      padding-bottom: 40px; } }
  @media screen and (min-width: 1024px) {
    .about-media-releases__wrapper .about-media-releases {
      padding-bottom: 64px; } }
  @media screen and (min-width: 1280px) {
    .about-media-releases__wrapper .about-media-releases {
      padding-bottom: 64px; } }
  @media screen and (min-width: 640px) {
    .about-media-releases__wrapper .about-media-releases {
      margin-bottom: 40px; } }
  @media screen and (min-width: 1024px) {
    .about-media-releases__wrapper .about-media-releases {
      margin-bottom: 64px; } }
  @media screen and (min-width: 1280px) {
    .about-media-releases__wrapper .about-media-releases {
      margin-bottom: 64px; } }
  .about-media-releases__wrapper .about-media-releases__title {
    text-align: center;
    padding: 32px 0; }
    @media screen and (min-width: 640px) {
      .about-media-releases__wrapper .about-media-releases__title {
        padding: 40px 0; } }
    @media screen and (min-width: 1024px) {
      .about-media-releases__wrapper .about-media-releases__title {
        padding: 64px 0; } }
    @media screen and (min-width: 1280px) {
      .about-media-releases__wrapper .about-media-releases__title {
        padding: 64px 0; } }
  .about-media-releases__wrapper .about-media-releases__content {
    width: 80%;
    margin: auto; }
    .about-media-releases__wrapper .about-media-releases__content .releases-box {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center; }
      .about-media-releases__wrapper .about-media-releases__content .releases-box-item:not(:nth-of-type(4n + 1)) {
        border-top: 3px solid #a32732;
        padding-top: 40px; }
      .about-media-releases__wrapper .about-media-releases__content .releases-box-item {
        width: 100%;
        max-width: 100%; }
        .about-media-releases__wrapper .about-media-releases__content .releases-box-item__title {
          font-weight: 700;
          letter-spacing: 1.5px;
          line-height: 28px; }
        .about-media-releases__wrapper .about-media-releases__content .releases-box-item--padding {
          padding: 0 32px; }
        .about-media-releases__wrapper .about-media-releases__content .releases-box-item__content {
          display: flex;
          justify-content: space-between;
          flex-direction: row; }
          .about-media-releases__wrapper .about-media-releases__content .releases-box-item__content-desc {
            flex-basis: 70%;
            width: 70%;
            color: #707070;
            margin: 32px 0; }
          .about-media-releases__wrapper .about-media-releases__content .releases-box-item__content-btn {
            flex-basis: 30%;
            width: 30%; }
            .about-media-releases__wrapper .about-media-releases__content .releases-box-item__content-btn .more {
              margin: 0;
              text-transform: uppercase; }

.releases-pagination {
  width: 315px;
  margin: 0 auto; }
  .releases-pagination .swiper-container {
    padding: 0 28px; }
  .releases-pagination .releases-box-item {
    transition: .3s all linear;
    opacity: 1;
    visibility: hidden; }
  .releases-pagination .releases-box-item.hidden {
    display: none;
    opacity: 0;
    visibility: visible; }
  .releases-pagination .active {
    background-color: rgba(163, 39, 50, 0.9);
    color: white;
    font-weight: bold; }
  .releases-pagination__button {
    cursor: pointer;
    border: 1px solid #a32732;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px !important;
    width: 42px !important;
    line-height: 42px !important;
    transition: .3s all linear; }
    .releases-pagination__button span {
      display: block;
      pointer-events: none; }
    .releases-pagination__button:hover {
      background-color: #a32732;
      color: white;
      font-weight: bold; }
  .releases-pagination__next, .releases-pagination__prev {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 12px; }
    .releases-pagination__next::after, .releases-pagination__prev::after {
      content: ""; }
  .releases-pagination__next {
    background-image: url("../assets/images/about/pagination_next.svg"); }
  .releases-pagination__prev {
    background-image: url("../assets/images/about/pagination_prev.svg"); }

@media (max-width: 1024px) {
  .about-media-releases__wrapper .about-media-releases__content .releases-box {
    padding-bottom: 40px; }
  .about-media-releases__wrapper .about-media-releases__content .releases-box-item__title {
    line-height: 23px; }
  .about-media-releases__wrapper .about-media-releases__content .releases-box-item--padding {
    padding: 0; } }

@media (max-width: 900px) {
  .about-media-releases__wrapper .about-media-releases__content .releases-box-item:nth-of-type(4n) .releases-box-item__content-btn {
    margin-bottom: 0; }
  .about-media-releases__wrapper .about-media-releases__content .releases-box-item__content {
    flex-direction: column; }
    .about-media-releases__wrapper .about-media-releases__content .releases-box-item__content-desc, .about-media-releases__wrapper .about-media-releases__content .releases-box-item__content-btn {
      flex: 0 0 100%;
      width: 100%; }
    .about-media-releases__wrapper .about-media-releases__content .releases-box-item__content-btn {
      display: block;
      margin-bottom: 32px; }
    .about-media-releases__wrapper .about-media-releases__content .releases-box-item__content-desc {
      -webkit-line-clamp: 3; } }

@media (max-width: 425px) {
  .about-media-releases__wrapper .about-media-releases {
    padding-bottom: 32px; }
  .about-media-releases__wrapper .about-media-releases__content .releases-box {
    padding-bottom: 32px; } }

.about-media-video__wrapper {
  margin: 32px 0; }
  @media screen and (min-width: 640px) {
    .about-media-video__wrapper {
      margin: 40px 0; } }
  @media screen and (min-width: 1024px) {
    .about-media-video__wrapper {
      margin: 64px 0; } }
  @media screen and (min-width: 1280px) {
    .about-media-video__wrapper {
      margin: 64px 0; } }
  .about-media-video__wrapper .about-media-video {
    margin-bottom: 32px; }
    @media screen and (min-width: 640px) {
      .about-media-video__wrapper .about-media-video {
        margin-bottom: 40px; } }
    @media screen and (min-width: 1024px) {
      .about-media-video__wrapper .about-media-video {
        margin-bottom: 64px; } }
    @media screen and (min-width: 1280px) {
      .about-media-video__wrapper .about-media-video {
        margin-bottom: 64px; } }
    .about-media-video__wrapper .about-media-video .model {
      position: fixed;
      top: 0;
      left: 0;
      display: none;
      justify-content: center;
      align-items: center;
      width: 100vw;
      height: 100vh;
      background-color: #a32732;
      z-index: 10000; }
      .about-media-video__wrapper .about-media-video .model.active {
        display: flex;
        animation: showFlexRL 0.1s linear forwards; }
      .about-media-video__wrapper .about-media-video .model-closed {
        background-color: white;
        content: "";
        position: absolute;
        top: 0;
        right: 0px;
        width: 80px;
        height: 80px;
        border-bottom-left-radius: 50%; }
        .about-media-video__wrapper .about-media-video .model-closed::before, .about-media-video__wrapper .about-media-video .model-closed::after {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 50%;
          height: 3px;
          border-radius: 2px;
          background-color: #a32732; }
        .about-media-video__wrapper .about-media-video .model-closed::before {
          transform: translate(-50%, -50%) rotate(45deg); }
        .about-media-video__wrapper .about-media-video .model-closed::after {
          transform: translate(-50%, -50%) rotate(-45deg); }
      .about-media-video__wrapper .about-media-video .model .video__model {
        width: 900px;
        height: 768px; }
        .about-media-video__wrapper .about-media-video .model .video__model-item {
          width: 100%;
          height: 100%;
          position: relative; }
          .about-media-video__wrapper .about-media-video .model .video__model-item--img {
            width: 100%; }
          .about-media-video__wrapper .about-media-video .model .video__model-item--title {
            text-align: center;
            color: white;
            font-size: 18px;
            padding-top: 40px;
            font-weight: 500; }
            @media screen and (min-width: 1024px) {
              .about-media-video__wrapper .about-media-video .model .video__model-item--title {
                font-size: 20px; } }
            @media (max-width: 1280px) {
              .about-media-video__wrapper .about-media-video .model .video__model-item--title {
                padding-top: 32px; } }
            @media (max-width: 640px) {
              .about-media-video__wrapper .about-media-video .model .video__model-item--title {
                padding-top: 12px; } }
        .about-media-video__wrapper .about-media-video .model .video__model .swiper-container {
          padding: 45px; }
        .about-media-video__wrapper .about-media-video .model .video__model .video__model-swiper .model-button--next, .about-media-video__wrapper .about-media-video .model .video__model .video__model-swiper .model-button--prev {
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat; }
          .about-media-video__wrapper .about-media-video .model .video__model .video__model-swiper .model-button--next::after, .about-media-video__wrapper .about-media-video .model .video__model .video__model-swiper .model-button--prev::after {
            content: ""; }
        .about-media-video__wrapper .about-media-video .model .video__model .video__model-swiper .model-button--next {
          background-image: url("../assets/images/about/pagination_next--white.png"); }
        .about-media-video__wrapper .about-media-video .model .video__model .video__model-swiper .model-button--prev {
          background-image: url("../assets/images/about/pagination_prev--white.png"); }
    .about-media-video__wrapper .about-media-video__list {
      display: grid;
      grid-auto-rows: 55px;
      gap: 12px;
      margin-bottom: 40px; }
    .about-media-video__wrapper .about-media-video__item:nth-of-type(1) {
      grid-column: 1 / span 9;
      grid-row: 1 / span 8; }
    .about-media-video__wrapper .about-media-video__item:nth-of-type(2) {
      grid-column: 10 / span 6;
      grid-row: 1 / span 4;
      padding-left: 38px; }
    .about-media-video__wrapper .about-media-video__item:nth-of-type(3) {
      grid-column: 10 / span 6;
      grid-row: 5 / span 4;
      padding-left: 38px; }
    .about-media-video__wrapper .about-media-video__item:nth-of-type(4) {
      grid-column: 1 / span 5;
      grid-row: 9 / span 4;
      padding-top: 38px;
      padding-right: 18px; }
      .about-media-video__wrapper .about-media-video__item:nth-of-type(4)::after {
        top: calc(50% + 19px);
        left: calc(50% + 9px); }
    .about-media-video__wrapper .about-media-video__item:nth-of-type(5) {
      grid-column: 6 / span 5;
      grid-row: 9 / span 4;
      padding-top: 38px;
      padding-left: 9px;
      padding-right: 9px; }
      .about-media-video__wrapper .about-media-video__item:nth-of-type(5)::after {
        top: calc(50% + 19px);
        left: calc(50% + 9px); }
    .about-media-video__wrapper .about-media-video__item:nth-of-type(6) {
      grid-column: 11 / span 5;
      grid-row: 9 / span 4;
      padding-top: 38px;
      padding-left: 18px; }
      .about-media-video__wrapper .about-media-video__item:nth-of-type(6)::after {
        top: calc(50% + 19px);
        left: calc(50% + 9px); }
    .about-media-video__wrapper .about-media-video__item {
      position: relative; }
      .about-media-video__wrapper .about-media-video__item img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
      .about-media-video__wrapper .about-media-video__item:hover::after {
        opacity: 0.7; }
      .about-media-video__wrapper .about-media-video__item iframe {
        width: 100% !important;
        height: 100% !important;
        pointer-events: none; }
      .about-media-video__wrapper .about-media-video__item.about-media-video__item--video::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 83px;
        height: 83px;
        border-radius: 50%;
        background-image: url("../assets/images/about/btn-pause.png");
        z-index: 1;
        transition: 0.3s all linear;
        cursor: pointer; }

@media (max-width: 1024px) {
  .about-media-video__wrapper .about-media-video__list {
    gap: 8px;
    grid-auto-rows: 40px;
    margin-bottom: 10px; }
  .about-media-video__wrapper .about-media-video__item:nth-of-type(1) {
    grid-column: 1 / span 9;
    grid-row: 1 / span 8; }
  .about-media-video__wrapper .about-media-video__item:nth-of-type(2) {
    grid-column: 10 / span 6;
    grid-row: 1 / span 4;
    padding-left: 22px; }
  .about-media-video__wrapper .about-media-video__item:nth-of-type(3) {
    grid-column: 10 / span 6;
    grid-row: 5 / span 4;
    padding-left: 22px; }
  .about-media-video__wrapper .about-media-video__item:nth-of-type(4) {
    grid-column: 1 / span 5;
    grid-row: 9 / span 4;
    padding-top: 22px;
    padding-right: 10px; }
    .about-media-video__wrapper .about-media-video__item:nth-of-type(4)::after {
      top: calc(50% + 11px);
      left: calc(50% + 5px); }
  .about-media-video__wrapper .about-media-video__item:nth-of-type(5) {
    grid-column: 6 / span 5;
    grid-row: 9 / span 4;
    padding-top: 22px;
    padding-left: 5px;
    padding-right: 5px; }
    .about-media-video__wrapper .about-media-video__item:nth-of-type(5)::after {
      top: calc(50% + 11px);
      left: calc(50% + 5px); }
  .about-media-video__wrapper .about-media-video__item:nth-of-type(6) {
    grid-column: 11 / span 5;
    grid-row: 9 / span 4;
    padding-top: 22px;
    padding-left: 10px; }
    .about-media-video__wrapper .about-media-video__item:nth-of-type(6)::after {
      top: calc(50% + 11px);
      left: calc(50% + 5px); } }

@media (max-width: 900px) {
  .about-media-video__wrapper .about-media-video__list {
    gap: 8px; }
  .about-media-video__wrapper .about-media-video__item:nth-of-type(1) {
    grid-column: 1 / span 8;
    grid-row: 1 / span 8; }
  .about-media-video__wrapper .about-media-video__item:nth-of-type(2) {
    grid-column: 9 / span 4;
    grid-row: 1 / span 4;
    padding-left: 0px; }
  .about-media-video__wrapper .about-media-video__item:nth-of-type(3) {
    grid-column: 9 / span 4;
    grid-row: 5 / span 4;
    padding-left: 0px; }
  .about-media-video__wrapper .about-media-video__item:nth-of-type(4) {
    grid-column: 1 / span 4;
    grid-row: 9 / span 4;
    padding-top: 0px;
    padding-right: 0px; }
    .about-media-video__wrapper .about-media-video__item:nth-of-type(4)::after {
      top: 50%;
      left: 50%; }
  .about-media-video__wrapper .about-media-video__item:nth-of-type(5) {
    grid-column: 5 / span 4;
    grid-row: 9 / span 4;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px; }
    .about-media-video__wrapper .about-media-video__item:nth-of-type(5)::after {
      top: 50%;
      left: 50%; }
  .about-media-video__wrapper .about-media-video__item:nth-of-type(6) {
    grid-column: 9 / span 4;
    grid-row: 9 / span 4;
    padding-top: 0px;
    padding-left: 0px; }
    .about-media-video__wrapper .about-media-video__item:nth-of-type(6)::after {
      top: 50%;
      left: 50%; } }

@media (max-width: 640px) {
  .about-media-video__wrapper .about-media-video__list {
    gap: 8px;
    grid-auto-rows: 45px; }
  .about-media-video__wrapper .about-media-video__item:nth-of-type(1) {
    grid-column: 1 / span 12;
    grid-row: 1 / span 8; }
  .about-media-video__wrapper .about-media-video__item:nth-of-type(2) {
    grid-column: 1 / span 6;
    grid-row: 9 / span 4;
    padding-left: 0px; }
  .about-media-video__wrapper .about-media-video__item:nth-of-type(3) {
    grid-column: 7 / span 6;
    grid-row: 9 / span 4;
    padding-left: 0px; }
  .about-media-video__wrapper .about-media-video__item:nth-of-type(4) {
    grid-column: 1 / span 4;
    grid-row: 13 / span 3;
    padding-top: 0px;
    padding-right: 0px; }
    .about-media-video__wrapper .about-media-video__item:nth-of-type(4)::after {
      top: 50%;
      left: 50%; }
  .about-media-video__wrapper .about-media-video__item:nth-of-type(5) {
    grid-column: 5 / span 4;
    grid-row: 13 / span 3;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px; }
    .about-media-video__wrapper .about-media-video__item:nth-of-type(5)::after {
      top: 50%;
      left: 50%; }
  .about-media-video__wrapper .about-media-video__item:nth-of-type(6) {
    grid-column: 9 / span 4;
    grid-row: 13 / span 3;
    padding-top: 0px;
    padding-left: 0px; }
    .about-media-video__wrapper .about-media-video__item:nth-of-type(6)::after {
      top: 50%;
      left: 50%; } }

@media (max-width: 425px) {
  .about-media-video__wrapper .about-media-video__list {
    gap: 4px; }
  .about-media-video__wrapper .about-media-video__item:nth-of-type(1) {
    grid-column: 1 / span 4;
    grid-row: 1 / span 4; }
  .about-media-video__wrapper .about-media-video__item:nth-of-type(2) {
    grid-column: 1 / span 4;
    grid-row: 5 / span 4;
    padding-left: 0px; }
  .about-media-video__wrapper .about-media-video__item:nth-of-type(3) {
    grid-column: 1 / span 4;
    grid-row: 9 / span 4;
    padding-left: 0px; }
  .about-media-video__wrapper .about-media-video__item:nth-of-type(4) {
    grid-column: 1 / span 4;
    grid-row: 13 / span 4;
    padding-top: 0px;
    padding-right: 0px; }
    .about-media-video__wrapper .about-media-video__item:nth-of-type(4)::after {
      top: 50%;
      left: 50%; }
  .about-media-video__wrapper .about-media-video__item:nth-of-type(5) {
    grid-column: 1 / span 4;
    grid-row: 17 / span 4;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px; }
    .about-media-video__wrapper .about-media-video__item:nth-of-type(5)::after {
      top: 50%;
      left: 50%; }
  .about-media-video__wrapper .about-media-video__item:nth-of-type(6) {
    grid-column: 1 / span 4;
    grid-row: 21 / span 4;
    padding-top: 0px;
    padding-left: 0px; }
    .about-media-video__wrapper .about-media-video__item:nth-of-type(6)::after {
      top: 50%;
      left: 50%; } }

@media (max-width: 1280px) {
  .about-media-video__wrapper .about-media-video .model .video__model {
    width: 90%;
    height: 90%; } }

.about-story-blog__wrapper .about-story-blog {
  margin: 32px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between; }
  @media screen and (min-width: 640px) {
    .about-story-blog__wrapper .about-story-blog {
      margin: 40px 0; } }
  @media screen and (min-width: 1024px) {
    .about-story-blog__wrapper .about-story-blog {
      margin: 64px 0; } }
  @media screen and (min-width: 1280px) {
    .about-story-blog__wrapper .about-story-blog {
      margin: 64px 0; } }
  .about-story-blog__wrapper .about-story-blog .title-blog, .about-story-blog__wrapper .about-story-blog .about-story-blog--left .blog-item__content-title, .about-story-blog__wrapper .about-story-blog--left .about-story-blog .blog-item__content-title, .about-story-blog__wrapper .about-story-blog .about-story-blog--right .about-story-blog__title--right, .about-story-blog__wrapper .about-story-blog--right .about-story-blog .about-story-blog__title--right, .about-story-blog__wrapper .about-story-blog .about-story-blog--right .blog-item__content-title, .about-story-blog__wrapper .about-story-blog--right .about-story-blog .blog-item__content-title, .about-story-blog__wrapper .about-story-blog .blog-detail__wrapper .blog-detail--right .blog-item__content-title, .blog-detail__wrapper .blog-detail--right .about-story-blog__wrapper .about-story-blog .blog-item__content-title {
    font-weight: 700;
    text-transform: uppercase; }
  .about-story-blog__wrapper .about-story-blog--left {
    flex: 0 0 70%;
    width: 70%; }
    .about-story-blog__wrapper .about-story-blog--left .blog {
      margin-bottom: 32px;
      display: grid;
      grid-template-columns: auto auto;
      gap: 30px 20px; }
      @media screen and (min-width: 640px) {
        .about-story-blog__wrapper .about-story-blog--left .blog {
          margin-bottom: 40px; } }
      @media screen and (min-width: 1024px) {
        .about-story-blog__wrapper .about-story-blog--left .blog {
          margin-bottom: 64px; } }
      @media screen and (min-width: 1280px) {
        .about-story-blog__wrapper .about-story-blog--left .blog {
          margin-bottom: 64px; } }
      @media screen and (min-width: 1024px) {
        .about-story-blog__wrapper .about-story-blog--left .blog {
          gap: 38px 50px; } }
      .about-story-blog__wrapper .about-story-blog--left .blog-item {
        grid-column: 1fr;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        transition: 0.3s all linear; }
        .about-story-blog__wrapper .about-story-blog--left .blog-item:hover {
          opacity: 0.8; }
        .about-story-blog__wrapper .about-story-blog--left .blog-item__img {
          width: 100%;
          height: 271px;
          object-fit: cover;
          object-position: center; }
        .about-story-blog__wrapper .about-story-blog--left .blog-item__content {
          margin-right: 32px;
          margin-left: 32px; }
          @media (max-width: 1280px) {
            .about-story-blog__wrapper .about-story-blog--left .blog-item__content {
              margin-right: 24px; } }
          @media (max-width: 640px) {
            .about-story-blog__wrapper .about-story-blog--left .blog-item__content {
              margin-right: 16px; } }
          @media (max-width: 1280px) {
            .about-story-blog__wrapper .about-story-blog--left .blog-item__content {
              margin-left: 24px; } }
          @media (max-width: 640px) {
            .about-story-blog__wrapper .about-story-blog--left .blog-item__content {
              margin-left: 16px; } }
          .about-story-blog__wrapper .about-story-blog--left .blog-item__content-title {
            margin: 32px 0; }
            @media (max-width: 1280px) {
              .about-story-blog__wrapper .about-story-blog--left .blog-item__content-title {
                margin: 24px 0; } }
            @media (max-width: 640px) {
              .about-story-blog__wrapper .about-story-blog--left .blog-item__content-title {
                margin: 16px 0; } }
          .about-story-blog__wrapper .about-story-blog--left .blog-item__content-desc {
            color: #707070;
            font-family: "UTM-NEO-Sans-Intel", sans-serif;
            font-size: 16px;
            font-weight: 400;
            margin-bottom: 32px;
            color: #707070; }
            @media (max-width: 1024px) {
              .about-story-blog__wrapper .about-story-blog--left .blog-item__content-desc {
                font-size: 15px; } }
            @media (max-width: 1280px) {
              .about-story-blog__wrapper .about-story-blog--left .blog-item__content-desc {
                margin-bottom: 24px; } }
            @media (max-width: 640px) {
              .about-story-blog__wrapper .about-story-blog--left .blog-item__content-desc {
                margin-bottom: 16px; } }
    .about-story-blog__wrapper .about-story-blog--left .releases-pagination {
      margin: 0 auto; }
  .about-story-blog__wrapper .about-story-blog--right {
    flex: 0 0 25%;
    width: 25%; }
    .about-story-blog__wrapper .about-story-blog--right .about-story-blog__title--right {
      margin-bottom: 40px;
      color: #707070;
      font-family: "UTM-NEO-Sans-Intel", sans-serif;
      font-size: 20px;
      font-weight: 400;
      color: #000;
      font-family: "HELVETICA-NEUE", "Segoe UI", sans-serif; }
      @media (max-width: 1280px) {
        .about-story-blog__wrapper .about-story-blog--right .about-story-blog__title--right {
          margin-bottom: 32px; } }
      @media (max-width: 640px) {
        .about-story-blog__wrapper .about-story-blog--right .about-story-blog__title--right {
          margin-bottom: 12px; } }
      @media (max-width: 1024px) {
        .about-story-blog__wrapper .about-story-blog--right .about-story-blog__title--right {
          font-size: 18px; } }
      @media (max-width: 640px) {
        .about-story-blog__wrapper .about-story-blog--right .about-story-blog__title--right {
          font-size: 16px; } }
    .about-story-blog__wrapper .about-story-blog--right .blog {
      display: flex;
      flex-direction: column; }
      .about-story-blog__wrapper .about-story-blog--right .blog-item {
        transition: 0.3s all linear;
        padding-top: 20px; }
        .about-story-blog__wrapper .about-story-blog--right .blog-item:hover {
          opacity: 0.8; }
        .about-story-blog__wrapper .about-story-blog--right .blog-item__img {
          width: 100%;
          height: 238px;
          object-fit: cover;
          object-position: center; }
        .about-story-blog__wrapper .about-story-blog--right .blog-item__content-title {
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          margin: 12px 0; }
        @media screen and (min-width: 1024px) {
          .about-story-blog__wrapper .about-story-blog--right .blog-item:not(:last-child) {
            border-bottom: 1px solid rgba(163, 39, 50, 0.9); } }

@media (max-width: 1024px) {
  .about-story-blog__wrapper .about-story-blog {
    flex-direction: column; }
  .about-story-blog__wrapper .about-story-blog--left {
    flex: 0 0 100%;
    width: 100%; }
  .about-story-blog__wrapper .about-story-blog--right {
    flex: 0 0 100%;
    width: 100%;
    margin-top: 32px; } }
  @media screen and (max-width: 1024px) and (min-width: 640px) {
    .about-story-blog__wrapper .about-story-blog--right {
      margin-top: 40px; } }
  @media screen and (max-width: 1024px) and (min-width: 1024px) {
    .about-story-blog__wrapper .about-story-blog--right {
      margin-top: 64px; } }
  @media screen and (max-width: 1024px) and (min-width: 1280px) {
    .about-story-blog__wrapper .about-story-blog--right {
      margin-top: 64px; } }

@media (max-width: 1024px) {
    .about-story-blog__wrapper .about-story-blog--right .blog {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin-left: 1%; }
    .about-story-blog__wrapper .about-story-blog--right .blog-item {
      padding-top: 12px;
      width: 33.333%;
      padding-right: 2%;
      border-bottom: 0; }
  .about-story-blog__wrapper .about-story-blog--right .about-story-blog__title--right {
    margin-left: 1%; } }

@media (max-width: 768px) {
  .about-story-blog__wrapper .about-story-blog--right .blog-item {
    width: 50%; } }

@media (max-width: 640px) {
  .about-story-blog__wrapper .about-story-blog--right .blog-item {
    width: 100%; } }

@media (max-width: 640px) {
  .about-story-blog__wrapper .about-story-blog--left .blog {
    grid-template-columns: auto; } }

@media (max-width: 640px) {
  .releases-pagination {
    width: 302px; }
  .releases-pagination .swiper-container {
    padding: 0 35px; } }

.intro__button {
  margin-top: 40px; }
  @media (max-width: 1280px) {
    .intro__button {
      margin-top: 32px; } }
  @media (max-width: 640px) {
    .intro__button {
      margin-top: 12px; } }
  .intro__button--link {
    padding: 12px 24px !important; }
    @media (max-width: 1024px) {
      .intro__button--link {
        padding: 8px 12px !important; } }
    @media (max-width: 640px) {
      .intro__button--link {
        font-size: 14px; } }

.faq-content__wrapper .faq-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 32px 0; }
  @media screen and (min-width: 640px) {
    .faq-content__wrapper .faq-content {
      padding: 40px 0; } }
  @media screen and (min-width: 1024px) {
    .faq-content__wrapper .faq-content {
      padding: 64px 0; } }
  @media screen and (min-width: 1280px) {
    .faq-content__wrapper .faq-content {
      padding: 64px 0; } }
  .faq-content__wrapper .faq-content .faq-content__left {
    width: 23%;
    overflow: hidden; }
    .faq-content__wrapper .faq-content .faq-content__left .faq-cateheading {
      display: inline-block;
      position: relative;
      font-weight: 700; }
      .faq-content__wrapper .faq-content .faq-content__left .faq-cateheading::after {
        position: absolute;
        content: "";
        top: 50%;
        left: calc(100% + 12px);
        height: 1px;
        width: 100%;
        background: #a32732;
        opacity: 0.8;
        transform: translateY(-50%); }
    .faq-content__wrapper .faq-content .faq-content__left .faq-left__items {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 40px;
      width: 100%;
      background: #ececec;
      border-radius: 12px; }
      @media (max-width: 1280px) {
        .faq-content__wrapper .faq-content .faq-content__left .faq-left__items {
          margin-top: 32px; } }
      @media (max-width: 640px) {
        .faq-content__wrapper .faq-content .faq-content__left .faq-left__items {
          margin-top: 12px; } }
      .faq-content__wrapper .faq-content .faq-content__left .faq-left__items .faq-left__list {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 20px 0;
        position: relative;
        cursor: pointer;
        transition: 0.2s all ease;
        font-family: "SVN-AvenirNext", sans-serif;
        color: #000;
        text-transform: uppercase;
        font-weight: 500;
        transition: 0.2s all ease-in-out; }
        @media (max-width: 1280px) {
          .faq-content__wrapper .faq-content .faq-content__left .faq-left__items .faq-left__list {
            padding: 12px 0; } }
        @media (max-width: 640px) {
          .faq-content__wrapper .faq-content .faq-content__left .faq-left__items .faq-left__list {
            padding: 8px 0; } }
        .faq-content__wrapper .faq-content .faq-content__left .faq-left__items .faq-left__list:first-child {
          border-top-left-radius: 12px;
          border-top-right-radius: 12px; }
        .faq-content__wrapper .faq-content .faq-content__left .faq-left__items .faq-left__list:last-child {
          border-bottom-left-radius: 12px;
          border-bottom-right-radius: 12px; }
        .faq-content__wrapper .faq-content .faq-content__left .faq-left__items .faq-left__list.active {
          background-color: #a32732;
          color: white; }
        .faq-content__wrapper .faq-content .faq-content__left .faq-left__items .faq-left__list:hover {
          background-color: #a32732;
          color: white; }
        .faq-content__wrapper .faq-content .faq-content__left .faq-left__items .faq-left__list:not(:first-child)::after {
          width: 100%;
          position: absolute;
          content: "";
          top: 0%;
          left: 50%;
          height: 1px;
          background: #a32732;
          transform: translateX(-50%);
          opacity: 0.7; }
          @media (max-width: 1280px) {
            .faq-content__wrapper .faq-content .faq-content__left .faq-left__items .faq-left__list:not(:first-child)::after {
              display: none; } }
        .faq-content__wrapper .faq-content .faq-content__left .faq-left__items .faq-left__list .item__desc {
          width: 100%;
          padding: 0 24px; }
        .faq-content__wrapper .faq-content .faq-content__left .faq-left__items .faq-left__list .item__img {
          display: none;
          width: 35px;
          height: 35px;
          border-radius: 50%;
          object-fit: cover;
          object-position: center;
          pointer-events: none;
          box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
          margin-left: 8px; }
  .faq-content__wrapper .faq-content .faq-content__right {
    width: 75%;
    padding-left: 60px;
    margin-top: -20px; }
    .faq-content__wrapper .faq-content .faq-content__right .content-title .title {
      margin-bottom: 24px; }
      @media (max-width: 1280px) {
        .faq-content__wrapper .faq-content .faq-content__right .content-title .title {
          margin-bottom: 16px; } }
      @media (max-width: 640px) {
        .faq-content__wrapper .faq-content .faq-content__right .content-title .title {
          margin-bottom: 12px; } }
    .faq-content__wrapper .faq-content .faq-content__right .faq-right__items .faq-right__list.active {
      transition: 0.3s all linear; }
      .faq-content__wrapper .faq-content .faq-content__right .faq-right__items .faq-right__list.active .content__img2 {
        height: 100% !important;
        margin: 40px 0; }
        @media (max-width: 1280px) {
          .faq-content__wrapper .faq-content .faq-content__right .faq-right__items .faq-right__list.active .content__img2 {
            margin: 32px 0; } }
        @media (max-width: 640px) {
          .faq-content__wrapper .faq-content .faq-content__right .faq-right__items .faq-right__list.active .content__img2 {
            margin: 12px 0; } }
      .faq-content__wrapper .faq-content .faq-content__right .faq-right__items .faq-right__list.active .right-list__items::after {
        content: "\268A"; }
    .faq-content__wrapper .faq-content .faq-content__right .faq-right__items .faq-right__list .right-list__items {
      cursor: pointer;
      display: flex;
      align-items: center;
      position: relative;
      padding: 24px;
      border-top: 1px solid #a32732; }
      @media (max-width: 1280px) {
        .faq-content__wrapper .faq-content .faq-content__right .faq-right__items .faq-right__list .right-list__items {
          padding: 16px; } }
      @media (max-width: 640px) {
        .faq-content__wrapper .faq-content .faq-content__right .faq-right__items .faq-right__list .right-list__items {
          padding: 12px; } }
      .faq-content__wrapper .faq-content .faq-content__right .faq-right__items .faq-right__list .right-list__items:hover {
        background-color: #ececec; }
      .faq-content__wrapper .faq-content .faq-content__right .faq-right__items .faq-right__list .right-list__items::before {
        content: "";
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 0;
        padding-left: 10px;
        padding-top: 10px;
        height: 0;
        border-radius: 100%;
        background-color: #a32732; }
      .faq-content__wrapper .faq-content .faq-content__right .faq-right__items .faq-right__list .right-list__items::after {
        content: "\271A";
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(-50%, -50%);
        font-family: "SVN-AvenirNext", sans-serif;
        color: #000;
        font-size: 32px;
        font-weight: 500;
        vertical-align: middle;
        color: #a32732;
        font-weight: bold; }
        @media (max-width: 1024px) {
          .faq-content__wrapper .faq-content .faq-content__right .faq-right__items .faq-right__list .right-list__items::after {
            font-size: 26px; } }
        @media (max-width: 640px) {
          .faq-content__wrapper .faq-content .faq-content__right .faq-right__items .faq-right__list .right-list__items::after {
            font-size: 22px; } }
      .faq-content__wrapper .faq-content .faq-content__right .faq-right__items .faq-right__list .right-list__items .item_img {
        display: none; }
      .faq-content__wrapper .faq-content .faq-content__right .faq-right__items .faq-right__list .right-list__items .item__desc2 {
        padding-left: 10px;
        padding-right: 25px; }
      .faq-content__wrapper .faq-content .faq-content__right .faq-right__items .faq-right__list .right-list__items .item_img2 {
        margin-left: auto; }
    .faq-content__wrapper .faq-content .faq-content__right .faq-right__items .faq-right__list .content__img2 {
      height: 0;
      overflow: hidden;
      letter-spacing: 1.15px; }
      .faq-content__wrapper .faq-content .faq-content__right .faq-right__items .faq-right__list .content__img2 ul {
        list-style-type: disc;
        padding-left: 2.2rem; }
        .faq-content__wrapper .faq-content .faq-content__right .faq-right__items .faq-right__list .content__img2 ul > li > ul {
          list-style-type: circle;
          padding-left: 2rem; }
          .faq-content__wrapper .faq-content .faq-content__right .faq-right__items .faq-right__list .content__img2 ul > li > ul > li > ul {
            list-style-type: square;
            padding-left: 2rem; }
      .faq-content__wrapper .faq-content .faq-content__right .faq-right__items .faq-right__list .content__img2 .text-content__img2 p {
        color: #707070; }
      .faq-content__wrapper .faq-content .faq-content__right .faq-right__items .faq-right__list .content__img2 .text-content__img2 > *:not(:first-child) {
        margin-top: 30px; }

@media (max-width: 1280px) {
  .faq-content__wrapper .faq-content {
    flex-direction: column; }
  .faq-content__wrapper .faq-content .faq-content__right {
    width: 100%;
    margin-top: 0;
    padding-left: 0; }
  .faq-content__wrapper .faq-content .faq-content__left {
    width: 100%; }
  .faq-content__wrapper .faq-content .faq-content__left .faq-left__items {
    flex-direction: row;
    justify-content: flex-start;
    overflow-x: scroll;
    border-radius: 0;
    align-items: stretch; }
    .faq-content__wrapper .faq-content .faq-content__left .faq-left__items .faq-left__list {
      min-width: 180px; }
      .faq-content__wrapper .faq-content .faq-content__left .faq-left__items .faq-left__list:first-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0; }
      .faq-content__wrapper .faq-content .faq-content__left .faq-left__items .faq-left__list:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0; }
  .faq-content__wrapper .faq-content .faq-content__left .faq-left__items .faq-left__list {
    min-width: 272px; }
    .faq-content__wrapper .faq-content .faq-content__left .faq-left__items .faq-left__list:not(:first-child) {
      margin-left: 0; }
  .faq-content__wrapper .faq-content .faq-content__left .faq-cateheading::after {
    display: none; }
  .faq-content__wrapper .faq-content .faq-content__left {
    margin-bottom: 36px; }
  .faq-content__wrapper .faq-content .faq-content__right .content-title .title {
    margin-bottom: 16px; } }

@media (max-width: 640px) {
  .faq-content__wrapper .faq-content .faq-content__right .content-title .title {
    margin-bottom: 8px; }
  .faq-content__wrapper .faq-content .faq-content__right .faq-right__items .faq-right__list .content__img2 .text-content__img2 {
    margin: 16px 0; } }

.contactus-content__wrapper .contactus__tittle {
  position: relative;
  margin: 32px 0; }
  @media screen and (min-width: 640px) {
    .contactus-content__wrapper .contactus__tittle {
      margin: 40px 0; } }
  @media screen and (min-width: 1024px) {
    .contactus-content__wrapper .contactus__tittle {
      margin: 64px 0; } }
  @media screen and (min-width: 1280px) {
    .contactus-content__wrapper .contactus__tittle {
      margin: 64px 0; } }
  .contactus-content__wrapper .contactus__tittle::after {
    position: absolute;
    content: "";
    top: 120%;
    left: 50%;
    height: 2px;
    width: 100%;
    background: #a32732;
    transform: translateX(-50%); }

.contactus-content__wrapper .contactus-content {
  margin-bottom: 32px; }
  .contactus-content__wrapper .contactus-content .contactus-content__left .content-main {
    text-align: left;
    margin-bottom: 20px; }
    @media screen and (min-width: 640px) {
      .contactus-content__wrapper .contactus-content .contactus-content__left .content-main {
        margin-bottom: 32px; } }
    @media screen and (min-width: 1024px) {
      .contactus-content__wrapper .contactus-content .contactus-content__left .content-main {
        margin-bottom: 40px; } }
    @media screen and (min-width: 1280px) {
      .contactus-content__wrapper .contactus-content .contactus-content__left .content-main {
        margin-bottom: 40px; } }
    .contactus-content__wrapper .contactus-content .contactus-content__left .content-main__title {
      width: 100%;
      position: relative;
      margin-bottom: 20px;
      text-transform: uppercase;
      font-weight: bold; }
      .contactus-content__wrapper .contactus-content .contactus-content__left .content-main__title::after {
        position: absolute;
        content: "";
        top: calc(100% + 10px);
        left: 50%;
        height: 1px;
        width: 100%;
        opacity: 0.2;
        background: #a32732;
        transform: translateX(-50%); }
    .contactus-content__wrapper .contactus-content .contactus-content__left .content-main__desc {
      width: 98%;
      text-align: left;
      color: inherit;
      margin-bottom: 16px; }
      @media screen and (min-width: 640px) {
        .contactus-content__wrapper .contactus-content .contactus-content__left .content-main__desc {
          margin-bottom: 20px; } }
      @media screen and (min-width: 1024px) {
        .contactus-content__wrapper .contactus-content .contactus-content__left .content-main__desc {
          margin-bottom: 20px; } }
      @media screen and (min-width: 1280px) {
        .contactus-content__wrapper .contactus-content .contactus-content__left .content-main__desc {
          margin-bottom: 20px; } }
    .contactus-content__wrapper .contactus-content .contactus-content__left .content-main__email {
      color: inherit;
      margin-bottom: 40px; }
      @media (max-width: 1280px) {
        .contactus-content__wrapper .contactus-content .contactus-content__left .content-main__email {
          margin-bottom: 32px; } }
      @media (max-width: 640px) {
        .contactus-content__wrapper .contactus-content .contactus-content__left .content-main__email {
          margin-bottom: 12px; } }
    .contactus-content__wrapper .contactus-content .contactus-content__left .content-main__desc2 {
      width: 70%;
      text-align: left;
      margin: 20px 0px;
      font-size: 18px;
      font-family: SEGOEUIL; }
    .contactus-content__wrapper .contactus-content .contactus-content__left .content-main > *:last-child {
      margin-bottom: 0; }
  .contactus-content__wrapper .contactus-content .contactus-right .contact-map {
    width: 100%;
    display: flex;
    justify-content: flex-end; }
    .contactus-content__wrapper .contactus-content .contactus-right .contact-map__iframe {
      padding-bottom: 64px;
      padding-top: 32px;
      border: 0;
      width: 605px;
      height: 728px; }

@media (max-width: 1024px) {
  .contactus-content__left,
  .contactus-right {
    flex: 0 0 100%;
    max-width: 100%; }
  .contactus-content__wrapper .contactus-content .contactus-right .contact-map__iframe {
    width: 100%;
    padding-bottom: 36px; } }

@media (max-width: 640px) {
  .contactus-content__wrapper .contactus-content .contactus-content__left .content-main {
    flex: 0 0 100%;
    max-width: 100%; }
  .contactus-content__wrapper .contactus-content .contactus-content__left .content-main__title,
  .contactus-content__wrapper .contactus-content .contactus-content__left .content-main__desc {
    width: 100%;
    margin-bottom: 36px; }
  .contactus-content__wrapper .contactus-content .contactus-content__left .content-main__desc {
    padding-right: 0px; } }

.details-infor__wrapper .container .details-infor, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor {
  margin-top: 32px; }
  @media screen and (min-width: 640px) {
    .details-infor__wrapper .container .details-infor, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor {
      margin-top: 40px; } }
  @media screen and (min-width: 1024px) {
    .details-infor__wrapper .container .details-infor, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor {
      margin-top: 64px; } }
  @media screen and (min-width: 1280px) {
    .details-infor__wrapper .container .details-infor, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor {
      margin-top: 64px; } }
  .details-infor__wrapper .container .details-infor .details-infor__liveview, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .details-infor__liveview, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .details-infor__liveview {
    padding: 0 30px; }
    .details-infor__wrapper .container .details-infor .details-infor__liveview .mySwiper2, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .details-infor__liveview .mySwiper2, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .details-infor__liveview .mySwiper2 {
      margin: 10px 0 38px 0; }
      .details-infor__wrapper .container .details-infor .details-infor__liveview .mySwiper2 .swiper-wrapper .swiper-slide, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .details-infor__liveview .mySwiper2 .swiper-wrapper .swiper-slide, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .details-infor__liveview .mySwiper2 .swiper-wrapper .swiper-slide {
        cursor: zoom-in; }
        .details-infor__wrapper .container .details-infor .details-infor__liveview .mySwiper2 .swiper-wrapper .swiper-slide *, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .details-infor__liveview .mySwiper2 .swiper-wrapper .swiper-slide *, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .details-infor__liveview .mySwiper2 .swiper-wrapper .swiper-slide * {
          width: 100%;
          height: 100%; }
        .details-infor__wrapper .container .details-infor .details-infor__liveview .mySwiper2 .swiper-wrapper .swiper-slide.swiper-slide-zoomed, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .details-infor__liveview .mySwiper2 .swiper-wrapper .swiper-slide.swiper-slide-zoomed, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .details-infor__liveview .mySwiper2 .swiper-wrapper .swiper-slide.swiper-slide-zoomed {
          cursor: zoom-out; }
        .details-infor__wrapper .container .details-infor .details-infor__liveview .mySwiper2 .swiper-wrapper .swiper-slide img, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .details-infor__liveview .mySwiper2 .swiper-wrapper .swiper-slide img, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .details-infor__liveview .mySwiper2 .swiper-wrapper .swiper-slide img {
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); }
    .details-infor__wrapper .container .details-infor .details-infor__liveview .mySwiper, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .details-infor__liveview .mySwiper, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .details-infor__liveview .mySwiper {
      width: 70%; }
      .details-infor__wrapper .container .details-infor .details-infor__liveview .mySwiper .swiper-wrapper .swiper-slide, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .details-infor__liveview .mySwiper .swiper-wrapper .swiper-slide, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .details-infor__liveview .mySwiper .swiper-wrapper .swiper-slide {
        cursor: pointer; }
        .details-infor__wrapper .container .details-infor .details-infor__liveview .mySwiper .swiper-wrapper .swiper-slide *, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .details-infor__liveview .mySwiper .swiper-wrapper .swiper-slide *, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .details-infor__liveview .mySwiper .swiper-wrapper .swiper-slide * {
          border-radius: 5px; }
    .details-infor__wrapper .container .details-infor .details-infor__liveview .swiper-btn__nav .swiper-button-prev, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .details-infor__liveview .swiper-btn__nav .swiper-button-prev, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .details-infor__liveview .swiper-btn__nav .swiper-button-prev {
      left: 80px;
      padding: 7px; }
      @media (max-width: 425px) {
        .details-infor__wrapper .container .details-infor .details-infor__liveview .swiper-btn__nav .swiper-button-prev, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .details-infor__liveview .swiper-btn__nav .swiper-button-prev, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .details-infor__liveview .swiper-btn__nav .swiper-button-prev {
          left: 20px; } }
    .details-infor__wrapper .container .details-infor .details-infor__liveview .swiper-btn__nav .swiper-button-next, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .details-infor__liveview .swiper-btn__nav .swiper-button-next, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .details-infor__liveview .swiper-btn__nav .swiper-button-next {
      right: 80px;
      padding: 7px; }
      @media (max-width: 425px) {
        .details-infor__wrapper .container .details-infor .details-infor__liveview .swiper-btn__nav .swiper-button-next, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .details-infor__liveview .swiper-btn__nav .swiper-button-next, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .details-infor__liveview .swiper-btn__nav .swiper-button-next {
          right: 20px; } }
  .details-infor__wrapper .container .details-infor .detail-infor__content .product-infor .product-infor__title, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-infor .product-infor__title, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-infor .product-infor__title {
    font-family: "SVN-AvenirNext", sans-serif;
    color: #000;
    font-size: 50px;
    font-weight: 500;
    font-weight: 700;
    margin-bottom: 12px; }
    @media (max-width: 1024px) {
      .details-infor__wrapper .container .details-infor .detail-infor__content .product-infor .product-infor__title, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-infor .product-infor__title, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-infor .product-infor__title {
        font-size: 40px; } }
    @media (max-width: 640px) {
      .details-infor__wrapper .container .details-infor .detail-infor__content .product-infor .product-infor__title, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-infor .product-infor__title, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-infor .product-infor__title {
        font-size: 32px; } }
  .details-infor__wrapper .container .details-infor .detail-infor__content .product-infor .product-infor__price, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-infor .product-infor__price, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-infor .product-infor__price {
    font-family: HELVETICANEUELTSTD-ROMAN;
    font-size: 40px;
    color: #a32732;
    margin: 10px 0; }
  .details-infor__wrapper .container .details-infor .detail-infor__content .product-oftions, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-oftions, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-oftions {
    padding: 7px 0; }
    .details-infor__wrapper .container .details-infor .detail-infor__content .product-oftions .dropdown-value, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-oftions .dropdown-value, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-oftions .dropdown-value {
      display: flex;
      align-items: center; }
      .details-infor__wrapper .container .details-infor .detail-infor__content .product-oftions .dropdown-value .size, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-oftions .dropdown-value .size, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-oftions .dropdown-value .size {
        width: 60%; }
      .details-infor__wrapper .container .details-infor .detail-infor__content .product-oftions .dropdown-value .amount, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-oftions .dropdown-value .amount, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-oftions .dropdown-value .amount {
        width: 40%; }
  .details-infor__wrapper .container .details-infor .detail-infor__content .product-rate, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-rate, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-rate {
    padding: 30px 0; }
    .details-infor__wrapper .container .details-infor .detail-infor__content .product-rate .rate-section, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-rate .rate-section, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-rate .rate-section {
      padding: 7px 0;
      align-items: center; }
      .details-infor__wrapper .container .details-infor .detail-infor__content .product-rate .rate-section .rate-title__items *, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-rate .rate-section .rate-title__items *, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-rate .rate-section .rate-title__items * {
        color: #707070;
        white-space: nowrap; }
      .details-infor__wrapper .container .details-infor .detail-infor__content .product-rate .rate-section .rate-title__items a:hover, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-rate .rate-section .rate-title__items a:hover, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-rate .rate-section .rate-title__items a:hover {
        color: #a32732; }
      .details-infor__wrapper .container .details-infor .detail-infor__content .product-rate .rate-section:not(:last-child) .rate-title__items *, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-rate .rate-section:not(:last-child) .rate-title__items *, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-rate .rate-section:not(:last-child) .rate-title__items * {
        border-right: 1px solid #707070; }
      .details-infor__wrapper .container .details-infor .detail-infor__content .product-rate .rate-section .rate-star__items .items-star, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-rate .rate-section .rate-star__items .items-star, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-rate .rate-section .rate-star__items .items-star {
        display: flex;
        align-items: center; }
        .details-infor__wrapper .container .details-infor .detail-infor__content .product-rate .rate-section .rate-star__items .items-star *, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-rate .rate-section .rate-star__items .items-star *, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-rate .rate-section .rate-star__items .items-star * {
          margin-right: 5px;
          position: relative;
          width: 2rem;
          height: 2rem;
          text-indent: -9999rem;
          display: inline-block;
          align-items: center; }
          .details-infor__wrapper .container .details-infor .detail-infor__content .product-rate .rate-section .rate-star__items .items-star *::after, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-rate .rate-section .rate-star__items .items-star *::after, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-rate .rate-section .rate-star__items .items-star *::after {
            position: absolute;
            top: 0;
            left: 0;
            content: "";
            width: 2rem;
            height: 2rem;
            background-image: url("../assets/icons/white-star-blueborder.png");
            background-repeat: no-repeat;
            background-position: center;
            text-indent: 0;
            display: inline; }
        .details-infor__wrapper .container .details-infor .detail-infor__content .product-rate .rate-section .rate-star__items .items-star .active::after, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-rate .rate-section .rate-star__items .items-star .active::after, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-rate .rate-section .rate-star__items .items-star .active::after {
          background-image: url("../assets/icons/blue-star.png"); }
  .details-infor__wrapper .container .details-infor .detail-infor__content .product-feature-warranty .nav-feature, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-feature-warranty .nav-feature, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-feature-warranty .nav-feature {
    display: flex;
    align-items: center;
    font-size: 17px;
    color: #a32732;
    font-family: "SVN-AvenirNext", sans-serif;
    text-transform: uppercase;
    position: relative;
    overflow-x: scroll;
    overflow-y: hidden;
    padding-bottom: 12px; }
    .details-infor__wrapper .container .details-infor .detail-infor__content .product-feature-warranty .nav-feature::-webkit-scrollbar, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-feature-warranty .nav-feature::-webkit-scrollbar, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-feature-warranty .nav-feature::-webkit-scrollbar {
      display: none; }
    .details-infor__wrapper .container .details-infor .detail-infor__content .product-feature-warranty .nav-feature .product-feature, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-feature-warranty .nav-feature .product-feature, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-feature-warranty .nav-feature .product-feature {
      cursor: pointer;
      white-space: nowrap; }
      .details-infor__wrapper .container .details-infor .detail-infor__content .product-feature-warranty .nav-feature .product-feature:not(:first-child), .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-feature-warranty .nav-feature .product-feature:not(:first-child), .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-feature-warranty .nav-feature .product-feature:not(:first-child) {
        margin-left: 24px; }
    .details-infor__wrapper .container .details-infor .detail-infor__content .product-feature-warranty .nav-feature::after, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-feature-warranty .nav-feature::after, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-feature-warranty .nav-feature::after {
      position: absolute;
      content: "";
      bottom: 8px;
      right: 0;
      width: 100%;
      height: 1px;
      background: #a32732;
      opacity: 0.4; }
    .details-infor__wrapper .container .details-infor .detail-infor__content .product-feature-warranty .nav-feature .active, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-feature-warranty .nav-feature .active, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-feature-warranty .nav-feature .active {
      position: relative; }
      .details-infor__wrapper .container .details-infor .detail-infor__content .product-feature-warranty .nav-feature .active::after, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-feature-warranty .nav-feature .active::after, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-feature-warranty .nav-feature .active::after {
        position: absolute;
        content: "";
        bottom: -5px;
        left: 0;
        width: 80%;
        border: solid 2px #bf0d3e;
        background: #bf0d3e;
        border-radius: 3px;
        z-index: 1; }
  .details-infor__wrapper .container .details-infor .detail-infor__content .product-feature-warranty .product-selected .product-selected--value, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-feature-warranty .product-selected .product-selected--value, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-feature-warranty .product-selected .product-selected--value {
    display: none; }
    .details-infor__wrapper .container .details-infor .detail-infor__content .product-feature-warranty .product-selected .product-selected--value.active, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-feature-warranty .product-selected .product-selected--value.active, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-feature-warranty .product-selected .product-selected--value.active {
      display: block; }
  .details-infor__wrapper .container .details-infor .detail-infor__content .product-feature-warranty .feature-details, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-feature-warranty .feature-details, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-feature-warranty .feature-details {
    margin-top: 30px; }
    .details-infor__wrapper .container .details-infor .detail-infor__content .product-feature-warranty .feature-details .dropdown-feature, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-feature-warranty .feature-details .dropdown-feature, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-feature-warranty .feature-details .dropdown-feature {
      border-bottom: solid 1px #c0c0c0; }
      .details-infor__wrapper .container .details-infor .detail-infor__content .product-feature-warranty .feature-details .dropdown-feature .dropdown-item:hover, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-feature-warranty .feature-details .dropdown-feature .dropdown-item:hover, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-feature-warranty .feature-details .dropdown-feature .dropdown-item:hover {
        color: #a32732; }
      .details-infor__wrapper .container .details-infor .detail-infor__content .product-feature-warranty .feature-details .dropdown-feature:last-child, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-feature-warranty .feature-details .dropdown-feature:last-child, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-feature-warranty .feature-details .dropdown-feature:last-child {
        border-bottom: none; }
      .details-infor__wrapper .container .details-infor .detail-infor__content .product-feature-warranty .feature-details .dropdown-feature .dropdown-item, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-feature-warranty .feature-details .dropdown-feature .dropdown-item, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-feature-warranty .feature-details .dropdown-feature .dropdown-item {
        border: none;
        border-radius: 0;
        width: 100%;
        padding: 10px 0 25px 0;
        text-align: start; }
        .details-infor__wrapper .container .details-infor .detail-infor__content .product-feature-warranty .feature-details .dropdown-feature .dropdown-item span img, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-feature-warranty .feature-details .dropdown-feature .dropdown-item span img, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-feature-warranty .feature-details .dropdown-feature .dropdown-item span img {
          transition: 0.4s ease-in-out; }
        .details-infor__wrapper .container .details-infor .detail-infor__content .product-feature-warranty .feature-details .dropdown-feature .dropdown-item .active-caret img, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-feature-warranty .feature-details .dropdown-feature .dropdown-item .active-caret img, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-feature-warranty .feature-details .dropdown-feature .dropdown-item .active-caret img {
          transform: rotate(180deg); }
      .details-infor__wrapper .container .details-infor .detail-infor__content .product-feature-warranty .feature-details .dropdown-feature .feature-content, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-feature-warranty .feature-details .dropdown-feature .feature-content, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-feature-warranty .feature-details .dropdown-feature .feature-content {
        transition: max-height 0.4s ease-in-out;
        max-height: 0;
        overflow: hidden;
        color: #707070; }
      .details-infor__wrapper .container .details-infor .detail-infor__content .product-feature-warranty .feature-details .dropdown-feature .active, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-feature-warranty .feature-details .dropdown-feature .active, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-feature-warranty .feature-details .dropdown-feature .active {
        height: auto;
        max-height: 300px;
        overflow-y: scroll;
        margin-bottom: 20px; }
        .details-infor__wrapper .container .details-infor .detail-infor__content .product-feature-warranty .feature-details .dropdown-feature .active::-webkit-scrollbar, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-feature-warranty .feature-details .dropdown-feature .active::-webkit-scrollbar, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-feature-warranty .feature-details .dropdown-feature .active::-webkit-scrollbar {
          width: 0; }
  .details-infor__wrapper .container .details-infor .detail-infor__content .product-feature-warranty .feature-icons, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-feature-warranty .feature-icons, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-feature-warranty .feature-icons {
    border-top: solid 1px #ececec;
    padding: 20px 0; }
    .details-infor__wrapper .container .details-infor .detail-infor__content .product-feature-warranty .feature-icons .swiper-slide, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-feature-warranty .feature-icons .swiper-slide, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-feature-warranty .feature-icons .swiper-slide {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center; }
      .details-infor__wrapper .container .details-infor .detail-infor__content .product-feature-warranty .feature-icons .swiper-slide .icon-avatar, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-feature-warranty .feature-icons .swiper-slide .icon-avatar, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-feature-warranty .feature-icons .swiper-slide .icon-avatar {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background: #ececec;
        margin-bottom: 10px; }
  .details-infor__wrapper .container .details-infor .detail-infor__content .product-order, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-order, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-order {
    margin-top: 20px; }
    .details-infor__wrapper .container .details-infor .detail-infor__content .product-order a, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-order a, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-order a {
      border: solid 3px #a32732;
      background-color: transparent;
      border-radius: 5px;
      text-align: center;
      padding: 16px 0;
      display: block;
      color: #a32732;
      font-weight: 700; }
      .details-infor__wrapper .container .details-infor .detail-infor__content .product-order a:hover, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-order a:hover, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-order a:hover {
        background-color: #a32732;
        color: white; }
  .details-infor__wrapper .container .details-infor .detail-infor__content .product-ask, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-ask, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-ask {
    display: flex;
    flex-direction: column;
    margin-top: 40px; }
    @media (max-width: 1280px) {
      .details-infor__wrapper .container .details-infor .detail-infor__content .product-ask, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-ask, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-ask {
        margin-top: 32px; } }
    @media (max-width: 640px) {
      .details-infor__wrapper .container .details-infor .detail-infor__content .product-ask, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-ask, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-ask {
        margin-top: 12px; } }
    .details-infor__wrapper .container .details-infor .detail-infor__content .product-ask-item--top, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-ask-item--top, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-ask-item--top {
      display: flex;
      background-color: #a32732;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px; }
    .details-infor__wrapper .container .details-infor .detail-infor__content .product-ask-item--bottom, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-ask-item--bottom, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-ask-item--bottom {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      padding: 12px 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #520007;
      color: #fff; }
    .details-infor__wrapper .container .details-infor .detail-infor__content .product-ask-item__link, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-ask-item__link, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-ask-item__link {
      color: inherit;
      font-size: 18px;
      font-weight: 500; }
      @media screen and (min-width: 1024px) {
        .details-infor__wrapper .container .details-infor .detail-infor__content .product-ask-item__link, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-ask-item__link, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-ask-item__link {
          font-size: 20px; } }
    .details-infor__wrapper .container .details-infor .detail-infor__content .product-ask-item--left, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-ask-item--left, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-ask-item--left {
      width: 47%;
      display: flex;
      justify-content: center;
      align-items: center; }
    .details-infor__wrapper .container .details-infor .detail-infor__content .product-ask-item--right, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-ask-item--right, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-ask-item--right {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
    .details-infor__wrapper .container .details-infor .detail-infor__content .product-ask-item__img, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-ask-item__img, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-ask-item__img {
      width: 100%;
      height: 180px;
      display: flex;
      justify-content: center;
      align-items: flex-end; }
      .details-infor__wrapper .container .details-infor .detail-infor__content .product-ask-item__img img, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-ask-item__img img, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-ask-item__img img {
        height: 100%;
        object-fit: contain;
        object-position: bottom; }
    .details-infor__wrapper .container .details-infor .detail-infor__content .product-ask-item__title, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-ask-item__title, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-ask-item__title {
      display: block;
      padding: 16px 40px 16px 0;
      font-size: 18px;
      font-weight: 600;
      color: #fff; }
      @media screen and (min-width: 1024px) {
        .details-infor__wrapper .container .details-infor .detail-infor__content .product-ask-item__title, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-ask-item__title, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-ask-item__title {
          font-size: 20px; } }
    .details-infor__wrapper .container .details-infor .detail-infor__content .product-ask-item__desc, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-ask-item__desc, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-ask-item__desc {
      width: 100%;
      color: #707070;
      font-family: "UTM-NEO-Sans-Intel", sans-serif;
      font-size: 20px;
      font-weight: 400;
      padding-right: 24px;
      font-weight: 300;
      color: white;
      padding-bottom: 36px; }
      @media (max-width: 1024px) {
        .details-infor__wrapper .container .details-infor .detail-infor__content .product-ask-item__desc, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-ask-item__desc, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-ask-item__desc {
          font-size: 18px; } }
      @media (max-width: 640px) {
        .details-infor__wrapper .container .details-infor .detail-infor__content .product-ask-item__desc, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-ask-item__desc, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-ask-item__desc {
          font-size: 16px; } }
  .details-infor__wrapper .container .details-infor .detail-infor__content .product-warranty, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-warranty, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-warranty {
    display: block;
    margin-top: 24px;
    font-weight: 700;
    color: #a32732;
    white-space: nowrap; }
    .details-infor__wrapper .container .details-infor .detail-infor__content .product-warranty svg, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-warranty svg, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-warranty svg {
      margin-left: 24px; }
      @media (max-width: 1600px) {
        .details-infor__wrapper .container .details-infor .detail-infor__content .product-warranty svg, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-warranty svg, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-warranty svg {
          margin-left: 10px; } }
      @media (max-width: 1440px) {
        .details-infor__wrapper .container .details-infor .detail-infor__content .product-warranty svg, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-warranty svg, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-warranty svg {
          margin-left: 8px; } }
    .details-infor__wrapper .container .details-infor .detail-infor__content .product-warranty:hover, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-warranty:hover, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-warranty:hover {
      opacity: 0.7; }

@media (max-width: 1640px) and (min-width: 601px) {
  .details-infor__wrapper .container .details-infor .details-infor__liveview .swiper-btn__nav .swiper-button-prev, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .details-infor__liveview .swiper-btn__nav .swiper-button-prev, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .details-infor__liveview .swiper-btn__nav .swiper-button-prev {
    left: 60px; }
  .details-infor__wrapper .container .details-infor .details-infor__liveview .swiper-btn__nav .swiper-button-next, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .details-infor__liveview .swiper-btn__nav .swiper-button-next, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .details-infor__liveview .swiper-btn__nav .swiper-button-next {
    right: 60px; }
  .details-infor__wrapper .container .details-infor .detail-infor__content .product-infor .product-infor__title, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-infor .product-infor__title, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-infor .product-infor__title {
    margin: 0 0 20px 0;
    font-size: 30px; }
  .details-infor__wrapper .container .details-infor .detail-infor__content .product-infor .product-infor__price, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-infor .product-infor__price, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-infor .product-infor__price {
    font-size: 30px;
    margin: 7px 0; }
  .details-infor__wrapper .container .details-infor .detail-infor__content .product-oftions .dropdown-value .size, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-oftions .dropdown-value .size, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-oftions .dropdown-value .size, .details-infor__wrapper .container .details-infor .detail-infor__content .product-oftions .dropdown-value .amount, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-oftions .dropdown-value .amount, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-oftions .dropdown-value .amount {
    font-size: 13px; }
  .details-infor__wrapper .container .details-infor .detail-infor__content .product-rate, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-rate, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-rate {
    padding: 25px 0; }
    .details-infor__wrapper .container .details-infor .detail-infor__content .product-rate .rate-section, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-rate .rate-section, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-rate .rate-section {
      padding: 7px 0; }
      .details-infor__wrapper .container .details-infor .detail-infor__content .product-rate .rate-section .rate-title__items, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-rate .rate-section .rate-title__items, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-rate .rate-section .rate-title__items {
        color: #707070;
        font-size: 13px;
        white-space: nowrap; }
      .details-infor__wrapper .container .details-infor .detail-infor__content .product-rate .rate-section .rate-star__items .items-star *, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-rate .rate-section .rate-star__items .items-star *, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-rate .rate-section .rate-star__items .items-star * {
        width: 1.6rem;
        height: 1.6rem; }
        .details-infor__wrapper .container .details-infor .detail-infor__content .product-rate .rate-section .rate-star__items .items-star *::after, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-rate .rate-section .rate-star__items .items-star *::after, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-rate .rate-section .rate-star__items .items-star *::after {
          width: 1.6rem;
          height: 1.6rem; }
  .details-infor__wrapper .container .details-infor .detail-infor__content .product-feature-warranty .nav-feature, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-feature-warranty .nav-feature, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-feature-warranty .nav-feature {
    font-size: 13px; }
    .details-infor__wrapper .container .details-infor .detail-infor__content .product-feature-warranty .nav-feature .active, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-feature-warranty .nav-feature .active, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-feature-warranty .nav-feature .active {
      position: relative; }
      .details-infor__wrapper .container .details-infor .detail-infor__content .product-feature-warranty .nav-feature .active::after, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-feature-warranty .nav-feature .active::after, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-feature-warranty .nav-feature .active::after {
        border: solid 1.7px #bf0d3e; }
  .details-infor__wrapper .container .details-infor .detail-infor__content .product-feature-warranty .feature-details .dropdown-feature, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-feature-warranty .feature-details .dropdown-feature, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-feature-warranty .feature-details .dropdown-feature {
    border-bottom: solid 1px #c0c0c0; }
    .details-infor__wrapper .container .details-infor .detail-infor__content .product-feature-warranty .feature-details .dropdown-feature:last-child, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-feature-warranty .feature-details .dropdown-feature:last-child, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-feature-warranty .feature-details .dropdown-feature:last-child {
      border-bottom: none; }
    .details-infor__wrapper .container .details-infor .detail-infor__content .product-feature-warranty .feature-details .dropdown-feature .dropdown-item, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-feature-warranty .feature-details .dropdown-feature .dropdown-item, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-feature-warranty .feature-details .dropdown-feature .dropdown-item {
      font-size: 13px;
      padding: 10px 0 20px 0; }
    .details-infor__wrapper .container .details-infor .detail-infor__content .product-feature-warranty .feature-details .dropdown-feature .feature-content, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-feature-warranty .feature-details .dropdown-feature .feature-content, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-feature-warranty .feature-details .dropdown-feature .feature-content {
      font-size: 13px; }
  .details-infor__wrapper .container .details-infor .detail-infor__content .product-feature-warranty .feature-icons, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-feature-warranty .feature-icons, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-feature-warranty .feature-icons {
    padding: 14px 0; }
    .details-infor__wrapper .container .details-infor .detail-infor__content .product-feature-warranty .feature-icons .swiper-slide .icon-avatar, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-feature-warranty .feature-icons .swiper-slide .icon-avatar, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-feature-warranty .feature-icons .swiper-slide .icon-avatar {
      width: 45px;
      height: 45px; }
    .details-infor__wrapper .container .details-infor .detail-infor__content .product-feature-warranty .feature-icons .swiper-slide .title-icon, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-feature-warranty .feature-icons .swiper-slide .title-icon, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-feature-warranty .feature-icons .swiper-slide .title-icon {
      font-size: 13px; }
  .details-infor__wrapper .container .details-infor .detail-infor__content .product-order, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-order, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-order {
    padding: 15px 0; }
    .details-infor__wrapper .container .details-infor .detail-infor__content .product-order a, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content .product-order a, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content .product-order a {
      font-size: 15px; } }

@media (max-width: 1280px) {
  .details-infor__wrapper .container .details-infor, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor {
    flex-direction: column; }
    .details-infor__wrapper .container .details-infor .details-infor__liveview, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .details-infor__liveview, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .details-infor__liveview {
      flex: 0 0 100%;
      width: 100%;
      max-width: 100%; }
    .details-infor__wrapper .container .details-infor .detail-infor__content, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content {
      flex: 0 0 100%;
      width: 100%;
      max-width: 100%;
      margin-top: 32px; } }
    @media screen and (max-width: 1280px) and (min-width: 640px) {
      .details-infor__wrapper .container .details-infor .detail-infor__content, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content {
        margin-top: 40px; } }
    @media screen and (max-width: 1280px) and (min-width: 1024px) {
      .details-infor__wrapper .container .details-infor .detail-infor__content, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content {
        margin-top: 64px; } }
    @media screen and (max-width: 1280px) and (min-width: 1280px) {
      .details-infor__wrapper .container .details-infor .detail-infor__content, .details-infor__wrapper .wholesale-factory__content2 .right .content .details-infor .detail-infor__content, .wholesale-factory__content2 .right .details-infor__wrapper .content .details-infor .detail-infor__content {
        margin-top: 64px; } }

.details-recommend-video__wrapper {
  margin: calc(128px + 32px) 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; }
  .details-recommend-video__wrapper .details-recommend-video {
    display: flex;
    align-items: center; }
    .details-recommend-video__wrapper .details-recommend-video .left-recommend-video {
      width: 50%; }
      .details-recommend-video__wrapper .details-recommend-video .left-recommend-video .product-infor {
        padding-right: 32px; }
        @media screen and (min-width: 640px) {
          .details-recommend-video__wrapper .details-recommend-video .left-recommend-video .product-infor {
            padding-right: 40px; } }
        @media screen and (min-width: 1024px) {
          .details-recommend-video__wrapper .details-recommend-video .left-recommend-video .product-infor {
            padding-right: 64px; } }
        @media screen and (min-width: 1280px) {
          .details-recommend-video__wrapper .details-recommend-video .left-recommend-video .product-infor {
            padding-right: 64px; } }
        .details-recommend-video__wrapper .details-recommend-video .left-recommend-video .product-infor .product-infor__italic {
          color: #a32732;
          margin-top: 12px;
          font-family: SEGOEUII; }
        .details-recommend-video__wrapper .details-recommend-video .left-recommend-video .product-infor .product-infor__more {
          padding-top: 0;
          border-top: 0; }
  .details-recommend-video__wrapper .right-recommend-video {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 50%;
    height: calc(100% + 64px);
    width: 50%; }
    .details-recommend-video__wrapper .right-recommend-video .video-section {
      height: 100%; }
      .details-recommend-video__wrapper .right-recommend-video .video-section__img,
      .details-recommend-video__wrapper .right-recommend-video .video-section img,
      .details-recommend-video__wrapper .right-recommend-video .video-section iframe,
      .details-recommend-video__wrapper .right-recommend-video .video-section video {
        width: 100%;
        height: 100%;
        object-position: center;
        object-fit: cover; }

@media (max-width: 1280px) {
  .details-recommend-video__wrapper {
    margin: calc(64px + 24px) 0; }
    .details-recommend-video__wrapper .right-recommend-video {
      height: calc(100% + 48px); } }

@media (max-width: 768px) {
  .details-recommend-video__wrapper {
    margin: 48px 0; }
    .details-recommend-video__wrapper .right-recommend-video {
      height: 100%; } }

@media (max-width: 768px) {
  .details-recommend-video__wrapper {
    flex-direction: column;
    height: auto; }
  .details-recommend-video__wrapper .details-recommend-video .left-recommend-video {
    width: 100%; }
  .details-recommend-video__wrapper .details-recommend-video .left-recommend-video .product-infor {
    padding: 0; }
  .details-recommend-video__wrapper .right-recommend-video {
    position: static;
    top: auto;
    right: auto;
    width: 100%;
    height: 420px;
    transform: none; }
    .details-recommend-video__wrapper .right-recommend-video .video-section {
      width: 100%;
      height: 100%; }
      .details-recommend-video__wrapper .right-recommend-video .video-section img {
        max-height: 420px;
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center; }
  .details-recommend-video__wrapper .right-recommend-video {
    margin-top: 24px; } }

.details-function-benefit__wrapper {
  margin-bottom: 80px; }
  @media screen and (min-width: 640px) {
    .details-function-benefit__wrapper {
      margin-bottom: 100px; } }
  @media screen and (min-width: 1024px) {
    .details-function-benefit__wrapper {
      margin-bottom: 128px; } }
  @media screen and (min-width: 1280px) {
    .details-function-benefit__wrapper {
      margin-bottom: 128px; } }
  .details-function-benefit__wrapper .details-nav {
    margin-bottom: 32px; }
    @media screen and (min-width: 640px) {
      .details-function-benefit__wrapper .details-nav {
        margin-bottom: 40px; } }
    @media screen and (min-width: 1024px) {
      .details-function-benefit__wrapper .details-nav {
        margin-bottom: 64px; } }
    @media screen and (min-width: 1280px) {
      .details-function-benefit__wrapper .details-nav {
        margin-bottom: 64px; } }
    .details-function-benefit__wrapper .details-nav .nav-function {
      display: flex;
      align-items: center;
      color: #a32732;
      font-family: SEGOEUI;
      text-transform: uppercase;
      position: relative; }
      .details-function-benefit__wrapper .details-nav .nav-function * {
        cursor: pointer;
        margin-right: 20px; }
      .details-function-benefit__wrapper .details-nav .nav-function::after {
        position: absolute;
        content: "";
        bottom: -4px;
        right: 0;
        width: 98%;
        height: 1px;
        background: #a32732;
        opacity: 0.4; }
      .details-function-benefit__wrapper .details-nav .nav-function .active {
        position: relative; }
        .details-function-benefit__wrapper .details-nav .nav-function .active::after {
          position: absolute;
          content: "";
          bottom: -5px;
          left: 0;
          width: 70%;
          border: solid 2px #bf0d3e;
          background: #bf0d3e;
          border-radius: 3px;
          z-index: 1; }
  .details-function-benefit__wrapper .details-function-benefit .content--value {
    display: none;
    flex-wrap: wrap; }
    .details-function-benefit__wrapper .details-function-benefit .content--value.active {
      display: flex; }
  .details-function-benefit__wrapper .details-function-benefit .details-function {
    width: 50%;
    padding-right: 32px; }
    @media screen and (min-width: 640px) {
      .details-function-benefit__wrapper .details-function-benefit .details-function {
        padding-right: 40px; } }
    @media screen and (min-width: 1024px) {
      .details-function-benefit__wrapper .details-function-benefit .details-function {
        padding-right: 64px; } }
    @media screen and (min-width: 1280px) {
      .details-function-benefit__wrapper .details-function-benefit .details-function {
        padding-right: 64px; } }
    @media (max-width: 768px) {
      .details-function-benefit__wrapper .details-function-benefit .details-function {
        padding-right: 0; } }
    .details-function-benefit__wrapper .details-function-benefit .details-function .content-function .product-infor .product-infor__title {
      margin-bottom: 40px; }
      @media (max-width: 1280px) {
        .details-function-benefit__wrapper .details-function-benefit .details-function .content-function .product-infor .product-infor__title {
          margin-bottom: 32px; } }
      @media (max-width: 640px) {
        .details-function-benefit__wrapper .details-function-benefit .details-function .content-function .product-infor .product-infor__title {
          margin-bottom: 12px; } }
    .details-function-benefit__wrapper .details-function-benefit .details-function .content-function .product-infor .product-infor__more {
      margin-bottom: 12px;
      padding-top: 0;
      border-top: 0; }
    .details-function-benefit__wrapper .details-function-benefit .details-function .content-function .product-infor .product-infor__list-details ul {
      margin-left: 18px; }
      .details-function-benefit__wrapper .details-function-benefit .details-function .content-function .product-infor .product-infor__list-details ul li {
        list-style-type: initial;
        margin-bottom: 10px; }
        .details-function-benefit__wrapper .details-function-benefit .details-function .content-function .product-infor .product-infor__list-details ul li p {
          color: #707070; }
    .details-function-benefit__wrapper .details-function-benefit .details-function .content-function .product-infor .product-infor__content {
      -webkit-line-clamp: initial;
      -webkit-box-orient: initial;
      display: block;
      max-height: 220px;
      overflow-y: scroll;
      overflow-x: hidden;
      margin-bottom: 0; }
      @media (max-width: 768px) {
        .details-function-benefit__wrapper .details-function-benefit .details-function .content-function .product-infor .product-infor__content {
          margin-bottom: 40px; } }
  @media (max-width: 768px) and (max-width: 1280px) {
    .details-function-benefit__wrapper .details-function-benefit .details-function .content-function .product-infor .product-infor__content {
      margin-bottom: 32px; } }
  @media (max-width: 768px) and (max-width: 640px) {
    .details-function-benefit__wrapper .details-function-benefit .details-function .content-function .product-infor .product-infor__content {
      margin-bottom: 12px; } }
  .details-function-benefit__wrapper .details-function-benefit .details-benefit {
    width: 50%;
    height: 620px;
    display: flex;
    align-items: center;
    justify-content: center; }
    .details-function-benefit__wrapper .details-function-benefit .details-benefit img,
    .details-function-benefit__wrapper .details-function-benefit .details-benefit video,
    .details-function-benefit__wrapper .details-function-benefit .details-benefit iframe {
      width: 100%;
      height: 100%;
      object-fit: cover; }

@media (max-width: 768px) {
  .details-function-benefit__wrapper .details-nav {
    margin-bottom: 36px; }
    .details-function-benefit__wrapper .details-nav .nav-function {
      width: 100%;
      flex: 0 0 100%;
      max-width: 100%;
      overflow-x: scroll;
      overflow-y: hidden;
      padding: 12px; }
  .details-function-benefit__wrapper .details-function-benefit .content--value {
    flex-direction: column; }
  .details-function-benefit__wrapper .details-function-benefit .details-function {
    width: 100%; }
  .details-function-benefit__wrapper .details-function-benefit .details-benefit {
    width: 100%; }
  .details-function-benefit__wrapper .details-function-benefit .details-benefit img {
    width: 100%; } }

.video-plush {
  margin-top: 80px;
  position: relative; }
  @media (max-width: 768px) {
    .video-plush {
      margin-top: 64px; } }
  .video-plush-ytb {
    width: 100%; }
    .video-plush-ytb iframe, .video-plush-ytb img, .video-plush-ytb video {
      width: 100%;
      height: 916px; }
      @media (max-width: 425px) {
        .video-plush-ytb iframe, .video-plush-ytb img, .video-plush-ytb video {
          height: 100vh; } }
  .video-plush-content {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    text-align: center; }
    .video-plush-content__title > * {
      font-family: "SVN-AvenirNext", sans-serif;
      color: #000;
      font-size: 50px;
      font-weight: 500;
      text-transform: uppercase;
      font-weight: 700; }
      @media (max-width: 1024px) {
        .video-plush-content__title > * {
          font-size: 40px; } }
      @media (max-width: 640px) {
        .video-plush-content__title > * {
          font-size: 32px; } }
    .video-plush-content__desc {
      margin-top: 32px; }
      .video-plush-content__desc > * {
        font-family: "SVN-AvenirNext", sans-serif;
        color: #000;
        font-size: 32px;
        font-weight: 500;
        font-weight: 400;
        font-family: "Segoe UI", sans-serif; }
        @media (max-width: 1024px) {
          .video-plush-content__desc > * {
            font-size: 26px; } }
        @media (max-width: 640px) {
          .video-plush-content__desc > * {
            font-size: 22px; } }

.details-review__wrapper {
  background: #a32732;
  padding: 32px 0; }
  @media screen and (min-width: 640px) {
    .details-review__wrapper {
      padding: 40px 0; } }
  @media screen and (min-width: 1024px) {
    .details-review__wrapper {
      padding: 64px 0; } }
  @media screen and (min-width: 1280px) {
    .details-review__wrapper {
      padding: 64px 0; } }
  .details-review__wrapper .details-review {
    margin-bottom: 40px; }
    @media (max-width: 1280px) {
      .details-review__wrapper .details-review {
        margin-bottom: 32px; } }
    @media (max-width: 640px) {
      .details-review__wrapper .details-review {
        margin-bottom: 12px; } }
    .details-review__wrapper .details-review .details-review__title .product-infor p {
      color: #c0c0c0; }
    .details-review__wrapper .details-review .details-review__form .form-left {
      margin-top: 32px;
      display: flex;
      flex-direction: column; }
      @media (max-width: 1280px) {
        .details-review__wrapper .details-review .details-review__form .form-left {
          margin-top: 24px; } }
      @media (max-width: 640px) {
        .details-review__wrapper .details-review .details-review__form .form-left {
          margin-top: 16px; } }
      .details-review__wrapper .details-review .details-review__form .form-left .rate-comment {
        flex: 1; }
        .details-review__wrapper .details-review .details-review__form .form-left .rate-comment textarea {
          border-radius: 10px;
          width: 100%;
          height: 100%;
          border: none;
          background: #ffffff;
          padding-left: 20px;
          resize: none;
          padding-top: 12px; }
      .details-review__wrapper .details-review .details-review__form .form-left .rate-section {
        align-items: center;
        margin-bottom: 24px; }
        .details-review__wrapper .details-review .details-review__form .form-left .rate-section .rate-title__items {
          color: #c0c0c0; }
        .details-review__wrapper .details-review .details-review__form .form-left .rate-section .rate-star__items .items-star {
          display: inline; }
          .details-review__wrapper .details-review .details-review__form .form-left .rate-section .rate-star__items .items-star * {
            margin-right: 5px;
            position: relative;
            width: 2rem;
            height: 2rem;
            text-indent: -9999rem;
            display: inline-block; }
            .details-review__wrapper .details-review .details-review__form .form-left .rate-section .rate-star__items .items-star *::after {
              position: absolute;
              top: 0;
              left: 0;
              content: "";
              width: 2rem;
              height: 2rem;
              background-image: url("../assets/icons/white-star-whiteborder.png");
              background-repeat: no-repeat;
              background-position: center;
              text-indent: 0;
              display: inline; }
          .details-review__wrapper .details-review .details-review__form .form-left .rate-section .rate-star__items .items-star *:hover::after {
            background-image: url("../assets/icons/white-star.png"); }
          .details-review__wrapper .details-review .details-review__form .form-left .rate-section .rate-star__items .items-star *.active::after {
            background-image: url("../assets/icons/white-star.png"); }
    .details-review__wrapper .details-review .details-review__form .form-right {
      margin-top: 32px; }
      @media (max-width: 1280px) {
        .details-review__wrapper .details-review .details-review__form .form-right {
          margin-top: 24px; } }
      @media (max-width: 640px) {
        .details-review__wrapper .details-review .details-review__form .form-right {
          margin-top: 16px; } }
      .details-review__wrapper .details-review .details-review__form .form-right .row-input {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px; }
        .details-review__wrapper .details-review .details-review__form .form-right .row-input input {
          width: 49%; }
      .details-review__wrapper .details-review .details-review__form .form-right .collumn-input {
        height: 140px;
        flex-direction: column;
        display: flex;
        justify-content: space-between; }
        .details-review__wrapper .details-review .details-review__form .form-right .collumn-input button {
          border-radius: 10px;
          width: 100%;
          height: 61px;
          border: solid 1px #ffffff;
          background: #a32732;
          color: #c0c0c0;
          text-align: center;
          transition: .3s all linear; }
          .details-review__wrapper .details-review .details-review__form .form-right .collumn-input button:hover {
            border: solid 1px #a32732;
            color: #a32732;
            background: #c0c0c0; }
      .details-review__wrapper .details-review .details-review__form .form-right input {
        border-radius: 10px;
        width: 100%;
        height: 61px;
        border: none;
        background: #ffffff;
        padding-left: 20px; }
        .details-review__wrapper .details-review .details-review__form .form-right input ::placeholder {
          color: #707070; }
  .details-review__wrapper .details-user__comment {
    margin-top: 24px; }
    .details-review__wrapper .details-user__comment .comment__more {
      margin-top: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      display: none; }
      .details-review__wrapper .details-user__comment .comment__more.active {
        display: flex; }
      .details-review__wrapper .details-user__comment .comment__more--btn {
        position: relative;
        width: 36px;
        height: 36px;
        color: #ececec;
        border-radius: 2px; }
        .details-review__wrapper .details-user__comment .comment__more--btn::after {
          position: absolute;
          content: "\279C";
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(90deg);
          width: 100%;
          height: 100%;
          border-radius: 2px;
          background-color: #707070;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer; }
        .details-review__wrapper .details-user__comment .comment__more--btn:hover::after {
          box-shadow: 0 0 3px rgba(112, 112, 112, 0.3);
          background-color: rgba(112, 112, 112, 0.3); }
    .details-review__wrapper .details-user__comment .comment__reviews .amount-comment {
      color: #c0c0c0;
      align-items: center;
      padding-bottom: 12px; }
    .details-review__wrapper .details-user__comment .comment__section {
      display: flex;
      flex-direction: column;
      color: #c0c0c0; }
      .details-review__wrapper .details-user__comment .comment__section .comment-items {
        border-top: solid 1px #ffffff; }
        .details-review__wrapper .details-user__comment .comment__section .comment-items:last-child {
          border-bottom: none; }
        .details-review__wrapper .details-user__comment .comment__section .comment-items .user-comment-section .active {
          height: 100%; }
        .details-review__wrapper .details-user__comment .comment__section .comment-items .user-comment-section .rate-section {
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 12px 8px 12px 8px;
          height: 100%;
          margin-left: 0; }
          .details-review__wrapper .details-user__comment .comment__section .comment-items .user-comment-section .rate-section--left {
            flex: 1;
            display: flex;
            justify-content: flex-start;
            align-items: center; }
            .details-review__wrapper .details-user__comment .comment__section .comment-items .user-comment-section .rate-section--left > *:not(:first-child) {
              margin-left: 20px; }
          .details-review__wrapper .details-user__comment .comment__section .comment-items .user-comment-section .rate-section--right {
            display: flex;
            justify-content: space-between;
            align-items: center; }
            .details-review__wrapper .details-user__comment .comment__section .comment-items .user-comment-section .rate-section--right .comment__date {
              font-size: 18px;
              font-style: italic;
              color: rgba(192, 192, 192, 0.7);
              font-weight: bold; }
            .details-review__wrapper .details-user__comment .comment__section .comment-items .user-comment-section .rate-section--right .comment__del {
              margin-left: 18px;
              display: flex;
              justify-content: center;
              align-items: center; }
          .details-review__wrapper .details-user__comment .comment__section .comment-items .user-comment-section .rate-section:hover {
            background: rgba(112, 112, 112, 0.5); }
          .details-review__wrapper .details-user__comment .comment__section .comment-items .user-comment-section .rate-section .user-avatar {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 36px; }
            .details-review__wrapper .details-user__comment .comment__section .comment-items .user-comment-section .rate-section .user-avatar .user-name {
              border-radius: 50%;
              width: 35px;
              height: 35px; }
          .details-review__wrapper .details-user__comment .comment__section .comment-items .user-comment-section .rate-section .rate-title__items {
            color: #c0c0c0;
            width: 320px; }
          .details-review__wrapper .details-user__comment .comment__section .comment-items .user-comment-section .rate-section .rate-star__items .items-star {
            display: inline; }
            .details-review__wrapper .details-user__comment .comment__section .comment-items .user-comment-section .rate-section .rate-star__items .items-star * {
              margin-right: 5px;
              position: relative;
              width: 2rem;
              height: 2rem;
              text-indent: -9999rem;
              display: inline-block; }
              .details-review__wrapper .details-user__comment .comment__section .comment-items .user-comment-section .rate-section .rate-star__items .items-star *::after {
                position: absolute;
                top: 0;
                left: 0;
                content: "";
                width: 2rem;
                height: 2rem;
                background-image: url("../assets/icons/white-star-whiteborder.png");
                background-repeat: no-repeat;
                background-position: center;
                text-indent: 0;
                display: inline; }
              .details-review__wrapper .details-user__comment .comment__section .comment-items .user-comment-section .rate-section .rate-star__items .items-star *.active::after {
                background-image: url("../assets/icons/white-star.png"); }
        .details-review__wrapper .details-user__comment .comment__section .comment-items .user-comment {
          height: 0;
          overflow: hidden; }
          .details-review__wrapper .details-user__comment .comment__section .comment-items .user-comment .comment {
            padding: 12px 8px 12px 8px;
            background-color: rgba(112, 112, 112, 0.1); }

@media (max-width: 768px) {
  .details-review__wrapper .details-review .details-review__form .form-left {
    flex: 0 0 100%;
    max-width: 100%; }
  .details-review__wrapper .details-review .details-review__form .form-right {
    flex: 0 0 100%;
    max-width: 100%; }
  .details-review__wrapper .details-review .details-review__form .form-right .row-input {
    flex-direction: column; }
    .details-review__wrapper .details-review .details-review__form .form-right .row-input input {
      width: 100%; }
      .details-review__wrapper .details-review .details-review__form .form-right .row-input input:not(:first-child) {
        margin-top: 20px; }
  .details-review__wrapper .details-user__comment .comment__section .comment-items .user-comment-section .rate-section .rate-title__items {
    width: 200px; }
  .details-review__wrapper .details-review .details-review__form .form-left .rate-section .rate-title__items {
    flex: 0 0 40%;
    max-width: 40%; }
  .details-review__wrapper .details-review .details-review__form .form-left .rate-section .rate-star__items {
    flex: 0 0 60%;
    max-width: 60%; }
  .details-review__wrapper .details-review .details-review__form .form-left .rate-comment textarea {
    height: 120px; }
  .details-review__wrapper .details-review .details-review__form .form-right input, .details-review__wrapper .details-review .details-review__form .form-right button {
    height: 56px; }
  .details-review__wrapper .details-user__comment .comment__section .comment-items .user-comment-section .rate-section--right {
    flex: 0 0 25%;
    width: 25%;
    text-align: right; }
  .details-review__wrapper .details-user__comment .comment__section .comment-items .user-comment-section .rate-section--left {
    flex-direction: column;
    text-align: center; }
    .details-review__wrapper .details-user__comment .comment__section .comment-items .user-comment-section .rate-section--left > *:not(:first-child) {
      margin-top: 4px; }
  .details-review__wrapper .details-user__comment .comment__section .comment-items .user-comment-section .rate-section--left > :not(:first-child) {
    margin-left: 0; } }

.details-comprarison__wrapper {
  margin-top: 32px;
  margin-bottom: 80px; }
  @media screen and (min-width: 640px) {
    .details-comprarison__wrapper {
      margin-top: 40px; } }
  @media screen and (min-width: 1024px) {
    .details-comprarison__wrapper {
      margin-top: 64px; } }
  @media screen and (min-width: 1280px) {
    .details-comprarison__wrapper {
      margin-top: 64px; } }
  @media screen and (min-width: 640px) {
    .details-comprarison__wrapper {
      margin-bottom: 100px; } }
  @media screen and (min-width: 1024px) {
    .details-comprarison__wrapper {
      margin-bottom: 128px; } }
  @media screen and (min-width: 1280px) {
    .details-comprarison__wrapper {
      margin-bottom: 128px; } }
  .details-comprarison__wrapper .details-comprarison .comparision-title {
    margin-bottom: 32px;
    text-align: center;
    font-family: "SVN-AvenirNext", sans-serif;
    color: #000;
    font-size: 50px;
    font-weight: 500; }
    @media screen and (min-width: 640px) {
      .details-comprarison__wrapper .details-comprarison .comparision-title {
        margin-bottom: 40px; } }
    @media screen and (min-width: 1024px) {
      .details-comprarison__wrapper .details-comprarison .comparision-title {
        margin-bottom: 64px; } }
    @media screen and (min-width: 1280px) {
      .details-comprarison__wrapper .details-comprarison .comparision-title {
        margin-bottom: 64px; } }
    @media (max-width: 1024px) {
      .details-comprarison__wrapper .details-comprarison .comparision-title {
        font-size: 40px; } }
    @media (max-width: 640px) {
      .details-comprarison__wrapper .details-comprarison .comparision-title {
        font-size: 32px; } }
  .details-comprarison__wrapper .details-comprarison .comparision-section {
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    flex-wrap: nowrap; }
    .details-comprarison__wrapper .details-comprarison .comparision-section .col-infor {
      min-width: 220px;
      width: 220px;
      flex: auto; }
    .details-comprarison__wrapper .details-comprarison .comparision-section .product-name {
      text-align: center;
      padding: 70px 0; }
    .details-comprarison__wrapper .details-comprarison .comparision-section .infor-name {
      padding: 30px 0; }
    .details-comprarison__wrapper .details-comprarison .comparision-section .col-product {
      min-width: 320px;
      width: 320px;
      flex: auto; }
      .details-comprarison__wrapper .details-comprarison .comparision-section .col-product .product-name {
        color: #ececec;
        text-transform: uppercase;
        font-size: 18px;
        border-radius: 15px 15px 0 0;
        background: #a32732;
        border-bottom: solid 2px #c0c0c0; }
        @media screen and (min-width: 1024px) {
          .details-comprarison__wrapper .details-comprarison .comparision-section .col-product .product-name {
            font-size: 20px; } }
      .details-comprarison__wrapper .details-comprarison .comparision-section .col-product .active-sale {
        position: relative;
        text-decoration: line-through; }
        .details-comprarison__wrapper .details-comprarison .comparision-section .col-product .active-sale .sale {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 40px; }
        .details-comprarison__wrapper .details-comprarison .comparision-section .col-product .active-sale .discount {
          font-weight: 600;
          text-align: center;
          position: absolute;
          bottom: 0;
          right: 0;
          padding: 7px 20px;
          background: #c0c0c0;
          color: #a32732;
          border-radius: 20px 0 0 0; }
          .details-comprarison__wrapper .details-comprarison .comparision-section .col-product .active-sale .discount .cheap {
            font-size: 15px;
            font-weight: 400; }
        .details-comprarison__wrapper .details-comprarison .comparision-section .col-product .active-sale .trial {
          position: absolute;
          bottom: 15%;
          left: 50%;
          transform: translateX(-50%);
          font-size: 15px; }
      .details-comprarison__wrapper .details-comprarison .comparision-section .col-product .infor-name {
        text-align: center; }
        .details-comprarison__wrapper .details-comprarison .comparision-section .col-product .infor-name:last-child {
          border-radius: 0 0 15px 15px; }
        .details-comprarison__wrapper .details-comprarison .comparision-section .col-product .infor-name:nth-child(odd) {
          background: #ececec;
          color: #a32732; }
        .details-comprarison__wrapper .details-comprarison .comparision-section .col-product .infor-name:nth-child(even) {
          background: #a32732;
          color: #ececec; }

@media (max-width: 1024px) {
  .details-comprarison__wrapper .details-comprarison .comparision-section .col-infor {
    text-align: right; } }

.details-collections__wrapper {
  margin: 32px 0; }
  @media screen and (min-width: 640px) {
    .details-collections__wrapper {
      margin: 40px 0; } }
  @media screen and (min-width: 1024px) {
    .details-collections__wrapper {
      margin: 64px 0; } }
  @media screen and (min-width: 1280px) {
    .details-collections__wrapper {
      margin: 64px 0; } }
  .details-collections__wrapper .details-collections .details-collections__title {
    margin-bottom: 40px;
    text-align: center;
    display: block; }
    @media (max-width: 1280px) {
      .details-collections__wrapper .details-collections .details-collections__title {
        margin-bottom: 32px; } }
    @media (max-width: 640px) {
      .details-collections__wrapper .details-collections .details-collections__title {
        margin-bottom: 12px; } }
  .details-collections__wrapper .details-collections__section .collections__section {
    margin-top: -48px; }
    .details-collections__wrapper .details-collections__section .collections__section .collection__items {
      margin-top: 48px;
      flex: 0 0 33.3333%;
      max-width: 33.3333%; }
      @media (max-width: 1280px) {
        .details-collections__wrapper .details-collections__section .collections__section .collection__items {
          flex: 0 0 50%;
          max-width: 50%; } }
      @media (max-width: 768px) {
        .details-collections__wrapper .details-collections__section .collections__section .collection__items {
          flex: 0 0 100%;
          max-width: 100%; } }
      .details-collections__wrapper .details-collections__section .collections__section .collection__items .items .items__img img {
        width: 100%;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); }
      .details-collections__wrapper .details-collections__section .collections__section .collection__items .items .items__title {
        margin-top: 40px;
        padding: 13px 0; }
        @media (max-width: 768px) {
          .details-collections__wrapper .details-collections__section .collections__section .collection__items .items .items__title {
            margin-top: 24px;
            padding: 0; } }
        .details-collections__wrapper .details-collections__section .collections__section .collection__items .items .items__title > * {
          color: #a32732;
          height: 72px;
          text-transform: uppercase;
          display: block;
          font-weight: 700; }
          .details-collections__wrapper .details-collections__section .collections__section .collection__items .items .items__title > *:hover {
            opacity: .7; }
      .details-collections__wrapper .details-collections__section .collections__section .collection__items .items .items__content,
      .details-collections__wrapper .details-collections__section .collections__section .collection__items .items .items__price {
        margin-top: 28px; }
        @media (max-width: 768px) {
          .details-collections__wrapper .details-collections__section .collections__section .collection__items .items .items__content,
          .details-collections__wrapper .details-collections__section .collections__section .collection__items .items .items__price {
            margin-top: 16px; } }
      .details-collections__wrapper .details-collections__section .collections__section .collection__items .items .items__description {
        position: relative;
        margin-top: 20px;
        border-top: solid 1px #707070;
        border-bottom: solid 1px #707070;
        height: 36px; }
        .details-collections__wrapper .details-collections__section .collections__section .collection__items .items .items__description p {
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          line-height: 36px; }

@media (max-width: 1280px) {
  .details-collections__wrapper .collections__section {
    flex-wrap: wrap; }
    .details-collections__wrapper .collections__section .collection__items {
      flex: 0 0 33.3333%;
      max-width: 33.3333%; } }

@media (max-width: 900px) {
  .details-collections__wrapper .collections__section .collection__items {
    flex: 0 0 50%;
    max-width: 50%; } }

@media (max-width: 640px) {
  .details-collections__wrapper .collections__section .collection__items {
    flex: 0 0 100%;
    max-width: 100%; } }

.blog-detail__wrapper .blog-detail {
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  justify-content: space-between; }
  @media screen and (min-width: 640px) {
    .blog-detail__wrapper .blog-detail {
      margin-top: 40px; } }
  @media screen and (min-width: 1024px) {
    .blog-detail__wrapper .blog-detail {
      margin-top: 64px; } }
  @media screen and (min-width: 1280px) {
    .blog-detail__wrapper .blog-detail {
      margin-top: 64px; } }
  .blog-detail__wrapper .blog-detail--left {
    flex: 0 0 70%;
    width: 70%; }
    .blog-detail__wrapper .blog-detail--left > *:not(:first-child) {
      margin-top: 24px; }
      @media (max-width: 1280px) {
        .blog-detail__wrapper .blog-detail--left > *:not(:first-child) {
          margin-top: 16px; } }
      @media (max-width: 640px) {
        .blog-detail__wrapper .blog-detail--left > *:not(:first-child) {
          margin-top: 12px; } }
    .blog-detail__wrapper .blog-detail--left__title {
      margin-bottom: 40px; }
      @media (max-width: 1280px) {
        .blog-detail__wrapper .blog-detail--left__title {
          margin-bottom: 32px; } }
      @media (max-width: 640px) {
        .blog-detail__wrapper .blog-detail--left__title {
          margin-bottom: 12px; } }
    .blog-detail__wrapper .blog-detail--left__img {
      width: 100%;
      height: 629px;
      object-fit: cover;
      object-position: center; }
    .blog-detail__wrapper .blog-detail--left img {
      width: 100%;
      object-fit: cover;
      object-position: center; }
    .blog-detail__wrapper .blog-detail--left ul {
      list-style-type: disc;
      padding-left: 24px; }
    .blog-detail__wrapper .blog-detail--left p {
      white-space: pre-line;
      line-height: 1.2;
      word-spacing: 1px; }
    .blog-detail__wrapper .blog-detail--left__desc {
      white-space: pre-line;
      line-height: 1.2;
      word-spacing: 1px; }
  .blog-detail__wrapper .blog-detail--right {
    flex: 0 0 25%;
    width: 25%; }
    .blog-detail__wrapper .blog-detail--right .blog-detail__title--right {
      font-weight: 700;
      margin-bottom: 40px; }
      @media (max-width: 1280px) {
        .blog-detail__wrapper .blog-detail--right .blog-detail__title--right {
          margin-bottom: 32px; } }
      @media (max-width: 640px) {
        .blog-detail__wrapper .blog-detail--right .blog-detail__title--right {
          margin-bottom: 12px; } }
    .blog-detail__wrapper .blog-detail--right .blog {
      display: flex;
      flex-direction: column; }
      .blog-detail__wrapper .blog-detail--right .blog-item {
        padding-top: 8px;
        border-bottom: 1px solid rgba(163, 39, 50, 0.25);
        transition: 0.3s all linear; }
        .blog-detail__wrapper .blog-detail--right .blog-item__img {
          height: 238px;
          width: 100%;
          object-position: center;
          object-fit: cover; }
        .blog-detail__wrapper .blog-detail--right .blog-item__content-title {
          margin: 8px 0;
          font-weight: 700; }
        .blog-detail__wrapper .blog-detail--right .blog-item:hover {
          opacity: 0.7;
          background-color: rgba(192, 192, 192, 0.7); }
    .blog-detail__wrapper .blog-detail--right .share-detail {
      margin-bottom: 32px; }
      @media (max-width: 1280px) {
        .blog-detail__wrapper .blog-detail--right .share-detail {
          margin-bottom: 24px; } }
      @media (max-width: 640px) {
        .blog-detail__wrapper .blog-detail--right .share-detail {
          margin-bottom: 16px; } }
      .blog-detail__wrapper .blog-detail--right .share-detail__title {
        padding-bottom: 5px;
        border-bottom: 1px solid #a32732; }
      .blog-detail__wrapper .blog-detail--right .share-detail-social {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: 32px; }
        .blog-detail__wrapper .blog-detail--right .share-detail-social li + li {
          margin-left: 12px; }
        .blog-detail__wrapper .blog-detail--right .share-detail-social__item {
          margin-top: 32px; }
      .blog-detail__wrapper .blog-detail--right .share-detail-form {
        display: flex;
        flex-direction: column; }
        .blog-detail__wrapper .blog-detail--right .share-detail-form--info {
          padding: 8px 12px;
          font-size: 14px; }
          .blog-detail__wrapper .blog-detail--right .share-detail-form--info.error {
            color: rgba(255, 0, 0, 0.658); }
          .blog-detail__wrapper .blog-detail--right .share-detail-form--info.success {
            color: #4bb543; }
        .blog-detail__wrapper .blog-detail--right .share-detail-form .group-input {
          display: flex;
          flex-direction: row; }
        .blog-detail__wrapper .blog-detail--right .share-detail-form input {
          height: 60px;
          outline: none;
          border: none; }
          .blog-detail__wrapper .blog-detail--right .share-detail-form input:hover, .blog-detail__wrapper .blog-detail--right .share-detail-form input:focus {
            outline: none;
            border: none; }
        .blog-detail__wrapper .blog-detail--right .share-detail-form input[type="email"] {
          width: 70%;
          padding-left: 16px;
          background-color: #ececec;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px; }
        .blog-detail__wrapper .blog-detail--right .share-detail-form input[type="submit"] {
          font-weight: bold;
          text-transform: uppercase;
          font-size: 16px;
          padding: 0 8px;
          color: white;
          background-color: #a32732;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          transition: 0.3s all linear; }
          .blog-detail__wrapper .blog-detail--right .share-detail-form input[type="submit"]:hover, .blog-detail__wrapper .blog-detail--right .share-detail-form input[type="submit"]:active {
            opacity: 0.9; }

@media (max-width: 1280px) {
  .blog-detail__wrapper .blog-detail--right .share-detail-form input[type="email"] {
    width: 100%;
    border-radius: 4px; }
  .blog-detail__wrapper .blog-detail--right .share-detail-form input[type="submit"] {
    width: 100%;
    border-radius: 4px; }
  .blog-detail__wrapper .blog-detail--right .share-detail-form .group-input {
    flex-direction: column; }
    .blog-detail__wrapper .blog-detail--right .share-detail-form .group-input > *:not(:first-child) {
      margin-top: 12px; } }

@media (max-width: 1024px) {
  .blog-detail__wrapper .blog-detail {
    flex-direction: column; }
  .blog-detail__wrapper .blog-detail--right {
    margin-top: 32px; } }
  @media screen and (max-width: 1024px) and (min-width: 640px) {
    .blog-detail__wrapper .blog-detail--right {
      margin-top: 40px; } }
  @media screen and (max-width: 1024px) and (min-width: 1024px) {
    .blog-detail__wrapper .blog-detail--right {
      margin-top: 64px; } }
  @media screen and (max-width: 1024px) and (min-width: 1280px) {
    .blog-detail__wrapper .blog-detail--right {
      margin-top: 64px; } }

@media (max-width: 1024px) {
  .blog-detail__wrapper .blog-detail--left,
  .blog-detail__wrapper .blog-detail--right {
    flex: 0 0 100%;
    width: 100%; }
  .blog-detail__wrapper .blog-detail--right .share-detail-form .group-input {
    width: 60%; }
    .blog-detail__wrapper .blog-detail--right .share-detail-form .group-input input {
      height: 46px; }
      .blog-detail__wrapper .blog-detail--right .share-detail-form .group-input input:focus {
        border: 1px solid #a32732; }
  .blog-detail__wrapper .blog-detail--right .blog-detail__title--right {
    margin-left: 1%; }
  .blog-detail__wrapper .blog-detail--right .blog {
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0 1%; }
    .blog-detail__wrapper .blog-detail--right .blog-item {
      width: 50%;
      padding-right: 2%;
      padding-top: 12px;
      border-bottom: 0; } }

@media (max-width: 640px) {
  .blog-detail__wrapper .blog-detail--right .share-detail-form .group-input {
    width: 100%; } }

@media (max-width: 425px) {
  .blog-detail__wrapper .blog-detail--right .blog-item {
    width: 100%; } }

.dealer-product__wrapper .dealer-product {
  margin: 0 -3%; }
  .dealer-product__wrapper .dealer-product__title {
    text-align: center;
    margin: 32px 0; }
    @media screen and (min-width: 640px) {
      .dealer-product__wrapper .dealer-product__title {
        margin: 40px 0; } }
    @media screen and (min-width: 1024px) {
      .dealer-product__wrapper .dealer-product__title {
        margin: 64px 0; } }
    @media screen and (min-width: 1280px) {
      .dealer-product__wrapper .dealer-product__title {
        margin: 64px 0; } }
  .dealer-product__wrapper .dealer-product-list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: -1%;
    margin-right: -1%; }
  .dealer-product__wrapper .dealer-product-item {
    flex: 0 0 33.3333%;
    padding: 0 1% 40px; }
    .dealer-product__wrapper .dealer-product-item__link {
      display: flex;
      flex-direction: column;
      transition: 0.3s;
      width: 100%;
      height: 100%; }
      .dealer-product__wrapper .dealer-product-item__link:hover {
        background-color: #ececec; }
    .dealer-product__wrapper .dealer-product-item__img {
      width: 100%;
      height: 336px;
      object-fit: cover;
      object-position: center; }
    .dealer-product__wrapper .dealer-product-item__content {
      padding: 5px 0;
      flex: 1;
      display: flex;
      flex-direction: column; }
    .dealer-product__wrapper .dealer-product-item__title {
      letter-spacing: 2px;
      font-weight: 700;
      margin: 12px 0;
      text-transform: uppercase;
      flex: 1;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden; }
    .dealer-product__wrapper .dealer-product-item__desc {
      padding: 12px 0;
      border-bottom: 1px solid #707070;
      color: #707070; }
  .dealer-product__wrapper .dealer-product .releases-pagination {
    width: 315px; }

@media (max-width: 1280px) {
  .dealer-product__wrapper .dealer-product-item {
    flex: 0 0 50%; } }

@media (max-width: 1024px) {
  .dealer-product__wrapper .dealer-product {
    margin: 0; }
  .dealer-product__wrapper .dealer-product-item__link {
    width: 100%; }
  .dealer-product__wrapper .dealer-product-item__img {
    width: 100%;
    object-fit: cover;
    object-position: center; } }

@media (max-width: 768px) {
  .dealer-product__wrapper .dealer-product-item {
    flex: 0 0 100%; }
  .dealer-product__wrapper .dealer-product-item__img {
    height: 434px; } }

@media (max-width: 640px) {
  .dealer-product__wrapper .dealer-product-item {
    flex: 0 0 100%; }
  .dealer-product__wrapper .dealer-product-item__content {
    text-align: center; }
  .dealer-product__wrapper .dealer-product-item__img {
    height: 360px; } }

.dealer-help__wrapper {
  background: #ececec;
  padding-bottom: 32px; }
  @media screen and (min-width: 640px) {
    .dealer-help__wrapper {
      padding-bottom: 40px; } }
  @media screen and (min-width: 1024px) {
    .dealer-help__wrapper {
      padding-bottom: 64px; } }
  @media screen and (min-width: 1280px) {
    .dealer-help__wrapper {
      padding-bottom: 64px; } }
  .dealer-help__wrapper .container, .dealer-help__wrapper .wholesale-factory__content2 .right .content, .wholesale-factory__content2 .right .dealer-help__wrapper .content {
    padding-left: 0;
    padding-right: 0; }
    .dealer-help__wrapper .container .dealer-help .help-title, .dealer-help__wrapper .wholesale-factory__content2 .right .content .dealer-help .help-title, .wholesale-factory__content2 .right .dealer-help__wrapper .content .dealer-help .help-title {
      text-align: center;
      padding: 32px 0; }
      @media screen and (min-width: 640px) {
        .dealer-help__wrapper .container .dealer-help .help-title, .dealer-help__wrapper .wholesale-factory__content2 .right .content .dealer-help .help-title, .wholesale-factory__content2 .right .dealer-help__wrapper .content .dealer-help .help-title {
          padding: 40px 0; } }
      @media screen and (min-width: 1024px) {
        .dealer-help__wrapper .container .dealer-help .help-title, .dealer-help__wrapper .wholesale-factory__content2 .right .content .dealer-help .help-title, .wholesale-factory__content2 .right .dealer-help__wrapper .content .dealer-help .help-title {
          padding: 64px 0; } }
      @media screen and (min-width: 1280px) {
        .dealer-help__wrapper .container .dealer-help .help-title, .dealer-help__wrapper .wholesale-factory__content2 .right .content .dealer-help .help-title, .wholesale-factory__content2 .right .dealer-help__wrapper .content .dealer-help .help-title {
          padding: 64px 0; } }
    .dealer-help__wrapper .container .dealer-help .help-section, .dealer-help__wrapper .wholesale-factory__content2 .right .content .dealer-help .help-section, .wholesale-factory__content2 .right .dealer-help__wrapper .content .dealer-help .help-section {
      align-self: center;
      justify-content: space-between;
      margin: auto; }
      .dealer-help__wrapper .container .dealer-help .help-section .col, .dealer-help__wrapper .wholesale-factory__content2 .right .content .dealer-help .help-section .col, .wholesale-factory__content2 .right .dealer-help__wrapper .content .dealer-help .help-section .col,
      .dealer-help__wrapper .container .dealer-help .help-section [class*="col-"],
      .dealer-help__wrapper .wholesale-factory__content2 .right .content .dealer-help .help-section [class*="col-"],
      .wholesale-factory__content2 .right .dealer-help__wrapper .content .dealer-help .help-section [class*="col-"] {
        padding-left: 15px;
        padding-right: 15px; }
      .dealer-help__wrapper .container .dealer-help .help-section .dealer-items .img-section, .dealer-help__wrapper .wholesale-factory__content2 .right .content .dealer-help .help-section .dealer-items .img-section, .wholesale-factory__content2 .right .dealer-help__wrapper .content .dealer-help .help-section .dealer-items .img-section {
        position: relative; }
        .dealer-help__wrapper .container .dealer-help .help-section .dealer-items .img-section img, .dealer-help__wrapper .wholesale-factory__content2 .right .content .dealer-help .help-section .dealer-items .img-section img, .wholesale-factory__content2 .right .dealer-help__wrapper .content .dealer-help .help-section .dealer-items .img-section img {
          width: 100%; }
        .dealer-help__wrapper .container .dealer-help .help-section .dealer-items .img-section .overlay-section, .dealer-help__wrapper .wholesale-factory__content2 .right .content .dealer-help .help-section .dealer-items .img-section .overlay-section, .wholesale-factory__content2 .right .dealer-help__wrapper .content .dealer-help .help-section .dealer-items .img-section .overlay-section {
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          position: absolute;
          bottom: 0;
          left: 0;
          padding: 10px 0;
          width: 100%;
          background: #a32732; }
          .dealer-help__wrapper .container .dealer-help .help-section .dealer-items .img-section .overlay-section .title, .dealer-help__wrapper .wholesale-factory__content2 .right .content .dealer-help .help-section .dealer-items .img-section .overlay-section .title, .wholesale-factory__content2 .right .dealer-help__wrapper .content .dealer-help .help-section .dealer-items .img-section .overlay-section .title {
            font-size: 22px;
            font-family: "SVN-AvenirNext", sans-serif;
            font-weight: 500;
            color: #c0c0c0;
            transform: translateY(4px); }
          .dealer-help__wrapper .container .dealer-help .help-section .dealer-items .img-section .overlay-section .more, .dealer-help__wrapper .wholesale-factory__content2 .right .content .dealer-help .help-section .dealer-items .img-section .overlay-section .more, .wholesale-factory__content2 .right .dealer-help__wrapper .content .dealer-help .help-section .dealer-items .img-section .overlay-section .more {
            margin: 0;
            border-color: #c0c0c0; }
            .dealer-help__wrapper .container .dealer-help .help-section .dealer-items .img-section .overlay-section .more img, .dealer-help__wrapper .wholesale-factory__content2 .right .content .dealer-help .help-section .dealer-items .img-section .overlay-section .more img, .wholesale-factory__content2 .right .dealer-help__wrapper .content .dealer-help .help-section .dealer-items .img-section .overlay-section .more img {
              display: none; }
            .dealer-help__wrapper .container .dealer-help .help-section .dealer-items .img-section .overlay-section .more p, .dealer-help__wrapper .wholesale-factory__content2 .right .content .dealer-help .help-section .dealer-items .img-section .overlay-section .more p, .wholesale-factory__content2 .right .dealer-help__wrapper .content .dealer-help .help-section .dealer-items .img-section .overlay-section .more p {
              color: #c0c0c0; }

@media (max-width: 1440px) {
  .dealer-help__wrapper .container .dealer-help .help-section .dealer-items .img-section .overlay-section .title, .dealer-help__wrapper .wholesale-factory__content2 .right .content .dealer-help .help-section .dealer-items .img-section .overlay-section .title, .wholesale-factory__content2 .right .dealer-help__wrapper .content .dealer-help .help-section .dealer-items .img-section .overlay-section .title, .dealer-help__wrapper .container .dealer-help .help-section .dealer-items .img-section .overlay-section .more, .dealer-help__wrapper .wholesale-factory__content2 .right .content .dealer-help .help-section .dealer-items .img-section .overlay-section .more, .wholesale-factory__content2 .right .dealer-help__wrapper .content .dealer-help .help-section .dealer-items .img-section .overlay-section .more {
    font-size: 20px; } }

@media (max-width: 1280px) {
  .dealer-help__wrapper .container .dealer-help .help-section .dealer-items .img-section .overlay-section .more, .dealer-help__wrapper .wholesale-factory__content2 .right .content .dealer-help .help-section .dealer-items .img-section .overlay-section .more, .wholesale-factory__content2 .right .dealer-help__wrapper .content .dealer-help .help-section .dealer-items .img-section .overlay-section .more {
    font-size: 20px; }
  .dealer-help__wrapper .container .dealer-help .help-section .dealer-items .img-section .overlay-section .title, .dealer-help__wrapper .wholesale-factory__content2 .right .content .dealer-help .help-section .dealer-items .img-section .overlay-section .title, .wholesale-factory__content2 .right .dealer-help__wrapper .content .dealer-help .help-section .dealer-items .img-section .overlay-section .title {
    font-size: 22px; }
  .dealer-help__wrapper .container .dealer-help .help-section, .dealer-help__wrapper .wholesale-factory__content2 .right .content .dealer-help .help-section, .wholesale-factory__content2 .right .dealer-help__wrapper .content .dealer-help .help-section {
    flex-direction: column; }
    .dealer-help__wrapper .container .dealer-help .help-section > *, .dealer-help__wrapper .wholesale-factory__content2 .right .content .dealer-help .help-section > *, .wholesale-factory__content2 .right .dealer-help__wrapper .content .dealer-help .help-section > * {
      flex: 0 0 80%;
      max-width: 80%;
      margin-left: auto;
      margin-right: auto; }
    .dealer-help__wrapper .container .dealer-help .help-section .dealer-items + .dealer-items, .dealer-help__wrapper .wholesale-factory__content2 .right .content .dealer-help .help-section .dealer-items + .dealer-items, .wholesale-factory__content2 .right .dealer-help__wrapper .content .dealer-help .help-section .dealer-items + .dealer-items {
      margin-top: 40px; } }
    @media (max-width: 1280px) and (max-width: 1280px) {
      .dealer-help__wrapper .container .dealer-help .help-section .dealer-items + .dealer-items, .dealer-help__wrapper .wholesale-factory__content2 .right .content .dealer-help .help-section .dealer-items + .dealer-items, .wholesale-factory__content2 .right .dealer-help__wrapper .content .dealer-help .help-section .dealer-items + .dealer-items {
        margin-top: 32px; } }
    @media (max-width: 1280px) and (max-width: 640px) {
      .dealer-help__wrapper .container .dealer-help .help-section .dealer-items + .dealer-items, .dealer-help__wrapper .wholesale-factory__content2 .right .content .dealer-help .help-section .dealer-items + .dealer-items, .wholesale-factory__content2 .right .dealer-help__wrapper .content .dealer-help .help-section .dealer-items + .dealer-items {
        margin-top: 12px; } }

@media (max-width: 640px) {
  .dealer-help__wrapper .container .dealer-help .help-section .dealer-items .img-section .overlay-section .more, .dealer-help__wrapper .wholesale-factory__content2 .right .content .dealer-help .help-section .dealer-items .img-section .overlay-section .more, .wholesale-factory__content2 .right .dealer-help__wrapper .content .dealer-help .help-section .dealer-items .img-section .overlay-section .more {
    font-size: 16px; }
  .dealer-help__wrapper .container .dealer-help .help-section .dealer-items .img-section .overlay-section .title, .dealer-help__wrapper .wholesale-factory__content2 .right .content .dealer-help .help-section .dealer-items .img-section .overlay-section .title, .wholesale-factory__content2 .right .dealer-help__wrapper .content .dealer-help .help-section .dealer-items .img-section .overlay-section .title {
    font-size: 20px; }
  .dealer-help__wrapper .container .dealer-help .help-section > *, .dealer-help__wrapper .wholesale-factory__content2 .right .content .dealer-help .help-section > *, .wholesale-factory__content2 .right .dealer-help__wrapper .content .dealer-help .help-section > * {
    flex: 0 0 100%;
    max-width: 100%; } }

@media (max-width: 425px) {
  .dealer-help__wrapper .container .dealer-help .help-section .dealer-items .img-section .overlay-section .more, .dealer-help__wrapper .wholesale-factory__content2 .right .content .dealer-help .help-section .dealer-items .img-section .overlay-section .more, .wholesale-factory__content2 .right .dealer-help__wrapper .content .dealer-help .help-section .dealer-items .img-section .overlay-section .more {
    font-size: 10px;
    padding: 5px 5px; }
  .dealer-help__wrapper .container .dealer-help .help-section .dealer-items .img-section .overlay-section .title, .dealer-help__wrapper .wholesale-factory__content2 .right .content .dealer-help .help-section .dealer-items .img-section .overlay-section .title, .wholesale-factory__content2 .right .dealer-help__wrapper .content .dealer-help .help-section .dealer-items .img-section .overlay-section .title {
    font-size: 16px; } }

.dealer-new__wrapper {
  padding-bottom: 32px; }
  @media screen and (min-width: 640px) {
    .dealer-new__wrapper {
      padding-bottom: 40px; } }
  @media screen and (min-width: 1024px) {
    .dealer-new__wrapper {
      padding-bottom: 64px; } }
  @media screen and (min-width: 1280px) {
    .dealer-new__wrapper {
      padding-bottom: 64px; } }
  .dealer-new__wrapper .dealer-new .new-title {
    text-align: center;
    padding: 80px 0; }
  .dealer-new__wrapper .dealer-new .new-section .col, .dealer-new__wrapper .dealer-new .new-section [class*=col-] {
    padding-left: 15px;
    padding-right: 15px; }
  .dealer-new__wrapper .dealer-new .new-section .dealer-items .img-section {
    position: relative; }
    .dealer-new__wrapper .dealer-new .new-section .dealer-items .img-section img {
      width: 100%; }
    .dealer-new__wrapper .dealer-new .new-section .dealer-items .img-section .overlay-section {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 10px 0;
      width: 100%;
      background: #a32732; }
      .dealer-new__wrapper .dealer-new .new-section .dealer-items .img-section .overlay-section .title {
        font-size: 27px;
        font-family: HELVETICANEUELTSTD-ROMAN;
        color: #c0c0c0;
        transform: translateY(4px); }
      .dealer-new__wrapper .dealer-new .new-section .dealer-items .img-section .overlay-section .more {
        margin: 0;
        border-color: #c0c0c0; }
        .dealer-new__wrapper .dealer-new .new-section .dealer-items .img-section .overlay-section .more p {
          color: #c0c0c0; }

@media (max-width: 1280px) {
  .dealer-new__wrapper .dealer-new .new-section {
    flex-direction: column; }
    .dealer-new__wrapper .dealer-new .new-section > * {
      flex: 0 0 80%;
      max-width: 80%;
      margin-left: auto;
      margin-right: auto; }
    .dealer-new__wrapper .dealer-new .new-section > *:not(:first-child) {
      margin-top: 50px; }
  .dealer-new__wrapper .dealer-new .new-title {
    padding: 50px; } }

@media (max-width: 1024px) {
  .dealer-new__wrapper .dealer-new .new-title {
    padding: 36px 0; } }

@media (max-width: 640px) {
  .dealer-new__wrapper .dealer-new .new-section > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .dealer-new__wrapper .dealer-new .new-section > *:not(:first-child) {
    margin-top: 36px; } }

.dealer-navigation__wrapper {
  padding-bottom: 32px; }
  @media screen and (min-width: 640px) {
    .dealer-navigation__wrapper {
      padding-bottom: 40px; } }
  @media screen and (min-width: 1024px) {
    .dealer-navigation__wrapper {
      padding-bottom: 64px; } }
  @media screen and (min-width: 1280px) {
    .dealer-navigation__wrapper {
      padding-bottom: 64px; } }
  .dealer-navigation__wrapper .container, .dealer-navigation__wrapper .wholesale-factory__content2 .right .content, .wholesale-factory__content2 .right .dealer-navigation__wrapper .content {
    padding-right: 0;
    padding-left: 0; }
    .dealer-navigation__wrapper .container .dealer-navigation, .dealer-navigation__wrapper .wholesale-factory__content2 .right .content .dealer-navigation, .wholesale-factory__content2 .right .dealer-navigation__wrapper .content .dealer-navigation {
      display: flex;
      align-items: center;
      justify-content: flex-start; }
      .dealer-navigation__wrapper .container .dealer-navigation > *:not(:first-child), .dealer-navigation__wrapper .wholesale-factory__content2 .right .content .dealer-navigation > *:not(:first-child), .wholesale-factory__content2 .right .dealer-navigation__wrapper .content .dealer-navigation > *:not(:first-child) {
        margin-left: 24px; }
        @media (max-width: null) {
          .dealer-navigation__wrapper .container .dealer-navigation > *:not(:first-child), .dealer-navigation__wrapper .wholesale-factory__content2 .right .content .dealer-navigation > *:not(:first-child), .wholesale-factory__content2 .right .dealer-navigation__wrapper .content .dealer-navigation > *:not(:first-child) {
            margin-left: 12px; } }
      .dealer-navigation__wrapper .container .dealer-navigation .more, .dealer-navigation__wrapper .wholesale-factory__content2 .right .content .dealer-navigation .more, .wholesale-factory__content2 .right .dealer-navigation__wrapper .content .dealer-navigation .more {
        margin: 0;
        padding: 16px 10px; }
        .dealer-navigation__wrapper .container .dealer-navigation .more p, .dealer-navigation__wrapper .wholesale-factory__content2 .right .content .dealer-navigation .more p, .wholesale-factory__content2 .right .dealer-navigation__wrapper .content .dealer-navigation .more p {
          font-size: 27px;
          text-transform: initial; }
      .dealer-navigation__wrapper .container .dealer-navigation .navigate-text__items, .dealer-navigation__wrapper .wholesale-factory__content2 .right .content .dealer-navigation .navigate-text__items, .wholesale-factory__content2 .right .dealer-navigation__wrapper .content .dealer-navigation .navigate-text__items {
        font-size: 27px;
        color: #a32732; }
        .dealer-navigation__wrapper .container .dealer-navigation .navigate-text__items:last-child, .dealer-navigation__wrapper .wholesale-factory__content2 .right .content .dealer-navigation .navigate-text__items:last-child, .wholesale-factory__content2 .right .dealer-navigation__wrapper .content .dealer-navigation .navigate-text__items:last-child {
          margin-right: 0; }
      .dealer-navigation__wrapper .container .dealer-navigation .navigate-text__items:not(:last-child), .dealer-navigation__wrapper .wholesale-factory__content2 .right .content .dealer-navigation .navigate-text__items:not(:last-child), .wholesale-factory__content2 .right .dealer-navigation__wrapper .content .dealer-navigation .navigate-text__items:not(:last-child) {
        position: relative; }
        .dealer-navigation__wrapper .container .dealer-navigation .navigate-text__items:not(:last-child)::after, .dealer-navigation__wrapper .wholesale-factory__content2 .right .content .dealer-navigation .navigate-text__items:not(:last-child)::after, .wholesale-factory__content2 .right .dealer-navigation__wrapper .content .dealer-navigation .navigate-text__items:not(:last-child)::after {
          position: absolute;
          top: 0;
          right: -12px;
          width: 1px;
          height: 100%;
          content: "";
          background: #a32732; }

@media (max-width: 1440px) {
  .dealer-navigation__wrapper .container .dealer-navigation .navigate-text__items:not(:last-child)::after, .dealer-navigation__wrapper .wholesale-factory__content2 .right .content .dealer-navigation .navigate-text__items:not(:last-child)::after, .wholesale-factory__content2 .right .dealer-navigation__wrapper .content .dealer-navigation .navigate-text__items:not(:last-child)::after {
    right: -12px; } }

@media (max-width: 1280px) {
  .dealer-navigation__wrapper .container .dealer-navigation .navigate-text__items:not(:last-child)::after, .dealer-navigation__wrapper .wholesale-factory__content2 .right .content .dealer-navigation .navigate-text__items:not(:last-child)::after, .wholesale-factory__content2 .right .dealer-navigation__wrapper .content .dealer-navigation .navigate-text__items:not(:last-child)::after {
    right: -20%; }
  .dealer-navigation__wrapper .container .dealer-navigation .more p, .dealer-navigation__wrapper .wholesale-factory__content2 .right .content .dealer-navigation .more p, .wholesale-factory__content2 .right .dealer-navigation__wrapper .content .dealer-navigation .more p {
    font-size: 20px; }
  .dealer-navigation__wrapper .container .dealer-navigation .navigate-text__items, .dealer-navigation__wrapper .wholesale-factory__content2 .right .content .dealer-navigation .navigate-text__items, .wholesale-factory__content2 .right .dealer-navigation__wrapper .content .dealer-navigation .navigate-text__items {
    font-size: 20px; } }

@media (max-width: 1024px) {
  .dealer-navigation__wrapper .container .dealer-navigation .navigate-text__items:not(:last-child)::after, .dealer-navigation__wrapper .wholesale-factory__content2 .right .content .dealer-navigation .navigate-text__items:not(:last-child)::after, .wholesale-factory__content2 .right .dealer-navigation__wrapper .content .dealer-navigation .navigate-text__items:not(:last-child)::after {
    display: none; }
  .dealer-navigation__wrapper .container .dealer-navigation, .dealer-navigation__wrapper .wholesale-factory__content2 .right .content .dealer-navigation, .wholesale-factory__content2 .right .dealer-navigation__wrapper .content .dealer-navigation {
    flex-direction: column; }
  .dealer-navigation__wrapper .container .dealer-navigation > :not(:first-child), .dealer-navigation__wrapper .wholesale-factory__content2 .right .content .dealer-navigation > :not(:first-child), .wholesale-factory__content2 .right .dealer-navigation__wrapper .content .dealer-navigation > :not(:first-child) {
    margin-left: 0; }
  .dealer-navigation__wrapper .container .dealer-navigation .navigate-text__items, .dealer-navigation__wrapper .wholesale-factory__content2 .right .content .dealer-navigation .navigate-text__items, .wholesale-factory__content2 .right .dealer-navigation__wrapper .content .dealer-navigation .navigate-text__items {
    padding: 12px;
    margin-top: 12px; }
  .dealer-navigation__wrapper .container .dealer-navigation .more, .dealer-navigation__wrapper .wholesale-factory__content2 .right .content .dealer-navigation .more, .wholesale-factory__content2 .right .dealer-navigation__wrapper .content .dealer-navigation .more {
    padding: 8px 12px; }
  .dealer-navigation__wrapper {
    padding: 0 0 36px 0; } }

@media (max-width: 640px) {
  .dealer-navigation__wrapper .container .dealer-navigation .more p, .dealer-navigation__wrapper .wholesale-factory__content2 .right .content .dealer-navigation .more p, .wholesale-factory__content2 .right .dealer-navigation__wrapper .content .dealer-navigation .more p {
    font-size: 18px; }
  .dealer-navigation__wrapper .container .dealer-navigation .navigate-text__items, .dealer-navigation__wrapper .wholesale-factory__content2 .right .content .dealer-navigation .navigate-text__items, .wholesale-factory__content2 .right .dealer-navigation__wrapper .content .dealer-navigation .navigate-text__items {
    font-size: 18px; }
  .dealer-navigation__wrapper .container .dealer-navigation .more p, .dealer-navigation__wrapper .wholesale-factory__content2 .right .content .dealer-navigation .more p, .wholesale-factory__content2 .right .dealer-navigation__wrapper .content .dealer-navigation .more p {
    margin-right: 8px; } }

@media (max-width: 320px) {
  .dealer-navigation__wrapper .container .dealer-navigation .more p, .dealer-navigation__wrapper .wholesale-factory__content2 .right .content .dealer-navigation .more p, .wholesale-factory__content2 .right .dealer-navigation__wrapper .content .dealer-navigation .more p {
    font-size: 16px; }
  .dealer-navigation__wrapper .container .dealer-navigation .navigate-text__items, .dealer-navigation__wrapper .wholesale-factory__content2 .right .content .dealer-navigation .navigate-text__items, .wholesale-factory__content2 .right .dealer-navigation__wrapper .content .dealer-navigation .navigate-text__items {
    font-size: 16px; }
  .dealer-navigation__wrapper .container .dealer-navigation .more p, .dealer-navigation__wrapper .wholesale-factory__content2 .right .content .dealer-navigation .more p, .wholesale-factory__content2 .right .dealer-navigation__wrapper .content .dealer-navigation .more p {
    margin-right: 8px; } }

.dealer-navigation-digital__wrapper .dealer-navigation-digital {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 32px 0; }
  @media screen and (min-width: 640px) {
    .dealer-navigation-digital__wrapper .dealer-navigation-digital {
      margin: 40px 0; } }
  @media screen and (min-width: 1024px) {
    .dealer-navigation-digital__wrapper .dealer-navigation-digital {
      margin: 64px 0; } }
  @media screen and (min-width: 1280px) {
    .dealer-navigation-digital__wrapper .dealer-navigation-digital {
      margin: 64px 0; } }
  .dealer-navigation-digital__wrapper .dealer-navigation-digital .items {
    display: flex;
    justify-content: center;
    position: relative; }
    .dealer-navigation-digital__wrapper .dealer-navigation-digital .items::before {
      position: absolute;
      content: "";
      top: 50%;
      right: 0;
      transform: translate(-50%, -50%);
      height: 50%;
      width: 2px;
      background: #a32732; }
    .dealer-navigation-digital__wrapper .dealer-navigation-digital .items:nth-child(3n) {
      margin-left: unset; }
      .dealer-navigation-digital__wrapper .dealer-navigation-digital .items:nth-child(3n)::before {
        display: none; }
    .dealer-navigation-digital__wrapper .dealer-navigation-digital .items .nav-items {
      height: 100%;
      padding: 20px 55px 20px 30px;
      display: flex;
      flex-wrap: wrap;
      cursor: pointer;
      position: relative;
      font-size: 27px;
      color: #000;
      background-color: transparent;
      min-width: 20%; }
      .dealer-navigation-digital__wrapper .dealer-navigation-digital .items .nav-items p {
        width: 100%; }
      @media (max-width: 425px) {
        .dealer-navigation-digital__wrapper .dealer-navigation-digital .items .nav-items {
          font-size: 20px; } }
      .dealer-navigation-digital__wrapper .dealer-navigation-digital .items .nav-items img {
        display: none; }
      .dealer-navigation-digital__wrapper .dealer-navigation-digital .items .nav-items.active {
        padding: 20px 30px 20px 30px;
        color: #fff;
        background-color: #a32732;
        border-radius: 6px;
        position: relative; }
        .dealer-navigation-digital__wrapper .dealer-navigation-digital .items .nav-items.active::after {
          margin-left: 3px;
          width: 2rem;
          height: 2rem;
          position: absolute;
          top: 50%;
          right: 8px;
          transform: translateY(-50%);
          content: "";
          background-repeat: no-repeat;
          background-position: center; }

@media (max-width: 960px) {
  .dealer-navigation-digital__wrapper .dealer-navigation-digital {
    flex-direction: column; }
    .dealer-navigation-digital__wrapper .dealer-navigation-digital .items {
      flex: 0 0 100%;
      width: 100%;
      max-width: 100%; }
      .dealer-navigation-digital__wrapper .dealer-navigation-digital .items::before {
        display: none; } }

@media (max-width: 960px) and (max-width: 1024px) {
  .dealer-navigation-digital__wrapper .dealer-navigation-digital .nav-items {
    padding: 10px 40px 10px 10px;
    margin-right: 0;
    margin-top: 12px; } }

.dealer-digital-list__wrapper .dealer-digital-list .list-items {
  position: relative;
  margin-top: 80px; }
  @media screen and (min-width: 640px) {
    .dealer-digital-list__wrapper .dealer-digital-list .list-items {
      margin-top: 100px; } }
  @media screen and (min-width: 1024px) {
    .dealer-digital-list__wrapper .dealer-digital-list .list-items {
      margin-top: 128px; } }
  @media screen and (min-width: 1280px) {
    .dealer-digital-list__wrapper .dealer-digital-list .list-items {
      margin-top: 128px; } }
  .dealer-digital-list__wrapper .dealer-digital-list .list-items .row {
    align-items: center; }
    .dealer-digital-list__wrapper .dealer-digital-list .list-items .row .items__img {
      width: 828px;
      height: 582px;
      display: flex;
      align-items: center;
      position: relative; }
      .dealer-digital-list__wrapper .dealer-digital-list .list-items .row .items__img img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center; }
    .dealer-digital-list__wrapper .dealer-digital-list .list-items .row .items__infor .product-infor {
      padding-right: 32px;
      padding-left: 32px; }
      @media screen and (min-width: 640px) {
        .dealer-digital-list__wrapper .dealer-digital-list .list-items .row .items__infor .product-infor {
          padding-right: 40px; } }
      @media screen and (min-width: 1024px) {
        .dealer-digital-list__wrapper .dealer-digital-list .list-items .row .items__infor .product-infor {
          padding-right: 64px; } }
      @media screen and (min-width: 1280px) {
        .dealer-digital-list__wrapper .dealer-digital-list .list-items .row .items__infor .product-infor {
          padding-right: 64px; } }
      @media screen and (min-width: 640px) {
        .dealer-digital-list__wrapper .dealer-digital-list .list-items .row .items__infor .product-infor {
          padding-left: 40px; } }
      @media screen and (min-width: 1024px) {
        .dealer-digital-list__wrapper .dealer-digital-list .list-items .row .items__infor .product-infor {
          padding-left: 64px; } }
      @media screen and (min-width: 1280px) {
        .dealer-digital-list__wrapper .dealer-digital-list .list-items .row .items__infor .product-infor {
          padding-left: 64px; } }
      .dealer-digital-list__wrapper .dealer-digital-list .list-items .row .items__infor .product-infor .product-infor__title {
        text-transform: uppercase;
        font-size: 22px;
        font-weight: 600;
        color: #000; }
        @media screen and (min-width: 640px) {
          .dealer-digital-list__wrapper .dealer-digital-list .list-items .row .items__infor .product-infor .product-infor__title {
            font-size: 26px; } }
        @media screen and (min-width: 1024px) {
          .dealer-digital-list__wrapper .dealer-digital-list .list-items .row .items__infor .product-infor .product-infor__title {
            font-size: 30px; } }
      .dealer-digital-list__wrapper .dealer-digital-list .list-items .row .items__infor .product-infor .product-infor__content {
        justify-content: flex-end;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden; }
      .dealer-digital-list__wrapper .dealer-digital-list .list-items .row .items__infor .product-infor .product-infor__more {
        color: #707070;
        margin-bottom: 12px;
        display: block;
        width: 100%; }
      .dealer-digital-list__wrapper .dealer-digital-list .list-items .row .items__infor .product-infor .btn {
        margin-bottom: 0; }
  .dealer-digital-list__wrapper .dealer-digital-list .list-items:first-child {
    margin-top: 0; }
  .dealer-digital-list__wrapper .dealer-digital-list .list-items:nth-child(even) .items__img {
    text-align: end; }
  .dealer-digital-list__wrapper .dealer-digital-list .list-items:nth-child(even) .row {
    flex-direction: row; }
    .dealer-digital-list__wrapper .dealer-digital-list .list-items:nth-child(even) .row .items__infor .product-infor {
      display: flex;
      flex-direction: column;
      align-items: flex-start; }
      .dealer-digital-list__wrapper .dealer-digital-list .list-items:nth-child(even) .row .items__infor .product-infor .product-infor__content {
        text-align: left; }
      .dealer-digital-list__wrapper .dealer-digital-list .list-items:nth-child(even) .row .items__infor .product-infor .product-infor__more {
        text-align: left; }
  .dealer-digital-list__wrapper .dealer-digital-list .list-items:nth-child(odd) .row {
    flex-direction: row-reverse; }
    .dealer-digital-list__wrapper .dealer-digital-list .list-items:nth-child(odd) .row .items__infor .product-infor {
      display: flex;
      flex-direction: column;
      align-items: flex-end; }
      .dealer-digital-list__wrapper .dealer-digital-list .list-items:nth-child(odd) .row .items__infor .product-infor .product-infor__content {
        text-align: end; }
      .dealer-digital-list__wrapper .dealer-digital-list .list-items:nth-child(odd) .row .items__infor .product-infor .product-infor__more {
        text-align: right; }
  .dealer-digital-list__wrapper .dealer-digital-list .list-items .underlay-background {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 100%;
    height: 450px;
    background: #ececec;
    z-index: -1; }

@media (max-width: 1280px) {
  .dealer-digital-list__wrapper .dealer-digital-list .list-items .row .items__infor .product-infor {
    padding: 0 24px; }
  .dealer-digital-list__wrapper .dealer-digital-list .list-items .row .items__img {
    width: 711px;
    height: 500px; } }

@media (max-width: 1024px) {
  .dealer-digital-list__wrapper .dealer-digital-list .list-items:first-child {
    margin-top: 80px; } }

@media (max-width: 768px) {
  .dealer-digital-list__wrapper .dealer-digital-list .list-items:nth-child(odd) .row {
    flex-direction: column; }
  .dealer-digital-list__wrapper .dealer-digital-list .list-items:first-child {
    margin-top: 42px; }
  .dealer-digital-list__wrapper .dealer-digital-list .list-items {
    padding: 18px 0; }
  .dealer-digital-list__wrapper .dealer-digital-list .list-items .underlay-background {
    height: 100%; }
  .dealer-digital-list__wrapper .dealer-digital-list .list-items .row .items__img {
    width: 100%;
    max-width: 100%; }
  .dealer-digital-list__wrapper .dealer-digital-list .list-items .row .items__infor {
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: 36px; }
    .dealer-digital-list__wrapper .dealer-digital-list .list-items .row .items__infor .product-infor {
      padding: 0; }
  .dealer-digital-list__wrapper .dealer-digital-list .list-items:nth-child(odd) .row .items__infor .product-infor {
    align-items: flex-start; }
  .dealer-digital-list__wrapper .dealer-digital-list .list-items:nth-child(odd) .row .items__infor .product-infor .product-infor__content {
    text-align: start; } }

@media (max-width: 768px) {
  .dealer-navigation-digital__wrapper .dealer-navigation-digital .nav-items {
    font-size: 18px; } }

.wholesale--dealer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 32px;
  margin-bottom: 80px; }
  @media screen and (min-width: 640px) {
    .wholesale--dealer {
      margin-top: 40px; } }
  @media screen and (min-width: 1024px) {
    .wholesale--dealer {
      margin-top: 64px; } }
  @media screen and (min-width: 1280px) {
    .wholesale--dealer {
      margin-top: 64px; } }
  @media screen and (min-width: 640px) {
    .wholesale--dealer {
      margin-bottom: 100px; } }
  @media screen and (min-width: 1024px) {
    .wholesale--dealer {
      margin-bottom: 128px; } }
  @media screen and (min-width: 1280px) {
    .wholesale--dealer {
      margin-bottom: 128px; } }
  .wholesale--dealer__content1 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start; }
    .wholesale--dealer__content1 .left1 {
      color: #000;
      text-align: center; }
  .wholesale--dealer__content2 {
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    margin-bottom: 80px; }
    @media (max-width: 1280px) {
      .wholesale--dealer__content2 {
        margin-top: 32px; } }
    @media (max-width: 640px) {
      .wholesale--dealer__content2 {
        margin-top: 12px; } }
    @media screen and (min-width: 640px) {
      .wholesale--dealer__content2 {
        margin-bottom: 100px; } }
    @media screen and (min-width: 1024px) {
      .wholesale--dealer__content2 {
        margin-bottom: 128px; } }
    @media screen and (min-width: 1280px) {
      .wholesale--dealer__content2 {
        margin-bottom: 128px; } }
    .wholesale--dealer__content2 .left2 {
      justify-content: flex-end;
      width: 50%;
      padding: 0 10% 0 0%; }
      .wholesale--dealer__content2 .left2 .content {
        width: 100%;
        display: grid;
        gap: 12px;
        grid-auto-columns: auto;
        grid-auto-rows: auto;
        min-height: 550px; }
        @media (max-width: 640px) {
          .wholesale--dealer__content2 .left2 .content {
            min-height: 480px; } }
        @media (max-width: 475px) {
          .wholesale--dealer__content2 .left2 .content {
            min-height: 420px; } }
        @media (max-width: 425px) {
          .wholesale--dealer__content2 .left2 .content {
            min-height: 340px; } }
        @media (max-width: 320px) {
          .wholesale--dealer__content2 .left2 .content {
            min-height: 280px; } }
        .wholesale--dealer__content2 .left2 .content img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
        .wholesale--dealer__content2 .left2 .content img:nth-child(1) {
          grid-column: 1 / span 3;
          grid-row: 1 / span 4; }
        .wholesale--dealer__content2 .left2 .content img:nth-child(2) {
          grid-column: 4 / span 2;
          grid-row: 2 / span 4; }
        .wholesale--dealer__content2 .left2 .content img:nth-child(3) {
          grid-column: 2 / span 2;
          grid-row: 5 / span 3; }
        .wholesale--dealer__content2 .left2 .content img:nth-child(4) {
          grid-column: 4 / span 3;
          grid-row: 6 / span 8; }
    .wholesale--dealer__content2 .right2 {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center; }
      @media (min-width: 1860px) {
        .wholesale--dealer__content2 .right2 {
          padding-right: 10%; } }
      .wholesale--dealer__content2 .right2 .content2 {
        margin-top: 8px;
        list-style-type: none;
        font-size: 18px; }
        @media screen and (min-width: 1024px) {
          .wholesale--dealer__content2 .right2 .content2 {
            font-size: 20px; } }
        .wholesale--dealer__content2 .right2 .content2 li {
          margin: 15px 0; }
  .wholesale--dealer__content3 .title3 {
    text-align: center;
    margin-bottom: 32px; }
    @media screen and (min-width: 640px) {
      .wholesale--dealer__content3 .title3 {
        margin-bottom: 40px; } }
    @media screen and (min-width: 1024px) {
      .wholesale--dealer__content3 .title3 {
        margin-bottom: 64px; } }
    @media screen and (min-width: 1280px) {
      .wholesale--dealer__content3 .title3 {
        margin-bottom: 64px; } }
  .wholesale--dealer__content3 .content {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 20px 40px; }
    .wholesale--dealer__content3 .content .box .images img {
      width: 100%;
      height: 260px;
      object-fit: cover;
      object-position: center; }
    .wholesale--dealer__content3 .content .title {
      color: #000;
      margin: 16px 0; }
    .wholesale--dealer__content3 .content .btn a {
      border: none;
      font-style: italic;
      padding-left: 0;
      background-color: transparent; }
      .wholesale--dealer__content3 .content .btn a:hover {
        cursor: pointer;
        background-color: transparent;
        color: #a32732;
        opacity: 0.7; }
        .wholesale--dealer__content3 .content .btn a:hover svg path {
          stroke: #a32732; }
    .wholesale--dealer__content3 .content .text {
      font-size: 18px;
      -webkit-box-orient: vertical;
      white-space: wrap;
      color: #707070;
      margin: 15px 0; }
      @media screen and (min-width: 1024px) {
        .wholesale--dealer__content3 .content .text {
          font-size: 20px; } }

@media (max-width: 1024px) {
  .wholesale--dealer__content3 .content {
    grid-template-columns: auto auto;
    gap: 20px; } }

@media (max-width: 1280px) {
  .wholesale--dealer__content1 {
    flex-direction: column; }
    .wholesale--dealer__content1 .left1,
    .wholesale--dealer__content1 .right1 {
      width: 100%; }
    .wholesale--dealer__content1 .right1 {
      padding-top: 24px; }
  .wholesale--dealer__content2 {
    flex-direction: column; }
    .wholesale--dealer__content2 .left2 {
      width: 100%;
      padding: 0; }
    .wholesale--dealer__content2 .right2 {
      width: 100%;
      padding-top: 24px;
      padding-right: 0; }
      .wholesale--dealer__content2 .right2 p {
        margin-bottom: 15px; }
  .wholesale--dealer__content3 .content {
    grid-template-columns: auto;
    gap: 20px; }
  .wholesale--dealer__content3 .content .box .images img {
    height: 360px; } }

@media (max-width: 425px) {
  .wholesale--dealer__content3 .content .box .images img {
    height: 240px; } }

.wholesale--commitmet {
  background-color: #A32732;
  padding: 32px 0; }
  @media screen and (min-width: 640px) {
    .wholesale--commitmet {
      padding: 40px 0; } }
  @media screen and (min-width: 1024px) {
    .wholesale--commitmet {
      padding: 64px 0; } }
  @media screen and (min-width: 1280px) {
    .wholesale--commitmet {
      padding: 64px 0; } }
  .wholesale--commitmet__title {
    text-align: center;
    margin-bottom: 32px; }
    @media screen and (min-width: 640px) {
      .wholesale--commitmet__title {
        margin-bottom: 40px; } }
    @media screen and (min-width: 1024px) {
      .wholesale--commitmet__title {
        margin-bottom: 64px; } }
    @media screen and (min-width: 1280px) {
      .wholesale--commitmet__title {
        margin-bottom: 64px; } }
    .wholesale--commitmet__title .title1 {
      color: #c0c0c0; }
    .wholesale--commitmet__title .title2 {
      margin-top: 32px;
      font-family: "SVN-AvenirNext", sans-serif;
      color: #000;
      font-size: 20px;
      font-weight: 500;
      font-family: inherit;
      font-weight: 400;
      color: #c0c0c0;
      text-transform: uppercase; }
      @media (max-width: 1280px) {
        .wholesale--commitmet__title .title2 {
          margin-top: 24px; } }
      @media (max-width: 640px) {
        .wholesale--commitmet__title .title2 {
          margin-top: 16px; } }
      @media (max-width: 1024px) {
        .wholesale--commitmet__title .title2 {
          font-size: 19px; } }
      @media (max-width: 640px) {
        .wholesale--commitmet__title .title2 {
          font-size: 18px; } }
  .wholesale--commitmet__content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    align-items: center;
    width: 100%;
    margin: auto;
    flex-wrap: wrap; }
    .wholesale--commitmet__content .commitmet__link {
      color: #c0c0c0;
      width: 240px;
      flex: 0 0 33.333%;
      margin-top: 40px; }
      @media (max-width: 1280px) {
        .wholesale--commitmet__content .commitmet__link {
          margin-top: 32px; } }
      @media (max-width: 640px) {
        .wholesale--commitmet__content .commitmet__link {
          margin-top: 12px; } }
      .wholesale--commitmet__content .commitmet__link p {
        margin-top: 12px;
        text-align: center; }
      .wholesale--commitmet__content .commitmet__link img {
        width: 112px;
        height: 112px;
        object-position: center;
        object-fit: contain;
        transition: 0.3s; }
      .wholesale--commitmet__content .commitmet__link:hover img {
        transform: scale(1.1); }

@media (max-width: 1024px) {
  .wholesale--commitmet__content .commitmet__link {
    margin: 24px 0;
    flex: 0 0 50%; } }

@media (max-width: 640px) {
  .wholesale--commitmet__content .commitmet__link {
    margin: 24px 0;
    flex: 0 0 100%; } }

.wholesale--family {
  background-color: #c1bfbf;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 32px 0; }
  @media screen and (min-width: 640px) {
    .wholesale--family {
      padding: 40px 0; } }
  @media screen and (min-width: 1024px) {
    .wholesale--family {
      padding: 64px 0; } }
  @media screen and (min-width: 1280px) {
    .wholesale--family {
      padding: 64px 0; } }
  .wholesale--family__content {
    width: 686px; }
    .wholesale--family__content .title {
      margin-bottom: 32px; }
      @media screen and (min-width: 640px) {
        .wholesale--family__content .title {
          margin-bottom: 40px; } }
      @media screen and (min-width: 1024px) {
        .wholesale--family__content .title {
          margin-bottom: 64px; } }
      @media screen and (min-width: 1280px) {
        .wholesale--family__content .title {
          margin-bottom: 64px; } }
    .wholesale--family__content .content {
      text-transform: uppercase;
      margin-bottom: 32px;
      font-weight: 700;
      font-family: inherit; }
      @media (max-width: 1280px) {
        .wholesale--family__content .content {
          margin-bottom: 24px; } }
      @media (max-width: 640px) {
        .wholesale--family__content .content {
          margin-bottom: 16px; } }
    .wholesale--family__content .form-input {
      display: flex;
      flex-direction: column; }
      .wholesale--family__content .form-input .row-input {
        display: flex;
        justify-content: space-between; }
        .wholesale--family__content .form-input .row-input input {
          padding-left: 10px;
          width: 49%;
          border-radius: 8px;
          border: 1px;
          height: 50px;
          margin: 10px 0;
          letter-spacing: 1px;
          transition: 0.3s; }
        .wholesale--family__content .form-input .row-input input:focus,
        .wholesale--family__content .form-input .row-input textarea:focus {
          border: 1px solid #a32732; }
      .wholesale--family__content .form-input .colum-input input {
        padding-left: 10px;
        border-radius: 8px;
        border: 1px;
        height: 50px;
        margin: 10px 0;
        width: 100%;
        letter-spacing: 1px;
        transition: 0.3s; }
      .wholesale--family__content .form-input .colum-input textarea {
        width: 100%;
        height: 150px;
        border-radius: 8px;
        outline: 0;
        padding-left: 10px;
        line-height: 40px;
        resize: none;
        letter-spacing: 1px;
        outline: none;
        transition: 0.3s;
        border: 0;
        margin: 10px 0; }
      .wholesale--family__content .form-input .colum-input input:focus,
      .wholesale--family__content .form-input .colum-input textarea:focus {
        border: 1px solid #a32732; }
      .wholesale--family__content .form-input .button-send input[type="submit"] {
        appearance: none;
        outline: none;
        border: none;
        background: none;
        border-radius: 0;
        display: inline-flex;
        white-space: nowrap;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 15px;
        margin-top: 18px;
        color: #a32732;
        border: 3px solid #a32732;
        background-color: white;
        border-radius: 8px;
        font-size: 18px;
        font-weight: bold;
        text-transform: uppercase;
        transition: 0.16s all ease-in-out; }
        @media screen and (min-width: 1024px) {
          .wholesale--family__content .form-input .button-send input[type="submit"] {
            font-size: 20px; } }
        .wholesale--family__content .form-input .button-send input[type="submit"]:hover {
          background-color: #a32732;
          color: white; }

@media (max-width: 900px) {
  .wholesale--family__content {
    width: 90%; } }

@media (max-width: 640px) {
  .wholesale--family__content {
    width: 96%; }
  input {
    width: 100% !important; }
  .wholesale--family__content .form-input .row-input {
    flex-direction: column; } }

.wholesale-service {
  margin: 32px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around; }
  @media screen and (min-width: 640px) {
    .wholesale-service {
      margin: 40px 0; } }
  @media screen and (min-width: 1024px) {
    .wholesale-service {
      margin: 64px 0; } }
  @media screen and (min-width: 1280px) {
    .wholesale-service {
      margin: 64px 0; } }
  .wholesale-service-left {
    flex-basis: 50%;
    width: 50%;
    position: relative;
    padding-right: 48px;
    padding-left: 48px; }
    .wholesale-service-left .swiper-button-next,
    .wholesale-service-left .swiper-button-prev {
      background-color: #c0c0c0;
      background-repeat: no-repeat;
      background-size: auto;
      background-position: center;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); }
      .wholesale-service-left .swiper-button-next::after,
      .wholesale-service-left .swiper-button-prev::after {
        content: ""; }
      .wholesale-service-left .swiper-button-next:hover,
      .wholesale-service-left .swiper-button-prev:hover {
        background-color: white; }
    .wholesale-service-left .swiper-button-next {
      background-image: url("../assets/images/about/pagination_next.svg"); }
    .wholesale-service-left .swiper-button-prev {
      background-image: url("../assets/images/about/pagination_prev.svg"); }
    .wholesale-service-left .title {
      font-family: "SVN-AvenirNext", sans-serif;
      color: #000;
      font-size: 50px;
      font-weight: 500;
      color: #041e42;
      position: relative; }
      @media (max-width: 1024px) {
        .wholesale-service-left .title {
          font-size: 40px; } }
      @media (max-width: 640px) {
        .wholesale-service-left .title {
          font-size: 32px; } }
      .wholesale-service-left .title span {
        font-family: "SVN-AvenirNext", sans-serif;
        color: #000;
        font-size: 65px;
        font-weight: 500; }
        @media (max-width: 1024px) {
          .wholesale-service-left .title span {
            font-size: 50px; } }
        @media (max-width: 640px) {
          .wholesale-service-left .title span {
            font-size: 45px; } }
      .wholesale-service-left .title span::after {
        position: absolute;
        bottom: 25px;
        left: 0;
        width: 80px;
        transform: rotate(120deg);
        height: 1px;
        background: #041e42;
        content: ""; }
    .wholesale-service-left .sub-title {
      padding-left: 0px; }
      .wholesale-service-left .sub-title > * {
        font-family: "SVN-AvenirNext", sans-serif;
        color: #000;
        font-size: 32px;
        font-weight: 500;
        font-family: "Segoe UI", sans-serif;
        font-weight: 200; }
        @media (max-width: 1024px) {
          .wholesale-service-left .sub-title > * {
            font-size: 26px; } }
        @media (max-width: 640px) {
          .wholesale-service-left .sub-title > * {
            font-size: 22px; } }
      @media (max-width: 768px) {
        .wholesale-service-left .sub-title {
          text-align: center; } }
    .wholesale-service-left .text {
      margin-bottom: 16px; }
      .wholesale-service-left .text p {
        margin: 10px 0;
        padding-left: 0px; }
    .wholesale-service-left .images {
      max-width: 100%;
      margin-left: 0;
      position: relative; }
      .wholesale-service-left .images .swiper-pagination {
        left: 50%;
        transform: translateX(-50%); }
        .wholesale-service-left .images .swiper-pagination-bullet {
          margin: 0 6px;
          position: relative; }
          .wholesale-service-left .images .swiper-pagination-bullet-active {
            background-color: #977; }
          .wholesale-service-left .images .swiper-pagination-bullet-active::before {
            content: "";
            position: absolute;
            width: 5px;
            height: 5px;
            background-color: #a32732;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 50%; }
      .wholesale-service-left .images .swiper-container {
        height: 100%; }
      .wholesale-service-left .images img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        height: auto; }
  .wholesale-service-right {
    flex-basis: 50%;
    width: 50%;
    padding-left: 3%; }
    .wholesale-service-right .images {
      width: 100%;
      height: 100%; }
      .wholesale-service-right .images img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center; }

@media (max-width: 1440px) {
  .wholesale-service {
    padding: 0 0 64px 0; } }

@media (max-width: 1024px) {
  .wholesale-service-right .images {
    height: auto; } }

@media (max-width: 768px) {
  .wholesale-service {
    flex-direction: column;
    align-items: center; }
  .wholesale-service-left,
  .wholesale-service-right {
    width: 100%;
    flex: 0 0 100%; }
  .wholesale-service-right {
    padding-left: 0;
    margin-top: 32px; }
  .wholesale-service-left .images {
    margin: 0 auto; }
  .wholesale-service-left .text {
    text-align: center; }
  .wholesale-service-left .title {
    text-align: center; }
    .wholesale-service-left .title h3 {
      display: inline-block;
      position: relative; }
  .wholesale-service-left .text p {
    padding-left: 0; } }

@media (max-width: 640px) {
  .wholesale-service {
    padding: 0; }
  .wholesale-service-right .images img {
    max-height: 450px; } }

.wholesale-factory__background {
  position: relative;
  height: 450px;
  width: 100%; }
  .wholesale-factory__background-img,
  .wholesale-factory__background img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center; }
  .wholesale-factory__background .opacity {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0)); }

.wholesale-factory__content1 {
  padding: 32px 0;
  width: 30%;
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translateY(-50%);
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.4); }
  @media screen and (min-width: 640px) {
    .wholesale-factory__content1 {
      padding: 40px 0; } }
  @media screen and (min-width: 1024px) {
    .wholesale-factory__content1 {
      padding: 64px 0; } }
  @media screen and (min-width: 1280px) {
    .wholesale-factory__content1 {
      padding: 64px 0; } }
  .wholesale-factory__content1 .text {
    color: white; }
  .wholesale-factory__content1 p {
    margin-top: 40px;
    color: white; }
    @media (max-width: 1280px) {
      .wholesale-factory__content1 p {
        margin-top: 32px; } }
    @media (max-width: 640px) {
      .wholesale-factory__content1 p {
        margin-top: 12px; } }

.wholesale-factory__menu {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  margin: 32px 0; }
  @media screen and (min-width: 640px) {
    .wholesale-factory__menu {
      margin: 40px 0; } }
  @media screen and (min-width: 1024px) {
    .wholesale-factory__menu {
      margin: 64px 0; } }
  @media screen and (min-width: 1280px) {
    .wholesale-factory__menu {
      margin: 64px 0; } }
  .wholesale-factory__menu .menu-1,
  .wholesale-factory__menu .menu-2,
  .wholesale-factory__menu .menu-3 {
    width: 100%;
    max-width: 470px; }
    .wholesale-factory__menu .menu-1 .title,
    .wholesale-factory__menu .menu-2 .title,
    .wholesale-factory__menu .menu-3 .title {
      font-family: "SVN-AvenirNext", sans-serif;
      color: #000;
      font-size: 32px;
      font-weight: 500;
      color: 000;
      margin: 40px 0; }
      @media (max-width: 1024px) {
        .wholesale-factory__menu .menu-1 .title,
        .wholesale-factory__menu .menu-2 .title,
        .wholesale-factory__menu .menu-3 .title {
          font-size: 26px; } }
      @media (max-width: 640px) {
        .wholesale-factory__menu .menu-1 .title,
        .wholesale-factory__menu .menu-2 .title,
        .wholesale-factory__menu .menu-3 .title {
          font-size: 22px; } }
      @media (max-width: 1280px) {
        .wholesale-factory__menu .menu-1 .title,
        .wholesale-factory__menu .menu-2 .title,
        .wholesale-factory__menu .menu-3 .title {
          margin: 32px 0; } }
      @media (max-width: 640px) {
        .wholesale-factory__menu .menu-1 .title,
        .wholesale-factory__menu .menu-2 .title,
        .wholesale-factory__menu .menu-3 .title {
          margin: 12px 0; } }
  .wholesale-factory__menu .menu-2 {
    border-left: 1px dashed #000;
    border-right: 1px dashed #000; }

.wholesale-factory__content2 {
  position: relative;
  display: flex;
  justify-content: space-between; }
  .wholesale-factory__content2 .left {
    width: 100%;
    height: 100%;
    background-color: #a32732;
    position: absolute;
    top: 0;
    left: 0; }
  .wholesale-factory__content2 .center {
    position: absolute;
    bottom: 0;
    left: 43.22916%;
    transform: translateX(-50%);
    width: 30%;
    display: flex;
    justify-content: center; }
  .wholesale-factory__content2 .right {
    position: relative;
    height: 100%;
    width: 100%;
    min-height: 410px;
    text-align: center;
    padding: 48px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; }
    .wholesale-factory__content2 .right .content .title {
      font-family: "SVN-AvenirNext", sans-serif;
      color: #000;
      font-size: 50px;
      font-weight: 500;
      color: white; }
      @media (max-width: 1024px) {
        .wholesale-factory__content2 .right .content .title {
          font-size: 40px; } }
      @media (max-width: 640px) {
        .wholesale-factory__content2 .right .content .title {
          font-size: 32px; } }
    .wholesale-factory__content2 .right .content .subtitle {
      display: block;
      color: white;
      font-weight: 700;
      padding: 10px 0 15px 0;
      margin-bottom: 36px;
      position: relative; }
      .wholesale-factory__content2 .right .content .subtitle::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        width: 50%;
        height: 1px;
        border-top: 1px solid white; }
    .wholesale-factory__content2 .right .content .text {
      font-style: italic;
      color: rgba(255, 255, 255, 0.7); }

.wholesale-factory__content3 {
  width: 96%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; }
  @media (min-width: 576px) {
    .wholesale-factory__content3 {
      max-width: 95%; } }
  @media (min-width: 1025px) {
    .wholesale-factory__content3 {
      max-width: 900px; } }
  @media (min-width: 1280px) {
    .wholesale-factory__content3 {
      max-width: 1200px;
      padding-right: 3%;
      padding-left: 3%; } }
  @media (min-width: 1440px) {
    .wholesale-factory__content3 {
      max-width: 1280px;
      padding-right: 3%;
      padding-left: 3%; } }
  @media (min-width: 1600px) {
    .wholesale-factory__content3 {
      max-width: 1366px;
      padding-right: 3%;
      padding-left: 3%; } }
  @media (min-width: 1800px) {
    .wholesale-factory__content3 {
      max-width: 1640px;
      padding-right: 5%;
      padding-left: 5%; } }
  .wholesale-factory__content3 .left {
    margin: 32px 0;
    width: 550px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; }
    @media screen and (min-width: 640px) {
      .wholesale-factory__content3 .left {
        margin: 40px 0; } }
    @media screen and (min-width: 1024px) {
      .wholesale-factory__content3 .left {
        margin: 64px 0; } }
    @media screen and (min-width: 1280px) {
      .wholesale-factory__content3 .left {
        margin: 64px 0; } }
    .wholesale-factory__content3 .left .content {
      width: 550px;
      padding-left: 2%;
      padding-right: 2%; }
      .wholesale-factory__content3 .left .content .title {
        color: #041e42; }
      .wholesale-factory__content3 .left .content .text {
        color: #707070;
        margin: 40px 0; }
        @media (max-width: 1280px) {
          .wholesale-factory__content3 .left .content .text {
            margin: 32px 0; } }
        @media (max-width: 640px) {
          .wholesale-factory__content3 .left .content .text {
            margin: 12px 0; } }
      .wholesale-factory__content3 .left .content .menu {
        padding: 0; }
        .wholesale-factory__content3 .left .content .menu li {
          margin: 25px 0;
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          align-items: center; }
          .wholesale-factory__content3 .left .content .menu li img {
            width: 64px;
            padding-right: 12px; }
          .wholesale-factory__content3 .left .content .menu li p {
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            -webkit-box-orient: vertical;
            width: 90%;
            margin-left: auto;
            width: 84%; }
  .wholesale-factory__content3 .right {
    padding: 0;
    margin: 32px 0; }
    @media screen and (min-width: 640px) {
      .wholesale-factory__content3 .right {
        margin: 40px 0; } }
    @media screen and (min-width: 1024px) {
      .wholesale-factory__content3 .right {
        margin: 64px 0; } }
    @media screen and (min-width: 1280px) {
      .wholesale-factory__content3 .right {
        margin: 64px 0; } }
    .wholesale-factory__content3 .right img {
      height: 100%;
      object-position: center;
      object-fit: cover; }
    .wholesale-factory__content3 .right .text {
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      overflow: hidden; }

.wholesale-factory__content4 {
  height: 480px;
  display: flex;
  flex-direction: row;
  justify-content: space-between; }
  .wholesale-factory__content4 .content4__item {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 49%;
    background-color: #a32732; }
    .wholesale-factory__content4 .content4__item::after {
      content: "";
      position: absolute;
      top: 5%;
      left: 50%;
      transform: translateX(-50%);
      background-image: url("../assets/wholesale/icons/ws-s-2.png");
      background-size: contain;
      background-repeat: no-repeat;
      width: 56px;
      height: 56px; }
    .wholesale-factory__content4 .content4__item:nth-child(2n + 1) .images {
      position: absolute;
      bottom: 4px;
      right: 10px; }
    .wholesale-factory__content4 .content4__item:nth-child(2n + 1) .text {
      position: absolute;
      top: 20%;
      right: 40%;
      bottom: 5%; }
    .wholesale-factory__content4 .content4__item:nth-child(2n) .images {
      position: absolute;
      left: 10px;
      bottom: 4px;
      transform: scaleX(-1); }
    .wholesale-factory__content4 .content4__item:nth-child(2n) .text {
      position: absolute;
      top: 20%;
      left: 40%; }
    .wholesale-factory__content4 .content4__item .images {
      height: 85%;
      width: 35%; }
      .wholesale-factory__content4 .content4__item .images img {
        height: 100%;
        object-fit: contain;
        object-position: bottom; }
    .wholesale-factory__content4 .content4__item .text {
      color: #c0c0c0;
      width: 300px;
      height: 320px; }
      .wholesale-factory__content4 .content4__item .text .title {
        font-family: "SVN-AvenirNext", sans-serif;
        color: #000;
        font-size: 50px;
        font-weight: 500;
        color: #c0c0c0; }
        @media (max-width: 1024px) {
          .wholesale-factory__content4 .content4__item .text .title {
            font-size: 40px; } }
        @media (max-width: 640px) {
          .wholesale-factory__content4 .content4__item .text .title {
            font-size: 32px; } }
      .wholesale-factory__content4 .content4__item .text .subtitle {
        font-size: 16px;
        margin: 10px 0 50px 0; }
      .wholesale-factory__content4 .content4__item .text .description {
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 4;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        font-style: italic; }

.wholesale-factory__content5 {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #a32732;
  margin: 32px 0; }
  @media screen and (min-width: 640px) {
    .wholesale-factory__content5 {
      margin: 40px 0; } }
  @media screen and (min-width: 1024px) {
    .wholesale-factory__content5 {
      margin: 64px 0; } }
  @media screen and (min-width: 1280px) {
    .wholesale-factory__content5 {
      margin: 64px 0; } }
  .wholesale-factory__content5 .title {
    margin-bottom: 32px; }
    @media (max-width: 1280px) {
      .wholesale-factory__content5 .title {
        margin-bottom: 24px; } }
    @media (max-width: 640px) {
      .wholesale-factory__content5 .title {
        margin-bottom: 16px; } }
  .wholesale-factory__content5 .subtitle {
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 12px; }
  .wholesale-factory__content5 .text {
    display: flex;
    justify-content: space-between;
    flex-direction: row; }
    .wholesale-factory__content5 .text .phone {
      border-right: 1px #707070 solid;
      padding-right: 15px; }
    .wholesale-factory__content5 .text .email {
      padding-left: 15px; }

@media (max-width: 1280px) {
  .wholesale-factory__content1 {
    width: 40%; } }

@media (max-width: 1024px) {
  .wholesale-factory__content1 {
    left: 10%; } }

@media (max-width: 640px) {
  .wholesale-factory__content1 {
    width: 90%;
    padding: 12px;
    background-color: rgba(112, 112, 112, 0.4);
    max-height: 330px;
    overflow: hidden;
    left: 5%; }
  .wholesale-factory__background {
    max-height: 360px; } }

@media (max-width: 768px) {
  .wholesale-factory__menu {
    flex-direction: column; }
  .wholesale-factory__menu .menu-1 {
    padding-bottom: 24px; }
  .wholesale-factory__menu .menu-2 {
    border-left: 0;
    border-right: 0;
    border-top: 1px dashed #041e42;
    border-bottom: 1px dashed #041e42;
    padding: 24px 0; }
  .wholesale-factory__menu .menu-3 {
    padding-top: 24px; }
  .wholesale-factory__menu .menu-1 .title,
  .wholesale-factory__menu .menu-2 .title,
  .wholesale-factory__menu .menu-3 .title {
    margin: 20px 0; } }

@media (max-width: 1280px) {
  .wholesale-factory__content3 .left .content {
    width: auto; } }

@media (max-width: 640px) {
  .wholesale-factory__content3 .left .content .text {
    margin: 24px 0; } }

@media (max-width: 1440px) {
  .wholesale-factory__content4 .content4__item::after {
    display: none; }
  .wholesale-factory__content4 .content4__item:nth-child(2n + 1) .text {
    right: 50%; }
  .wholesale-factory__content4 .content4__item:nth-child(2n) .text {
    left: 50%; }
  .wholesale-factory__content4 .content4__item .images {
    width: 45%; }
  .wholesale-factory__content4 .content4__item .text {
    left: 50% !important;
    top: calc(50% + 24px) !important;
    transform: translateX(-50%);
    width: 90%;
    height: calc(50% - 48px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center; }
  .wholesale-factory__content4 .content4__item .text .subtitle {
    margin: 12px 0; }
  .wholesale-factory__content4 .content4__item .images {
    top: 24px;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    height: calc(50% - 24px); } }

@media (max-width: 425px) {
  .wholesale-factory__content4 {
    flex-direction: column;
    height: auto; }
  .wholesale-factory__content4 .content4__item {
    height: 380px;
    width: 100%; }
    .wholesale-factory__content4 .content4__item:not(:first-child) {
      margin-top: 12px; } }

.mousse-section {
  background-color: #ebebeb;
  padding-bottom: 32px; }
  @media screen and (min-width: 640px) {
    .mousse-section {
      padding-bottom: 40px; } }
  @media screen and (min-width: 1024px) {
    .mousse-section {
      padding-bottom: 64px; } }
  @media screen and (min-width: 1280px) {
    .mousse-section {
      padding-bottom: 64px; } }
  .mousse-section .mousse-type {
    padding: 32px 0; }
    @media screen and (min-width: 640px) {
      .mousse-section .mousse-type {
        padding: 40px 0; } }
    @media screen and (min-width: 1024px) {
      .mousse-section .mousse-type {
        padding: 64px 0; } }
    @media screen and (min-width: 1280px) {
      .mousse-section .mousse-type {
        padding: 64px 0; } }
  .mousse-section .title-mousse-type {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .mousse-section .title-mousse-type .title h3 {
      text-align: center; }
    .mousse-section .title-mousse-type .menu {
      margin-top: 32px;
      display: flex;
      text-align: center;
      justify-content: space-evenly;
      align-self: center;
      width: 100%;
      flex-wrap: wrap; }
      @media screen and (min-width: 640px) {
        .mousse-section .title-mousse-type .menu {
          margin-top: 40px; } }
      @media screen and (min-width: 1024px) {
        .mousse-section .title-mousse-type .menu {
          margin-top: 64px; } }
      @media screen and (min-width: 1280px) {
        .mousse-section .title-mousse-type .menu {
          margin-top: 64px; } }
      .mousse-section .title-mousse-type .menu__item {
        width: 160px;
        height: 100px;
        margin-top: 12px;
        display: flex;
        flex-direction: column;
        align-items: center; }
        @media (max-width: 425px) {
          .mousse-section .title-mousse-type .menu__item {
            height: auto; } }
      .mousse-section .title-mousse-type .menu p {
        color: #041e42;
        margin-top: 12px;
        white-space: nowrap;
        font-weight: 700;
        text-transform: uppercase; }
      .mousse-section .title-mousse-type .menu img {
        width: 54px;
        height: 54px; }
  @media (max-width: 1280px) {
    .mousse-section .title-mattress-type .menu {
      width: 100%;
      justify-content: space-evenly; } }
  @media (max-width: 1024px) {
    .mousse-section .title-mattress-type .menu {
      width: 90%;
      justify-content: space-between; } }
  @media (max-width: 900px) {
    .mousse-section .title-mattress-type .menu {
      width: 100%;
      justify-content: space-between; } }
  @media (max-width: 768px) {
    .mousse-section .title-mattress-type .menu__item {
      width: 50%; } }
  .mousse-section .collection-item {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 80px; }
    @media (max-width: 768px) {
      .mousse-section .collection-item {
        margin-top: 64px; } }
    @media (max-width: 425px) {
      .mousse-section .collection-item {
        margin-top: 32px; } }
    .mousse-section .collection-item:nth-of-type(2n) {
      flex-direction: row-reverse; }
      @media (max-width: 640px) {
        .mousse-section .collection-item:nth-of-type(2n) {
          flex-direction: row; } }
      .mousse-section .collection-item:nth-of-type(2n) .collection-content {
        text-align: left; }
  .mousse-section .collection__left {
    flex: 0 0 50%;
    display: flex;
    justify-content: center;
    align-items: center; }
    @media (max-width: 640px) {
      .mousse-section .collection__left {
        flex: 0 0 100%; } }
    .mousse-section .collection__left > * {
      width: 100%; }
  .mousse-section .collection-right {
    padding: 40px 80px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    @media (max-width: 1336px) {
      .mousse-section .collection-right {
        padding: 76px 60px; } }
    @media (max-width: 1280px) {
      .mousse-section .collection-right {
        padding: 54px 22px; } }
  .mousse-section .collection-content {
    text-align: left;
    font-family: "SVN-AvenirNext", sans-serif; }
    .mousse-section .collection-content__title > * {
      font-family: "SVN-AvenirNext", sans-serif;
      color: #000;
      font-size: 50px;
      font-weight: 500;
      font-weight: 400;
      font-family: "UTM-NEO-Sans-Intel", sans-serif; }
      @media (max-width: 1024px) {
        .mousse-section .collection-content__title > * {
          font-size: 40px; } }
      @media (max-width: 640px) {
        .mousse-section .collection-content__title > * {
          font-size: 32px; } }
      .mousse-section .collection-content__title > * a {
        color: inherit; }
    .mousse-section .collection-content__desc {
      margin-top: 24px; }
      .mousse-section .collection-content__desc > * {
        color: #707070;
        font-weight: 500;
        font-size: 18px; }
        @media screen and (min-width: 1024px) {
          .mousse-section .collection-content__desc > * {
            font-size: 20px; } }
    .mousse-section .collection-content__more {
      margin-top: 32px;
      padding-top: 24px;
      border-top: 1px solid #707070;
      color: #000;
      font-size: 18px;
      font-weight: 500; }
      @media screen and (min-width: 1024px) {
        .mousse-section .collection-content__more {
          font-size: 20px; } }

.mousse-contact {
  margin-bottom: 32px; }
  @media screen and (min-width: 640px) {
    .mousse-contact {
      margin-bottom: 40px; } }
  @media screen and (min-width: 1024px) {
    .mousse-contact {
      margin-bottom: 64px; } }
  @media screen and (min-width: 1280px) {
    .mousse-contact {
      margin-bottom: 64px; } }
  .mousse-contact-wrapper {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-top: 80px; }
    @media screen and (min-width: 640px) {
      .mousse-contact-wrapper {
        padding-top: 100px; } }
    @media screen and (min-width: 1024px) {
      .mousse-contact-wrapper {
        padding-top: 128px; } }
    @media screen and (min-width: 1280px) {
      .mousse-contact-wrapper {
        padding-top: 128px; } }
  .mousse-contact__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    padding-top: 80px; }
    @media screen and (min-width: 640px) {
      .mousse-contact__bg {
        padding-top: 100px; } }
    @media screen and (min-width: 1024px) {
      .mousse-contact__bg {
        padding-top: 128px; } }
    @media screen and (min-width: 1280px) {
      .mousse-contact__bg {
        padding-top: 128px; } }
    .mousse-contact__bg img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .mousse-contact-content {
    flex: 0 0 50%;
    z-index: 2;
    padding: 82px 0 82px 82px; }
    @media screen and (max-width: 767px) {
      .mousse-contact-content {
        flex: 0 0 68%;
        padding: 40px 24px; } }
  .mousse-contact__title > * {
    font-family: "SVN-AvenirNext", sans-serif;
    color: #000;
    font-size: 50px;
    font-weight: 500;
    font-family: "SVN-AvenirNext", sans-serif;
    color: #fff;
    font-weight: 500; }
    @media (max-width: 1024px) {
      .mousse-contact__title > * {
        font-size: 40px; } }
    @media (max-width: 640px) {
      .mousse-contact__title > * {
        font-size: 32px; } }
  .mousse-contact-button {
    margin-top: 30px; }
  .mousse-contact-staff {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 50%;
    height: 100%; }
    @media screen and (max-width: 767px) {
      .mousse-contact-staff {
        width: 40%; } }
    .mousse-contact-staff img {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: bottom; }

.title-purchase-type {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 32px; }
  .title-purchase-type > * {
    text-align: center; }
  @media screen and (min-width: 640px) {
    .title-purchase-type {
      padding-bottom: 40px; } }
  @media screen and (min-width: 1024px) {
    .title-purchase-type {
      padding-bottom: 64px; } }
  @media screen and (min-width: 1280px) {
    .title-purchase-type {
      padding-bottom: 64px; } }

.purchase-section {
  padding-bottom: 32px; }
  @media screen and (min-width: 640px) {
    .purchase-section {
      padding-bottom: 40px; } }
  @media screen and (min-width: 1024px) {
    .purchase-section {
      padding-bottom: 64px; } }
  @media screen and (min-width: 1280px) {
    .purchase-section {
      padding-bottom: 64px; } }
  .purchase-section .purchase-type {
    padding: 32px 0; }
    @media screen and (min-width: 640px) {
      .purchase-section .purchase-type {
        padding: 40px 0; } }
    @media screen and (min-width: 1024px) {
      .purchase-section .purchase-type {
        padding: 64px 0; } }
    @media screen and (min-width: 1280px) {
      .purchase-section .purchase-type {
        padding: 64px 0; } }
  .purchase-section .purchase-products {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: -20px;
    margin-left: -10px;
    margin-right: -10px; }
    .purchase-section .purchase-products > li {
      flex: 0 0 100%;
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 20px; }
      @media screen and (min-width: 768px) {
        .purchase-section .purchase-products > li {
          flex: 0 0 50%; } }
      @media screen and (min-width: 1280px) {
        .purchase-section .purchase-products > li {
          flex: 0 0 33.3333%; } }
    .purchase-section .purchase-products-item {
      height: 100%;
      background-color: #fff;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
      border: 1px solid #e6e6e6;
      border-radius: 0 0 4px 4px;
      display: flex;
      flex-direction: column; }
      .purchase-section .purchase-products-item .thumbnail {
        position: relative;
        width: 100%;
        padding-top: 100%; }
        .purchase-section .purchase-products-item .thumbnail img,
        .purchase-section .purchase-products-item .thumbnail > a {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover; }
        .purchase-section .purchase-products-item .thumbnail-promo {
          z-index: 2;
          position: absolute;
          top: 16px;
          left: 16px;
          padding: 3px 8px;
          color: #fff;
          font-weight: 400;
          font-size: 14px;
          line-height: 150%;
          font-family: "SVN-AvenirNext", sans-serif;
          background-color: #e80826;
          border-radius: 4px; }
          .purchase-section .purchase-products-item .thumbnail-promo span {
            font-weight: 600; }
      .purchase-section .purchase-products-item .content {
        flex: 1 1 auto;
        padding: 24px 16px;
        display: flex;
        flex-direction: column; }
        .purchase-section .purchase-products-item .content-name {
          flex: 1 1 auto; }
          .purchase-section .purchase-products-item .content-name > * {
            font-family: "SVN-AvenirNext", sans-serif;
            font-size: 24px;
            font-weight: 600;
            color: #000; }
            @media screen and (min-width: 640px) {
              .purchase-section .purchase-products-item .content-name > * {
                font-size: 28px; } }
            @media screen and (min-width: 1024px) {
              .purchase-section .purchase-products-item .content-name > * {
                font-size: 32px; } }
        .purchase-section .purchase-products-item .content-rating {
          margin-top: 6px;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          font-size: 12px;
          color: #a32732;
          font-weight: 500;
          font-family: "SVN-AvenirNext", sans-serif; }
          .purchase-section .purchase-products-item .content-rating .icon {
            width: 16px;
            height: 16px; }
            .purchase-section .purchase-products-item .content-rating .icon > * {
              width: 100%;
              max-height: 100%; }
        .purchase-section .purchase-products-item .content-prices {
          margin-top: 12px; }
          .purchase-section .purchase-products-item .content-prices-listed {
            font-family: "SVN-AvenirNext", sans-serif;
            color: #a32732;
            font-size: 20px;
            font-weight: 600; }
            @media screen and (min-width: 1024px) {
              .purchase-section .purchase-products-item .content-prices-listed {
                font-size: 24px; } }
          .purchase-section .purchase-products-item .content-prices-pre {
            margin-top: 4px;
            font-family: "SVN-AvenirNext", sans-serif;
            color: #707070;
            font-size: 14px;
            line-height: 24px;
            text-decoration: line-through; }
        .purchase-section .purchase-products-item .content-desc {
          margin-top: 10px;
          font-family: "SVN-AvenirNext", sans-serif;
          font-size: 14px;
          color: #707070;
          font-size: 500; }

.purchase-service-wrapper {
  position: relative;
  color: #fff;
  padding-top: 32px;
  padding-bottom: 32px;
  background-color: #a32732; }
  @media screen and (min-width: 640px) {
    .purchase-service-wrapper {
      padding-top: 40px; } }
  @media screen and (min-width: 1024px) {
    .purchase-service-wrapper {
      padding-top: 64px; } }
  @media screen and (min-width: 1280px) {
    .purchase-service-wrapper {
      padding-top: 64px; } }
  @media screen and (min-width: 640px) {
    .purchase-service-wrapper {
      padding-bottom: 40px; } }
  @media screen and (min-width: 1024px) {
    .purchase-service-wrapper {
      padding-bottom: 64px; } }
  @media screen and (min-width: 1280px) {
    .purchase-service-wrapper {
      padding-bottom: 64px; } }
  .purchase-service-wrapper .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: 1; }
  .purchase-service-wrapper > * {
    position: relative;
    z-index: 2; }
  .purchase-service-wrapper .title-purchase-type > * {
    color: #fff; }
  .purchase-service-wrapper .services {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-left: 100px;
    padding-right: 100px;
    position: relative; }
    .purchase-service-wrapper .services > li {
      flex: 0 0 100%;
      padding-left: var(--side, 0);
      padding-right: var(--side, 0);
      padding-top: 20px; }
      @media screen and (min-width: 640px) {
        .purchase-service-wrapper .services > li {
          padding-top: 32px; } }
      @media screen and (min-width: 1024px) {
        .purchase-service-wrapper .services > li {
          padding-top: 40px; } }
      @media screen and (min-width: 1280px) {
        .purchase-service-wrapper .services > li {
          padding-top: 40px; } }
      @media screen and (min-width: 768px) {
        .purchase-service-wrapper .services > li {
          flex: 0 0 50%; } }
      @media screen and (min-width: 1280px) {
        .purchase-service-wrapper .services > li {
          flex: 0 0 33.3333%; } }
    .purchase-service-wrapper .services-item {
      text-align: center; }
      .purchase-service-wrapper .services-item .thumbnail {
        width: 48%;
        padding-top: 48%;
        margin: 0 auto;
        position: relative; }
        .purchase-service-wrapper .services-item .thumbnail > * {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: block;
          object-fit: contain; }
      .purchase-service-wrapper .services-item .content {
        margin-top: 20px; }
        .purchase-service-wrapper .services-item .content-name > * {
          font-family: "SVN-AvenirNext", sans-serif;
          font-size: 20px;
          font-weight: 600; }
          @media screen and (min-width: 1024px) {
            .purchase-service-wrapper .services-item .content-name > * {
              font-size: 24px; } }
        .purchase-service-wrapper .services-item .content-desc {
          margin-top: 6px;
          font-family: "SVN-AvenirNext", sans-serif;
          font-size: 14px;
          font-weight: 500; }
    .purchase-service-wrapper .services .swiper-container {
      position: static; }
    .purchase-service-wrapper .services .swiper-button-prev,
    .purchase-service-wrapper .services .swiper-button-next {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border: 1px solid #fff;
      border-radius: 100%;
      background-color: rgba(255, 255, 255, 0.2); }
      .purchase-service-wrapper .services .swiper-button-prev::after,
      .purchase-service-wrapper .services .swiper-button-next::after {
        content: "";
        width: 100%;
        height: 100%;
        background-image: url('data:image/svg+xml,%3Csvg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.09128 7L0 12.4447L1.45436 14L8 7L1.45436 0L0 1.55531L5.09128 7Z" fill="white"/%3E%3C/svg%3E');
        background-repeat: no-repeat;
        background-position: center; }
    .purchase-service-wrapper .services .swiper-button-prev {
      left: 30px;
      transform: rotate(180deg); }
    .purchase-service-wrapper .services .swiper-button-next {
      right: 30px; }

.product-detail-section-wrapper {
  padding-top: 32px;
  padding-bottom: 32px; }
  @media screen and (min-width: 640px) {
    .product-detail-section-wrapper {
      padding-top: 40px; } }
  @media screen and (min-width: 1024px) {
    .product-detail-section-wrapper {
      padding-top: 64px; } }
  @media screen and (min-width: 1280px) {
    .product-detail-section-wrapper {
      padding-top: 64px; } }
  @media screen and (min-width: 640px) {
    .product-detail-section-wrapper {
      padding-bottom: 40px; } }
  @media screen and (min-width: 1024px) {
    .product-detail-section-wrapper {
      padding-bottom: 64px; } }
  @media screen and (min-width: 1280px) {
    .product-detail-section-wrapper {
      padding-bottom: 64px; } }
  .product-detail-section-wrapper .detail-infor__liveview {
    padding: 0 52px 0 0; }
    @media screen and (max-width: 767px) {
      .product-detail-section-wrapper .detail-infor__liveview {
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0 0 30px 0; } }
    .product-detail-section-wrapper .detail-infor__liveview .mySwiper2 {
      margin: 10px 0 10px 0; }
      .product-detail-section-wrapper .detail-infor__liveview .mySwiper2 .swiper-wrapper .swiper-slide {
        cursor: zoom-in; }
        .product-detail-section-wrapper .detail-infor__liveview .mySwiper2 .swiper-wrapper .swiper-slide * {
          width: 100%;
          height: 100%; }
        .product-detail-section-wrapper .detail-infor__liveview .mySwiper2 .swiper-wrapper .swiper-slide.swiper-slide-zoomed {
          cursor: zoom-out; }
        .product-detail-section-wrapper .detail-infor__liveview .mySwiper2 .swiper-wrapper .swiper-slide img {
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); }
    .product-detail-section-wrapper .detail-infor__liveview .mySwiper {
      width: 90%; }
      .product-detail-section-wrapper .detail-infor__liveview .mySwiper .swiper-wrapper .swiper-slide {
        cursor: pointer; }
        .product-detail-section-wrapper .detail-infor__liveview .mySwiper .swiper-wrapper .swiper-slide * {
          border-radius: 5px; }
    .product-detail-section-wrapper .detail-infor__liveview .swiper-btn__nav-detail {
      position: relative; }
      .product-detail-section-wrapper .detail-infor__liveview .swiper-btn__nav-detail .swiper-slide.is-actived {
        border-radius: 5px;
        border: 2px solid #520007; }
      .product-detail-section-wrapper .detail-infor__liveview .swiper-btn__nav-detail .swiper-button-prev,
      .product-detail-section-wrapper .detail-infor__liveview .swiper-btn__nav-detail .swiper-button-next {
        position: absolute;
        top: 50%;
        user-select: none; }
        .product-detail-section-wrapper .detail-infor__liveview .swiper-btn__nav-detail .swiper-button-prev::after,
        .product-detail-section-wrapper .detail-infor__liveview .swiper-btn__nav-detail .swiper-button-next::after {
          display: none; }
      .product-detail-section-wrapper .detail-infor__liveview .swiper-btn__nav-detail .swiper-button-prev {
        left: 0px;
        padding: 0 7px 0 0; }
        @media (max-width: 425px) {
          .product-detail-section-wrapper .detail-infor__liveview .swiper-btn__nav-detail .swiper-button-prev {
            left: 0px; } }
      .product-detail-section-wrapper .detail-infor__liveview .swiper-btn__nav-detail .swiper-button-next {
        right: 0px;
        padding: 0 0 0 7px; }
        @media (max-width: 425px) {
          .product-detail-section-wrapper .detail-infor__liveview .swiper-btn__nav-detail .swiper-button-next {
            right: 0px; } }
  @media screen and (max-width: 767px) {
    .product-detail-section-wrapper .detail-infor__content {
      width: 100%;
      flex: 0 0 100%;
      max-width: 100%; } }
  .product-detail-section-wrapper .detail-infor__content .product-infor__title {
    font-family: "SVN-AvenirNext", sans-serif;
    color: #000;
    font-size: 32px;
    font-weight: 500;
    font-weight: 600;
    margin-bottom: 12px; }
    @media (max-width: 1024px) {
      .product-detail-section-wrapper .detail-infor__content .product-infor__title {
        font-size: 26px; } }
    @media (max-width: 640px) {
      .product-detail-section-wrapper .detail-infor__content .product-infor__title {
        font-size: 22px; } }
  .product-detail-section-wrapper .detail-infor__content .product-infor-item {
    font-family: "SVN-AvenirNext", sans-serif;
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap; }
    .product-detail-section-wrapper .detail-infor__content .product-infor-item .rating {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      font-size: 12px;
      color: #a32732;
      font-weight: 500;
      font-family: "SVN-AvenirNext", sans-serif; }
      .product-detail-section-wrapper .detail-infor__content .product-infor-item .rating .icon {
        width: 16px;
        height: 16px; }
        .product-detail-section-wrapper .detail-infor__content .product-infor-item .rating .icon > * {
          width: 100%;
          max-height: 100%; }
    .product-detail-section-wrapper .detail-infor__content .product-infor-item .reviews {
      margin-left: 16px;
      border-left: 1px solid #d3d3d3;
      padding-left: 16px;
      font-family: "SVN-AvenirNext", sans-serif;
      font-size: 12px;
      font-weight: 500;
      color: #000; }
      .product-detail-section-wrapper .detail-infor__content .product-infor-item .reviews span {
        color: #a32732; }
    .product-detail-section-wrapper .detail-infor__content .product-infor-item__prices .prices-pre {
      margin-top: 4px;
      font-family: "SVN-AvenirNext", sans-serif;
      color: #707070;
      font-size: 14px;
      line-height: 24px;
      text-decoration: line-through; }
    .product-detail-section-wrapper .detail-infor__content .product-infor-item__prices .prices-listed {
      width: 100%;
      font-family: "SVN-AvenirNext", sans-serif;
      color: #a32732;
      font-size: 28px;
      font-weight: 600;
      display: flex;
      align-items: center;
      flex-wrap: wrap; }
      @media screen and (min-width: 640px) {
        .product-detail-section-wrapper .detail-infor__content .product-infor-item__prices .prices-listed {
          font-size: 32px; } }
      @media screen and (min-width: 1024px) {
        .product-detail-section-wrapper .detail-infor__content .product-infor-item__prices .prices-listed {
          font-size: 36px; } }
      .product-detail-section-wrapper .detail-infor__content .product-infor-item__prices .prices-listed P:not([class="promo"]) > *:last-child {
        margin-right: 20px; }
    .product-detail-section-wrapper .detail-infor__content .product-infor-item__prices .promo {
      font-size: 14px;
      line-height: 21px;
      background-color: #e80826;
      color: #fff;
      border-radius: 4px;
      padding: 3px 8px;
      font-weight: 400; }
      .product-detail-section-wrapper .detail-infor__content .product-infor-item__prices .promo span {
        font-weight: 600; }
    .product-detail-section-wrapper .detail-infor__content .product-infor-item__desc {
      margin-top: 30px;
      color: #707070;
      font-size: 16px;
      line-height: 24px;
      font-weight: 500; }
    .product-detail-section-wrapper .detail-infor__content .product-infor-item__name {
      color: #000;
      font-weight: 600;
      font-size: 18px;
      min-width: 132px;
      margin-right: 16px; }
      @media screen and (min-width: 1024px) {
        .product-detail-section-wrapper .detail-infor__content .product-infor-item__name {
          font-size: 20px; } }
      @media screen and (max-width: 1023px) {
        .product-detail-section-wrapper .detail-infor__content .product-infor-item__name {
          display: inline-flex;
          align-items: center; } }
    .product-detail-section-wrapper .detail-infor__content .product-infor-item.credit {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start; }
      .product-detail-section-wrapper .detail-infor__content .product-infor-item.credit .credit-ls {
        flex: 1;
        font-size: 16px;
        font-weight: 500;
        color: #000; }
        .product-detail-section-wrapper .detail-infor__content .product-infor-item.credit .credit-ls > ul {
          list-style-type: none; }
          .product-detail-section-wrapper .detail-infor__content .product-infor-item.credit .credit-ls > ul > li {
            font-size: 16px;
            font-weight: 500;
            color: #000; }
            .product-detail-section-wrapper .detail-infor__content .product-infor-item.credit .credit-ls > ul > li:not(:first-child) {
              display: none; }
      .product-detail-section-wrapper .detail-infor__content .product-infor-item.credit .viewmore {
        display: inline-block;
        color: #a32732;
        text-decoration: underline;
        font-size: 16px;
        font-weight: 500; }
    .product-detail-section-wrapper .detail-infor__content .product-infor-item__selects {
      flex: 0 0 100%;
      margin-top: 2px; }
    .product-detail-section-wrapper .detail-infor__content .product-infor-item.selects {
      margin-top: 24px; }
      .product-detail-section-wrapper .detail-infor__content .product-infor-item.selects .selects-item {
        padding-top: 10px;
        display: flex;
        align-items: center; }
        .product-detail-section-wrapper .detail-infor__content .product-infor-item.selects .selects-item-name {
          width: 132px;
          margin-right: 16px;
          font-size: 18px;
          font-weight: 500;
          color: #000; }
        .product-detail-section-wrapper .detail-infor__content .product-infor-item.selects .selects-item-option {
          flex: 1;
          max-width: 304px;
          position: relative; }
          .product-detail-section-wrapper .detail-infor__content .product-infor-item.selects .selects-item-option select {
            cursor: pointer;
            font-family: "SVN-AvenirNext", sans-serif;
            appearance: none;
            width: 100%;
            border: 1px solid #520007;
            border-radius: 4px;
            background: transparent;
            outline: none;
            font-size: 14px;
            font-weight: 500;
            color: #000;
            padding: 12px 32px 12px 16px; }
          .product-detail-section-wrapper .detail-infor__content .product-infor-item.selects .selects-item-option .arrow {
            position: absolute;
            top: 0;
            right: 16px;
            width: 11px;
            user-select: none;
            pointer-events: none;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center; }
    .product-detail-section-wrapper .detail-infor__content .product-infor-item__features {
      flex: 1;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-top: -10px;
      margin-left: -10px; }
      @media screen and (max-width: 1023px) {
        .product-detail-section-wrapper .detail-infor__content .product-infor-item__features {
          margin-top: 10px;
          flex: 0 0 100%; } }
    .product-detail-section-wrapper .detail-infor__content .product-infor-item.features {
      margin-top: 24px; }
      .product-detail-section-wrapper .detail-infor__content .product-infor-item.features .features-item {
        padding-left: 10px;
        padding-top: 10px;
        min-width: 33.3333%; }
        @media screen and (max-width: 575px) {
          .product-detail-section-wrapper .detail-infor__content .product-infor-item.features .features-item {
            width: 50%; } }
        .product-detail-section-wrapper .detail-infor__content .product-infor-item.features .features-item > label {
          max-width: 100%;
          width: 100%;
          padding: 12px 24px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          font-weight: 500;
          color: #000;
          border: 1px solid #520007;
          border-radius: 4px;
          transition: 0.3s all ease-in-out;
          cursor: pointer;
          position: relative; }
          .product-detail-section-wrapper .detail-infor__content .product-infor-item.features .features-item > label:hover {
            background-color: #a32732;
            color: #fff; }
          @media screen and (max-width: 1023px) {
            .product-detail-section-wrapper .detail-infor__content .product-infor-item.features .features-item > label {
              padding: 12px 17px; } }
        .product-detail-section-wrapper .detail-infor__content .product-infor-item.features .features-item input[type="radio"] {
          display: none; }
          .product-detail-section-wrapper .detail-infor__content .product-infor-item.features .features-item input[type="radio"]:checked ~ label {
            background-color: #a32732;
            color: #fff; }
    .product-detail-section-wrapper .detail-infor__content .product-infor-item.qty {
      margin-top: 24px; }
    .product-detail-section-wrapper .detail-infor__content .product-infor-item__qty {
      display: flex;
      /* Chrome, Safari, Edge, Opera */
      /* Firefox */ }
      .product-detail-section-wrapper .detail-infor__content .product-infor-item__qty .value-button {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "SVN-AvenirNext", sans-serif;
        width: 40px;
        height: 40px;
        font-size: 18px;
        border: 1px solid #e6e6e6;
        border-radius: 2px;
        user-select: none;
        cursor: pointer; }
        @media screen and (min-width: 1024px) {
          .product-detail-section-wrapper .detail-infor__content .product-infor-item__qty .value-button {
            font-size: 20px; } }
      .product-detail-section-wrapper .detail-infor__content .product-infor-item__qty input::-webkit-outer-spin-button,
      .product-detail-section-wrapper .detail-infor__content .product-infor-item__qty input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0; }
      .product-detail-section-wrapper .detail-infor__content .product-infor-item__qty input[type="number"] {
        -moz-appearance: textfield; }
      .product-detail-section-wrapper .detail-infor__content .product-infor-item__qty input {
        appearance: none;
        -webkit-appearance: none;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 56px !important;
        height: 100%;
        color: #000;
        font-size: 18px;
        font-weight: 600;
        border: 1px solid #e6e6e6; }
        @media screen and (min-width: 1024px) {
          .product-detail-section-wrapper .detail-infor__content .product-infor-item__qty input {
            font-size: 20px; } }
    .product-detail-section-wrapper .detail-infor__content .product-infor-item.button {
      margin-top: 20px; }
    .product-detail-section-wrapper .detail-infor__content .product-infor-item__button {
      padding-top: 10px;
      flex: 0 0 50%; }
      .product-detail-section-wrapper .detail-infor__content .product-infor-item__button a {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        padding: 12px 24px;
        text-align: center;
        color: #000;
        font-size: 18px;
        font-weight: 500;
        text-align: center;
        border: 1px solid #520007;
        border-radius: 6px;
        transition: 0.16s all ease-in-out; }
        @media screen and (min-width: 1024px) {
          .product-detail-section-wrapper .detail-infor__content .product-infor-item__button a {
            font-size: 20px; } }
        .product-detail-section-wrapper .detail-infor__content .product-infor-item__button a.buynow {
          color: #fff;
          background-color: #a32732;
          border-color: #a32732; }

.product-description {
  background-color: #f4f4f4;
  padding: 32px 0; }
  @media screen and (min-width: 640px) {
    .product-description {
      padding: 40px 0; } }
  @media screen and (min-width: 1024px) {
    .product-description {
      padding: 64px 0; } }
  @media screen and (min-width: 1280px) {
    .product-description {
      padding: 64px 0; } }
  .product-description-wrapper .title {
    margin-bottom: 24px; }
  .product-description-wrapper .writeux {
    font-family: "SVN-AvenirNext", sans-serif;
    color: #707070;
    font-size: 16px;
    font-weight: 500; }
    .product-description-wrapper .writeux img {
      display: block;
      width: 100%;
      height: auto;
      margin: 0 auto; }

.product-techs {
  padding: 80px 0; }
  @media screen and (min-width: 640px) {
    .product-techs {
      padding: 100px 0; } }
  @media screen and (min-width: 1024px) {
    .product-techs {
      padding: 128px 0; } }
  @media screen and (min-width: 1280px) {
    .product-techs {
      padding: 128px 0; } }
  .product-techs-wrapper .title {
    padding-bottom: 32px; }
    @media screen and (min-width: 640px) {
      .product-techs-wrapper .title {
        padding-bottom: 40px; } }
    @media screen and (min-width: 1024px) {
      .product-techs-wrapper .title {
        padding-bottom: 64px; } }
    @media screen and (min-width: 1280px) {
      .product-techs-wrapper .title {
        padding-bottom: 64px; } }
  .product-techs-wrapper .techs-item {
    align-items: center; }
    @media screen and (max-width: 767px) {
      .product-techs-wrapper .techs-item > .col-6 {
        flex: 0 0 100%;
        max-width: 100%; } }
    .product-techs-wrapper .techs-item:not(:first-child) {
      margin-top: 40px; }
    .product-techs-wrapper .techs-item:nth-of-type(2n) {
      flex-direction: row-reverse; }
      .product-techs-wrapper .techs-item:nth-of-type(2n) .techs-item-content {
        padding-left: 0px;
        padding-right: 0px; }
        @media screen and (min-width: 768px) {
          .product-techs-wrapper .techs-item:nth-of-type(2n) .techs-item-content {
            padding-right: 0px; } }
        @media screen and (min-width: 1280px) {
          .product-techs-wrapper .techs-item:nth-of-type(2n) .techs-item-content {
            padding-right: 60px; } }
    .product-techs-wrapper .techs-item img {
      width: 100%;
      object-fit: cover; }
    .product-techs-wrapper .techs-item-content {
      padding-right: 0;
      padding-left: 0px;
      padding-top: 20px; }
      @media screen and (min-width: 768px) {
        .product-techs-wrapper .techs-item-content {
          padding-top: 0;
          padding-left: 0px; } }
      @media screen and (min-width: 1280px) {
        .product-techs-wrapper .techs-item-content {
          padding-left: 60px; } }
    .product-techs-wrapper .techs-item__name {
      margin-bottom: 20px; }
      .product-techs-wrapper .techs-item__name > * {
        font-family: "UTM-NEO-Sans-Intel", sans-serif;
        font-weight: 700; }

.product-reviews {
  padding-top: 80px; }
  @media screen and (min-width: 640px) {
    .product-reviews {
      padding-top: 100px; } }
  @media screen and (min-width: 1024px) {
    .product-reviews {
      padding-top: 128px; } }
  @media screen and (min-width: 1280px) {
    .product-reviews {
      padding-top: 128px; } }
  .product-reviews-wrapper {
    font-family: "SVN-AvenirNext", sans-serif; }
    .product-reviews-wrapper .wrapper {
      border: 1px solid #e6e6e6;
      border-radius: 8px; }
      .product-reviews-wrapper .wrapper-header {
        display: flex;
        flex-wrap: wrap;
        padding: 6px 16px 16px 16px;
        border-bottom: 1px solid #e6e6e6; }
        @media screen and (max-width: 767px) {
          .product-reviews-wrapper .wrapper-header {
            flex-direction: column; } }
        .product-reviews-wrapper .wrapper-header > * {
          margin-top: 10px; }
        .product-reviews-wrapper .wrapper-header--left {
          flex: 1;
          display: flex; }
        .product-reviews-wrapper .wrapper-header .reviews-btn-write {
          appearance: none;
          background: #a32732;
          color: #fff;
          outline: none;
          border: 1px solid #a32732;
          border-radius: 6px;
          padding: 12px 24px;
          min-width: 305px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 18px;
          font-weight: 500;
          font-family: "SVN-AvenirNext", sans-serif; }
          @media screen and (min-width: 1024px) {
            .product-reviews-wrapper .wrapper-header .reviews-btn-write {
              font-size: 20px; } }
        .product-reviews-wrapper .wrapper-header .reviews-wrapper {
          display: flex;
          align-items: center;
          font-size: 24px;
          font-weight: 600;
          font-family: "SVN-AvenirNext", sans-serif;
          color: #000; }
          @media screen and (min-width: 640px) {
            .product-reviews-wrapper .wrapper-header .reviews-wrapper {
              font-size: 28px; } }
          @media screen and (min-width: 1024px) {
            .product-reviews-wrapper .wrapper-header .reviews-wrapper {
              font-size: 32px; } }
          .product-reviews-wrapper .wrapper-header .reviews-wrapper .icon {
            margin-left: 6px;
            margin-bottom: 2px;
            display: flex;
            align-items: center; }
          .product-reviews-wrapper .wrapper-header .reviews-wrapper--more {
            flex: 1;
            margin-left: 16px;
            margin-right: 16px;
            display: flex;
            align-items: center; }
            .product-reviews-wrapper .wrapper-header .reviews-wrapper--more ul {
              list-style-type: none;
              display: flex;
              flex-wrap: wrap;
              margin-top: -4px; }
              .product-reviews-wrapper .wrapper-header .reviews-wrapper--more ul > li {
                display: block;
                padding-top: 4px; }
                .product-reviews-wrapper .wrapper-header .reviews-wrapper--more ul > li > a {
                  display: inline-flex;
                  justify-content: center;
                  align-items: center;
                  padding: 4px 24px;
                  border: 1px solid #520007;
                  color: #a32732;
                  font-size: 12px;
                  font-weight: 500;
                  border-radius: 4px;
                  transition: 0.3s all ease-in-out; }
                .product-reviews-wrapper .wrapper-header .reviews-wrapper--more ul > li.is-actived > a {
                  background-color: #a32732;
                  border-color: #a32732;
                  color: #fff; }
      .product-reviews-wrapper .wrapper-posts .emptry {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 24px 16px; }
      .product-reviews-wrapper .wrapper-posts .posts-item {
        padding: 24px 16px;
        display: flex;
        align-items: flex-start;
        font-family: "SVN-AvenirNext", sans-serif; }
        .product-reviews-wrapper .wrapper-posts .posts-item:not(:first-child) {
          position: relative; }
          .product-reviews-wrapper .wrapper-posts .posts-item:not(:first-child)::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            border-top: 1px solid #e6e6e6; }
        .product-reviews-wrapper .wrapper-posts .posts-item-avt {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 42px;
          flex: 0 0 42px;
          margin-right: 12px; }
          .product-reviews-wrapper .wrapper-posts .posts-item-avt > * {
            position: relative;
            width: 100%;
            padding-top: 100%;
            border-radius: 100%; }
            .product-reviews-wrapper .wrapper-posts .posts-item-avt > * img {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              object-fit: cover; }
        .product-reviews-wrapper .wrapper-posts .posts-item-content {
          flex: 1; }
          .product-reviews-wrapper .wrapper-posts .posts-item-content .name {
            font-size: 14px;
            font-weight: 500;
            color: #000;
            line-height: 150%; }
          .product-reviews-wrapper .wrapper-posts .posts-item-content .rating {
            margin-top: 4px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            font-size: 12px;
            color: #ff8a00;
            font-weight: 500;
            font-family: "SVN-AvenirNext", sans-serif; }
            .product-reviews-wrapper .wrapper-posts .posts-item-content .rating .icon {
              width: 16px;
              height: 16px; }
              .product-reviews-wrapper .wrapper-posts .posts-item-content .rating .icon > * {
                width: 100%;
                max-height: 100%; }
          .product-reviews-wrapper .wrapper-posts .posts-item-content .time {
            margin-top: 6px;
            font-size: 14px;
            font-weight: 400;
            color: #999999;
            line-height: 150%; }
          .product-reviews-wrapper .wrapper-posts .posts-item-content .posts-product {
            margin-top: 10px;
            display: flex;
            align-items: flex-start; }
            .product-reviews-wrapper .wrapper-posts .posts-item-content .posts-product-thumb {
              width: 60px;
              height: 60px;
              margin-right: 10px; }
              .product-reviews-wrapper .wrapper-posts .posts-item-content .posts-product-thumb > * {
                width: 100%;
                height: 100%;
                object-fit: cover; }
            .product-reviews-wrapper .wrapper-posts .posts-item-content .posts-product-content .product-name {
              margin-bottom: 10px; }
              .product-reviews-wrapper .wrapper-posts .posts-item-content .posts-product-content .product-name > * {
                color: #000;
                font-size: 16px;
                font-weight: 600; }
            .product-reviews-wrapper .wrapper-posts .posts-item-content .posts-product-content .product-features {
              display: flex;
              margin-top: -6px;
              margin-left: -5px;
              margin-right: -5px; }
              @media screen and (max-width: 639px) {
                .product-reviews-wrapper .wrapper-posts .posts-item-content .posts-product-content .product-features {
                  flex-direction: column; } }
              .product-reviews-wrapper .wrapper-posts .posts-item-content .posts-product-content .product-features__item {
                padding-top: 6px;
                padding-left: 5px;
                padding-right: 5px;
                font-size: 12px;
                line-height: 2;
                color: #000;
                font-weight: 600; }
                @media screen and (max-width: 1023px) {
                  .product-reviews-wrapper .wrapper-posts .posts-item-content .posts-product-content .product-features__item {
                    line-height: inherit; } }
                .product-reviews-wrapper .wrapper-posts .posts-item-content .posts-product-content .product-features__item span {
                  font-weight: 500; }
          .product-reviews-wrapper .wrapper-posts .posts-item-content .comment {
            margin-top: 16px;
            max-width: 537px;
            width: 100%; }
            .product-reviews-wrapper .wrapper-posts .posts-item-content .comment .writeux {
              font-family: "SVN-AvenirNext", sans-serif;
              color: #707070;
              font-size: 14px;
              line-height: 150%;
              font-weight: 500; }
            .product-reviews-wrapper .wrapper-posts .posts-item-content .comment-images {
              margin-top: 10px;
              display: flex;
              list-style-type: none; }
              .product-reviews-wrapper .wrapper-posts .posts-item-content .comment-images > li {
                display: flex;
                width: 60px;
                height: 60px;
                margin-left: 4px; }
                .product-reviews-wrapper .wrapper-posts .posts-item-content .comment-images > li img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover; }
      .product-reviews-wrapper .wrapper-pagination .pagination > ul {
        padding: 10px 16px;
        border-top: 1px solid #e6e6e6; }

.product-related {
  padding-top: 80px;
  padding-bottom: 0px !important; }
  @media screen and (min-width: 640px) {
    .product-related {
      padding-top: 100px; } }
  @media screen and (min-width: 1024px) {
    .product-related {
      padding-top: 128px; } }
  @media screen and (min-width: 1280px) {
    .product-related {
      padding-top: 128px; } }
  .product-related-wrapper .title {
    text-align: center;
    padding-bottom: 32px; }
    @media screen and (min-width: 640px) {
      .product-related-wrapper .title {
        padding-bottom: 40px; } }
    @media screen and (min-width: 1024px) {
      .product-related-wrapper .title {
        padding-bottom: 64px; } }
    @media screen and (min-width: 1280px) {
      .product-related-wrapper .title {
        padding-bottom: 64px; } }
  .product-related-more {
    margin-top: 20px;
    text-align: center; }
    @media screen and (min-width: 640px) {
      .product-related-more {
        margin-top: 32px; } }
    @media screen and (min-width: 1024px) {
      .product-related-more {
        margin-top: 40px; } }
    @media screen and (min-width: 1280px) {
      .product-related-more {
        margin-top: 40px; } }
    .product-related-more a {
      font-family: "SVN-AvenirNext", sans-serif;
      padding: 12px 24px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      min-width: 305px;
      background-color: #a32732;
      color: #fff;
      border-radius: 6px;
      font-size: 18px;
      font-weight: 500; }
      @media screen and (min-width: 1024px) {
        .product-related-more a {
          font-size: 20px; } }

.popup-notify {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2001;
  width: 100%;
  height: 100%;
  background-color: #55555588;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
  opacity: 0;
  visibility: hidden; }

@keyframes showedPopup {
  100% {
    opacity: 1;
    visibility: visible; } }
  .popup-notify.is-showed {
    display: flex;
    animation: showedPopup 0.3s ease-in-out forwards; }
  .popup-notify .modal {
    position: relative;
    width: 540px;
    max-width: 96%;
    max-height: 94%;
    overflow-y: auto;
    padding: 24px;
    background-color: #fff;
    border-radius: 6px;
    border: 1px solid #e6e6e6;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2); }
    @media screen and (min-width: 1024px) {
      .popup-notify .modal {
        width: 720px; } }
    .popup-notify .modal-wrapper .name {
      margin-bottom: 20px; }
      .popup-notify .modal-wrapper .name > * {
        font-family: "SVN-AvenirNext", sans-serif;
        font-size: 16px;
        font-weight: 500;
        color: #000; }
        @media screen and (min-width: 1024px) {
          .popup-notify .modal-wrapper .name > * {
            font-size: 24px; } }
    .popup-notify .modal-wrapper .desc {
      margin-bottom: 20px;
      font-size: 14px;
      font-weight: 400; }
      @media screen and (min-width: 1024px) {
        .popup-notify .modal-wrapper .desc {
          font-size: 20px; } }
    .popup-notify .modal-wrapper .button {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin: -8px -10px 0 -10px; }
      .popup-notify .modal-wrapper .button a,
      .popup-notify .modal-wrapper .button button {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        color: #007aff;
        font-weight: 500;
        text-transform: uppercase;
        padding: 8px 10px 0px 10px; }
        @media screen and (min-width: 1024px) {
          .popup-notify .modal-wrapper .button a,
          .popup-notify .modal-wrapper .button button {
            font-size: 20px; } }

.popup-reviews {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  width: 100%;
  height: 100%;
  background-color: #55555588;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
  opacity: 0;
  visibility: hidden; }

@keyframes showedPopup {
  100% {
    opacity: 1;
    visibility: visible; } }
  .popup-reviews.is-showed {
    display: flex;
    animation: showedPopup 0.3s ease-in-out forwards; }
  .popup-reviews .modal {
    position: relative;
    width: 930px;
    max-width: 96%;
    max-height: 94%;
    overflow-y: auto;
    padding: 24px;
    background-color: #fff;
    border-radius: 6px;
    border: 1px solid #e6e6e6;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2); }
    .popup-reviews .modal-close {
      position: absolute;
      top: 24px;
      right: 20px;
      width: 32px;
      height: 32px;
      border: 1px solid #a32732;
      border-radius: 100%;
      z-index: 1;
      font-size: 0px;
      color: transparent;
      transition: 0.3s all ease-in-out;
      cursor: pointer; }
      .popup-reviews .modal-close::after, .popup-reviews .modal-close::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 52%;
        height: 0;
        border-top: 2px solid #a32732;
        border-radius: 2px;
        user-select: none;
        pointer-events: none;
        transition: 0.3s all ease-in-out; }
      .popup-reviews .modal-close::before {
        transform: translate(-50%, -50%) rotate(45deg); }
      .popup-reviews .modal-close::after {
        transform: translate(-50%, -50%) rotate(-45deg); }
      .popup-reviews .modal-close:hover {
        background-color: #520007;
        border-color: #520007; }
        .popup-reviews .modal-close:hover::after, .popup-reviews .modal-close:hover::before {
          border-color: #fff; }
    .popup-reviews .modal-wrapper .name {
      padding-left: 24px;
      padding-bottom: 20px;
      border-bottom: 1px solid #e6e6e6; }
      .popup-reviews .modal-wrapper .name > * {
        font-family: "SVN-AvenirNext", sans-serif;
        font-size: 24px;
        font-weight: 500;
        color: #000; }
        @media screen and (min-width: 640px) {
          .popup-reviews .modal-wrapper .name > * {
            font-size: 28px; } }
        @media screen and (min-width: 1024px) {
          .popup-reviews .modal-wrapper .name > * {
            font-size: 32px; } }
    .popup-reviews .modal-wrapper-form {
      margin-top: 16px; }
      .popup-reviews .modal-wrapper-form .posts-product {
        display: flex;
        align-items: stretch;
        width: 100%; }
        @media screen and (max-width: 767px) {
          .popup-reviews .modal-wrapper-form .posts-product {
            flex-wrap: wrap;
            flex-direction: column; } }
        .popup-reviews .modal-wrapper-form .posts-product-thumb {
          position: relative;
          width: 102px;
          height: 102px;
          margin-right: 16px; }
          @media screen and (max-width: 639px) {
            .popup-reviews .modal-wrapper-form .posts-product-thumb {
              width: 100%;
              max-width: 100%;
              height: 0;
              padding-top: 100%;
              margin-bottom: 20px;
              margin-right: 0; } }
          .popup-reviews .modal-wrapper-form .posts-product-thumb > * {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover; }
        .popup-reviews .modal-wrapper-form .posts-product-content {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          overflow: hidden; }
          .popup-reviews .modal-wrapper-form .posts-product-content .product-name {
            margin-bottom: 10px; }
            .popup-reviews .modal-wrapper-form .posts-product-content .product-name > * {
              color: #000;
              font-size: 30px;
              font-weight: 600; }
          .popup-reviews .modal-wrapper-form .posts-product-content .product-features {
            display: flex;
            flex-wrap: wrap;
            margin-top: -10px;
            margin-left: -40px;
            margin-right: -40px; }
            @media screen and (max-width: 1023px) {
              .popup-reviews .modal-wrapper-form .posts-product-content .product-features {
                flex-direction: column; } }
            .popup-reviews .modal-wrapper-form .posts-product-content .product-features__item {
              display: flex;
              flex-wrap: wrap;
              padding-top: 10px;
              padding-left: 40px;
              padding-right: 40px;
              font-size: 16px;
              line-height: 2;
              color: #000;
              font-weight: 600; }
              .popup-reviews .modal-wrapper-form .posts-product-content .product-features__item > * {
                display: flex;
                align-items: center; }
                @media screen and (max-width: 1023px) {
                  .popup-reviews .modal-wrapper-form .posts-product-content .product-features__item > * {
                    min-width: 140px; } }
                .popup-reviews .modal-wrapper-form .posts-product-content .product-features__item > *:not(:last-child) {
                  margin-right: 16px; }
              .popup-reviews .modal-wrapper-form .posts-product-content .product-features__item span {
                font-weight: 500; }
              .popup-reviews .modal-wrapper-form .posts-product-content .product-features__item .selects-item-option {
                position: relative; }
                @media screen and (max-width: 1023px) {
                  .popup-reviews .modal-wrapper-form .posts-product-content .product-features__item .selects-item-option {
                    flex: 1; } }
                .popup-reviews .modal-wrapper-form .posts-product-content .product-features__item .selects-item-option .arrow {
                  position: absolute;
                  top: 0;
                  right: 16px;
                  width: 11px;
                  user-select: none;
                  pointer-events: none;
                  height: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center; }
                .popup-reviews .modal-wrapper-form .posts-product-content .product-features__item .selects-item-option select {
                  cursor: pointer;
                  font-family: "SVN-AvenirNext", sans-serif;
                  appearance: none;
                  width: 100%;
                  border: 1px solid #520007;
                  border-radius: 4px;
                  background: transparent;
                  outline: none;
                  font-size: 14px;
                  font-weight: 500;
                  color: #000;
                  padding: 1px 32px 1px 16px; }
      .popup-reviews .modal-wrapper-form .comment {
        margin-top: 24px; }
        .popup-reviews .modal-wrapper-form .comment textarea {
          appearance: none;
          background: transparent;
          outline: none;
          border: none;
          padding: 22px 24px;
          border: 1px solid #520007;
          resize: none;
          width: 100%;
          font-family: "SVN-AvenirNext", sans-serif;
          font-size: 14px;
          font-weight: 500;
          color: #000; }
          .popup-reviews .modal-wrapper-form .comment textarea::placeholder {
            color: #707070;
            font-weight: 500;
            font-style: normal; }
      .popup-reviews .modal-wrapper-form .upload {
        margin-top: 16px; }
        .popup-reviews .modal-wrapper-form .upload input {
          display: none; }
        .popup-reviews .modal-wrapper-form .upload label {
          cursor: pointer;
          padding: 4px 20px;
          margin-bottom: 16px;
          border: 1px solid #520007;
          border-radius: 4px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          font-family: "SVN-AvenirNext", sans-serif;
          font-size: 14px;
          color: #520007;
          font-weight: 500;
          transition: 0.16s all ease-in-out; }
          .popup-reviews .modal-wrapper-form .upload label .icon,
          .popup-reviews .modal-wrapper-form .upload label svg {
            width: 24px;
            height: 24px;
            margin-right: 14px; }
            .popup-reviews .modal-wrapper-form .upload label .icon *,
            .popup-reviews .modal-wrapper-form .upload label svg * {
              transition: 0.16s all ease-in-out; }
          .popup-reviews .modal-wrapper-form .upload label:hover {
            background-color: #520007;
            color: #fff; }
            .popup-reviews .modal-wrapper-form .upload label:hover svg [stroke]:not([stroke="none"]) {
              stroke: #fff; }
            .popup-reviews .modal-wrapper-form .upload label:hover svg [fill]:not([fill="none"]) {
              fill: #fff; }
        .popup-reviews .modal-wrapper-form .upload__img-wrap {
          display: flex;
          flex-wrap: wrap;
          margin: 0 -10px; }
          @media screen and (max-width: 767px) {
            .popup-reviews .modal-wrapper-form .upload__img-wrap {
              margin: 0 -4px; } }
        .popup-reviews .modal-wrapper-form .upload__img-box {
          width: 14.28%;
          padding: 0 10px;
          margin-bottom: 16px; }
          @media screen and (max-width: 767px) {
            .popup-reviews .modal-wrapper-form .upload__img-box {
              padding: 0 4px; } }
        .popup-reviews .modal-wrapper-form .upload__img-close {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          background-color: rgba(0, 0, 0, 0.5);
          position: absolute;
          top: 10px;
          right: 10px;
          text-align: center;
          line-height: 24px;
          z-index: 1;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center; }
          .popup-reviews .modal-wrapper-form .upload__img-close:after {
            content: "\2716";
            font-size: 14px;
            color: white; }
        .popup-reviews .modal-wrapper-form .upload .img-bg {
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          position: relative;
          padding-bottom: 100%; }
      .popup-reviews .modal-wrapper-form .infor {
        margin-top: 8px; }
      .popup-reviews .modal-wrapper-form .group-input {
        display: flex;
        flex-direction: column; }
        .popup-reviews .modal-wrapper-form .group-input label {
          font-size: 16px;
          font-weight: 600;
          color: #000; }
        .popup-reviews .modal-wrapper-form .group-input input {
          margin-top: 6px;
          appearance: none;
          background: transparent;
          outline: none;
          border: none;
          border-bottom: 1px solid #000;
          font-family: "SVN-AvenirNext", sans-serif;
          font-size: 14px;
          font-weight: 500;
          color: #000; }
          .popup-reviews .modal-wrapper-form .group-input input::placeholder {
            color: #707070;
            font-weight: 500;
            font-style: normal; }
      .popup-reviews .modal-wrapper-form .submit {
        margin-top: 30px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap; }
        .popup-reviews .modal-wrapper-form .submit button[type="submit"],
        .popup-reviews .modal-wrapper-form .submit input[type="submit"] {
          margin-top: 10px;
          min-width: 305px;
          appearance: none;
          background: transparent;
          outline: none;
          border: none;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          padding: 12px 24px;
          border-radius: 6px;
          color: #fff;
          background-color: #a32732;
          transition: 0.3s all ease-in-out;
          font-family: "SVN-AvenirNext", sans-serif;
          font-size: 18px;
          font-weight: 500; }
          @media screen and (min-width: 1024px) {
            .popup-reviews .modal-wrapper-form .submit button[type="submit"],
            .popup-reviews .modal-wrapper-form .submit input[type="submit"] {
              font-size: 20px; } }
          .popup-reviews .modal-wrapper-form .submit button[type="submit"]:hover,
          .popup-reviews .modal-wrapper-form .submit input[type="submit"]:hover {
            background-color: #520007; }
    .popup-reviews .modal-wrapper-ratings {
      margin-top: 16px;
      padding-top: 26px;
      border-top: 1px solid #e6e6e6;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      font-size: 16px;
      font-weight: 600;
      color: #000; }
      .popup-reviews .modal-wrapper-ratings .rating {
        margin-left: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        flex-direction: row-reverse;
        position: relative; }
        @media screen and (max-width: 1023px) {
          .popup-reviews .modal-wrapper-ratings .rating {
            flex: 0 0 100%;
            justify-content: flex-end;
            margin-top: 10px;
            margin-left: 0; } }
        .popup-reviews .modal-wrapper-ratings .rating-0 {
          filter: grayscale(100%); }
        .popup-reviews .modal-wrapper-ratings .rating > input {
          display: none; }
          .popup-reviews .modal-wrapper-ratings .rating > input:checked ~ label,
          .popup-reviews .modal-wrapper-ratings .rating > input:checked ~ label ~ label {
            background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23A32732' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e"); }
          .popup-reviews .modal-wrapper-ratings .rating > input:not(:checked) ~ label:hover,
          .popup-reviews .modal-wrapper-ratings .rating > input:not(:checked) ~ label:hover ~ label {
            background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23520007' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e"); }
        .popup-reviews .modal-wrapper-ratings .rating > label {
          cursor: pointer;
          width: 26px;
          height: 26px;
          background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23e3e3e3' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e");
          background-repeat: no-repeat;
          background-position: center;
          background-size: 76%;
          transition: 0.3s;
          margin-right: 16px;
          order: 3; }
          @media screen and (max-width: 1023px) {
            .popup-reviews .modal-wrapper-ratings .rating > label {
              margin-right: 8px; } }
        .popup-reviews .modal-wrapper-ratings .rating #rating-5:checked ~ .emoji-wrapper .emoji .rating-5,
        .popup-reviews .modal-wrapper-ratings .rating #rating-4:checked ~ .emoji-wrapper .emoji .rating-4,
        .popup-reviews .modal-wrapper-ratings .rating #rating-3:checked ~ .emoji-wrapper .emoji .rating-3,
        .popup-reviews .modal-wrapper-ratings .rating #rating-2:checked ~ .emoji-wrapper .emoji .rating-2,
        .popup-reviews .modal-wrapper-ratings .rating #rating-1:checked ~ .emoji-wrapper .emoji .rating-1 {
          display: block;
          opacity: 1;
          visibility: visible; }
        .popup-reviews .modal-wrapper-ratings .rating .emoji-wrapper {
          order: 1; }
          .popup-reviews .modal-wrapper-ratings .rating .emoji-wrapper .emoji {
            display: flex;
            align-items: center;
            transition: 0.3s;
            font-size: 14px;
            font-weight: 500;
            font-family: "SVN-AvenirNext", sans-serif;
            color: #a32732; }
            .popup-reviews .modal-wrapper-ratings .rating .emoji-wrapper .emoji [class*="rating-"] {
              white-space: wrap;
              display: none;
              opacity: 0;
              visibility: hidden;
              transition: 0.3s all ease-in-out; }
              .popup-reviews .modal-wrapper-ratings .rating .emoji-wrapper .emoji [class*="rating-"].is-checked {
                display: block;
                opacity: 1;
                visibility: visible; }

.excerpt-hidden {
  height: 20px;
  overflow: hidden;
  position: relative; }
  .excerpt-hidden:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 64px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 80%, rgba(255, 255, 255, 0.4) 100%); }

.excerpt-visible {
  height: auto;
  overflow: auto; }

p.infor-error {
  font-size: 12px;
  color: #e80826;
  margin-top: 4px; }

.cart-section-wrapper {
  padding-top: 32px; }
  @media screen and (min-width: 640px) {
    .cart-section-wrapper {
      padding-top: 40px; } }
  @media screen and (min-width: 1024px) {
    .cart-section-wrapper {
      padding-top: 64px; } }
  @media screen and (min-width: 1280px) {
    .cart-section-wrapper {
      padding-top: 64px; } }
  .cart-section-wrapper .cart-form {
    margin-top: 24px;
    border: 2px solid #e6e6e6;
    border-radius: 6px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2); }
    .cart-section-wrapper .cart-form table {
      width: 100%;
      border-collapse: collapse; }
      .cart-section-wrapper .cart-form table thead > tr {
        border-bottom: 1px solid #e6e6e6; }
        .cart-section-wrapper .cart-form table thead > tr > th {
          font-size: 16px;
          font-weight: 600;
          text-transform: uppercase;
          color: #707070;
          padding-top: 10px;
          padding-bottom: 10px; }
          .cart-section-wrapper .cart-form table thead > tr > th:first-child {
            width: 50%;
            padding-left: 16px; }
          .cart-section-wrapper .cart-form table thead > tr > th:last-child {
            padding-right: 16px; }
      .cart-section-wrapper .cart-form table tbody > tr > td {
        padding: 24px 0; }
        .cart-section-wrapper .cart-form table tbody > tr > td:first-child {
          padding-left: 16px; }
        .cart-section-wrapper .cart-form table tbody > tr > td:last-child {
          padding-right: 16px; }
        .cart-section-wrapper .cart-form table tbody > tr > td .posts-product {
          display: flex;
          align-items: stretch;
          flex-wrap: wrap;
          width: 100%; }
          .cart-section-wrapper .cart-form table tbody > tr > td .posts-product-thumb {
            position: relative;
            width: 102px;
            height: 102px;
            margin-right: 16px; }
            @media screen and (max-width: 639px) {
              .cart-section-wrapper .cart-form table tbody > tr > td .posts-product-thumb {
                width: 100%;
                max-width: 100%;
                height: 0;
                padding-top: 100%;
                margin-bottom: 20px;
                margin-right: 0; } }
            .cart-section-wrapper .cart-form table tbody > tr > td .posts-product-thumb > * {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              object-fit: cover; }
          .cart-section-wrapper .cart-form table tbody > tr > td .posts-product-content {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            overflow: hidden;
            flex-wrap: wrap; }
            @media screen and (max-width: 639px) {
              .cart-section-wrapper .cart-form table tbody > tr > td .posts-product-content {
                flex: 0 0 100%; } }
            .cart-section-wrapper .cart-form table tbody > tr > td .posts-product-content .product-name > * {
              color: #000;
              font-size: 30px;
              font-weight: 600; }
            .cart-section-wrapper .cart-form table tbody > tr > td .posts-product-content .product-features {
              margin-top: 16px;
              display: flex;
              flex-direction: column; }
              .cart-section-wrapper .cart-form table tbody > tr > td .posts-product-content .product-features__item {
                display: flex;
                flex-wrap: wrap;
                font-size: 16px;
                color: #000;
                font-weight: 600; }
                .cart-section-wrapper .cart-form table tbody > tr > td .posts-product-content .product-features__item:not(:first-child) {
                  margin-top: 10px; }
                .cart-section-wrapper .cart-form table tbody > tr > td .posts-product-content .product-features__item .options {
                  display: flex;
                  flex-direction: column; }
                .cart-section-wrapper .cart-form table tbody > tr > td .posts-product-content .product-features__item span {
                  font-weight: 500; }
                .cart-section-wrapper .cart-form table tbody > tr > td .posts-product-content .product-features__item select {
                  appearance: none;
                  background: transparent;
                  outline: none;
                  margin-left: 16px;
                  border: 1px solid #520007;
                  border-radius: 4px;
                  font-size: 14px;
                  font-weight: 500;
                  color: #000;
                  padding: 1px 16px; }
        .cart-section-wrapper .cart-form table tbody > tr > td .prices {
          font-size: 18px;
          font-weight: 600;
          color: #000; }
          @media screen and (min-width: 1024px) {
            .cart-section-wrapper .cart-form table tbody > tr > td .prices {
              font-size: 20px; } }
          .cart-section-wrapper .cart-form table tbody > tr > td .prices-total {
            font-size: 18px;
            font-weight: 600;
            color: #a32732; }
            @media screen and (min-width: 1024px) {
              .cart-section-wrapper .cart-form table tbody > tr > td .prices-total {
                font-size: 20px; } }
        .cart-section-wrapper .cart-form table tbody > tr > td .qty {
          display: flex;
          /* Chrome, Safari, Edge, Opera */
          /* Firefox */ }
          @media screen and (max-width: 1023px) {
            .cart-section-wrapper .cart-form table tbody > tr > td .qty {
              width: 100%; } }
          .cart-section-wrapper .cart-form table tbody > tr > td .qty .value-button {
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: "SVN-AvenirNext", sans-serif;
            width: 40px;
            height: 40px;
            font-size: 18px;
            border: 1px solid #e6e6e6;
            border-radius: 2px;
            user-select: none;
            cursor: pointer; }
            @media screen and (min-width: 1024px) {
              .cart-section-wrapper .cart-form table tbody > tr > td .qty .value-button {
                font-size: 20px; } }
          .cart-section-wrapper .cart-form table tbody > tr > td .qty input::-webkit-outer-spin-button,
          .cart-section-wrapper .cart-form table tbody > tr > td .qty input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0; }
          .cart-section-wrapper .cart-form table tbody > tr > td .qty input[type="number"] {
            -moz-appearance: textfield; }
          .cart-section-wrapper .cart-form table tbody > tr > td .qty input {
            appearance: none;
            -webkit-appearance: none;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            width: 56px;
            height: 100%;
            color: #000;
            font-size: 18px;
            font-weight: 600;
            border: 1px solid #e6e6e6; }
            @media screen and (min-width: 1024px) {
              .cart-section-wrapper .cart-form table tbody > tr > td .qty input {
                font-size: 20px; } }
        .cart-section-wrapper .cart-form table tbody > tr > td .remove {
          font-size: 0px;
          color: transparent;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          width: 24px;
          height: 24px;
          background-color: transparent;
          border: 1px solid #cccccc;
          border-radius: 100%;
          position: relative;
          transition: 0.3s all ease-in-out;
          cursor: pointer; }
          .cart-section-wrapper .cart-form table tbody > tr > td .remove::after, .cart-section-wrapper .cart-form table tbody > tr > td .remove::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 52%;
            height: 0;
            border-top: 2px solid #666666;
            border-radius: 2px;
            user-select: none;
            pointer-events: none;
            transition: 0.3s all ease-in-out; }
          .cart-section-wrapper .cart-form table tbody > tr > td .remove::before {
            transform: translate(-50%, -50%) rotate(45deg); }
          .cart-section-wrapper .cart-form table tbody > tr > td .remove::after {
            transform: translate(-50%, -50%) rotate(-45deg); }
          .cart-section-wrapper .cart-form table tbody > tr > td .remove:hover {
            background-color: #520007;
            border-color: #520007; }
            .cart-section-wrapper .cart-form table tbody > tr > td .remove:hover::after, .cart-section-wrapper .cart-form table tbody > tr > td .remove:hover::before {
              border-color: #fff;
              color: #fff; }
          @media screen and (max-width: 1023px) {
            .cart-section-wrapper .cart-form table tbody > tr > td .remove {
              width: auto;
              min-width: 136px;
              border-radius: 0;
              height: 48px;
              padding: 12px 24px;
              cursor: pointer; }
              .cart-section-wrapper .cart-form table tbody > tr > td .remove::after {
                content: none; }
              .cart-section-wrapper .cart-form table tbody > tr > td .remove::before {
                content: attr(data-text);
                position: relative;
                top: 0;
                left: 0;
                border: none;
                font-size: 18px;
                width: 100%;
                height: 100%;
                color: #000;
                display: flex;
                justify-content: center;
                align-items: center;
                transform: none; }
              .cart-section-wrapper .cart-form table tbody > tr > td .remove:hover::before {
                color: #fff; } }
          @media screen and (max-width: 639px) {
            .cart-section-wrapper .cart-form table tbody > tr > td .remove {
              width: 100%; } }
      .cart-section-wrapper .cart-form table tbody > tr:not(:first-child) {
        border-top: 1px solid #e6e6e6; }
      @media screen and (max-width: 1023px) {
        .cart-section-wrapper .cart-form table thead,
        .cart-section-wrapper .cart-form table thead > tr,
        .cart-section-wrapper .cart-form table thead > tr > td {
          display: none; }
        .cart-section-wrapper .cart-form table tbody,
        .cart-section-wrapper .cart-form table tbody > tr,
        .cart-section-wrapper .cart-form table tbody > tr > td {
          display: block;
          text-align: left !important; }
        .cart-section-wrapper .cart-form table tbody > tr {
          position: relative;
          border-width: 2px;
          padding: 20px 20px 20px 20px; }
        .cart-section-wrapper .cart-form table tbody > tr > td {
          position: relative;
          display: flex;
          align-items: center;
          white-space: normal;
          padding: 8px 0px !important; }
          .cart-section-wrapper .cart-form table tbody > tr > td:not(:first-child) {
            padding-left: 118px !important; }
          .cart-section-wrapper .cart-form table tbody > tr > td:nth-last-child(2) {
            display: none; } }
      @media screen and (max-width: 639px) {
        .cart-section-wrapper .cart-form table tbody > tr > td:not(:first-child) {
          padding-left: 0px !important; } }
  .cart-section-wrapper .cart-bill {
    margin-top: 12px;
    padding: 24px 16px 24px 50%;
    background-color: whitesmoke;
    border: 1px solid #e6e6e6;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2); }
    @media screen and (max-width: 1023px) {
      .cart-section-wrapper .cart-bill {
        padding-left: 20px;
        padding-right: 20px; } }
    .cart-section-wrapper .cart-bill-item {
      display: flex;
      align-items: center;
      font-size: 18px;
      font-weight: 600;
      color: #000; }
      @media screen and (min-width: 1024px) {
        .cart-section-wrapper .cart-bill-item {
          font-size: 20px; } }
      @media screen and (max-width: 639px) {
        .cart-section-wrapper .cart-bill-item {
          flex-direction: column;
          align-items: flex-start; }
          .cart-section-wrapper .cart-bill-item > *:not(:first-child) {
            margin-top: 10px; } }
      .cart-section-wrapper .cart-bill-item .group-input-code {
        display: flex;
        flex-direction: column; }
        @media screen and (max-width: 639px) {
          .cart-section-wrapper .cart-bill-item .group-input-code {
            width: 100%; }
            .cart-section-wrapper .cart-bill-item .group-input-code form {
              width: 100%; } }
        .cart-section-wrapper .cart-bill-item .group-input-code .infor-text {
          display: block;
          margin-top: 4px;
          width: 100%;
          text-align: right;
          font-size: 14px;
          font-weight: 500;
          color: #000; }
          @media screen and (max-width: 639px) {
            .cart-section-wrapper .cart-bill-item .group-input-code .infor-text {
              text-align: left; } }
        .cart-section-wrapper .cart-bill-item .group-input-code .error-text {
          display: block;
          margin-top: 4px;
          width: 100%;
          text-align: right;
          font-size: 14px;
          font-weight: 500;
          color: #e80826; }
          @media screen and (max-width: 639px) {
            .cart-section-wrapper .cart-bill-item .group-input-code .error-text {
              text-align: left; } }
        .cart-section-wrapper .cart-bill-item .group-input-code .code-submit {
          display: flex;
          flex-direction: row-reverse;
          flex-wrap: wrap; }
          @media screen and (max-width: 639px) {
            .cart-section-wrapper .cart-bill-item .group-input-code .code-submit {
              flex-direction: column; } }
          .cart-section-wrapper .cart-bill-item .group-input-code .code-submit button {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding: 6px 8px;
            height: 32px;
            appearance: none;
            background: transparent;
            outline: none;
            border: 1px solid #520007;
            font-size: 14px;
            font-weight: 500;
            color: #fff;
            background-color: #a32732;
            transition: 0.2s all ease-in-out; }
            .cart-section-wrapper .cart-bill-item .group-input-code .code-submit button:hover {
              background-color: #520007; }
            .cart-section-wrapper .cart-bill-item .group-input-code .code-submit button:disabled {
              opacity: 0.7; }
              .cart-section-wrapper .cart-bill-item .group-input-code .code-submit button:disabled:hover {
                background-color: #a32732; }
            @media screen and (max-width: 639px) {
              .cart-section-wrapper .cart-bill-item .group-input-code .code-submit button {
                width: 100%; } }
          .cart-section-wrapper .cart-bill-item .group-input-code .code-submit input {
            appearance: none;
            outline: none;
            border: 1px solid #520007;
            width: 156px;
            max-width: 100%;
            height: 32px;
            padding: 6px 8px;
            display: flex;
            align-items: center;
            font-size: 14px;
            font-weight: 500;
            color: #000;
            background: transparent; }
            .cart-section-wrapper .cart-bill-item .group-input-code .code-submit input:disabled {
              opacity: 0.7; }
      .cart-section-wrapper .cart-bill-item.input-code {
        align-items: flex-start; }
      .cart-section-wrapper .cart-bill-item.total > *:last-child {
        color: #a32732;
        font-size: 30px; }
      .cart-section-wrapper .cart-bill-item:not(:first-child) {
        margin-top: 16px; }
        @media screen and (max-width: 639px) {
          .cart-section-wrapper .cart-bill-item:not(:first-child) {
            margin-top: 20px; } }
      .cart-section-wrapper .cart-bill-item > *:first-child {
        flex: 1; }
      .cart-section-wrapper .cart-bill-item > * {
        display: flex;
        align-items: center; }
    .cart-section-wrapper .cart-bill-submit {
      padding-top: 24px;
      margin-top: 16px;
      border-top: 1px solid #e6e6e6;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end; }
      .cart-section-wrapper .cart-bill-submit button,
      .cart-section-wrapper .cart-bill-submit input[type="submit"] {
        min-width: 269px;
        appearance: none;
        background: transparent;
        outline: none;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 12px 24px;
        border-radius: 6px;
        color: #fff;
        background-color: #a32732;
        transition: 0.3s all ease-in-out;
        font-family: "SVN-AvenirNext", sans-serif;
        font-size: 18px;
        font-weight: 500; }
        @media screen and (max-width: 639px) {
          .cart-section-wrapper .cart-bill-submit button,
          .cart-section-wrapper .cart-bill-submit input[type="submit"] {
            width: 100%; } }
        @media screen and (min-width: 1024px) {
          .cart-section-wrapper .cart-bill-submit button,
          .cart-section-wrapper .cart-bill-submit input[type="submit"] {
            font-size: 20px; } }
        .cart-section-wrapper .cart-bill-submit button:hover,
        .cart-section-wrapper .cart-bill-submit input[type="submit"]:hover {
          background-color: #520007; }

.payment-section-wrapper {
  padding-top: 32px; }
  @media screen and (min-width: 640px) {
    .payment-section-wrapper {
      padding-top: 40px; } }
  @media screen and (min-width: 1024px) {
    .payment-section-wrapper {
      padding-top: 64px; } }
  @media screen and (min-width: 1280px) {
    .payment-section-wrapper {
      padding-top: 64px; } }

.payment-section-infor {
  margin-top: 40px; }
  .payment-section-infor .infor-header,
  .payment-section-infor .payment-bank-header {
    padding: 10px 16px;
    background-color: #a32732;
    color: #fff;
    border: 1px solid #e6e6e6;
    border-radius: 6px 6px 0 0; }
    .payment-section-infor .infor-header > *,
    .payment-section-infor .payment-bank-header > * {
      font-size: 16px;
      font-weight: 600; }
  .payment-section-infor .infor {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2); }
    .payment-section-infor .infor-group {
      padding: 0 24px 24px 24px;
      border: 1px solid #e6e6e6;
      border-radius: 0 0 6px 6px; }
      .payment-section-infor .infor-group .group-input {
        display: flex;
        flex-direction: column;
        margin-top: 24px; }
        .payment-section-infor .infor-group .group-input label {
          font-size: 16px;
          font-weight: 600;
          color: #000; }
        .payment-section-infor .infor-group .group-input input,
        .payment-section-infor .infor-group .group-input textarea {
          margin-top: 6px;
          resize: none;
          appearance: none;
          background: transparent;
          outline: none;
          border: none;
          border-bottom: 1px solid #000;
          font-family: "SVN-AvenirNext", sans-serif;
          font-size: 14px;
          font-weight: 500;
          color: #000; }
          .payment-section-infor .infor-group .group-input input::placeholder,
          .payment-section-infor .infor-group .group-input textarea::placeholder {
            color: #707070;
            font-weight: 500;
            font-style: normal; }
  .payment-section-infor .payment-product {
    margin-top: 24px;
    border: 1px solid #e6e6e6;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    border-radius: 6px; }
    .payment-section-infor .payment-product-form table {
      width: 100%;
      border-collapse: collapse; }
      .payment-section-infor .payment-product-form table thead > tr {
        border-bottom: 1px solid #e6e6e6; }
        .payment-section-infor .payment-product-form table thead > tr > th {
          font-size: 16px;
          font-weight: 600;
          text-transform: uppercase;
          color: #707070;
          padding-top: 10px;
          padding-bottom: 10px; }
          .payment-section-infor .payment-product-form table thead > tr > th:first-child {
            width: 50%;
            padding-left: 16px; }
          .payment-section-infor .payment-product-form table thead > tr > th:last-child {
            text-align: right;
            padding-right: 80px; }
      .payment-section-infor .payment-product-form table tbody > tr > td {
        padding: 24px 0;
        font-size: 18px;
        font-weight: 600;
        color: #000; }
        @media screen and (min-width: 1024px) {
          .payment-section-infor .payment-product-form table tbody > tr > td {
            font-size: 20px; } }
        .payment-section-infor .payment-product-form table tbody > tr > td:first-child {
          padding-left: 16px; }
        .payment-section-infor .payment-product-form table tbody > tr > td:last-child {
          padding-right: 80px;
          text-align: right; }
        .payment-section-infor .payment-product-form table tbody > tr > td .posts-product {
          display: flex;
          align-items: stretch;
          flex-wrap: wrap;
          width: 100%; }
          .payment-section-infor .payment-product-form table tbody > tr > td .posts-product-thumb {
            position: relative;
            width: 102px;
            height: 102px;
            margin-right: 16px; }
            @media screen and (max-width: 639px) {
              .payment-section-infor .payment-product-form table tbody > tr > td .posts-product-thumb {
                width: 100%;
                max-width: 100%;
                height: 0;
                padding-top: 100%;
                margin-bottom: 20px;
                margin-right: 0; } }
            .payment-section-infor .payment-product-form table tbody > tr > td .posts-product-thumb > * {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              object-fit: cover; }
          .payment-section-infor .payment-product-form table tbody > tr > td .posts-product-content {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            overflow: hidden;
            flex-wrap: wrap; }
            @media screen and (max-width: 639px) {
              .payment-section-infor .payment-product-form table tbody > tr > td .posts-product-content {
                flex: 0 0 100%; } }
            .payment-section-infor .payment-product-form table tbody > tr > td .posts-product-content .product-name > * {
              color: #000;
              font-size: 30px;
              font-weight: 600; }
            .payment-section-infor .payment-product-form table tbody > tr > td .posts-product-content .product-features {
              margin-top: 16px;
              display: flex;
              flex-direction: column; }
              .payment-section-infor .payment-product-form table tbody > tr > td .posts-product-content .product-features__item {
                display: flex;
                flex-wrap: wrap;
                font-size: 16px;
                color: #000;
                font-weight: 600; }
                .payment-section-infor .payment-product-form table tbody > tr > td .posts-product-content .product-features__item:not(:first-child) {
                  margin-top: 10px; }
                .payment-section-infor .payment-product-form table tbody > tr > td .posts-product-content .product-features__item .options {
                  display: flex;
                  flex-direction: column; }
                .payment-section-infor .payment-product-form table tbody > tr > td .posts-product-content .product-features__item span {
                  font-weight: 500; }
                .payment-section-infor .payment-product-form table tbody > tr > td .posts-product-content .product-features__item select {
                  appearance: none;
                  background: transparent;
                  outline: none;
                  margin-left: 16px;
                  border: 1px solid #520007;
                  border-radius: 4px;
                  font-size: 14px;
                  font-weight: 500;
                  color: #000;
                  padding: 1px 16px; }
        .payment-section-infor .payment-product-form table tbody > tr > td .prices {
          font-size: 18px;
          font-weight: 600;
          color: #000; }
          @media screen and (min-width: 1024px) {
            .payment-section-infor .payment-product-form table tbody > tr > td .prices {
              font-size: 20px; } }
          .payment-section-infor .payment-product-form table tbody > tr > td .prices-total {
            font-size: 18px;
            font-weight: 600;
            color: #a32732; }
            @media screen and (min-width: 1024px) {
              .payment-section-infor .payment-product-form table tbody > tr > td .prices-total {
                font-size: 20px; } }
        .payment-section-infor .payment-product-form table tbody > tr > td .qty {
          display: flex;
          /* Chrome, Safari, Edge, Opera */
          /* Firefox */ }
          @media screen and (max-width: 1023px) {
            .payment-section-infor .payment-product-form table tbody > tr > td .qty {
              width: 100%; } }
          .payment-section-infor .payment-product-form table tbody > tr > td .qty .value-button {
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: "SVN-AvenirNext", sans-serif;
            width: 40px;
            height: 40px;
            font-size: 18px;
            border: 1px solid #e6e6e6;
            border-radius: 2px;
            user-select: none;
            cursor: pointer; }
            @media screen and (min-width: 1024px) {
              .payment-section-infor .payment-product-form table tbody > tr > td .qty .value-button {
                font-size: 20px; } }
          .payment-section-infor .payment-product-form table tbody > tr > td .qty input::-webkit-outer-spin-button,
          .payment-section-infor .payment-product-form table tbody > tr > td .qty input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0; }
          .payment-section-infor .payment-product-form table tbody > tr > td .qty input[type="number"] {
            -moz-appearance: textfield; }
          .payment-section-infor .payment-product-form table tbody > tr > td .qty input {
            appearance: none;
            -webkit-appearance: none;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            width: 56px;
            color: #000;
            font-size: 18px;
            font-weight: 600;
            border: 1px solid #e6e6e6; }
            @media screen and (min-width: 1024px) {
              .payment-section-infor .payment-product-form table tbody > tr > td .qty input {
                font-size: 20px; } }
        .payment-section-infor .payment-product-form table tbody > tr > td .remove {
          font-size: 0px;
          color: transparent;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          width: 24px;
          height: 24px;
          background-color: transparent;
          border: 1px solid #cccccc;
          border-radius: 100%;
          position: relative;
          transition: 0.3s all ease-in-out;
          cursor: pointer; }
          .payment-section-infor .payment-product-form table tbody > tr > td .remove::after, .payment-section-infor .payment-product-form table tbody > tr > td .remove::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 52%;
            height: 0;
            border-top: 2px solid #666666;
            border-radius: 2px;
            user-select: none;
            pointer-events: none;
            transition: 0.3s all ease-in-out; }
          .payment-section-infor .payment-product-form table tbody > tr > td .remove::before {
            transform: translate(-50%, -50%) rotate(45deg); }
          .payment-section-infor .payment-product-form table tbody > tr > td .remove::after {
            transform: translate(-50%, -50%) rotate(-45deg); }
          .payment-section-infor .payment-product-form table tbody > tr > td .remove:hover {
            background-color: #520007;
            border-color: #520007; }
            .payment-section-infor .payment-product-form table tbody > tr > td .remove:hover::after, .payment-section-infor .payment-product-form table tbody > tr > td .remove:hover::before {
              border-color: #fff; }
          @media screen and (max-width: 1023px) {
            .payment-section-infor .payment-product-form table tbody > tr > td .remove {
              width: auto;
              min-width: 136px;
              border-radius: 0;
              height: 48px;
              padding: 12px 24px;
              cursor: pointer; }
              .payment-section-infor .payment-product-form table tbody > tr > td .remove::after {
                content: none; }
              .payment-section-infor .payment-product-form table tbody > tr > td .remove::before {
                content: attr(data-text);
                position: relative;
                top: 0;
                left: 0;
                border: none;
                font-size: 18px;
                width: 100%;
                height: 100%;
                color: #000;
                display: flex;
                justify-content: center;
                align-items: center;
                transform: none; }
              .payment-section-infor .payment-product-form table tbody > tr > td .remove:hover::before {
                color: #fff; } }
          @media screen and (max-width: 639px) {
            .payment-section-infor .payment-product-form table tbody > tr > td .remove {
              width: 100%; } }
      .payment-section-infor .payment-product-form table tbody > tr:not(:first-child) {
        border-top: 1px solid #e6e6e6; }
      @media screen and (max-width: 1023px) {
        .payment-section-infor .payment-product-form table thead,
        .payment-section-infor .payment-product-form table thead > tr,
        .payment-section-infor .payment-product-form table thead > tr > td {
          display: none; }
        .payment-section-infor .payment-product-form table tbody,
        .payment-section-infor .payment-product-form table tbody > tr,
        .payment-section-infor .payment-product-form table tbody > tr > td {
          display: block;
          text-align: left !important; }
        .payment-section-infor .payment-product-form table tbody > tr {
          position: relative;
          border-width: 2px;
          padding: 20px 20px 20px 20px; }
        .payment-section-infor .payment-product-form table tbody > tr > td {
          position: relative;
          display: flex;
          align-items: center;
          white-space: normal;
          padding: 8px 0px !important; }
          .payment-section-infor .payment-product-form table tbody > tr > td:not(:first-child) {
            padding-left: 118px !important; }
          .payment-section-infor .payment-product-form table tbody > tr > td:nth-last-child(2) {
            color: #a32732; }
            .payment-section-infor .payment-product-form table tbody > tr > td:nth-last-child(2)::before {
              content: attr(data-title);
              margin-right: 4px; }
          .payment-section-infor .payment-product-form table tbody > tr > td:last-child {
            display: none; } }
      @media screen and (max-width: 639px) {
        .payment-section-infor .payment-product-form table tbody > tr > td:not(:first-child) {
          padding-left: 0px !important; } }
    .payment-section-infor .payment-product-bill {
      margin-top: 12px;
      padding: 24px 80px 24px 50%;
      background-color: whitesmoke;
      border: 1px solid #e6e6e6; }
      @media screen and (max-width: 1023px) {
        .payment-section-infor .payment-product-bill {
          padding-left: 20px;
          padding-right: 20px; } }
      .payment-section-infor .payment-product-bill-item {
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: 600;
        color: #000; }
        @media screen and (min-width: 1024px) {
          .payment-section-infor .payment-product-bill-item {
            font-size: 20px; } }
        @media screen and (max-width: 639px) {
          .payment-section-infor .payment-product-bill-item {
            flex-direction: column;
            align-items: flex-start; }
            .payment-section-infor .payment-product-bill-item > *:not(:first-child) {
              margin-top: 10px; } }
        .payment-section-infor .payment-product-bill-item > p > span {
          display: inline-block; }
        .payment-section-infor .payment-product-bill-item .group-input-code {
          display: flex;
          flex-direction: column; }
          @media screen and (max-width: 639px) {
            .payment-section-infor .payment-product-bill-item .group-input-code {
              width: 100%; }
              .payment-section-infor .payment-product-bill-item .group-input-code form {
                width: 100%; } }
          .payment-section-infor .payment-product-bill-item .group-input-code .infor-text {
            display: block;
            margin-top: 4px;
            width: 100%;
            text-align: right;
            font-size: 14px;
            font-weight: 500;
            color: #000; }
            @media screen and (max-width: 639px) {
              .payment-section-infor .payment-product-bill-item .group-input-code .infor-text {
                text-align: left; } }
          .payment-section-infor .payment-product-bill-item .group-input-code .error-text {
            display: block;
            margin-top: 4px;
            width: 100%;
            text-align: right;
            font-size: 14px;
            font-weight: 500;
            color: #e80826; }
            @media screen and (max-width: 639px) {
              .payment-section-infor .payment-product-bill-item .group-input-code .error-text {
                text-align: left; } }
          .payment-section-infor .payment-product-bill-item .group-input-code .code-submit {
            display: flex;
            flex-direction: row-reverse;
            flex-wrap: wrap; }
            @media screen and (max-width: 639px) {
              .payment-section-infor .payment-product-bill-item .group-input-code .code-submit {
                flex-direction: column; } }
            .payment-section-infor .payment-product-bill-item .group-input-code .code-submit button {
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
              padding: 6px 8px;
              height: 32px;
              appearance: none;
              background: transparent;
              outline: none;
              border: 1px solid #520007;
              font-size: 14px;
              font-weight: 500;
              color: #fff;
              background-color: #a32732;
              transition: 0.2s all ease-in-out; }
              .payment-section-infor .payment-product-bill-item .group-input-code .code-submit button:hover {
                background-color: #520007; }
              .payment-section-infor .payment-product-bill-item .group-input-code .code-submit button:disabled {
                opacity: 0.7; }
                .payment-section-infor .payment-product-bill-item .group-input-code .code-submit button:disabled:hover {
                  background-color: #a32732; }
              @media screen and (max-width: 639px) {
                .payment-section-infor .payment-product-bill-item .group-input-code .code-submit button {
                  width: 100%; } }
            .payment-section-infor .payment-product-bill-item .group-input-code .code-submit input {
              appearance: none;
              outline: none;
              border: 1px solid #520007;
              width: 156px;
              max-width: 100%;
              height: 32px;
              padding: 6px 8px;
              display: flex;
              align-items: center;
              font-size: 14px;
              font-weight: 500;
              color: #000;
              background: transparent; }
              .payment-section-infor .payment-product-bill-item .group-input-code .code-submit input:disabled {
                opacity: 0.7; }
        .payment-section-infor .payment-product-bill-item.input-code {
          align-items: flex-start; }
        .payment-section-infor .payment-product-bill-item.total > *:last-child {
          color: #a32732;
          font-size: 30px; }
        .payment-section-infor .payment-product-bill-item:not(:first-child) {
          margin-top: 16px; }
          @media screen and (max-width: 639px) {
            .payment-section-infor .payment-product-bill-item:not(:first-child) {
              margin-top: 20px; } }
        .payment-section-infor .payment-product-bill-item > *:first-child {
          flex: 1; }
        .payment-section-infor .payment-product-bill-item > * {
          display: flex;
          align-items: center;
          flex-wrap: wrap; }
      .payment-section-infor .payment-product-bill-submit {
        padding-top: 24px;
        margin-top: 16px;
        border-top: 1px solid #e6e6e6;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end; }
        .payment-section-infor .payment-product-bill-submit button,
        .payment-section-infor .payment-product-bill-submit input[type="submit"] {
          min-width: 269px;
          appearance: none;
          background: transparent;
          outline: none;
          border: none;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          padding: 12px 24px;
          border-radius: 6px;
          color: #fff;
          background-color: #a32732;
          transition: 0.3s all ease-in-out;
          font-family: "SVN-AvenirNext", sans-serif;
          font-size: 18px;
          font-weight: 500; }
          @media screen and (min-width: 1024px) {
            .payment-section-infor .payment-product-bill-submit button,
            .payment-section-infor .payment-product-bill-submit input[type="submit"] {
              font-size: 20px; } }
          .payment-section-infor .payment-product-bill-submit button:hover,
          .payment-section-infor .payment-product-bill-submit input[type="submit"]:hover {
            background-color: #520007; }
  .payment-section-infor .payment-bank {
    margin-top: 24px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2); }
    .payment-section-infor .payment-bank-list .methods {
      display: flex;
      padding: 16px; }
      .payment-section-infor .payment-bank-list .methods:not(:first-child) {
        border-top: 1px solid #e6e6e6; }
      .payment-section-infor .payment-bank-list .methods-input input {
        display: none; }
        .payment-section-infor .payment-bank-list .methods-input input:checked ~ .checkmark {
          background-color: #a32732; }
          .payment-section-infor .payment-bank-list .methods-input input:checked ~ .checkmark::after {
            top: 42%;
            left: 50%;
            width: 25%;
            height: 53%;
            border: solid white;
            border-width: 0 3px 3px 0;
            transform: translate(-50%, -50%) rotate(45deg); }
      .payment-section-infor .payment-bank-list .methods-input .checkmark {
        position: relative;
        width: 40px;
        height: 40px;
        border: 1px solid #520007;
        border-radius: 2px; }
        @media screen and (max-width: 639px) {
          .payment-section-infor .payment-bank-list .methods-input .checkmark {
            width: 30px;
            height: 30px; } }
        .payment-section-infor .payment-bank-list .methods-input .checkmark::after {
          content: "";
          position: absolute; }
      .payment-section-infor .payment-bank-list .methods-label {
        flex: 1; }
    .payment-section-infor .payment-bank-list .item {
      padding: 16px 0px 0 16px;
      width: 100%; }
      @media screen and (max-width: 639px) {
        .payment-section-infor .payment-bank-list .item {
          padding-top: 0px; } }
      .payment-section-infor .payment-bank-list .item-name {
        padding-bottom: 6px;
        border-bottom: 1px solid #000; }
        .payment-section-infor .payment-bank-list .item-name > * {
          font-size: 16px;
          font-weight: 600;
          color: #000; }
      .payment-section-infor .payment-bank-list .item-infor {
        margin-top: 10px;
        font-size: 16px;
        font-weight: 600;
        color: #707070; }
  .payment-section-infor .error-text {
    display: block;
    margin-top: 16px;
    width: 100%;
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    color: #e80826; }
  .payment-section-infor .submit {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center; }
    .payment-section-infor .submit button,
    .payment-section-infor .submit input[type="submit"] {
      min-width: 269px;
      appearance: none;
      background: transparent;
      outline: none;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 12px 24px;
      border-radius: 6px;
      color: #fff;
      background-color: #a32732;
      transition: 0.3s all ease-in-out;
      font-family: "SVN-AvenirNext", sans-serif;
      font-size: 18px;
      font-weight: 500; }
      @media screen and (min-width: 1024px) {
        .payment-section-infor .submit button,
        .payment-section-infor .submit input[type="submit"] {
          font-size: 20px; } }
      .payment-section-infor .submit button:hover,
      .payment-section-infor .submit input[type="submit"]:hover {
        background-color: #520007; }

.payment-success-wrapper {
  padding-top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center; }
  @media screen and (min-width: 640px) {
    .payment-success-wrapper {
      padding-top: 100px; } }
  @media screen and (min-width: 1024px) {
    .payment-success-wrapper {
      padding-top: 128px; } }
  @media screen and (min-width: 1280px) {
    .payment-success-wrapper {
      padding-top: 128px; } }
  .payment-success-wrapper .name {
    margin-top: 32px; }
    @media screen and (min-width: 640px) {
      .payment-success-wrapper .name {
        margin-top: 40px; } }
    @media screen and (min-width: 1024px) {
      .payment-success-wrapper .name {
        margin-top: 60px; } }
    @media screen and (min-width: 1280px) {
      .payment-success-wrapper .name {
        margin-top: 60px; } }
  .payment-success-wrapper .desc {
    font-size: 18px;
    font-weight: 500;
    color: #707070;
    margin-top: 18px;
    margin-bottom: 20px;
    text-align: center; }
    @media screen and (min-width: 1024px) {
      .payment-success-wrapper .desc {
        font-size: 20px; } }
    @media screen and (min-width: 640px) {
      .payment-success-wrapper .desc {
        margin-bottom: 32px; } }
    @media screen and (min-width: 1024px) {
      .payment-success-wrapper .desc {
        margin-bottom: 40px; } }
    @media screen and (min-width: 1280px) {
      .payment-success-wrapper .desc {
        margin-bottom: 40px; } }
  .payment-success-wrapper .btn-back > * {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-width: 269px;
    padding: 12px 24px;
    border-radius: 6px;
    color: #fff;
    background: #a32732;
    font-size: 18px;
    font-weight: 500;
    transition: 0.2s all ease-in-out; }
    @media screen and (min-width: 1024px) {
      .payment-success-wrapper .btn-back > * {
        font-size: 20px; } }
    .payment-success-wrapper .btn-back > *:hover {
      background: #520007; }
