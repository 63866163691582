.index-intro {
  .intro-pic-txt {
    position: relative;
    width: 100%;
    height: 100vh;

    @media (max-width: map-get($media, tl)) {
      max-height: 720px;
    }
    @media (max-width: map-get($media, ml)) {
      max-height: 520px;
    }
  }
  .intro-pic {
    width: 100%;
    height: 100%;

    img.intro__background {
      height: 100% !important;
      width: 100% !important;
      object-fit: cover;
      object-position: center;
    }

    iframe {
      height: 100% !important;
      width: 100% !important;
    }

    .ytp-large-play-button {
      top: 70% !important;
    }
  }
  .intro-txt {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 500;
    // width: 100%;
    text-align: center;

    padding: 12px;
    // max-width: 1344px;
    width: 100%;
    // @media (max-width: 1280px) {
    //   min-width: 79%;
    // }
    // @media (max-width: 1280px) {
    //   min-width: 66%;
    // }
    // @media (max-width: 1080px) {
    //   min-width: 79%;
    // }
    // @media (max-width: 872px) {
    //   min-width: 86%;
    // }
    // @media (max-width: 768px) {
    //   min-width: 86%;
    // }
    // @media (max-width: 640px) {
    //   min-width: 88%;
    // }
    // @media (max-width: 414px) {
    //   min-width: 90%;
    // }
    // @media (max-width: 404px) {
    //   min-width: 87%;
    // }
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img,
    svg {
      display: block;
      max-width: 100%;
      object-fit: contain;
      // margin-bottom: 20px;
      // @include rpmin(lg) {
      //   margin-bottom: 40px;
      // }
      // margin-bottom: map-get($size, margin-block-medium);
      margin-bottom: 20px;
    }

    .intro__heading {
      // width: 100%;
      @include spaceContainer;
      text-transform: uppercase;
      display: inline-block;
      color: white;
      margin-bottom: map-get($size, margin-block-medium);

      // letter-spacing: 40px;
      // letter-spacing: 4px;
      // white-space: nowrap;
      // font-family: 'SVN-AvenirNext';

      // @media (max-width: 1600px) {
      //   font-size: 68px;
      //   // letter-spacing: 12px;
      // }
      @media (max-width: 1380px) {
        // letter-spacing: 28px;
        font-size: 48px;
      }
      @media (max-width: 1024px) {
        font-size: 40px;
        // letter-spacing: 12px;
      }
      @media (max-width: 768px) {
        font-size: 32px;
        // letter-spacing: 18px;
      }
      // @media (max-width: 600px) {
      //   font-size: 40px;
      //   // letter-spacing: 10px;
      // }
      @media (max-width: 500px) {
        font-size: 28px;
        letter-spacing: 2px;
      }

      @media (max-width: 425px) {
        font-size: 38px;
        letter-spacing: 1px;
      }
      @media (max-width: 320px) {
        font-size: 32px;
        letter-spacing: 1px;
      }
    }

    .intro__desc {
      text-transform: uppercase;
      color: white;
      letter-spacing: 5px;

      @media (max-width: 500px) {
        font-size: 20px;
        letter-spacing: 2px;
      }

      @media (max-width: 425px) {
        font-size: 20px;
        letter-spacing: 1px;
      }
      @media (max-width: 320px) {
        font-size: 16px;
        letter-spacing: 1px;
      }
    }
  }
}

// @media (max-width: map-get($media, tl)){
//   .index-intro{
//     .intro-txt{
//       .intro__heading{
//         letter-spacing: 15px;
//       }
//     }
//   }
// }

// @media (max-width: map-get($media, tl)) {
//   .index-intro {
//     .intro-txt {
//       // .intro__heading{
//       //   letter-spacing: 5px;
//       // }
//       .intro__desc {
//         margin-top: map-get($size, margin-block-small);
//       }
//     }
//   }
// }
// @media (max-width: map-get($media, tm)){
//   .index-intro{
//     .intro-txt{
//       // .intro__heading{
//       //   letter-spacing: 5px;
//       // }
//       // .intro__desc{
//       //   margin-top: map-get($size, margin-block-small);
//       // }
//     }
//   }
// }
