.about-story-blog__wrapper {
  .about-story-blog {
    // margin-top: map-get($size, margin-block-large);
    @include spaceL(margin, 0);

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .title-blog {
      font-weight: 700;
      text-transform: uppercase;
    }

    &--left {
      flex: 0 0 70%;
      width: 70%;

      .blog {
        @include spaceL(margin-bottom);
        display: grid;
        grid-template-columns: auto auto;
        gap: 30px 20px;

        @include rpmin(lg) {
          gap: 38px 50px;
        }

        &-item {
          grid-column: 1fr;
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
          transition: 0.3s all linear;
          &:hover {
            opacity: 0.8;
          }
          &__img {
            width: 100%;
            height: 271px;

            object-fit: cover;
            object-position: center;
          }
          &__content {
            // padding: 0 map-get($size, margin-block-small);
            @include spaceS(margin-right);
            @include spaceS(margin-left);

            &-date {
              @extend .span--date;
            }
            &-title {
              @extend .title-blog;
              // margin: map-get($size, margin-block-small) 0;
              @include spaceS(margin, 0);
              // font-family: inherit;
            }
            &-desc {
              @include font-p-small;
              // margin-bottom: map-get($size, margin-block-small);
              @include spaceS(margin-bottom);
              color: map-get($mau, dark-gray);
              // font-weight: 300;
            }
          }
        }
      }

      .releases-pagination {
        margin: 0 auto;
        // width: 315px;
      }
    }
    &--right {
      flex: 0 0 25%;
      width: 25%;
      .about-story-blog__title--right {
        // margin-bottom: map-get($size, margin-block-medium);
        @include spaceM(margin-bottom);
        @include font-p;
        // color:rgba(map-get($mau, color-primary), 1);
        color: #000;

        // font-family: inherit;
        @extend .title-blog;
        font-family: "HELVETICA-NEUE", "Segoe UI", sans-serif;
      }
      .blog {
        display: flex;
        flex-direction: column;
        &-item {
          transition: 0.3s all linear;
          &:hover {
            opacity: 0.8;
          }
          &__img {
            width: 100%;

            height: 238px;
            object-fit: cover;
            object-position: center;
          }
          &__content {
            &-date {
              @extend .span--date;
            }
            &-title {
              // margin: map-get($size, margin-block-small) 0;
              @extend .title-blog;
              @include p-line-clamp(3);
              // font-family: inherit;
              margin: 12px 0;
            }
          }
          padding-top: 20px;

          &:not(:last-child) {
            // border-bottom: none;
            @include rpmin(lg) {
              border-bottom: 1px solid rgba(map-get($mau, color-primary), 0.9);
            }
          }
        }
      }
    }
  }
}

@media (max-width: map-get($media, tl)) {
  .about-story-blog__wrapper .about-story-blog {
    flex-direction: column;
  }
  .about-story-blog__wrapper .about-story-blog--left {
    flex: 0 0 100%;
    width: 100%;
  }
  .about-story-blog__wrapper .about-story-blog--right {
    flex: 0 0 100%;
    width: 100%;
    @include spaceL(margin-top);

    .blog {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin-left: 1%;
    }
    .blog-item {
      padding-top: 12px;
      width: 33.333%;
      padding-right: 2%;

      border-bottom: 0;
    }
  }
  .about-story-blog__wrapper .about-story-blog--right .about-story-blog__title--right {
    margin-left: 1%;
  }
}

// right
@media (max-width: map-get($media, ts)) {
  .about-story-blog__wrapper .about-story-blog--right {
    .blog-item {
      width: 50%;
    }
  }
  // .about-story-blog__wrapper .about-story-blog--left .releases-pagination {
  //   // margin:36px auto;
  // }
}
@media (max-width: map-get($media, ml)) {
  .about-story-blog__wrapper .about-story-blog--right {
    .blog-item {
      width: 100%;
    }
  }
}

// left
@media (max-width: map-get($media, ml)) {
  .about-story-blog__wrapper .about-story-blog--left .blog {
    grid-template-columns: auto;
  }
}
@media (max-width: map-get($media, ml)) {
  .releases-pagination {
    width: 302px;
  }
  .releases-pagination .swiper-container {
    padding: 0 35px;
  }
}
