@keyframes popup {
	0% {
		opacity: 0;
		visibility: hidden;
		clip-path: inset(0 0 0 100%);
	}
	100% {
		opacity: 1;
		visibility: visible;
		clip-path: inset(0 0 0 0);
	}
}
@keyframes closePopup {
	0% {
		opacity: 1;
		visibility: visible;
		clip-path: inset(0 0 0 0);
	}
	100% {
		opacity: 0;
		visibility: hidden;
		clip-path: inset(0 0 0 100%);
	}
}
$timeAnimation: 0.2s;
$animationPopupLogin: popup $timeAnimation ease-in-out forwards;
$animationCloseLogin: closePopup $timeAnimation ease-in-out forwards;

#form-forgot__wrapper,
#form-login__wrapper,
#form-user__wrapper,
#form-dealer__wrapper,
#form-custom__wrapper {
	display: none;
	&.active {
		display: flex;
		animation: $animationPopupLogin;
	}
}

.loginform-content__wrapper {
	position: fixed;
	z-index: 1000;
	width: 100%;
	height: 100vh;
	background-color: white;
	.loginform__close {
		position: absolute;
		top: 0;
		right: 0;
		background: map-get($mau, color-primary);
		color: map-get($mau, white-1);
		width: 50px;
		height: 50px;
		justify-content: center;
		border-bottom-left-radius: 20px;
		border: none;
		&::after {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			color: map-get($mau, white-1);
			content: "\2715";
			font-size: 22px;
			font-weight: 700;
		}
	}
	.loginform-content {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 80vh;
		.register {
			width: 50%;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			background: map-get($mau, color-primary);
			&-heading {
				margin-top: 50px;
				color: map-get($mau, gray-1);
			}
			&-customer {
				width: 90%;
				display: flex;

				justify-content: center;
				align-items: center;
				&__namelist {
					font-size: 18px;
					color: map-get($mau, gray-1);
					margin: 10px 20px;
					width: 60%;
				}
				&__checklist {
					margin-right: auto;
					width: 24px;
					height: 24px;
					border-radius: 20px;
				}
			}
			&-sendmess {
				width: 70%;
				display: flex;
				justify-content: space-between;
				&__textlist {
					padding-left: 10px;
					margin: 15px 0px;
					width: 49%;
					height: 50px;
					border-radius: 10px;
				}
			}
			&-enduser {
				padding: 0px 0px;
				width: 70%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				&__btn {
					width: 120px;
					height: 40px;
					margin: 20px 75px;
					border: solid 3px map-get($mau, white-1);
					background: map-get($mau, color-primary);
					border-radius: 20px;
					justify-content: center;
					// font-family: SEGOEUIB;
					@include font2;
					font-size: 16px;
					color: map-get($mau, gray-1);
				}
				&__textlist {
					padding-left: 10px;
					width: 100%;
					height: 50px;
					margin: 15px 0px;
					border-radius: 10px;
				}

				&__forgot {
					text-decoration: none;
					color: map-get($mau, gray-1);
				}
			}
		}

		.login {
			margin-left: 40px;
			width: 50%;
			height: 80%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			background: map-get($mau, color-primary);
			&-heading {
				margin: 20px;
				color: map-get($mau, gray-1);
			}
			&-customer {
				width: 50%;
				display: flex;

				justify-content: center;
				align-items: center;
				&__namelist {
					font-size: 18px;
					color: map-get($mau, gray-1);
					margin: 10px 20px;
					width: 60%;
				}
				&__checklist {
					margin-right: auto;
					width: 24px;
					height: 24px;
					border-radius: 20px;
				}
			}
			&-sendmess {
				width: 80%;
				display: flex;
				justify-content: space-between;
				&__textlist {
					padding-left: 10px;
					margin: 15px 0px;
					width: 49%;
					height: 50px;
					border-radius: 10px;
				}
			}
			&-enduser {
				padding: 0px 0px;
				width: 80%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				&__btn {
					width: 120px;
					height: 40px;
					margin: 20px 75px;
					border: solid 3px map-get($mau, white-1);
					background: map-get($mau, color-primary);
					border-radius: 20px;
					justify-content: center;
					// font-family: SEGOEUIB;
					@include font2;
					font-size: 16px;
					color: map-get($mau, gray-1);
				}
				&__textlist {
					padding-left: 10px;
					width: 100%;
					height: 50px;
					margin: 20px 0px;
					border-radius: 10px;
				}

				&__forgot {
					text-decoration: none;
					color: map-get($mau, gray-1);
				}
			}
		}
	}
	.login-right {
		width: 50%;
		height: 100%;
		.cart-section {
			padding: 0px 50px;
			width: 100%;
			position: relative;
			&::after {
				position: absolute;
				content: "";
				top: 50%;
				right: 47%;
				height: 1px;
				width: 100%;
				transform: rotate(90deg);
				background: map-get($mau, color-primary);
				opacity: 0.2;
			}
			.cart-content__title {
				margin: 40px 0px;
				display: flex;
				justify-content: center;
			}
			.cart {
				&-header {
					border: solid map-get($mau, color-primary) 1px;
					margin: 15px 0px;
					padding: 0;
				}
				&-content {
					margin: 40px 0px;
					position: relative;
					&::after {
						position: absolute;
						content: "";
						top: 120%;
						right: 0%;
						height: 1px;
						width: 100%;
						background: map-get($mau, color-primary);
						opacity: 0.2;
					}
					.cart-items {
						&__textview {
							width: 200px;
							height: 50px;
						}
						&__symbolprice {
							display: flex;
							justify-content: center;
							align-items: center;
							width: 85%;
							height: 50px;
							margin-left: 20px;
						}

						&__symboltotal {
							display: flex;
							justify-content: center;
							align-items: center;
							width: 85%;
							height: 50px;
							margin-left: 20px;
							background: map-get($mau, gray-1);
							position: relative;
							&::after {
								position: absolute;
								top: 50%;
								left: 90%;
								transform: translate(-50%, -50%);
								color: map-get($mau, white-1);
								content: "\2715";
								font-size: 22px;
								font-weight: 700;
							}
						}
						&__quanlity {
							display: flex;
							justify-content: center;
							align-items: center;
							width: 55%;
							height: 50px;
							margin-left: 25px;
							background: map-get($mau, gray-1);
						}
					}
				}
			}
			.cart-footer {
				width: 100%;
				// &__subtotal{

				// }
				&__click {
					justify-content: space-between;
					border: none;
					width: 180px;
					height: 50px;
				}
			}
		}
	}
}

// body{
// 	height: 100vh;
// 	overflow: hidden;
// }

.form__wrapper {
	position: fixed;
	z-index: 1000;

	width: 100%;
	height: 100vh;
	background-color: rgba(255, 255, 255, 0.7);

	.loginform__close {
		position: absolute;
		top: 0;
		right: 0;
		background: map-get($mau, color-primary);
		color: map-get($mau, white-1);
		width: 50px;
		height: 50px;
		justify-content: center;
		border-bottom-left-radius: 20px;
		border: none;
		&::after {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			color: map-get($mau, white-1);
			content: "\2715";
			font-size: 22px;
			font-weight: 700;
		}
	}

	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	background-color: white;

	.form__title {
		color: map-get($mau, color-primary);
		margin: 42px 0;
		text-align: center;
	}

	.form-content {
		box-shadow: 0 0 15px rgba(0, 0, 0, 0.7);

		padding: 0 24px 24px 24px;
		width: 560px;
		max-width: 100%;
		max-height: 90%;
		overflow-y: scroll;

		// &::-webkit-scrollbar{
		// 	width: 4px;
		// }

		// /* Track */
		// &::-webkit-scrollbar-track {
		// 	box-shadow: inset 0 0 5px map-get($mau, gray-1);
		// 	border-radius: 10px;
		// }

		// /* Handle */
		// &::-webkit-scrollbar-thumb {
		// 	background: map-get($mau, white);
		// 	border-radius: 10px;
		// }

		// /* Handle on hover */
		// &::-webkit-scrollbar-thumb:hover {
		// 	background: rgba(map-get($mau, dark-gray), .7);
		// }

		background: map-get($mau, color-primary);

		&__dealer {
			width: 720px;
		}

		&__title {
			color: map-get($mau, gray-1);
			font-weight: bold;
			padding: map-get($size, margin-block-large) 0;
			text-align: center;
			text-transform: uppercase;
		}
		&--group {
			width: 100%;
		}

		.input-group {
			width: 100%;

			display: flex;
			justify-content: center;
			align-items: center;

			&:not(:first-child) {
				margin-top: 24px;
			}

			&--checkbox {
				flex: 1;
				text-align: left;

				display: flex;
				justify-content: flex-start;
				align-items: center;

				label {
					white-space: nowrap;
					text-align: left;

					margin-left: 8px;
				}

				&:not(:first-child) {
					margin-left: 2%;
				}
			}

			label {
				width: 50%;
				justify-self: center;
			}
			input[type="checkbox"] {
				width: 20px;
				height: 20px;
			}

			input + input {
				margin-left: 2%;
			}
			input,
			textarea,
			select {
				border-radius: 10px;
				padding: 5px 16px;
				height: 56px;
				line-height: 36px;
				background-color: map-get($mau, white-1);
				letter-spacing: 1px;

				@include size20px;

				&.error {
					border: 2px solid map-get($mau, error) !important;
				}
				&.success {
					border: 2px solid map-get($mau, success) !important;
				}
			}
			input[type="text"],
			input[type="email"],
			input[type="password"],
			input[type="tel"],
			input[type="date"],
			textarea {
				width: 100%;
			}

			textarea {
				height: 120px;
				resize: none;
			}

			input[type="submit"] {
				width: 240px;
				max-width: 100%;

				border-radius: 56px;
				border: 3px solid map-get($mau, gray-1);

				background-color: transparent;
				color: map-get($mau, gray-1);

				&:hover {
					background-color: map-get($mau, gray-1);
					color: map-get($mau, color-primary);
				}
			}
			input:focus {
				box-shadow: 0 0 5px rgba(map-get($mau, dark-gray), 0.3);
			}
		}

		&--link {
			display: inline-block;
			color: map-get($mau, gray-1);
			margin: 24px 0;
			font-size: 16px;
			font-style: italic;

			&:hover,
			&:active {
				font-weight: bold;
				text-decoration: underline;
			}
		}
		&-register {
			margin-top: 24px;
			border-top: 1px solid map-get($mau, gray-1);
			display: flex;
			justify-content: flex-start;
			flex-direction: column;

			.form-content--link {
				margin: 12px 0;
			}
		}
	}

	.form-flex {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		width: 100%;
		background-color: white;
		overflow-y: scroll;
		overflow-x: hidden;
		margin-left: 2%;
		min-height: 70%;

		&--left {
			width: 30%;
			min-width: 360px;
		}

		&--right {
			width: 60%;
			min-width: 660px;
			margin-left: 2%;
			border-left: 1px solid map-get($mau, gray-1);
			align-self: stretch;

			.form-cart {
				padding: 24px;
				width: 100%;
				max-width: 100%;
				height: 100%;

				background-color: map-get($mau, white-1);
				color: map-get($mau, dark-gray);

				display: flex;
				flex-direction: column;

				&__title {
					color: map-get($mau, color-primary);
					margin: 24px 0;
					text-align: center;
					font-weight: bold;
					text-transform: uppercase;
				}

				&-table {
					width: 100%;
					display: flex;
					justify-content: flex-start;
					flex-direction: column;
					flex-grow: 1;

					&__product {
						margin: 24px 0;
						display: flex;
						flex-direction: column;
						align-items: center;

						position: relative;

						height: 250px;
						max-height: 250px;
						overflow-y: scroll;

						box-shadow: 0 0 4px rgba(map-get($mau, dark-gray), 0.1);

						&--item:first-child {
							position: sticky;
							top: 0;
							z-index: 1;

							border: 1px solid map-get($mau, color-primary);
							box-shadow: 0 5px 10px rgba(map-get($mau, dark-gray), 0.3);
							background-color: white;
						}

						&--item {
							display: flex;
							flex-direction: row;
							width: 100%;
						}
						.product__header {
							line-height: 46px;
						}

						.product__item {
							min-height: 120px;
							&:not(:first-child) {
								margin: 12px 0;
							}
							&--center {
								display: flex;
								flex-direction: row;
								align-items: center;
								justify-content: center;
							}
							&--span {
								display: block;

								min-width: 72px;
								min-height: 64px;
								padding: 0px 20px;
								line-height: 64px;

								border-radius: 3px;

								background-color: map-get($mau, gray-1);

								font-weight: bold;
								color: map-get($mau, color-primary);
								position: relative;

								user-select: none;
							}
							&--plus,
							&--minus {
								position: absolute;
								top: 50%;
								transform: translateY(-50%);
								width: 12px;
								height: 100%;
								opacity: 0.3;
								&:hover {
									opacity: 0.8;
								}
							}
							&--plus {
								left: auto;
								right: 4px;
								&::after {
									position: absolute;
									top: 50%;
									left: 50%;
									transform: translate(-50%, -50%);
									content: "\271A";
									pointer-events: none;
								}
							}
							&--minus {
								left: 4px;
								right: auto;
								&::after {
									position: absolute;
									top: 50%;
									left: 50%;
									transform: translate(-50%, -50%);
									content: "\268A";
									pointer-events: none;
								}
							}
							&--product {
								padding-left: 12px;
								flex-basis: 40%;
								// background-color: rgba(245, 7, 7, 0.651);
								&-img {
									width: 84px;
									height: 64px;
									max-height: 100%;
									margin-right: 2%;
								}
								&-content {
									padding-right: 8px;
									flex: 1;
								}
							}
							&--price {
								flex-basis: 20%;
								// background-color: rgba(0, 128, 0, 0.267);
								text-align: center;

								span {
									background-color: transparent;
								}
							}
							&--quanlity {
								flex-basis: 10%;
								// background-color:rgba(255, 255, 0, 0.425);
								text-align: center;
							}
							&--total {
								flex-basis: 20%;
								// background-color: rgba(137, 43, 226, 0.342);
								text-align: center;
							}
							&--close {
								cursor: pointer;
								flex-basis: 10%;
								// background-color: rgba(128, 128, 128, 0.425);
								text-align: center;

								span {
									min-width: 36px;
									min-height: 36px;
									line-height: 36px;

									position: relative;
									background-color: transparent;
									transition: 0.3s all linear;

									&:hover {
										background-color: map-get($mau, gray-1);
									}

									img {
										pointer-events: none;
									}
									// &::before,
									// &::after {
									// 	content: "";
									// 	position: absolute;
									// 	top: 50%;
									// 	left: 50%;
									// 	transform: translate(-50%, -50%);
									// 	width: 70%;
									// 	height: 2px;
									// 	background-color: map-get($mau, dark-gray);
									// 	border-radius: 1px;
									// }
									// &::before {
									// 	transform: translate(-50%, -50%) rotate(45deg);
									// }
									// &::after {
									// 	transform: translate(-50%, -50%) rotate(-45deg);
									// }
								}
							}
						}
					}

					&__footer {
						flex-grow: 1;
						display: flex;
						padding-top: 24px;
						border-top: 1px solid map-get($mau, gray-1);
						position: relative;
						max-height: 220px;
						.footer {
							&__note {
								flex-basis: 40%;
								resize: none;
								height: calc(100% - 4px);

								padding: 12px;
								margin: 4px 12px;
							}
							&-subtotal {
								position: absolute;
								top: 24px;
								right: 10%;
								p {
									color: map-get($mau, color-primary);

									span {
										font-weight: bold;
									}
								}
							}

							&-submit {
								flex-grow: 1;

								display: flex;
								justify-content: center;
								align-items: flex-end;
								flex-direction: row;

								input {
									border-radius: 8px;
									border: 0;
									background-color: transparent;

									min-width: 180px;
									min-height: 48px;
									padding: 5px 8px;
									background-color: map-get($mau, gray-2);
									margin: 0 12px;

									color: map-get($mau, color-primary);
									transition: 0.3s ease-in-out;
									&:focus,
									&:hover {
										font-size: 22px;
										// opacity: .7;
										font-weight: bold;
									}
								}
								input[type="submit"] {
									background-color: map-get($mau, color-primary);
									color: white;
								}
							}
						}
					}
				}
			}
		}

		.form-content {
			overflow-y: auto;
		}
	}
}

// @media (max-width: map-get($media, tl)){
// 	#form-custom__wrapper{
// 		.form-flex{
// 			justify-content: flex-start;
// 		}
// 	}
// }
@media (max-width: map-get($media, ts)) {
	.form__wrapper .form-content {
		width: 96%;
	}
}

@media (max-width: map-get($media, ps)) {
	#form-custom__wrapper {
		.form-flex {
			flex-direction: column;
			justify-content: flex-start;
		}
	}

	#form-custom__wrapper.form__wrapper {
		.form-flex {
			margin: 0;
			&--left {
				min-width: auto;
				width: 90%;
			}
			&--right {
				border: 0;
				width: 90%;
				min-width: auto;
				margin-left: 0;
				align-self: center;

				margin-bottom: 24px;

				.form-cart-table__product {
					max-height: none;
					height: auto;
					overflow-x: scroll;
					overflow-y: hidden;
					align-items: flex-start;
					box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
					&--item {
						min-width: 650px;
					}
				}
				.form-cart-table__footer {
					flex-direction: column;
					padding: 64px 0 0 0;
					.footer__note {
						width: 100%;
						height: 160px;
						flex-basis: initial;
						margin-bottom: 24px;
						border: 1px solid map-get($mau, color-primary);
						outline: none;
						transition: 0.3s ease-in-out;
						&:focus {
							padding-left: 24px;
						}
					}
				}
			}
			.form-content {
				overflow-y: visible;
				width: 100%;
			}
		}
		.form-content {
			max-height: none;
		}
	}
	#form-custom__wrapper.form__wrapper .form-flex--right .form-cart-table__footer {
		max-height: 320px;
	}
}

@media (max-width: map-get($media, ml)) {
	#form-custom__wrapper.form__wrapper {
		.form-flex {
			&--left {
				width: 98%;
			}
			&--right {
				width: 98%;
				.form-cart-table__footer .footer-submit {
					flex-direction: column;
					& > * {
						margin: 0;
					}
					& > *:not(:first-child) {
						margin-top: 12px;
					}
				}
			}
		}
	}
}
