.about-media-releases__wrapper{
  .about-media-releases{
    // padding-bottom: map-get($size, margin-block-large);
    @include spaceL(padding-bottom);
    @include spaceL(margin-bottom);
    background-color: map-get($mau, gray-2);
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // flex-direction: column;

    &__title{
      text-align: center;

      // padding: map-get($size, margin-block-large);
      @include spaceL(padding, 0);
    }

    &__content{
      width: 80%;
      margin: auto;

      .releases-box{
       display: flex;
       flex-direction: column;
       justify-content: space-between;
       align-items: center;

       &-item:not(:nth-of-type(4n + 1)){
        border-top: 3px solid map-get($mau, color-primary);

        padding-top: map-get($size, margin-block-medium);
       }

       &-item{
        width: 100%;
        max-width: 100%;
        &__title{
          font-weight: 700;
          letter-spacing: 1.5px;
          line-height: 28px;
        }
        &--padding{
          padding: 0 map-get($size, margin-block-small);
        }
        &__date{
          @extend .span--date;
        }
        &__content{
          display: flex;
          justify-content: space-between;
          flex-direction: row;

          &-desc{
            flex-basis: 70%;
            width: 70%;
            color: map-get($mau, dark-gray);
            margin: map-get($size, margin-block-small) 0;
          }
          &-btn{
            flex-basis: 30%;
            width: 30%;

            .more{
              margin: 0;
              text-transform: uppercase;
            }
          }
        }
       }
      }
    }
  }
}

.releases-pagination{
  width: 315px;
  margin: 0 auto;
  .swiper-container{
    padding: 0 28px;
  }

  .releases-box{
    &-item{
      transition: .3s all linear;
      opacity: 1;
      visibility: hidden;
    }
    &-item.hidden{
      display: none;
      opacity: 0;
      visibility: visible;
    }
  }

  .active{
    background-color: rgba(map-get($mau, color-primary), .9);
    color: white;
    font-weight: bold;
  }


  &__button{
    cursor: pointer;

    border: 1px solid map-get($mau, color-primary);
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;

    height: 42px !important;
    width: 42px !important;
    line-height: 42px !important;

    span{
      display: block;
      pointer-events: none;
    }
    transition: .3s all linear;
    &:hover{
      background-color: map-get($mau, color-primary);
      color: white;
      font-weight: bold;
    }
  }

  &__next,
  &__prev{
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 12px;

    &::after{
      content:"";
    }
  }

  &__next{
    background-image: url('../assets/images/about/pagination_next.svg');
  }
  &__prev{
    background-image: url('../assets/images/about/pagination_prev.svg');
  }
}


@media (max-width:map-get($media, tl)){
  .about-media-releases__wrapper{
    // .about-media-releases{
    //   padding-bottom: map-get($size, margin-block-medium);
    // }
    // .about-media-releases__title{
    //   padding: map-get($size, margin-block-medium) 0;
    // }

    .about-media-releases__content {
      .releases-box{
        padding-bottom: map-get($size, margin-block-medium);
      }
      .releases-box-item__title{
        line-height: 23px;
      }
      .releases-box-item--padding{
        padding: 0;
      }
    }
  }
}

@media (max-width:map-get($media, tm)){
  .about-media-releases__wrapper{
    .about-media-releases__content {
      .releases-box-item{
        &:nth-of-type(4n){
          .releases-box-item__content-btn{
            margin-bottom: 0;
          }
        }
      }
      .releases-box-item__content{
        flex-direction: column;

        &-desc,
        &-btn{
          flex: 0 0 100%;
          width: 100%;
        }

        &-btn{
          display: block;
          margin-bottom: map-get($size, margin-block-small);

        }
        &-desc{
          -webkit-line-clamp: 3;
        }
      }
    }
  }
}


@media (max-width:map-get($media, mm)){
  .about-media-releases__wrapper{
    .about-media-releases{
      padding-bottom: map-get($size, margin-block-small);
    }
    .about-media-releases__content {
      .releases-box{
        padding-bottom: map-get($size, margin-block-small);
      }
    }
  }
}