.dealer-product__wrapper {
  .dealer-product {
    margin: 0 -3%;
    // @include spaceL(padding-bottom);

    &__title {
      text-align: center;
      // margin: map-get($size, margin-block-large) 0;
      @include spaceL(margin, 0);
    }
    $margin-box: 34px;
    &-list {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: flex-start;
      margin-left: -1%;
      margin-right: -1%;
      // @include spaceM(margin-bottom);
    }
    &-item {
      // margin: 0 $margin-box map-get($size, margin-block-large);
      flex: 0 0 33.3333%;
      padding: 0 1% 40px;

      &__link {
        // display: inline-block;
        display: flex;
        flex-direction: column;

        transition: 0.3s;
        width: 100%;
        height: 100%;

        &:hover {
          background-color: map-get($mau, gray-2);
        }
      }
      &__img {
        width: 100%;
        height: 336px;
        object-fit: cover;
        object-position: center;
      }
      &__content {
        padding: 5px 0;
        flex: 1;

        display: flex;
        flex-direction: column;
      }
      &__title {
        letter-spacing: 2px;
        font-weight: 700;
        margin: 12px 0;
        text-transform: uppercase;

        flex: 1;

        @include p-line-clamp(2);
        // min-height: 71px;
        // @media (max-width: map-get($media, tl)) {
        //   min-height: 65px;
        // }
        // @media (max-width: map-get($media, ml)) {
        //   min-height: 55px;
        // }
      }
      &__desc {
        padding: 12px 0;
        border-bottom: 1px solid map-get($mau, dark-gray);
        color: map-get($mau, dark-gray);
      }
    }

    .releases-pagination {
      width: 315px;
    }
  }
}
@media (max-width: map-get($media, ps)) {
  .dealer-product__wrapper .dealer-product-item {
    flex: 0 0 50%;
  }
}
@media (max-width: map-get($media, tl)) {
  .dealer-product__wrapper {
    .dealer-product {
      margin: 0;
    }
  }
  // .dealer-product__wrapper .dealer-product-item {
  //   flex: 0 0 50%;
  // }
  .dealer-product__wrapper .dealer-product-item__link {
    width: 100%;
  }
  .dealer-product__wrapper .dealer-product-item__img {
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
}
@media (max-width: map-get($media, ts)) {
  .dealer-product__wrapper .dealer-product-item {
    flex: 0 0 100%;
  }

  .dealer-product__wrapper .dealer-product-item__img {
    height: 434px;
  }
}

@media (max-width: map-get($media, ml)) {
  .dealer-product__wrapper .dealer-product-item {
    flex: 0 0 100%;
  }
  .dealer-product__wrapper .dealer-product-item__content {
    text-align: center;
  }
  .dealer-product__wrapper .dealer-product-item__img {
    height: 360px;
  }
}
