.dropdown-value{
    .dropdown-item{
        margin-right: 10px;
        padding: 5px 10px;
        border: solid 2px map-get($mau, color-primary);
        border-radius: 5px;
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;
        background: none;
        color: map-get($mau, dark-gray );

        &.error{
            border: solid 2px map-get($mau, error);
        }
        &.success{
            border: solid 2px map-get($mau, success);
        }
       
        .dropdown-caret{
            height: 100%;
            margin-left: auto;
            img{
                max-width: none;
                margin-left: 10px;
            }
        }
    }
    
}
    