@mixin media--minmax($minwidth, $maxwidth) {
  @media (min-width: $minwidth) and (max-width: $maxwidth) {
    @content;
  }
}

@mixin media--min($minwidth) {
  @media (min-width: $minwidth) {
    @content;
  }
}

@mixin media--max($maxwidth) {
  @media (max-width: $maxwidth) {
    @content;
  }
}

@mixin animationRight($time) {
  animation: widthRight $time ease forwards;
}

////////////////////////////////////////////////
//////////// FONT WEIGHT //////////////////////

@mixin thin($important: false) {
  @if ($important) {
    font-weight: 100 !important;
  } @else {
    font-weight: 100;
  }
}
@mixin extra-light($important: false) {
  @if ($important) {
    font-weight: 200 !important;
  } @else {
    font-weight: 200;
  }
}
@mixin light($important: false) {
  @if ($important) {
    font-weight: 300 !important;
  } @else {
    font-weight: 300;
  }
}
@mixin regular($important: false) {
  @if ($important) {
    font-weight: 400 !important;
  } @else {
    font-weight: 400;
  }
}
@mixin normal($important: false) {
  @if ($important) {
    font-weight: 400 !important;
  } @else {
    font-weight: 400;
  }
}
@mixin medium($important: false) {
  @if ($important) {
    font-weight: 500 !important;
  } @else {
    font-weight: 500;
  }
}
@mixin semi-bold($important: false) {
  @if ($important) {
    font-weight: 600 !important;
  } @else {
    font-weight: 600;
  }
}
@mixin bold($important: false) {
  @if ($important) {
    font-weight: 700 !important;
  } @else {
    font-weight: 700;
  }
}
@mixin extra-bold($important: false) {
  @if ($important) {
    font-weight: 800 !important;
  } @else {
    font-weight: 800;
  }
}
@mixin ultra-bold($important: false) {
  font-weight: 900;
}

//////////// FONT WEIGHT ///////////////////////
////////////////////////////////////////////////

@mixin size20px {
  font-size: 18px;
  @include rpmin(lg) {
    font-size: 20px;
  }
}
@mixin size24px {
  font-size: 20px;
  @include rpmin(lg) {
    font-size: 24px;
  }
}
@mixin size30px {
  font-size: 22px;
  @include rpmin(md) {
    font-size: 26px;
  }
  @include rpmin(lg) {
    font-size: 30px;
  }
}
@mixin size32px {
  font-size: 24px;
  @include rpmin(md) {
    font-size: 28px;
  }
  @include rpmin(lg) {
    font-size: 32px;
  }
}
@mixin size36px {
  font-size: 28px;
  @include rpmin(md) {
    font-size: 32px;
  }
  @include rpmin(lg) {
    font-size: 36px;
  }
}
@mixin size50px {
  font-size: 32px;
  @include rpmin(md) {
    font-size: 40px;
  }
  @include rpmin(lg) {
    font-size: 50px;
  }
}
