$gap: 12;

$gap-medium: 8;
$gap-small: 4;

$row-large: 55;
$row-medium: 40;
$row-small: 45;
.about-media-video__wrapper {
  // margin: map-get($size, margin-block-large) 0;
  @include spaceL(margin, 0);
  .about-media-video {
    @include spaceL(margin-bottom);
    .model {
      position: fixed;
      top: 0;
      // left: 0;
      left: 0;

      // display: flex;
      display: none;
      justify-content: center;
      align-items: center;
      &.active {
        display: flex;
        animation: showFlexRL $time-duration linear forwards;
      }

      width: 100vw;
      height: 100vh;
      background-color: map-get($mau, color-primary);
      z-index: 10000;

      &-closed {
        background-color: white;
        content: "";
        position: absolute;
        top: 0;
        right: 0px;
        width: 80px;
        height: 80px;

        border-bottom-left-radius: 50%;

        &::before,
        &::after {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          width: 50%;
          height: 3px;
          border-radius: 2px;
          background-color: map-get($mau, color-primary);
        }

        &::before {
          transform: translate(-50%, -50%) rotate(45deg);
        }
        &::after {
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }

      .video__model {
        width: 900px;
        height: 768px;

        &-item {
          width: 100%;
          height: 100%;
          position: relative;

          &--img {
            width: 100%;
          }

          &--title {
            text-align: center;
            color: white;

            // padding: map-get($size, margin-block-medium) 0;

            @include size20px;
            @include spaceM(padding-top);
            font-weight: 500;
          }
        }

        .swiper-container {
          padding: 45px;
        }
        .video__model-swiper {
          .model-button {
            &--next,
            &--prev {
              background-position: center;
              background-size: contain;
              background-repeat: no-repeat;

              &::after {
                content: "";
              }
            }

            &--next {
              background-image: url("../assets/images/about/pagination_next--white.png");
            }
            &--prev {
              background-image: url("../assets/images/about/pagination_prev--white.png");
            }
          }
        }
      }
    }

    &__list {
      display: grid;
      grid-auto-rows: $row-large + px;
      gap: $gap + px;
      margin-bottom: map-get($size, margin-block-medium);
    }

    $padding-large: 50 - $gap;
    $padding-medium: 30 - $gap;

    &__item:nth-of-type(1) {
      grid-column: 1 / span 9;
      grid-row: 1 / span 8;
    }
    &__item:nth-of-type(2) {
      grid-column: 10 / span 6;
      grid-row: 1 / span 4;
      padding-left: $padding-large + px;
    }
    &__item:nth-of-type(3) {
      grid-column: 10 / span 6;
      grid-row: 5 / span 4;
      padding-left: $padding-large + px;
    }
    &__item:nth-of-type(4) {
      grid-column: 1 / span 5;
      grid-row: 9 / span 4;

      padding-top: $padding-large + px;
      padding-right: $padding-medium + px;

      &::after {
        top: calc(50% + 19px);
        left: calc(50% + 9px);
      }
    }
    &__item:nth-of-type(5) {
      grid-column: 6 / span 5;
      grid-row: 9 / span 4;
      padding-top: $padding-large + px;
      padding-left: ($padding-medium / 2) + px;
      padding-right: ($padding-medium / 2) + px;

      &::after {
        top: calc(50% + 19px);
        left: calc(50% + 9px);
      }
    }
    &__item:nth-of-type(6) {
      grid-column: 11 / span 5;
      grid-row: 9 / span 4;
      padding-top: $padding-large + px;
      padding-left: $padding-medium + px;
      &::after {
        top: calc(50% + 19px);
        left: calc(50% + 9px);
      }
    }

    &__item {
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      &:hover::after {
        opacity: 0.7;
      }
      iframe {
        width: 100% !important;
        height: 100% !important;
        pointer-events: none;
      }
      &.about-media-video__item--video::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        $btn-size: 83px;

        width: $btn-size;
        height: $btn-size;

        border-radius: 50%;
        background-image: url("../assets/images/about/btn-pause.png");
        z-index: 1;

        transition: 0.3s all linear;
        cursor: pointer;
      }
    }
  }
}

@media (max-width: map-get($media, tl)) {
  $padding-large: 30 - $gap-medium;
  $padding-medium: 18 - $gap-medium;
  .about-media-video__wrapper {
    // margin: map-get($size, margin-block-medium) 0;
    .about-media-video__list {
      gap: $gap-medium + px;
      grid-auto-rows: $row-medium + px;
      margin-bottom: $padding-medium + px;
    }
    .about-media-video {
      &__item:nth-of-type(1) {
        grid-column: 1 / span 9;
        grid-row: 1 / span 8;
      }
      &__item:nth-of-type(2) {
        grid-column: 10 / span 6;
        grid-row: 1 / span 4;
        padding-left: $padding-large + px;
      }
      &__item:nth-of-type(3) {
        grid-column: 10 / span 6;
        grid-row: 5 / span 4;
        padding-left: $padding-large + px;
      }
      &__item:nth-of-type(4) {
        grid-column: 1 / span 5;
        grid-row: 9 / span 4;

        padding-top: $padding-large + px;
        padding-right: $padding-medium + px;

        &::after {
          top: calc(50% + 11px);
          left: calc(50% + 5px);
        }
      }
      &__item:nth-of-type(5) {
        grid-column: 6 / span 5;
        grid-row: 9 / span 4;
        padding-top: $padding-large + px;
        padding-left: ($padding-medium / 2) + px;
        padding-right: ($padding-medium / 2) + px;

        &::after {
          top: calc(50% + 11px);
          left: calc(50% + 5px);
        }
      }
      &__item:nth-of-type(6) {
        grid-column: 11 / span 5;
        grid-row: 9 / span 4;
        padding-top: $padding-large + px;
        padding-left: $padding-medium + px;
        &::after {
          top: calc(50% + 11px);
          left: calc(50% + 5px);
        }
      }
    }
  }
}

@media (max-width: map-get($media, tm)) {
  $padding-large: 0;
  $padding-medium: 0;
  .about-media-video__wrapper {
    .about-media-video__list {
      gap: $gap-medium + px;
    }
    .about-media-video {
      &__item:nth-of-type(1) {
        grid-column: 1 / span 8;
        grid-row: 1 / span 8;
      }
      &__item:nth-of-type(2) {
        grid-column: 9 / span 4;
        grid-row: 1 / span 4;
        padding-left: $padding-large + px;
      }
      &__item:nth-of-type(3) {
        grid-column: 9 / span 4;
        grid-row: 5 / span 4;
        padding-left: $padding-large + px;
      }
      &__item:nth-of-type(4) {
        grid-column: 1 / span 4;
        grid-row: 9 / span 4;
        padding-top: $padding-large + px;
        padding-right: $padding-medium + px;

        &::after {
          top: 50%;
          left: 50%;
        }
      }
      &__item:nth-of-type(5) {
        grid-column: 5 / span 4;
        grid-row: 9 / span 4;
        padding-top: $padding-large + px;
        padding-left: ($padding-medium / 2) + px;
        padding-right: ($padding-medium / 2) + px;

        &::after {
          top: 50%;
          left: 50%;
        }
      }
      &__item:nth-of-type(6) {
        grid-column: 9 / span 4;
        grid-row: 9 / span 4;
        padding-top: $padding-large + px;
        padding-left: $padding-medium + px;
        &::after {
          top: 50%;
          left: 50%;
        }
      }
    }
  }
}

@media (max-width: map-get($media, ml)) {
  $padding-large: 0;
  $padding-medium: 0;
  .about-media-video__wrapper {
    .about-media-video__list {
      gap: $gap-medium + px;
      grid-auto-rows: $row-small + px;
    }
    .about-media-video {
      &__item:nth-of-type(1) {
        grid-column: 1 / span 12;
        grid-row: 1 / span 8;
      }
      &__item:nth-of-type(2) {
        grid-column: 1 / span 6;
        grid-row: 9 / span 4;
        padding-left: $padding-large + px;
      }
      &__item:nth-of-type(3) {
        grid-column: 7 / span 6;
        grid-row: 9 / span 4;
        padding-left: $padding-large + px;
      }
      &__item:nth-of-type(4) {
        grid-column: 1 / span 4;
        grid-row: 13 / span 3;
        padding-top: $padding-large + px;
        padding-right: $padding-medium + px;

        &::after {
          top: 50%;
          left: 50%;
        }
      }
      &__item:nth-of-type(5) {
        grid-column: 5 / span 4;
        grid-row: 13 / span 3;
        padding-top: $padding-large + px;
        padding-left: ($padding-medium / 2) + px;
        padding-right: ($padding-medium / 2) + px;

        &::after {
          top: 50%;
          left: 50%;
        }
      }
      &__item:nth-of-type(6) {
        grid-column: 9 / span 4;
        grid-row: 13 / span 3;
        padding-top: $padding-large + px;
        padding-left: $padding-medium + px;
        &::after {
          top: 50%;
          left: 50%;
        }
      }
    }
  }
}

@media (max-width: map-get($media, mm)) {
  $padding-large: 0;
  $padding-medium: 0;
  .about-media-video__wrapper {
    // margin: map-get($size, margin-block-small) 0;
    .about-media-video__list {
      gap: $gap-small + px;
    }
    .about-media-video {
      &__item:nth-of-type(1) {
        grid-column: 1 / span 4;
        grid-row: 1 / span 4;
      }
      &__item:nth-of-type(2) {
        grid-column: 1 / span 4;
        grid-row: 5 / span 4;
        padding-left: $padding-large + px;
      }
      &__item:nth-of-type(3) {
        grid-column: 1 / span 4;
        grid-row: 9 / span 4;
        padding-left: $padding-large + px;
      }
      &__item:nth-of-type(4) {
        grid-column: 1 / span 4;
        grid-row: 13 / span 4;
        padding-top: $padding-large + px;
        padding-right: $padding-medium + px;

        &::after {
          top: 50%;
          left: 50%;
        }
      }
      &__item:nth-of-type(5) {
        grid-column: 1 / span 4;
        grid-row: 17 / span 4;
        padding-top: $padding-large + px;
        padding-left: ($padding-medium / 2) + px;
        padding-right: ($padding-medium / 2) + px;

        &::after {
          top: 50%;
          left: 50%;
        }
      }
      &__item:nth-of-type(6) {
        grid-column: 1 / span 4;
        grid-row: 21 / span 4;
        padding-top: $padding-large + px;
        padding-left: $padding-medium + px;
        &::after {
          top: 50%;
          left: 50%;
        }
      }
    }
  }
}

@media (max-width: map-get($media, ps)) {
  .about-media-video__wrapper .about-media-video .model .video__model {
    width: 90%;
    height: 90%;
  }
}
