@mixin rp($canvas) {
    @media screen and (max-width: map-get($media, #{$canvas})) {
        @content;
    }
}
@mixin rpmin($canvas) {
    @media screen and (min-width: map-get($minWidth, #{$canvas})) {
        @content;
    }
}
@mixin rpmax($canvas) {
    @media screen and (max-width: map-get($maxWidth, #{$canvas})) {
        @content;
    }
}
