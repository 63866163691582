.details-collections__wrapper {
    // margin-bottom: 100px;
    @include spaceL(margin, 0);
    .details-collections .details-collections__title {
        // padding: 80px 0;
        @include spaceM(margin-bottom);

        text-align: center;
        display: block;
    }

    .details-collections__section {
        .collections__section {
            margin-top: -48px;
            .collection__items {
                margin-top: 48px;

                flex: 0 0 33.3333%;
                max-width: 33.3333%;
                @media (max-width: map-get($media, ps)) {
                    flex: 0 0 50%;
                    max-width: 50%;
                }

                @media (max-width: map-get($media, ts)) {
                    flex: 0 0 100%;
                    max-width: 100%;
                }

                .items {
                    .items__img {
                        // height: 20%;
                        // height: 336px;

                        img {
                            width: 100%;
                            // height: 336px;

                            // @media (max-width: map-get($media, ts)) {
                            //     height: 434px;
                            // }
                            // @media (max-width: map-get($media, ml)) {
                            //     height: 360px;
                            // }

                            box-shadow: 0 3px 6px rgba(0,0,0,.16);
                        }
                    }
                    .items__title {
                        margin-top: 40px;
                        padding: 13px 0;

                        @media (max-width: 768px) {
                            margin-top: 24px;
                            padding: 0;
                            
                        }
                        &>* {
                            color: map-get($mau, color-primary);
                            height: 72px;
                            text-transform: uppercase;
                            // padding: 13px 0;
                            display: block;
                            font-weight: 700;

                            // @include p-line-clamp(2);
                            // min-height: 71px;
                            // @media (max-width: map-get($media, tl)) {
                            //     min-height: 65px;
                            // }
                            // @media (max-width: map-get($media, ml)) {
                            //     min-height: 55px;
                            // }

                            &:hover{
                                opacity: .7;
                            }
                        }
                    }
                    .items__content,
                    .items__price {
                        margin-top: 28px;
                        @media (max-width: 768px) {
                            margin-top: 16px;
                        }
                        // font-size: 18px;
                    }
                    .items__description {

                        position: relative;
                        margin-top: 20px;
                        border-top: solid 1px map-get($mau, dark-gray);
                        border-bottom: solid 1px map-get($mau, dark-gray);
                        height: 36px;
                        p {
                            @include p-line-clamp(1);
                            line-height: 36px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: map-get($media, ps)) {
    .details-collections__wrapper .collections__section {
        flex-wrap: wrap;
        .collection__items {
            flex: 0 0 33.3333%;
            max-width: 33.3333%;
        }
    }
}
@media (max-width: map-get($media, tm)) {
    .details-collections__wrapper .collections__section {
        .collection__items {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }
}
@media (max-width: map-get($media, ml)) {
    .details-collections__wrapper .collections__section {
        .collection__items {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
}
