.about-story-down__wrapper {
  // .container{
  // padding: 0 4%;
  // .breadcrumb-wrapper{
  //   margin: 0 -3%;
  // }

  .btn a {
    // margin: 40px 0;
    @include spaceM(margin-top);
    padding: 8px 16px 8px 5px;
    border: solid 2px #041e42;
    border-radius: 5px;
    cursor: pointer;
  }

  .our-story-down {
    // margin: map-get($size, margin-block-large) 0;
    @include spaceL(margin, 0);

    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    &__title {
      flex: 0 0 100%;
      width: 100%;
      @include spaceM(margin-bottom);

      &--heading {
        text-align: left;
        // padding-right: 20%;
      }
    }
    &__content {
      flex: 0 0 86%;
      width: 86%;

      @include rpmax(xl) {
        flex: 0 0 100%;
        width: 100%;
      }

      &--padding {
        // padding-right: 20%;
      }
      .btn {
        display: flex;
        justify-content: flex-start;

        .btn-arrow.btn-arrow--white {
          border-radius: 12px;
          padding-left: 12px;
          border: 2px solid map-get($mau, color-primary);

          box-shadow: 0 0 5px rgba(map-get($mau, color-primary), 0.3);
        }
      }
      // .more{
      //   margin-bottom: 0;
      // }
    }

    $size-paragraph-large: 115px;

    // &__paragraph {
    //   position: relative;
    //   &::before {
    //     position: absolute;
    //     // content: '\275E';
    //     content: "";
    //     background-image: url("../assets/icons/our-story.png");
    //     background-size: contain;
    //     background-repeat: no-repeat;
    //     background-position: center;

    //     width: 48px;
    //     height: 48px;
    //     top: calc(100% - 24px);
    //     left: 100%;
    //     transform-origin: left;
    //   }
    // }
    // }
  }
}

@media (max-width: map-get($media, tl)) {
  .about-story-down__wrapper {
    .our-story-down {
      flex-direction: column;
      // margin: 0;
      &__paragraph {
        margin: 18px 0;
        &::before {
          width: 36px;
          height: 36px;
          top: calc(100% - 18px);
        }
      }
      &__content {
        &--padding {
          padding-right: 36px;
        }
      }
    }
  }
}

@media (max-width: map-get($media, mm)) {
  .about-story-down__wrapper {
    .our-story-down {
      &__title {
        &--heading {
          padding-right: initial;
          text-align: center;
        }
      }
      &__paragraph {
        margin: 18px 0;
      }
      &__content {
        &--padding {
          padding-right: 36px;
        }
      }
    }
    .btn .btn-arrow.btn-arrow--white {
      font-size: 14px;
      padding: 8px 8px;
      @media (max-width: 425px) {
        font-size: 13px;
      }
    }
  }
}
