.header_wapper {
    position: fixed;
    .container {
        position: static;
        display: flex;
        justify-content: space-between;
        padding: 0;
    }
    // line-height: 103px;
    line-height: 92px;

    z-index: 1000;
    background-color: white;
    box-shadow: 0px 7px 10px #00000036;
    top: 0;
    left: 0;
    right: 0;
}

.hidden-none {
    display: none;
}

.header-top-section-left {
    justify-content: center;
    align-items: center;
    width: 260px;
    .as-logo {
        height: 100%;
        width: 260px;
        display: inline-block;
        transition: 0.3s;
        &:hover,
        &:active,
        &:focus {
            opacity: 0.7;
        }

        img {
        }
    }
}
.btn-menu {
    display: none;
}
.nav-bar-center {
    @extend .flex-center;
    flex-direction: row;
    white-space: nowrap;

    .navbar-item {
        // font-size: 16px;
        &:not(:first-child) {
            margin-left: 48px;
        }
        .nav-bar-link {
            display: block;
            // @include font-p-medium;
            // color: map-get($mau, color-primary);

            // font-family: "UTM-NEO-Sans-Intel", sans-serif;
            @include text-nav-header;
            text-transform: uppercase;
        }
        .nav-bar-link:hover,
        .nav-bar-link:active,
        .nav-bar-link:focus {
            width: 100%;
            height: 100%;
            font-weight: bold;
        }

        @media (max-width: map-get($media, ps)) {
            font-size: 16px;
        }
    }
}

.header-top-section-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    &__list {
        height: 35px;
        display: flex;
        flex-direction: row;
        align-items: center;
        vertical-align: middle;

        li {
            align-items: center;
            list-style: none;
            line-height: normal;

            & > a {
                padding: 0 7px;
                // color: #041e42;
                color: #a32732;

                transition: 0.3s;
                &:hover,
                &:active,
                &:focus {
                    opacity: 0.7;
                }
            }
        }
        li + li {
            // border-left: 1px solid gray;
        }

        .user {
            border-left: none;
            position: relative;
            user-select: none;
            // &__img {
            //     &.active {
            //         & ~ .user-box {
            //             display: block;
            //         }
            //     }
            // }
            .user-box {
                position: absolute;
                top: 100%;
                left: 50%;
                background-color: white;
                transform: translatex(-50%);
                display: none;

                &__content {
                    border-top: 2px solid map-get($mau, color-primary);
                    margin-top: 16px;
                    padding: 5px 12px;
                }
                &__item {
                    display: block;
                    position: relative;
                    cursor: pointer;
                    white-space: nowrap;

                    &::before {
                        content: "\2022";
                        position: absolute;
                        top: 50%;
                        left: -2%;
                        transform: translateY(-50%);
                    }
                }
            }
        }
        .shop-cart {
            position: fixed;
            top: 212px;
            right: 0;
            z-index: 100;

            // position: relative;
            @include font-p-small;

            .shop-cart__icon {
                display: flex;
                justify-content: center;
                align-items: center;

                width: 62px;
                height: 64px;
                background-color: #a32732;
                border-radius: 10px 0 0 10px;

                &.active {
                    & ~ .shop-cart-box {
                        animation: show-popup-block $time-duration linear forwards;
                        display: block;
                    }
                }
                &.after--hidden {
                    &::after {
                        display: none;
                    }
                }

                &::after {
                    content: attr(data-number);
                    position: absolute;
                    top: 12px;
                    right: 5px;
                    padding: 0 4px;
                    height: 18px;
                    min-width: 18px;

                    // transform: translate(0%, -60%);
                    border-radius: 50%;
                    border: 1px solid #000;
                    color: #fff;
                    font-size: 10px;
                    font-weight: 400;
                    @include font2;
                    line-height: 18px;
                    white-space: nowrap;
                    text-align: center;
                    background-color: #4f0007;

                    // // color: rgba(map-get($mau, color-primary), 1);
                    // font-weight: bold;
                    // font-size: 14px;
                    // min-width: 24px;
                    // min-height: 24px;

                    // padding: 2px 8px;
                    // background-color: map-get($mau, gray-2);
                    // text-align: center;
                    // line-height: 20px;
                }

                img {
                    pointer-events: none;
                }
            }

            &-box {
                display: none;

                position: absolute;
                top: 100%;
                right: 0%;

                margin-top: 12px;
                width: 500px;
                max-height: 450px;
                overflow-y: scroll;

                background-color: white;
                box-shadow: 0 0 5px rgba(map-get($mau, dark-gray), 0.3);

                clip-path: circle(14.6% at 50% 0);
                &--close {
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 36px;
                    height: 36px;
                    cursor: pointer;

                    &:hover {
                        background-color: map-get($mau, gray-1);
                    }

                    &::before,
                    &::after {
                        content: "";
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 70%;
                        height: 2px;
                        background-color: map-get($mau, dark-gray);
                        border-radius: 1px;
                    }
                    &::before {
                        transform: translate(-50%, -50%) rotate(45deg);
                    }
                    &::after {
                        transform: translate(-50%, -50%) rotate(-45deg);
                    }
                }
                &--margin {
                    position: relative;
                    margin: 16px;
                }

                &__title {
                    font-weight: bold;
                    letter-spacing: 1.5px;
                    line-height: 24px;

                    margin-bottom: map-get($size, margin-block-small);
                    display: block;
                }
                &__button {
                    outline: none;
                    // background-color: map-get($mau, gray-1);
                    background-color: map-get($mau, color-primary);
                    color: #fff;

                    width: 100%;
                    line-height: 46px;
                    text-align: center;
                    font-weight: bold;
                    border: 0;
                    display: block;

                    &:hover,
                    &:active {
                        box-shadow: 0 0 8px rgba(map-get($mau, dark-gray), 0.3);
                    }
                }
                &__list {
                    margin: 24px 0;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    max-height: 156px;
                    overflow-y: scroll;

                    // &::-webkit-scrollbar {
                    //     width: 4px;
                    // }

                    // /* Track */
                    // &::-webkit-scrollbar-track {
                    //     box-shadow: inset 0 0 5px map-get($mau, gray-1);
                    //     border-radius: 10px;
                    // }

                    // /* Handle */
                    // &::-webkit-scrollbar-thumb {
                    //     background: map-get($mau, white);
                    //     border-radius: 10px;
                    // }

                    // /* Handle on hover */
                    // &::-webkit-scrollbar-thumb:hover {
                    //     background: rgba(map-get($mau, dark-gray), 0.7);
                    // }

                    box-shadow: 0 0 4px rgba(map-get($mau, dark-gray), 0.1);
                }
                &__item {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    padding-right: 12px;

                    border: 0;
                    &:not(:first-child) {
                        margin: 12px 0;
                    }

                    &--img {
                        background-color: map-get($mau, gray-1);
                        width: 70px;
                        height: 55px;
                        object-fit: cover;
                        object-position: center;
                    }
                    &--right {
                        flex-basis: 30%;
                        width: 30%;

                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }
                    &--content {
                        flex-basis: 50%;
                        width: 50%;
                    }
                    &--amount {
                        width: 46px;
                        line-height: 46px;
                        white-space: pre-wrap;
                        font-weight: bold;
                        background-color: map-get($mau, gray-1);
                        text-align: center;
                    }
                    &--x {
                        width: 36px;
                        height: 36px;
                        position: relative;

                        &::before,
                        &::after {
                            content: "";
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            width: 70%;
                            height: 2px;
                            background-color: map-get($mau, dark-gray);
                            border-radius: 1px;
                        }
                        &::before {
                            transform: translate(-50%, -50%) rotate(45deg);
                        }
                        &::after {
                            transform: translate(-50%, -50%) rotate(-45deg);
                        }
                    }

                    &--price {
                        font-weight: bold;
                        @include font-p;
                    }
                }
                &__total {
                    width: 100%;
                    height: 56px;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;
                    align-items: center;
                    padding-right: 20%;
                    background-color: map-get($mau, gray-1);

                    &--desc {
                        @include size20px;
                    }
                    &--unit {
                        font-weight: bold;
                    }
                    &--price {
                        font-weight: bold;
                    }
                }
            }
        }

        .find {
            position: relative;
            &__img {
                &.active ~ .find-box {
                    display: flex;
                }
            }
            &-box {
                position: absolute;
                top: calc(100% + 38px);
                right: -10px;
                padding: 8px 12px;
                background: white;
                box-shadow: 0 0 5px rgba(map-get($mau, dark-gray), 0.7);
                display: none;
                justify-content: center;
                align-items: center;

                input[type="search"] {
                    padding: 5px 40px 5px 12px;
                    height: 36px;
                    width: 320px;
                    max-width: 100%;
                    border-radius: 5px;
                    border: 1px solid rgba(map-get($mau, color-primary), 0.3);
                    &:focus {
                        border: 2px solid rgba(map-get($mau, color-primary), 0.7);
                    }
                }

                &--submit {
                    width: 40px;
                    line-height: 36px;
                    background: transparent;
                    border: none;
                    position: absolute;
                    top: 50%;
                    right: 12px;
                    transform: translateY(-50%);
                    background: transparent;

                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;

                    &:hover {
                        background-color: rgba(map-get($mau, dark-gray), 0.4);
                    }
                }
            }
        }

        .languages {
            display: flex;
            margin-right: 12px;
            &-item {
                &:not(:first-child) {
                    margin-left: 6px;
                }
                & > a {
                    display: block;
                    width: 28px;
                    height: 20px;
                    position: relative;

                    &::after {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: #2f2f2f;
                        opacity: 0.8;
                        user-select: none;
                        pointer-events: none;
                        transition: 0.2s all ease-in-out;
                    }
                    img {
                        display: block;
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                    &:hover {
                        &::after {
                            opacity: 0;
                        }
                    }
                }
                &.is-actived {
                    & > * {
                        &::after {
                            content: none;
                        }
                    }
                }
            }
        }
    }
    &__ham {
        margin-left: 12px;
        height: 45px;
        width: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        user-select: none;

        .hamberger__img {
            cursor: pointer;
            height: 19px;
            width: 23px;
            // background-image: url("../assets/icons/ham.png");
            // background-repeat: no-repeat;
            // background-size: contain;
            // background-position: center;
            position: relative;

            &::after,
            &::before {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                height: 0;
                border-top: 2px solid #000;
                border-radius: 4px;

                transition: 0.2s all ease-in-out;
            }
            &::before {
                top: calc(50% - 7px);
            }
            &::before {
                box-shadow: 0 7px 0 #000;
            }
            &::after {
                top: calc(50% + 7px);
            }

            &.active {
                // background-image: url("../assets/icons/ham-close.png");

                &::before {
                    top: 50%;
                    box-shadow: 0 0 0 #000;
                    transform: translate(-50%, -50%) rotate(45deg);
                }
                &::after {
                    top: 50%;
                    transform: translate(-50%, -50%) rotate(-45deg);
                }

                & ~ .hamberger-box {
                    animation: show-hamberger $time-duration linear forwards;
                    display: block;
                }
            }
        }

        &:hover {
            // background-color: map-get($mau, gray-2);

            .hamberger__img {
                opacity: 0.8;
            }
        }
    }
    .hamberger {
        position: absolute;
        top: map-get($size, header-large);
        right: 0;
        width: 100%;
        height: calc(100vh - #{map-get($size, header-large)});
        background-color: rgba(map-get($mau, dark-gray), 0.1);
        display: none;
    }
    .hamberger-box {
        // animation: show-hamberger-close $time-duration linear forwards;
        display: none;
        z-index: 111;
        position: absolute;
        top: map-get($size, header-large);
        right: 0;
        width: 100%;
        max-width: 450px;
        max-height: calc(100vh - #{map-get($size, header-large)});
        overflow-y: scroll;
        background-color: map-get($mau, gray-1);

        box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
        // &::-webkit-scrollbar {
        //     width: 4px;
        // }
        // /* Track */
        // &::-webkit-scrollbar-track {
        //     box-shadow: inset 0 0 5px map-get($mau, gray-1);
        //     border-radius: 10px;
        // }
        // /* Handle */
        // &::-webkit-scrollbar-thumb {
        //     background: map-get($mau, white);
        //     border-radius: 10px;
        // }
        // /* Handle on hover */
        // &::-webkit-scrollbar-thumb:hover {
        //     background: rgba(map-get($mau, dark-gray), 0.7);
        // }

        &__list {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
        }
        // &__item:not(:last-child){
        // }
        &__item {
            width: 100%;
            &:not(:first-child) {
                .hamberger-box__link {
                    border-top: 1px solid map-get($mau, dark-gray);
                }
            }
            &:hover {
                background-color: map-get($mau, color-primary);
                opacity: 0.8;
                .hamberger-box__link {
                    color: white;
                }
            }
            &.hamberger-box__item-nav {
                display: none;
                visibility: hidden;
                opacity: 0;
            }

            &.active {
                background-color: map-get($mau, color-primary);
                .hamberger-box__link {
                    color: white;
                }
                .box-sub {
                    max-height: initial;

                    background-color: map-get($mau, gray-1);
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;

                    &__item {
                        width: 100%;
                        &:hover {
                            background-color: rgba(map-get($mau, color-primary), 0.7);
                            .box-sub__link {
                                color: white;
                            }
                            opacity: 0.8;
                        }
                        &:not(:first-child) {
                            .box-sub__link {
                                border-top: 1px solid rgba(map-get($mau, dark-gray), 0.7);
                            }
                        }
                    }
                    &__link {
                        display: block;
                        width: 60%;
                        margin: 0 auto;

                        color: map-get($mau, color-primary);
                        text-transform: uppercase;
                        font-size: 16px;

                        line-height: 45px;
                    }
                }

                .hamberger-box__link {
                    &.box-flus {
                        &::after {
                            content: "\268A";
                        }
                    }
                }
            }
            transition: max-height 2s linear;

            .box-sub {
                max-height: 0;
                display: none;
                transition: max-height 2s linear;
            }
        }
        &__link {
            display: block;
            width: 80%;
            margin: 0 auto;
            line-height: map-get($size, margin-block-large);
            color: map-get($mau, color-primary);
            text-transform: uppercase;

            &.box-flus {
                position: relative;
                &::after {
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 20px;
                    height: 20px;
                    content: "\271A";
                }
            }
        }
    }
}

@media (max-width: map-get($media, ps)) {
    .header-top-section-right {
        .hamberger-box {
            &__item {
                &.hamberger-box__item-nav {
                    display: block;
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
}

.dropdown {
    display: inline-block;
    width: 100%;
    left: 0;
    &-content {
        display: none;
        position: absolute;
        // background-color: #041e42;
        background-color: #a32732;

        line-height: map-get($size, header-large);
        z-index: 1;
        width: 100%;
        left: 0;
        a.nav-bar-link {
            text-decoration: none;
        }
        .nav-bar-section {
            @extend .flex-center;
            flex-direction: row;

            height: map-get($size, header-large);
            .nav {
                display: flex;
                text-align: center;
                justify-content: center;
                align-self: center;
                padding: 0 26px;
                a {
                    line-height: normal;
                    transition: 0.2s all ease-in-out;

                    &:hover {
                        transform: scale(1.2);
                    }
                    p {
                        color: white;
                        line-height: normal;
                    }
                }
            }
        }
    }
}
.dropdown:hover .dropdown-content {
    display: block;
}
.dropdown:hover .nav-bar-link {
    font-weight: bold;
}
.btn-menu:hover {
    cursor: pointer;
}

@media (max-width: map-get($media, ps)) {
    .header_wapper {
        line-height: 86px;
    }
    .header-top-section-left .as-logo img {
        // height: 50px;
    }
    .header-top-section-right__list .find-box {
        top: calc(100% + 30px);
    }
    // .block-featured {
    //     padding-top: 86px !important;
    // }
    .header-top-section-right {
        .hamberger-box {
            top: 86px;
            max-height: calc(100vh - 86px);
        }
        .header-top-section-right__list .shop-cart-box {
            margin: 0;
        }
        .shop-cart {
            // position: static;

            // max-height: calc(100vh - 86px);
            // max-width: 100%;
        }
    }
}

@media (max-width: map-get($media, ps)) {
    .nav-bar-center {
        display: none;
    }
}

@media (max-width: map-get($media, ts)) {
    .header_wapper {
        line-height: 78px;
        .header-top-section-right {
            .hamberger-box {
                top: 78px;
                max-height: calc(100vh - 78px);
            }
        }
    }
    .header-top-section-left .as-logo img {
        // height: 45px;
    }
    .header-top-section-right__list .find-box {
        top: calc(100% + 27px);
    }
    // .block-featured {
    //     padding-top: 78px !important;
    // }
}
@media (max-width: map-get($media, ml)) {
    .header-top-section-right {
        .hamberger-box {
            max-width: 100%;
        }

        .shop-cart-box {
            max-height: calc(100vh - 78px);
            max-width: 100%;
        }
    }

    .header-top-section-left {
        width: 150px;
        .as-logo {
            width: 100%;
        }
    }
}

@media (max-width: map-get($media, mm)) {
    .header-top-section-right__list li.phone {
        display: none;
    }
    .header-top-section-left .as-logo img {
        // height: 30px;
    }
    .header-top-section-right__list li + li {
        border-left: 0;
    }
    .header-top-section-right__list .find {
        position: static;
    }
    .header-top-section-right__list .find-box {
        top: 100%;
        right: 0;
        width: 100%;
    }
    .header-top-section-right__list .find-box input[type="search"] {
        width: 100%;
    }
}
