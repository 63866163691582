.about-story-business__wrapper {
  background-color: map-get($mau, gray-2);

  .our-story-business {
    // padding: map-get($size, margin-block-large) 0;
    @include spaceL(padding, 0);

    // &__title{
    //   // margin-bottom: map-get($size, margin-block-large);
    //   // @include spaceL(margin-bottom);

    // }

    &__list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;

      margin-left: -4px;
      margin-right: -4px;
    }

    &__item {
      width: 20%;
      padding-left: 8px;
      padding-right: 8px;

      display: flex;
      flex-direction: column;
      @include spaceL(margin-top);

      &-img {
        width: 65px;
        height: 65px;
        // border-radius: 50%;
        object-fit: cover;
        object-position: center;
        // margin-bottom:map-get($size, margin-block-medium);
        @include spaceM(margin-bottom);
      }

      &-title {
        display: block;

        @include p-line-clamp(2);
        min-height: 71px;
        @media (max-width: map-get($media, tl)) {
          min-height: 65px;
        }
        @media (max-width: map-get($media, ml)) {
          min-height: 55px;
        }
      }
      &-desc {
        margin-top: 8px;
        color: map-get($mau, dark-gray);

        display: block;
        -webkit-line-clamp: initial;
        -webkit-box-orient: initial;
        overflow: initial;

        @include rpmax(lg) {
          width: 80%;
        }
        @include rpmax(ml) {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: map-get($media, ps)) {
  .about-story-business__wrapper {
    .our-story-business {
      &__list {
        justify-content: flex-start;
      }
      &__item {
        flex-basis: 33.3333%;
      }
    }
  }
}
@media (max-width: map-get($media, tl)) {
  .about-story-business__wrapper {
    .our-story-business {
      &__item {
        flex-basis: 50%;
        // margin-top: map-get($size, margin-block-medium);
        &-img {
          margin-bottom: map-get($size, margin-block-small);
        }
      }
    }
  }
  .about-story-business__wrapper .our-story-business {
    padding: 36px 0;
  }

  // .about-story-business__wrapper .our-story-business__title{
  //   margin-bottom: 36px;
  // }
}
@media (max-width: map-get($media, mm)) {
  .about-story-business__wrapper {
    .our-story-business {
      &__title {
        &--heading {
          text-align: center;
        }
      }
      &__item {
        flex-basis: 100%;

        justify-content: center;
        align-items: center;

        &-title {
          text-align: center;
        }
        &-desc {
          text-align: center;
        }
      }
    }
  }
}
