.payment-success {
    &-wrapper {
        @include spaceXL(padding-top);

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;

        .name {
            // margin-top: 60px;
            @include space(margin-top, 60px);
        }

        .desc {
            @include size20px;
            font-weight: 500;
            color: #707070;

            margin-top: 18px;
            // margin-bottom: 40px;
            @include space(margin-bottom, 40px);

            text-align: center;
        }

        .btn-back {
            & > * {
                display: inline-flex;
                justify-content: center;
                align-items: center;

                min-width: 269px;
                padding: 12px 24px;
                border-radius: 6px;

                color: #fff;
                background: #a32732;

                @include size20px;
                font-weight: 500;

                transition: 0.2s all ease-in-out;

                &:hover {
                    background: #520007;
                }
            }
        }
    }
}
