.pagination,
.pagination > ul {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
}

.pagination {
    flex: 0 0 100%;
    max-width: 100%;

    --vh: 20px;
    --vw: 20px;

    --sText: 14px;

    --swArrow: 6px;
    --shArrow: 12px;

    @include rpmin(lg) {
        --vh: 24px;
        --vw: 24px;

        --wh: 24px;

        --sText: 14px;

        --swArrow: 6px;
        --shArrow: 12x;
    }

    --bgBtn: transparent;
    --text: #525252;

    --bgBtnHover: #a32732;
    --textHover: #fff;

    --bgArrow: transparent;
    --textArrow: #525252;

    --bgArrowHover: transparent;
    --textArrowHover: #a32732;

    list-style-type: none;

    & > ul {
        width: calc(100% + 16px);

        list-style-type: none;
        display: flex;
        align-items: center;

        li {
            display: flex;
            justify-content: center;
            align-items: center;

            cursor: pointer;
            user-select: none;

            &:not(.is-actived):hover > a {
                color: var(--textHover);
                background-color: var(--bgBtnHover);
            }
            & > a {
                display: inline-flex;

                font-size: var(--sText);
                font-weight: 500;

                justify-content: center;
                align-items: center;

                min-width: var(--vw);
                height: var(--vh);
                padding: 0 4px;

                color: var(--text);
                background-color: var(--bgBtn);

                transition: 0.3s ease-in-out;
                border-radius: 2px;
            }

            // @include size()
            svg {
                width: var(--swArrow);
                height: var(--shArrow);
            }

            &.is-actived > a {
                cursor: initial;
                color: var(--textHover) !important;
                background-color: var(--bgBtnHover) !important;
            }
        }

        li + li {
            margin-left: 10px;
            @include rpmin(lg) {
                margin-left: 23px;
            }
        }

        li.next,
        li.prev {
            background-color: var(--bgArrow);
            transition: 0.3s ease-in-out;

            display: flex;
            justify-content: center;
            align-items: center;

            & > a {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            svg {
                width: auto;
                height: auto;
            }

            path {
                &[fill] {
                    fill: var(--textArrow);
                }
                &[stroke] {
                    stroke: var(--textArrow);
                }
            }

            &:not(.btn-hidden):hover {
                background-color: var(--bgArrowHover);

                path {
                    &[fill] {
                        fill: var(--textArrowHover);
                    }
                    &[stroke] {
                        stroke: var(--textArrowHover);
                    }
                }
            }

            &.btn-hidden {
                display: none;
                cursor: initial;
                opacity: 0.3;
            }
        }

        // li.prev.btn-hidden + li{
        //     margin-left: 0;
        // }

        li.prev {
            display: flex;
            align-items: center;
            justify-content: center;

            a {
                display: flex;
                justify-content: center;
                align-items: center;

                margin-right: 40px;
            }
            svg {
                transform: rotate(180deg);
            }
        }
        li.next {
            display: flex;
            align-items: center;
            justify-content: center;

            a {
                width: 100%;
                height: 100%;

                display: flex;
                justify-content: center;
                align-items: center;

                margin-left: 40px;
            }
        }

        li.dot {
            user-select: none;
            pointer-events: none;

            background-color: var(--bgBtn);
        }
    }
}
