.product-detail-section {
  &-wrapper {
    @include spaceL(padding-top);
    @include spaceL(padding-bottom);

    .detail-infor__liveview {
      padding: 0 52px 0 0;

      @include rpmax(ml) {
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0 0 30px 0;
      }

      .mySwiper2 {
        margin: 10px 0 10px 0;

        .swiper-wrapper {
          .swiper-slide {
            * {
              width: 100%;
              height: 100%;
            }
            cursor: zoom-in;
            &.swiper-slide-zoomed {
              cursor: zoom-out;
            }

            img {
              box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
            }
          }
        }
      }
      .mySwiper {
        width: 90%;

        .swiper-wrapper {
          .swiper-slide {
            cursor: pointer;
            * {
              border-radius: 5px;
            }
          }
        }
      }
      .swiper-btn__nav-detail {
        position: relative;
        .swiper-slide {
          &.is-actived {
            border-radius: 5px;
            border: 2px solid #520007;
          }
        }
        .swiper-button-prev,
        .swiper-button-next {
          &::after {
            display: none;
          }
          position: absolute;
          top: 50%;
          user-select: none;
        }

        .swiper-button-prev {
          left: 0px;
          padding: 0 7px 0 0;
          @media (max-width: 425px) {
            left: 0px;
          }
        }
        .swiper-button-next {
          right: 0px;
          padding: 0 0 0 7px;
          @media (max-width: 425px) {
            right: 0px;
          }
        }
      }
    }
    .detail-infor__content {
      @include rpmax(ml) {
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }
      .product-infor {
        &__title {
          // margin-bottom:72px;
          // font-size: 40px;

          @include font-heading(4);
          font-weight: 600;

          // min-height: 120px;
          margin-bottom: 12px;
        }
        &-item {
          @include font2;
          margin-top: 10px;

          display: flex;
          flex-wrap: wrap;

          .rating {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            font-size: 12px;
            color: #a32732;
            font-weight: 500;

            @include font2;
            .icon {
              width: 16px;
              height: 16px;
              & > * {
                width: 100%;
                max-height: 100%;
              }
            }
          }

          .reviews {
            margin-left: 16px;
            border-left: 1px solid #d3d3d3;
            padding-left: 16px;
            @include font2;
            font-size: 12px;
            font-weight: 500;
            color: #000;
            span {
              color: #a32732;
            }
          }
          &__prices {
            .prices-pre {
              margin-top: 4px;
              @include font2;
              color: #707070;
              font-size: 14px;
              line-height: 24px;
              text-decoration: line-through;
            }

            .prices-listed {
              width: 100%;
              @include font2;
              color: #a32732;
              @include size36px;
              font-weight: 600;

              display: flex;
              align-items: center;
              flex-wrap: wrap;

              P:not([class="promo"]) > *:last-child {
                margin-right: 20px;
              }
            }

            .promo {
              font-size: 14px;
              line-height: 21px;
              background-color: #e80826;
              color: #fff;
              border-radius: 4px;
              padding: 3px 8px;
              font-weight: 400;
              span {
                font-weight: 600;
                // margin-right: 0px !important;
              }
            }
          }

          &__desc {
            margin-top: 30px;

            color: #707070;
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
          }

          &__name {
            color: #000;
            font-weight: 600;
            @include size20px;
            // line-height: 24px;
            min-width: 132px;

            margin-right: 16px;
            @include rpmax(lg) {
              display: inline-flex;
              align-items: center;
            }
          }
          &.credit {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;

            .credit-ls {
              flex: 1;

              font-size: 16px;
              font-weight: 500;
              color: #000;

              & > ul {
                list-style-type: none;

                & > li {
                  font-size: 16px;
                  font-weight: 500;
                  color: #000;

                  &:not(:first-child) {
                    display: none;
                  }
                }
              }
            }
            .viewmore {
              display: inline-block;

              color: #a32732;
              text-decoration: underline;
              font-size: 16px;
              font-weight: 500;
            }
          }

          &__selects {
            flex: 0 0 100%;
            margin-top: 2px;
          }
          &.selects {
            margin-top: 24px;

            .selects-item {
              padding-top: 10px;

              display: flex;
              // justify-content: space-between;
              align-items: center;

              &-name {
                width: 132px;
                margin-right: 16px;

                font-size: 18px;
                font-weight: 500;
                color: #000;
              }

              &-option {
                flex: 1;
                max-width: 304px;
                position: relative;

                select {
                  cursor: pointer;
                  @include font2;
                  appearance: none;

                  width: 100%;
                  border: 1px solid #520007;
                  border-radius: 4px;
                  background: transparent;
                  outline: none;
                  font-size: 14px;
                  font-weight: 500;
                  color: #000;
                  padding: 12px 32px 12px 16px;
                }

                .arrow {
                  position: absolute;
                  top: 0;
                  right: 16px;
                  width: 11px;
                  user-select: none;
                  pointer-events: none;
                  height: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }
              }
            }
          }

          &__features {
            flex: 1;
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            margin-top: -10px;
            margin-left: -10px;

            @include rpmax(lg) {
              margin-top: 10px;
              flex: 0 0 100%;
            }
          }
          &.features {
            margin-top: 24px;

            .features-item {
              padding-left: 10px;
              padding-top: 10px;
              min-width: 33.3333%;

              @include rpmax(sm) {
                width: 50%;
              }

              & > label {
                max-width: 100%;
                width: 100%;

                padding: 12px 24px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                font-weight: 500;
                color: #000;
                border: 1px solid #520007;
                border-radius: 4px;
                transition: 0.3s all ease-in-out;

                cursor: pointer;

                &:hover {
                  background-color: #a32732;
                  color: #fff;
                }

                position: relative;

                @include rpmax(lg) {
                  padding: 12px 17px;
                }
              }
              input[type="radio"] {
                display: none;
                &:checked {
                  & ~ label {
                    background-color: #a32732;
                    color: #fff;
                  }
                }
              }
            }
          }

          &.qty {
            margin-top: 24px;
          }

          &__qty {
            display: flex;

            .value-button {
              display: flex;
              align-items: center;
              justify-content: center;

              @include font2;
              width: 40px;
              height: 40px;
              @include size20px;
              border: 1px solid #e6e6e6;
              border-radius: 2px;

              user-select: none;
              cursor: pointer;
            }

            /* Chrome, Safari, Edge, Opera */
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }

            /* Firefox */
            input[type="number"] {
              -moz-appearance: textfield;
            }

            input {
              appearance: none;
              -webkit-appearance: none;
              display: inline-flex;
              justify-content: center;
              align-items: center;
              text-align: center;

              width: 56px !important;
              height: 100%;

              color: #000;
              @include size20px;
              font-weight: 600;
              border: 1px solid #e6e6e6;
            }
          }

          &.button {
            margin-top: 20px;
          }
          &__button {
            padding-top: 10px;
            flex: 0 0 50%;

            a {
              display: flex;
              width: 100%;
              height: 100%;

              justify-content: center;
              align-items: center;
              padding: 12px 24px;
              text-align: center;
              color: #000;
              @include size20px;
              font-weight: 500;
              text-align: center;

              border: 1px solid #520007;
              border-radius: 6px;
              transition: 0.16s all ease-in-out;

              &.buynow {
                color: #fff;
                background-color: #a32732;
                border-color: #a32732;
              }
            }
          }
        }
      }
    }
  }
}

.product-description {
  background-color: #f4f4f4;
  @include spaceL(padding, 0);
  &-wrapper {
    .title {
      margin-bottom: 24px;
    }
    .writeux {
      @include font2;
      color: #707070;
      font-size: 16px;
      font-weight: 500;

      img {
        display: block;
        width: 100%;
        height: auto;
        margin: 0 auto;
      }
    }
  }
}

.product-techs {
  @include spaceXL(padding, 0);
  &-wrapper {
    .title {
      @include spaceL(padding-bottom);
    }

    .techs {
      &-item {
        align-items: center;

        & > .col-6 {
          @include rpmax(ml) {
            flex: 0 0 100%;
            max-width: 100%;
          }
        }
        &:not(:first-child) {
          margin-top: 40px;
        }

        &:nth-of-type(2n) {
          flex-direction: row-reverse;

          .techs-item-content {
            padding-left: 0px;
            padding-right: 0px;
            @include rpmin(ml) {
              padding-right: 0px;
            }
            @include rpmin(xl) {
              padding-right: 60px;
            }
          }
        }
        img {
          width: 100%;
          object-fit: cover;
        }

        &-content {
          padding-right: 0;
          padding-left: 0px;
          padding-top: 20px;

          @include rpmin(ml) {
            padding-top: 0;
            padding-left: 0px;
          }
          @include rpmin(xl) {
            padding-left: 60px;
          }
        }
        &__name {
          margin-bottom: 20px;
          & > * {
            @include font1;
            @include bold;
          }
        }
      }
    }
  }
}

.product-reviews {
  @include spaceXL(padding-top);

  &-wrapper {
    @include font2;

    .wrapper {
      border: 1px solid #e6e6e6;
      border-radius: 8px;

      &-header {
        display: flex;
        flex-wrap: wrap;
        padding: 6px 16px 16px 16px;

        border-bottom: 1px solid #e6e6e6;
        @include rpmax(ml) {
          flex-direction: column;
        }
        & > * {
          margin-top: 10px;
        }

        &--left {
          flex: 1;
          display: flex;
        }

        .reviews-btn-write {
          appearance: none;

          background: #a32732;
          color: #fff;
          outline: none;
          border: 1px solid #a32732;
          border-radius: 6px;

          padding: 12px 24px;
          min-width: 305px;

          display: flex;
          justify-content: center;
          align-items: center;

          @include size20px;
          font-weight: 500;
          @include font2;
        }
        .reviews-wrapper {
          display: flex;
          align-items: center;
          @include size32px;
          font-weight: 600;
          @include font2;
          color: #000;

          .icon {
            margin-left: 6px;
            margin-bottom: 2px;
            display: flex;
            align-items: center;
          }

          &--more {
            flex: 1;
            margin-left: 16px;
            margin-right: 16px;

            display: flex;
            align-items: center;

            ul {
              list-style-type: none;
              display: flex;
              flex-wrap: wrap;
              margin-top: -4px;
              & > li {
                display: block;
                padding-top: 4px;
                & > a {
                  display: inline-flex;
                  justify-content: center;
                  align-items: center;

                  padding: 4px 24px;
                  border: 1px solid #520007;
                  color: #a32732;
                  font-size: 12px;
                  font-weight: 500;

                  border-radius: 4px;
                  transition: 0.3s all ease-in-out;
                }

                &.is-actived {
                  & > a {
                    background-color: #a32732;
                    border-color: #a32732;
                    color: #fff;
                  }
                }
              }
            }
          }
        }
      }

      &-posts {
        .emptry {
          display: flex;
          justify-content: center;
          align-items: center;

          padding: 24px 16px;
        }
        .posts-item {
          padding: 24px 16px;
          &:not(:first-child) {
            position: relative;
            &::after {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              border-top: 1px solid #e6e6e6;
            }
          }

          display: flex;
          align-items: flex-start;
          @include font2;

          &-avt {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 42px;
            flex: 0 0 42px;
            margin-right: 12px;
            & > * {
              position: relative;
              width: 100%;
              padding-top: 100%;
              border-radius: 100%;
              img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
          }
          &-content {
            flex: 1;
            .name {
              font-size: 14px;
              font-weight: 500;
              color: #000;
              line-height: 150%;
            }
            .rating {
              margin-top: 4px;

              display: flex;
              align-items: center;
              flex-wrap: wrap;

              font-size: 12px;
              color: #ff8a00;
              font-weight: 500;

              @include font2;
              .icon {
                width: 16px;
                height: 16px;
                & > * {
                  width: 100%;
                  max-height: 100%;
                }
              }
            }
            .time {
              margin-top: 6px;

              font-size: 14px;
              font-weight: 400;
              color: #999999;
              line-height: 150%;
            }

            .posts-product {
              margin-top: 10px;
              display: flex;
              align-items: flex-start;

              &-thumb {
                width: 60px;
                height: 60px;
                margin-right: 10px;
                & > * {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }

              &-content {
                .product-name {
                  margin-bottom: 10px;
                  & > * {
                    color: #000;
                    font-size: 16px;
                    font-weight: 600;
                  }
                }

                .product-features {
                  display: flex;
                  margin-top: -6px;
                  margin-left: -5px;
                  margin-right: -5px;

                  @include rpmax(md) {
                    flex-direction: column;
                  }
                  &__item {
                    padding-top: 6px;
                    padding-left: 5px;
                    padding-right: 5px;
                    font-size: 12px;
                    line-height: 2;
                    color: #000;
                    font-weight: 600;

                    @include rpmax(lg) {
                      line-height: inherit;
                    }
                    span {
                      font-weight: 500;
                    }
                  }
                }
              }
            }
            .comment {
              margin-top: 16px;
              max-width: 537px;
              width: 100%;

              .writeux {
                @include font2;
                color: #707070;
                font-size: 14px;
                line-height: 150%;
                font-weight: 500;
              }

              &-images {
                margin-top: 10px;

                display: flex;
                list-style-type: none;

                & > li {
                  display: flex;
                  width: 60px;
                  height: 60px;
                  margin-left: 4px;
                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                  }
                }
              }
            }
          }
        }
      }

      &-pagination {
        .pagination {
          & > ul {
            padding: 10px 16px;
            border-top: 1px solid #e6e6e6;
          }
        }

        // .pagination {
        //   & > ul {
        //     list-style-type: none;
        //     display: flex;
        //     justify-content: flex-end;

        //     margin-top: -8px;
        //     margin-left: -8px;
        //     margin-right: -8px;

        //     & > li {
        //       & > a {
        //         color: #525252;
        //         font-size: 14px;
        //         line-height: 2;
        //         font-weight: 500;

        //         display: flex;
        //         justify-content: center;
        //         align-items: center;
        //         width: 24px;
        //         height: 24px;

        //         background-color: transparent;
        //         border-radius: 2px;
        //       }

        //       padding-top: 8px;
        //       padding-left: 8px;
        //       padding-right: 8px;

        //       &.is-actived {
        //         & > a {
        //           background-color: #a32732;
        //           color: #fff;
        //         }
        //       }
        //     }
        //   }
        // }
      }
    }
  }
}

.product-related {
  @include spaceXL(padding-top);
  padding-bottom: 0px !important;
  &-wrapper {
    .title {
      text-align: center;
      @include spaceL(padding-bottom);
    }
  }
  &-more {
    // margin-top: 40px;
    @include space(margin-top, 40px);
    text-align: center;

    a {
      @include font2;

      padding: 12px 24px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      min-width: 305px;
      background-color: #a32732;
      color: #fff;
      border-radius: 6px;
      @include size20px;
      font-weight: 500;
    }
  }
}

.popup-notify {
  position: fixed;
  top: 0;
  left: 0;

  z-index: 2001;
  width: 100%;
  height: 100%;
  background-color: #55555588;

  display: flex;
  justify-content: center;
  align-items: center;

  display: none;
  opacity: 0;
  visibility: hidden;

  @keyframes showedPopup {
    100% {
      opacity: 1;
      visibility: visible;
    }
  }
  &.is-showed {
    display: flex;
    animation: showedPopup 0.3s ease-in-out forwards;
  }

  .modal {
    position: relative;
    width: 540px;
    max-width: 96%;
    max-height: 94%;
    overflow-y: auto;

    padding: 24px;

    background-color: #fff;
    border-radius: 6px;
    border: 1px solid #e6e6e6;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);

    @include rpmin(lg) {
      width: 720px;
    }

    &-wrapper {
      .name {
        margin-bottom: 20px;
        & > * {
          @include font2;
          font-size: 16px;
          font-weight: 500;
          color: #000;

          @include rpmin(lg) {
            font-size: 24px;
          }
        }
      }
      .desc {
        margin-bottom: 20px;

        font-size: 14px;
        font-weight: 400;

        @include rpmin(lg) {
          font-size: 20px;
        }
      }

      .button {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        margin: -8px -10px 0 -10px;

        a,
        button {
          display: inline-flex;
          justify-content: center;
          align-items: center;

          font-size: 14px;
          color: #007aff;
          font-weight: 500;

          text-transform: uppercase;

          padding: 8px 10px 0px 10px;

          @include rpmin(lg) {
            font-size: 20px;
          }
        }
      }
    }
  }
}
.popup-reviews {
  position: fixed;
  top: 0;
  left: 0;

  z-index: 2000;
  width: 100%;
  height: 100%;
  background-color: #55555588;

  display: flex;
  justify-content: center;
  align-items: center;

  display: none;
  opacity: 0;
  visibility: hidden;

  @keyframes showedPopup {
    100% {
      opacity: 1;
      visibility: visible;
    }
  }

  &.is-showed {
    display: flex;
    animation: showedPopup 0.3s ease-in-out forwards;
  }

  .modal {
    position: relative;
    width: 930px;
    max-width: 96%;
    max-height: 94%;
    overflow-y: auto;

    padding: 24px;

    background-color: #fff;
    border-radius: 6px;
    border: 1px solid #e6e6e6;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);

    &-close {
      position: absolute;
      top: 24px;
      right: 20px;
      width: 32px;
      height: 32px;
      border: 1px solid #a32732;
      border-radius: 100%;
      z-index: 1;

      font-size: 0px;
      color: transparent;
      transition: 0.3s all ease-in-out;
      cursor: pointer;

      &::after,
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 52%;
        height: 0;
        border-top: 2px solid #a32732;
        border-radius: 2px;

        user-select: none;
        pointer-events: none;
        transition: 0.3s all ease-in-out;
      }

      &::before {
        transform: translate(-50%, -50%) rotate(45deg);
      }
      &::after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }

      &:hover {
        background-color: #520007;
        border-color: #520007;

        &::after,
        &::before {
          border-color: #fff;
        }
      }
    }

    &-wrapper {
      .name {
        padding-left: 24px;
        padding-bottom: 20px;
        border-bottom: 1px solid #e6e6e6;
        & > * {
          @include font2;
          @include size32px;
          font-weight: 500;
          color: #000;
        }
      }

      &-form {
        margin-top: 16px;

        .posts-product {
          display: flex;
          align-items: stretch;
          @include rpmax(ml) {
            flex-wrap: wrap;
            flex-direction: column;
          }

          width: 100%;

          &-thumb {
            position: relative;
            width: 102px;
            height: 102px;
            margin-right: 16px;

            @include rpmax(md) {
              width: 100%;
              max-width: 100%;

              height: 0;
              padding-top: 100%;
              margin-bottom: 20px;
              margin-right: 0;
            }
            & > * {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          &-content {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            overflow: hidden;

            .product-name {
              margin-bottom: 10px;
              & > * {
                color: #000;
                font-size: 30px;
                font-weight: 600;
              }
            }

            .product-features {
              display: flex;
              flex-wrap: wrap;

              margin-top: -10px;
              margin-left: -40px;
              margin-right: -40px;

              @include rpmax(lg) {
                flex-direction: column;
              }
              &__item {
                display: flex;
                flex-wrap: wrap;

                padding-top: 10px;
                padding-left: 40px;
                padding-right: 40px;

                font-size: 16px;
                line-height: 2;
                color: #000;
                font-weight: 600;

                & > * {
                  display: flex;
                  align-items: center;

                  @include rpmax(lg) {
                    min-width: 140px;
                  }

                  &:not(:last-child) {
                    margin-right: 16px;
                  }
                }

                span {
                  font-weight: 500;
                }

                .selects-item-option {
                  position: relative;

                  @include rpmax(lg) {
                    flex: 1;
                  }
                  .arrow {
                    position: absolute;
                    top: 0;
                    right: 16px;
                    width: 11px;
                    user-select: none;
                    pointer-events: none;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  }

                  select {
                    cursor: pointer;

                    @include font2;
                    appearance: none;

                    width: 100%;
                    border: 1px solid #520007;
                    border-radius: 4px;
                    background: transparent;
                    outline: none;
                    font-size: 14px;
                    font-weight: 500;
                    color: #000;
                    padding: 1px 32px 1px 16px;
                  }
                }

                // select {
                //   appearance: none;
                //   background: transparent;
                //   outline: none;

                //   border: 1px solid #520007;
                //   border-radius: 4px;

                //   font-size: 14px;
                //   font-weight: 500;
                //   color: #000;

                //   padding: 1px 16px;
                // }
              }
            }
          }
        }

        .comment {
          margin-top: 24px;

          textarea {
            appearance: none;
            background: transparent;
            outline: none;
            border: none;

            padding: 22px 24px;
            border: 1px solid #520007;
            resize: none;
            width: 100%;

            @include font2;
            font-size: 14px;
            font-weight: 500;
            color: #000;

            &::placeholder {
              color: #707070;
              font-weight: 500;
              font-style: normal;
            }
          }
        }

        .upload {
          margin-top: 16px;

          input {
            display: none;
          }
          label {
            cursor: pointer;

            padding: 4px 20px;
            margin-bottom: 16px;
            border: 1px solid #520007;
            border-radius: 4px;

            display: inline-flex;
            justify-content: center;
            align-items: center;
            text-align: center;

            @include font2;
            font-size: 14px;
            color: #520007;
            font-weight: 500;

            transition: 0.16s all ease-in-out;

            .icon,
            svg {
              width: 24px;
              height: 24px;
              margin-right: 14px;
              * {
                transition: 0.16s all ease-in-out;
              }
            }

            &:hover {
              background-color: #520007;
              color: #fff;

              svg {
                [stroke]:not([stroke="none"]) {
                  stroke: #fff;
                }
                [fill]:not([fill="none"]) {
                  fill: #fff;
                }
              }
            }
          }

          &__img {
            &-wrap {
              display: flex;
              flex-wrap: wrap;
              margin: 0 -10px;
              @include rpmax(ml) {
                margin: 0 -4px;
              }
            }

            &-box {
              width: 14.28%;
              padding: 0 10px;
              margin-bottom: 16px;

              @include rpmax(ml) {
                padding: 0 4px;
              }
            }

            &-close {
              width: 24px;
              height: 24px;
              border-radius: 50%;
              background-color: rgba(0, 0, 0, 0.5);
              position: absolute;
              top: 10px;
              right: 10px;
              text-align: center;
              line-height: 24px;
              z-index: 1;
              cursor: pointer;

              display: flex;
              justify-content: center;
              align-items: center;

              &:after {
                content: "\2716";
                font-size: 14px;
                color: white;
              }
            }
          }

          .img-bg {
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            position: relative;
            padding-bottom: 100%;
          }
        }

        .infor {
          margin-top: 8px;
        }

        .group-input {
          display: flex;
          flex-direction: column;

          label {
            font-size: 16px;
            font-weight: 600;
            color: #000;
          }

          input {
            margin-top: 6px;

            appearance: none;
            background: transparent;
            outline: none;
            border: none;
            border-bottom: 1px solid #000;

            @include font2;
            font-size: 14px;
            font-weight: 500;
            color: #000;

            &::placeholder {
              color: #707070;
              font-weight: 500;
              font-style: normal;
            }
          }
        }

        .submit {
          margin-top: 30px;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;

          button[type="submit"],
          input[type="submit"] {
            margin-top: 10px;
            min-width: 305px;

            appearance: none;
            background: transparent;
            outline: none;
            border: none;

            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;

            padding: 12px 24px;
            border-radius: 6px;

            color: #fff;
            background-color: #a32732;

            transition: 0.3s all ease-in-out;

            @include font2;
            @include size20px;
            font-weight: 500;

            &:hover {
              background-color: #520007;
            }
          }
        }
      }

      &-ratings {
        margin-top: 16px;
        padding-top: 26px;
        border-top: 1px solid #e6e6e6;

        display: flex;
        align-items: center;
        flex-wrap: wrap;

        font-size: 16px;
        font-weight: 600;
        color: #000;

        .rating {
          margin-left: 20px;

          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          flex-direction: row-reverse;
          position: relative;

          @include rpmax(lg) {
            flex: 0 0 100%;
            justify-content: flex-end;

            margin-top: 10px;
            margin-left: 0;
          }
          &-0 {
            filter: grayscale(100%);
          }

          & > input {
            display: none;

            &:checked ~ label,
            &:checked ~ label ~ label {
              background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23A32732' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e");
            }

            &:not(:checked) ~ label:hover,
            &:not(:checked) ~ label:hover ~ label {
              background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23520007' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e");
            }
          }

          & > label {
            cursor: pointer;
            width: 26px;
            height: 26px;
            background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23e3e3e3' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e");
            background-repeat: no-repeat;
            background-position: center;
            background-size: 76%;
            transition: 0.3s;
            margin-right: 16px;
            order: 3;

            @include rpmax(lg) {
              margin-right: 8px;
            }
          }

          #rating-5:checked ~ .emoji-wrapper .emoji .rating-5,
          #rating-4:checked ~ .emoji-wrapper .emoji .rating-4,
          #rating-3:checked ~ .emoji-wrapper .emoji .rating-3,
          #rating-2:checked ~ .emoji-wrapper .emoji .rating-2,
          #rating-1:checked ~ .emoji-wrapper .emoji .rating-1 {
            display: block;
            opacity: 1;
            visibility: visible;
          }

          .emoji-wrapper {
            order: 1;

            .emoji {
              display: flex;
              align-items: center;
              transition: 0.3s;

              font-size: 14px;
              font-weight: 500;
              @include font2;
              color: #a32732;

              [class*="rating-"] {
                white-space: wrap;
                display: none;
                opacity: 0;
                visibility: hidden;
                transition: 0.3s all ease-in-out;
                &.is-checked {
                  display: block;
                  opacity: 1;
                  visibility: visible;
                }
              }
            }
          }
        }
      }
    }
  }
}

.excerpt-hidden {
  height: 20px;

  overflow: hidden;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 64px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 80%, rgba(255, 255, 255, 0.4) 100%);
  }
}

.excerpt-visible {
  height: auto;
  overflow: auto;
}

p.infor-error {
  font-size: 12px;
  color: #e80826;
  margin-top: 4px;
}
