.cart-section {
    &-wrapper {
        @include spaceL(padding-top);

        .cart-form {
            margin-top: 24px;

            border: 2px solid #e6e6e6;
            border-radius: 6px;
            box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
            table {
                width: 100%;
                border-collapse: collapse;

                thead {
                    & > tr {
                        border-bottom: 1px solid #e6e6e6;
                        & > th {
                            &:first-child {
                                width: 50%;
                                padding-left: 16px;
                            }

                            &:last-child {
                                padding-right: 16px;
                            }

                            font-size: 16px;
                            font-weight: 600;
                            text-transform: uppercase;
                            color: #707070;

                            padding-top: 10px;
                            padding-bottom: 10px;
                        }
                    }
                }

                tbody {
                    & > tr {
                        & > td {
                            padding: 24px 0;

                            &:first-child {
                                padding-left: 16px;
                            }

                            &:last-child {
                                padding-right: 16px;
                            }

                            .posts-product {
                                display: flex;
                                align-items: stretch;
                                flex-wrap: wrap;

                                width: 100%;

                                &-thumb {
                                    position: relative;
                                    width: 102px;
                                    height: 102px;
                                    margin-right: 16px;

                                    @include rpmax(md) {
                                        width: 100%;
                                        max-width: 100%;

                                        height: 0;
                                        padding-top: 100%;
                                        margin-bottom: 20px;
                                        margin-right: 0;
                                    }
                                    & > * {
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        width: 100%;
                                        height: 100%;
                                        object-fit: cover;
                                    }
                                }

                                &-content {
                                    flex: 1;

                                    display: flex;
                                    flex-direction: column;
                                    justify-content: space-between;
                                    overflow: hidden;
                                    flex-wrap: wrap;

                                    @include rpmax(md) {
                                        flex: 0 0 100%;
                                    }
                                    .product-name {
                                        & > * {
                                            color: #000;
                                            font-size: 30px;
                                            font-weight: 600;
                                        }
                                    }

                                    .product-features {
                                        margin-top: 16px;
                                        display: flex;
                                        flex-direction: column;

                                        &__item {
                                            display: flex;
                                            flex-wrap: wrap;

                                            font-size: 16px;
                                            color: #000;
                                            font-weight: 600;

                                            &:not(:first-child) {
                                                margin-top: 10px;
                                            }

                                            // @include rpmax(md) {
                                            //     flex-direction: column;
                                            // }

                                            .options {
                                                display: flex;
                                                flex-direction: column;
                                            }

                                            span {
                                                font-weight: 500;
                                            }
                                            select {
                                                appearance: none;
                                                background: transparent;
                                                outline: none;

                                                margin-left: 16px;
                                                border: 1px solid #520007;
                                                border-radius: 4px;

                                                font-size: 14px;
                                                font-weight: 500;
                                                color: #000;

                                                padding: 1px 16px;
                                            }
                                        }
                                    }
                                }
                            }

                            .prices {
                                @include size20px;
                                font-weight: 600;
                                color: #000;

                                &-total {
                                    @include size20px;
                                    font-weight: 600;
                                    color: #a32732;
                                }
                            }

                            .qty {
                                display: flex;
                                @include rpmax(lg) {
                                    width: 100%;
                                }
                                .value-button {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;

                                    @include font2;
                                    width: 40px;
                                    height: 40px;
                                    @include size20px;
                                    border: 1px solid #e6e6e6;
                                    border-radius: 2px;

                                    user-select: none;
                                    cursor: pointer;
                                }

                                /* Chrome, Safari, Edge, Opera */
                                input::-webkit-outer-spin-button,
                                input::-webkit-inner-spin-button {
                                    -webkit-appearance: none;
                                    margin: 0;
                                }

                                /* Firefox */
                                input[type="number"] {
                                    -moz-appearance: textfield;
                                }

                                input {
                                    appearance: none;
                                    -webkit-appearance: none;
                                    display: inline-flex;
                                    justify-content: center;
                                    align-items: center;
                                    text-align: center;

                                    width: 56px;
                                    height: 100%;
                                    color: #000;
                                    @include size20px;
                                    font-weight: 600;
                                    border: 1px solid #e6e6e6;
                                }
                            }

                            .remove {
                                font-size: 0px;
                                color: transparent;

                                display: flex;
                                justify-content: center;
                                align-items: center;
                                text-align: center;

                                width: 24px;
                                height: 24px;

                                background-color: transparent;
                                border: 1px solid #cccccc;
                                border-radius: 100%;

                                position: relative;

                                transition: 0.3s all ease-in-out;
                                cursor: pointer;

                                &::after,
                                &::before {
                                    content: "";
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                    width: 52%;
                                    height: 0;
                                    border-top: 2px solid #666666;
                                    border-radius: 2px;

                                    user-select: none;
                                    pointer-events: none;
                                    transition: 0.3s all ease-in-out;
                                }

                                &::before {
                                    transform: translate(-50%, -50%) rotate(45deg);
                                }
                                &::after {
                                    transform: translate(-50%, -50%) rotate(-45deg);
                                }

                                &:hover {
                                    background-color: #520007;
                                    border-color: #520007;

                                    &::after,
                                    &::before {
                                        border-color: #fff;
                                        color: #fff;
                                    }
                                }

                                @include rpmax(lg) {
                                    width: auto;
                                    min-width: 136px;

                                    border-radius: 0;
                                    height: 48px;
                                    padding: 12px 24px;

                                    cursor: pointer;

                                    &::after {
                                        content: none;
                                    }

                                    &::before {
                                        content: attr(data-text);
                                        position: relative;
                                        top: 0;
                                        left: 0;

                                        border: none;
                                        font-size: 18px;
                                        width: 100%;
                                        height: 100%;
                                        color: #000;

                                        display: flex;
                                        justify-content: center;
                                        align-items: center;

                                        transform: none;
                                    }
                                    &:hover {
                                        &::before {
                                            color: #fff;
                                        }
                                    }
                                }

                                @include rpmax(md) {
                                    width: 100%;
                                }
                            }
                        }

                        &:not(:first-child) {
                            border-top: 1px solid #e6e6e6;
                        }
                    }
                }

                @include rpmax(lg) {
                    thead,
                    thead > tr,
                    thead > tr > td {
                        display: none;
                    }
                    tbody,
                    tbody > tr,
                    tbody > tr > td {
                        display: block;
                        text-align: left !important;
                    }

                    tbody > tr {
                        position: relative;
                        border-width: 2px;

                        padding: 20px 20px 20px 20px;
                    }

                    tbody > tr > td {
                        position: relative;
                        display: flex;
                        align-items: center;

                        white-space: normal;

                        padding: 8px 0px !important;

                        // &:not(:first-child) {
                        //     border-top: 1px solid #e1e1e1;
                        //     // border-right: 1px solid #E1E1E1;
                        // }
                        &:not(:first-child) {
                            padding-left: 118px !important;
                        }

                        &:nth-last-child(2) {
                            display: none;
                        }

                        &:last-child {
                            // position: absolute;
                            // top: 10px;
                            // right: 10px;
                        }
                    }
                }
                @include rpmax(md) {
                    tbody > tr > td {
                        &:not(:first-child) {
                            padding-left: 0px !important;
                        }
                    }
                }
            }
        }

        .cart-bill {
            margin-top: 12px;

            padding: 24px 16px 24px 50%;
            background-color: hsl(0, 0%, 96%);
            border: 1px solid #e6e6e6;
            box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);

            @include rpmax(lg) {
                padding-left: 20px;
                padding-right: 20px;
            }

            &-item {
                display: flex;
                align-items: center;

                @include size20px;
                font-weight: 600;
                color: #000;

                @include rpmax(md) {
                    flex-direction: column;
                    align-items: flex-start;

                    & > * {
                        &:not(:first-child) {
                            margin-top: 10px;
                        }
                    }
                }

                .group-input-code {
                    display: flex;
                    flex-direction: column;

                    @include rpmax(md) {
                        width: 100%;

                        form {
                            width: 100%;
                        }
                    }

                    .infor-text {
                        display: block;
                        margin-top: 4px;
                        width: 100%;

                        text-align: right;
                        font-size: 14px;
                        font-weight: 500;
                        color: #000;

                        @include rpmax(md) {
                            text-align: left;
                        }
                    }
                    .error-text {
                        display: block;
                        margin-top: 4px;
                        width: 100%;

                        text-align: right;
                        font-size: 14px;
                        font-weight: 500;
                        color: #e80826;

                        @include rpmax(md) {
                            text-align: left;
                        }
                    }

                    .code-submit {
                        display: flex;
                        flex-direction: row-reverse;
                        flex-wrap: wrap;

                        @include rpmax(md) {
                            flex-direction: column;
                        }

                        button {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            text-align: center;

                            padding: 6px 8px;
                            height: 32px;

                            appearance: none;
                            background: transparent;
                            outline: none;
                            border: 1px solid #520007;

                            font-size: 14px;
                            font-weight: 500;
                            color: #fff;
                            background-color: #a32732;

                            transition: 0.2s all ease-in-out;

                            &:hover {
                                background-color: #520007;
                            }

                            &:disabled {
                                opacity: 0.7;
                                &:hover {
                                    background-color: #a32732;
                                }
                            }

                            @include rpmax(md) {
                                width: 100%;
                            }
                        }

                        input {
                            appearance: none;
                            outline: none;
                            border: 1px solid #520007;

                            width: 156px;
                            max-width: 100%;
                            height: 32px;
                            padding: 6px 8px;

                            display: flex;
                            align-items: center;

                            font-size: 14px;
                            font-weight: 500;
                            color: #000;

                            background: transparent;

                            &:disabled {
                                opacity: 0.7;
                            }
                        }
                    }
                }

                &.input-code {
                    align-items: flex-start;
                }

                &.total {
                    & > *:last-child {
                        color: #a32732;
                        font-size: 30px;
                    }
                }
                &:not(:first-child) {
                    margin-top: 16px;
                    @include rpmax(md) {
                        margin-top: 20px;
                    }
                }

                & > *:first-child {
                    flex: 1;
                }
                & > * {
                    display: flex;
                    align-items: center;
                }
            }

            &-submit {
                padding-top: 24px;
                margin-top: 16px;
                border-top: 1px solid #e6e6e6;

                display: flex;
                justify-content: flex-end;
                align-items: flex-end;

                button,
                input[type="submit"] {
                    min-width: 269px;
                    @include rpmax(md) {
                        width: 100%;
                    }

                    appearance: none;
                    background: transparent;
                    outline: none;
                    border: none;

                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;

                    padding: 12px 24px;
                    border-radius: 6px;

                    color: #fff;
                    background-color: #a32732;

                    transition: 0.3s all ease-in-out;

                    @include font2;
                    @include size20px;
                    font-weight: 500;

                    &:hover {
                        background-color: #520007;
                    }
                }
            }
        }
    }
}
