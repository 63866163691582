.details-recommend-video__wrapper{
    // padding: 80px 0;
    // height: 636px;
    // margin: 64px 0;
    // @include spaceL(margin-top);
    margin: calc(128px + 32px) 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .details-recommend-video{
        display: flex;
        // justify-content: center;
        align-items: center;
        .left-recommend-video{
            width: 50%;
            .product-infor{
                // padding: 0 140px 0 0;
                @include spaceL(padding-right);
                // .product-infor__title{
                //     // margin: 10px 0;
                // }
                .product-infor__italic{
                    color: map-get($mau, color-primary );
                    margin-top: 12px;
                    font-family: SEGOEUII;
                }
                .product-infor__more{
                    padding-top: 0;
                    border-top: 0;
                }
            }
        }
    }
    .right-recommend-video{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 50%;
        height: calc(100% + 64px);
        width: 50%;
        .video-section{
            height: 100%;
            &__img,
            img,
            iframe,
            video{
                width: 100%;
                height: 100%;
                object-position: center;
                object-fit: cover;
            }
        }
    }
}

// @media (max-width: 1440px) and (min-width:601px){
//     .details-recommend-video__wrapper{
//         .details-recommend-video{
//             .left-recommend-video{
//                 .product-infor{
//                     padding: 0 120px 0 0;
//                     // .product-infor__title{
//                     //     font-size: 40px;
//                     // }
//                     // .product-infor__italic{
//                     //     font-size: 17px;       
//                     // }
//                 }
//             }
            
//         }
//     }
// }

@media (max-width: map-get($media, ps)){
    .details-recommend-video__wrapper{
        margin: calc(64px + 24px) 0;
        .right-recommend-video{
            height: calc(100% + 48px);
        }
    }
}
@media (max-width: map-get($media, ts)){
    .details-recommend-video__wrapper{
        // @include spaceXL(margin, 0);
        margin: 48px 0;
        .right-recommend-video{
            height: 100%;
        }
    }
}


// @media (max-width: map-get($media, tl)){
//     .details-recommend-video__wrapper .details-recommend-video .left-recommend-video .product-infor{
//         @include spaceL(padding-right);
//     }
// }
@media (max-width: map-get($media, ts)){
    .details-recommend-video__wrapper{
        flex-direction: column;
        height: auto;
    }
    .details-recommend-video__wrapper .details-recommend-video .left-recommend-video{
        width: 100%;
    }
    .details-recommend-video__wrapper .details-recommend-video .left-recommend-video .product-infor{
        padding: 0;
    }
    .details-recommend-video__wrapper .right-recommend-video{
        position: static;
        top: auto;
        right: auto;
        width: 100%;
        height: 420px;
        transform: none;
        .video-section{
            width: 100%;
            height: 100%;
            img{
                max-height: 420px;
                height: 100%;
                width: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
    }
    .details-recommend-video__wrapper .right-recommend-video{
        margin-top: 24px;
    }
}