.about-story-our__wrapper {
  // margin: map-get($size, margin-block-large) 0;
  @include spaceL(margin, 0);

  .about-story-our {
    &__list {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    &__item {
      width: 100%;
      .our-item {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;

        &__img {
          height: 383px;
          flex-basis: 50%;
          width: 50%;
          object-fit: cover;
          object-position: center;
        }

        &__content {
          flex-basis: 50%;

          &-title {
            font-weight: 500;
            // font-weight: 700;
          }
          &-desc {
            // margin: map-get($size, margin-block-medium) 0;
            @include spaceM(margin, 0);
          }
          &-caption {
            font-style: italic;
            // opacity: .7;
            font-weight: 500;
            // font-weight: 700;
            color: map-get($mau, dark-gray);
          }
        }
      }

      &:nth-child(2n) {
        .our-item {
          flex-direction: row-reverse;
          &__content {
            padding-right: map-get($size, margin-block-large);
          }
        }
      }
      &:not(:nth-child(2n)) {
        .our-item {
          &__content {
            padding-left: map-get($size, margin-block-large);
          }
        }
      }

      &:not(:first-child) {
        margin-top: 40px;
      }
    }
  }
}

@media (max-width: map-get($media, tl)) {
  $padding-our--box: map-get($size, margin-block-medium);
  .about-story-our__wrapper {
    .about-story-our {
      &__item {
        &:nth-child(2n) {
          .our-item {
            &__content {
              padding-right: $padding-our--box;
            }
          }
        }
        &:not(:nth-child(2n)) {
          .our-item {
            &__content {
              padding-left: $padding-our--box;
            }
          }
        }
      }
    }
  }
}

@media (max-width: map-get($media, ts)) {
  $padding-our--box: map-get($size, margin-block-small) 12px;

  .about-story-our__wrapper {
    .about-story-our {
      &__item {
        &:nth-child(2n) {
          .our-item {
            &__content {
              padding: $padding-our--box;
            }
          }
        }
        &:not(:nth-child(2n)) {
          .our-item {
            &__content {
              padding: $padding-our--box;
            }
          }
        }
        .our-item {
          background-color: map-get($mau, gray-2);
          &__content {
            flex-basis: 100%;
            width: 100%;

            // &-desc{
            //   margin: map-get($size, margin-block-small) 0;
            // }
          }

          &__img {
            flex-basis: 100%;
            width: 100%;
          }
        }
      }
    }
  }
}
