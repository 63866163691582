.mattress-type {
    // margin-top: 80px;
    // margin-bottom: 100px;
    @include spaceL(padding, 0);
}
.title-mattress-type {
    // margin-bottom: 95px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title {
        @include spaceL(margin-bottom);
        h3 {
            text-align: center;
        }
    }
    .menu {
        // margin-top: 5%;
        display: flex;
        text-align: center;
        justify-content: space-evenly;
        align-self: center;
        width: 100%;
        flex-wrap: wrap;
        margin-top: -12px;
        margin-left: -12px;
        margin-right: -12px;

        &__item {
            height: auto;
            padding-top: 12px;
            padding-left: 12px;
            padding-right: 12px;

            // height: 100px;
            display: flex;
            flex-direction: column;
            align-items: center;

            @include rpmax(md) {
                width: 100%;
            }
        }

        p {
            color: #a32732;
            font-weight: 700;
            text-transform: uppercase;

            // margin-top: 12px;
            // white-space: nowrap;
        }
        img {
            width: 54px;
            height: 54px;
        }

        @include rpmax(ps) {
            width: 100%;
            justify-content: space-evenly;
        }

        @include rpmax(ls) {
            width: 100%;
            justify-content: space-between;
        }
    }
}

.mattress-type {
    .collection {
        &-item {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            // margin-top: 80px;
            // @media (max-width: 768px) {
            //     margin-top: 64px;
            // }
            // @media (max-width: 425px) {
            //     margin-top: 32px;
            // }

            @include space(margin-top, 80px);

            &:nth-of-type(2n) {
                flex-direction: row-reverse;

                // @media (max-width: 640px) {
                //     flex-direction: row;
                // }
                @include rpmax(ml) {
                    flex-direction: row;
                }

                .collection-content {
                    text-align: left;
                }
            }
        }

        &__left {
            padding: 76px 54px;
            @media (max-width: 640px) {
                padding: 45px 24px;
            }

            flex: 0 0 58.3333%;
            @include rpmax(ml) {
                flex: 0 0 100%;
            }

            box-shadow: 0 0px 3px rgba(0, 0, 0, 0.16);
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &-right {
            // padding: calc(76px + 45px) 90px;
            // @media (max-width: 1336px) {
            //     padding: 76px 60px;
            // }
            // @media (max-width: 1280px) {
            //     padding: 54px 22px;
            // }

            padding: 24px 24px;
            @include rpmin(xl) {
                padding: 32px 60px;
            }
            @include rpmin(yl) {
                padding: 40px 90px;
            }

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            box-shadow: 0 0px 3px rgba(0, 0, 0, 0.16);
            flex: 1;

            background-color: rgba(0, 0, 0, 0.02);
        }

        &-content {
            text-align: right;
            &__title {
                & > * {
                    text-transform: uppercase;
                    @include font-heading(4);
                    @include font2;
                    font-weight: 500;
                }
            }
            &__desc {
                margin-top: 24px;
                & > * {
                    color: #707070;
                }
            }

            &__button {
                margin-top: 32px;
            }
        }
    }
}
