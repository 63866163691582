.dealer-digital-list__wrapper {
    // .container{
    //     padding-left: 50px;
    //     padding-right: 50px;
    // }
    // @include spaceL(margin-bottom);
    .dealer-digital-list {
        .list-items {
            // margin-top: 300px;
            position: relative;
            @include spaceXL(margin-top);

            .row {
                align-items: center;
                .items__img {
                    width: 828px;
                    height: 582px;
                    display: flex;
                    align-items: center;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                    }
                    position: relative;

                    // img:not(:first-child){
                    //     position: absolute;
                    //     background-repeat: no-repeat;
                    //     background-size: cover;
                    //     top: 50%;
                    //     left: 0;
                    //     width: 70vw;
                    //     height: 120%;
                    //     max-width: initial;
                    //     z-index: -1;
                    //     opacity: .2;
                    //     transform: translateY(-50%)
                    // }
                }
                .items__infor {
                    .product-infor {
                        // padding: 0 100px;
                        @include spaceL(padding-right);
                        @include spaceL(padding-left);
                        .product-infor__title {
                            text-transform: uppercase;
                            // font-size: 30px;
                            // @include font-heading(4);
                            // font-weight: bold;
                            @include size30px;
                            @include semi-bold;
                            color: #000;
                        }
                        .product-infor__content {
                            justify-content: flex-end;
                            @include p-line-clamp(5);
                        }
                        .product-infor__more {
                            color: map-get($mau, dark-gray);
                            margin-bottom: 12px;
                            display: block;
                            width: 100%;
                        }
                        .btn {
                            margin-bottom: 0;
                            // p{
                            //     background-color: transparent !important;
                            // }
                        }
                    }
                }
            }
            &:first-child {
                margin-top: 0;
            }
            &:nth-child(even) {
                .items__img {
                    text-align: end;
                }
                .row {
                    flex-direction: row;
                    .items__infor {
                        .product-infor {
                            // padding-right: 100px;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            .product-infor__content {
                                text-align: left;
                            }
                            .product-infor__more {
                                text-align: left;
                            }
                        }
                    }
                }
            }
            &:nth-child(odd) {
                .row {
                    flex-direction: row-reverse;
                    .items__infor {
                        .product-infor {
                            // padding-right: 100px;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-end;
                            .product-infor__content {
                                text-align: end;
                            }
                            .product-infor__more {
                                text-align: right;
                            }
                        }
                    }
                }
            }
            .underlay-background {
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                width: 100%;
                height: 450px;
                background: map-get($mau, gray-2);
                z-index: -1;
            }
        }
    }
}

@media (max-width: map-get($media, ps)) {
    .dealer-digital-list__wrapper .dealer-digital-list .list-items .row .items__infor .product-infor {
        padding: 0 24px;
    }

    .dealer-digital-list__wrapper .dealer-digital-list .list-items .row .items__img {
        width: 711px;
        height: 500px;
    }
}
@media (max-width: map-get($media, tl)) {
    .dealer-digital-list__wrapper .dealer-digital-list .list-items:first-child {
        margin-top: 80px;
    }
}
@media (max-width: map-get($media, ts)) {
    .dealer-digital-list__wrapper .dealer-digital-list .list-items:nth-child(odd) .row {
        flex-direction: column;
    }
    .dealer-digital-list__wrapper .dealer-digital-list .list-items:first-child {
        margin-top: 42px;
    }
    .dealer-digital-list__wrapper .dealer-digital-list .list-items {
        padding: 18px 0;
    }
    .dealer-digital-list__wrapper .dealer-digital-list .list-items .underlay-background {
        // height: calc(100% + 36px);
        height: 100%;
    }
    .dealer-digital-list__wrapper .dealer-digital-list .list-items .row .items__img {
        width: 100%;
        max-width: 100%;
    }
    .dealer-digital-list__wrapper .dealer-digital-list .list-items .row .items__infor {
        flex: 0 0 100%;
        max-width: 100%;
        margin-top: 36px;
        .product-infor {
            padding: 0;
        }
    }
    .dealer-digital-list__wrapper .dealer-digital-list .list-items:nth-child(odd) .row .items__infor .product-infor {
        align-items: flex-start;
    }
    .dealer-digital-list__wrapper
        .dealer-digital-list
        .list-items:nth-child(odd)
        .row
        .items__infor
        .product-infor
        .product-infor__content {
        text-align: start;
        // margin: 36px 0;
    }
}
@media (max-width: map-get($media, ts)) {
    .dealer-navigation-digital__wrapper .dealer-navigation-digital .nav-items {
        font-size: 18px;
    }
}
