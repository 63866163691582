.mousse-section {
    background-color: #ebebeb;
    // padding-bottom: 60px;
    @include spaceL(padding-bottom);
    .mousse-type {
        // margin-top: 80px;
        // margin-bottom: 100px;
        @include spaceL(padding, 0);
    }
    .title-mousse-type {
        // margin-bottom: 95px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .title {
            h3 {
                text-align: center;
            }
        }
        .menu {
            // margin-top: 5%;
            @include spaceL(margin-top);
            display: flex;
            text-align: center;
            justify-content: space-evenly;
            align-self: center;
            width: 100%;
            flex-wrap: wrap;

            &__item {
                width: 160px;
                height: 100px;
                margin-top: 12px;
                display: flex;
                flex-direction: column;
                align-items: center;

                @media (max-width: 425px) {
                    height: auto;
                }
            }

            p {
                color: #041e42;
                margin-top: 12px;
                white-space: nowrap;
                font-weight: 700;
                text-transform: uppercase;
            }
            img {
                width: 54px;
                height: 54px;
            }
        }
    }

    @media (max-width: map-get($media, ps)) {
        .title-mattress-type .menu {
            width: 100%;
            justify-content: space-evenly;
        }
    }
    @media (max-width: map-get($media, tl)) {
        .title-mattress-type .menu {
            width: 90%;
            justify-content: space-between;
        }
    }
    @media (max-width: map-get($media, tm)) {
        .title-mattress-type .menu {
            width: 100%;
            justify-content: space-between;
        }
    }
    @media (max-width: map-get($media, ts)) {
        .title-mattress-type .menu {
            &__item {
                width: 50%;
            }
        }
    }

    .collection {
        &-item {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-top: 80px;
            @media (max-width: 768px) {
                margin-top: 64px;
            }
            @media (max-width: 425px) {
                margin-top: 32px;
            }

            &:nth-of-type(2n) {
                flex-direction: row-reverse;

                @media (max-width: 640px) {
                    flex-direction: row;
                }
                .collection-content {
                    text-align: left;
                }
            }
        }

        &__left {
            // padding: 76px 54px;
            // @media (max-width: 640px) {
            //     padding: 45px 24px;
            // }

            flex: 0 0 50%;
            @media (max-width: 640px) {
                flex: 0 0 100%;
            }
            display: flex;
            justify-content: center;
            align-items: center;

            & > * {
                width: 100%;
            }
        }
        &-right {
            padding: 40px 80px;

            @media (max-width: 1336px) {
                padding: 76px 60px;
            }
            @media (max-width: 1280px) {
                padding: 54px 22px;
            }

            flex: 1;
            // background-color: rgba(0,0,0,.02);
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        &-content {
            text-align: left;
            @include font2;
            &__title {
                & > * {
                    // text-transform: uppercase;
                    @include font-heading(3);
                    font-weight: 400;
                    @include font1;
                    a {
                        color: inherit;
                    }
                }
            }
            &__desc {
                margin-top: 24px;
                & > * {
                    color: #707070;
                    font-weight: 500;
                    @include size20px;
                    // line-height: 24px;
                }
            }

            // &__button{
            //     margin-top: 32px;
            // }

            &__more {
                margin-top: 32px;
                padding-top: 24px;
                border-top: 1px solid #707070;
                color: #000;
                @include size20px;
                // line-height: 24px;
                font-weight: 500;
            }
        }
    }
}

.mousse-contact {
    @include spaceL(margin-bottom);

    &-wrapper {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        @include spaceXL(padding-top);
    }

    &__bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
        @include spaceXL(padding-top);

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &-content {
        flex: 0 0 50%;
        z-index: 2;
        padding: 82px 0 82px 82px;

        @include rpmax(ml) {
            flex: 0 0 68%;
            padding: 40px 24px;
        }
    }

    &__title {
        & > * {
            @include font-heading(3);
            @include font2;
            color: #fff;
            font-weight: 500;
        }
    }

    &-button {
        margin-top: 30px;
    }

    &-staff {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 50%;
        height: 100%;

        @include rpmax(ml) {
            width: 40%;
        }

        img {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: bottom;
        }
    }
}
