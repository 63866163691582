.contactus-content__wrapper {
    .contactus {
        &__tittle {
            position: relative;

            // margin: 40px 0px;
            @include spaceL(margin, 0);
            &::after {
                position: absolute;
                content: "";
                top: 120%;
                left: 50%;
                height: 2px;
                width: 100%;
                background: map-get($mau, color-primary);
                transform: translateX(-50%);
            }
        }
    }
    .contactus-content {
        margin-bottom: map-get($size, margin-block-small);
        .contactus-content__left {
            .content {
                &-main {
                    text-align: left;
                    // margin-bottom: map-get($size, margin-block-small);
                    @include space(margin-bottom, 40px);

                    &__title {
                        // width: 60%;
                        // @media (max-width: 1860px) {
                        //     width: 75%;
                        // }
                        width: 100%;
                        position: relative;
                        margin-bottom: 20px;

                        text-transform: uppercase;
                        font-weight: bold;

                        &::after {
                            position: absolute;
                            content: "";
                            top: calc(100% + 10px);
                            left: 50%;
                            height: 1px;
                            width: 100%;
                            opacity: 0.2;
                            background: map-get($mau, color-primary);
                            transform: translateX(-50%);
                        }
                    }
                    &__desc {
                        width: 98%;
                        // @include spaceM(margin-bottom);
                        text-align: left;
                        color: inherit;
                        // padding-right: 100px;

                        @include space(margin-bottom, 20px);
                    }
                    // &__tel{
                    // }
                    &__email {
                        color: inherit;
                        @include spaceM(margin-bottom);
                    }
                    &__desc2 {
                        width: 70%;
                        text-align: left;
                        margin: 20px 0px;
                        font-size: 18px;
                        font-family: SEGOEUIL;
                    }
                    & > *:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
        .contactus-right {
            .contact-map {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                &__iframe {
                    padding-bottom: map-get($size, $key: margin-block-large);
                    padding-top: map-get($size, $key: margin-block-small);
                    border: 0;
                    width: 605px;
                    height: 728px;
                }
            }
        }
    }
}

@media (max-width: map-get($media, tl)) {
    .contactus-content__left,
    .contactus-right {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .contactus-content__wrapper .contactus-content .contactus-right .contact-map__iframe {
        width: 100%;
        padding-bottom: 36px;
    }

    // .contactus-content__wrapper .contactus__tittle{
    //     margin: 0 0 36px 0;
    // }
}

@media (max-width: map-get($media, ml)) {
    .contactus-content__wrapper .contactus-content .contactus-content__left .content-main {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .contactus-content__wrapper .contactus-content .contactus-content__left .content-main__title,
    .contactus-content__wrapper .contactus-content .contactus-content__left .content-main__desc {
        width: 100%;
        margin-bottom: 36px;
    }
    .contactus-content__wrapper .contactus-content .contactus-content__left .content-main__desc {
        padding-right: 0px;
    }
}
