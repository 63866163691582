// .about-media-logo {
//   padding: 80px 0;
//   @media (max-width: map-get($media, mm)) {
//     padding: 30px 0;
//   }
//   .container {
//     display: flex;
//     flex-direction: row;
//     justify-content: space-between;
//     &__item {
//     }
//   }
// }

#logo {
  .index-logo {
    @include spaceM(padding-bottom);

    .container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      .about-media-logo__item {
        width: 33.3333%;
        @include spaceM(padding-top);

        img {
          padding: 0 6px;
        }
      }

      .index-logo__item {
        width: 33.3333%;
        @include spaceM(padding-top);

        img {
          padding: 0 6px;
        }
      }
    }
  }
}

.about-media-logo {
  @include spaceM(padding-bottom);

  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  &__item {
    width: 33.3333%;
    @include spaceM(padding-top);

    img {
      padding: 0 6px;
    }
  }
}

// .about-media-logo__wrapper{
//   // margin: map-get($size, margin-block-large) 0;
//   @include spaceL(margin,0);

//   .about-media-logo{
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     flex-wrap: wrap;
//     margin: 0 -32px;
//     &__item{
//       width: 191px;
//       height: 141px;

//       margin: 0 32px;

//       object-fit: cover;
//       object-position: center;
//     }
//   }
// }

// // @media (max-width:map-get($media, tl)){
// //   .about-media-logo__wrapper{
// //     margin: map-get($size, margin-block-medium) 0;
// //   }
// // }
// @media (max-width:map-get($media, tm)){
//   .about-media-logo__wrapper{
//     .about-media-logo{
//       justify-content: center;
//     }
//   }
// }
// @media (max-width:map-get($media, ml)){
//   .about-media-logo__wrapper{
//     .about-media-logo{
//       &__item{
//         width: 124px;
//         height: 92px;
//       }
//     }
//   }
// }
// @media (max-width:map-get($media, mm)){
//   .about-media-logo__wrapper{
//     // margin: map-get($size, margin-block-small) 0;
//     .about-media-logo{
//       flex-direction: column;
//       &__item{
//         width: 191px;
//         height: 141px;
//       }
//       &__item:not(:first-child){
//         margin-top: map-get($size, margin-block-small);
//       }
//     }
//   }
// }
