$mau: (
	main: rgb(7, 65, 76),
	// color-primary: #041E42,
	color-primary: #a32732,
	color-title: #000,

	gray-2: #ececec,
	gray-1: #c0c0c0,
	dark-gray: #707070,
	white-1: #ffffff,
	black-op: rgba(0, 0, 0, 0.1),
	underline-red: #bf0d3e,

	error: rgba(255, 0, 0, 0.658),
	success: #4bb543
);

$media: (
	none: 0px,

	// mobile small
	ms: 320px,

	// mobile medium
	mm: 425px,

	// mobile large
	ml: 640px,

	// tablet small
	ts: 768px,

	// tablet medium
	tm: 900px,

	// tablet large
	tl: 1024px,

	// personal computer small
	ps: 1280px,

	// personal computer medium
	pm: 1440px,

	// personal computer large
	pl: 1920px
);

$size: (
	// header device size large
	header-large: 103px,
	// header device size small
	header-small: 78px,

	margin-block-x-large: 128px,
	margin-block-large: 64px,
	margin-block-medium: 40px,
	margin-block-small: 32px,

	font-h1: 80px,
	font-h1-tl: 56px,
	font-h1-ml: 30px,

	font-h2: 65px,
	font-h2-tl: 50px,
	font-h2-ml: 45px,

	font-h3: 50px,
	font-h3-tl: 40px,
	font-h3-ml: 32px,
	font-h3-mm: 20px,

	font-h4: 32px,
	font-h4-tl: 26px,
	font-h4-ml: 22px,
	font-h4-mm: 14px,

	// hover h4
	font-h7: 32px,
	font-h7-tl: 28px,
	font-h7-ml: 24px,

	font-h5: 20px,
	font-h5-tl: 19px,
	font-h5-ml: 18px,

	font-h6: 17px,
	font-h6-tl: 16px,
	font-h6-ml: 15px,

	font-p: 20px,
	font-p-tl: 18px,
	font-p-ml: 16px,

	font-p-small: 16px,
	font-p-small-tl: 15px,
	font-p-small-ml: 14px
);

@import "./variablesBs";

@mixin rpSpace($space, $xl, $lg, $md, $default, $important: "") {
	@if ($important == "") {
		#{$space}: $default;

		@include rpmin(md) {
			#{$space}: $md;
		}

		@include rpmin(lg) {
			#{$space}: $lg;
		}

		@include rpmin(xl) {
			#{$space}: $xl;
		}
	} @else {
		#{$space}: $default $important;

		@include rpmin(md) {
			#{$space}: $md $important;
		}

		@include rpmin(lg) {
			#{$space}: $lg $important;
		}

		@include rpmin(xl) {
			#{$space}: $xl $important;
		}
	}
}

@mixin rpSpaceSection($space, $xl, $lg, $md, $default, $side: 0, $important: "") {
	@if ($important == "") {
		#{$space}: $default $side;

		@include rpmin(md) {
			#{$space}: $md $side;
		}

		@include rpmin(lg) {
			#{$space}: $lg $side;
		}

		@include rpmin(xl) {
			#{$space}: $xl $side;
		}
	} @else {
		#{$space}: $default $side $important;

		@include rpmin(md) {
			#{$space}: $md $side $important;
		}

		@include rpmin(lg) {
			#{$space}: $lg $side $important;
		}

		@include rpmin(xl) {
			#{$space}: $xl $side $important;
		}
	}
}

@mixin space($key, $val, $important: "") {
	@if ($val == 128px) {
		@include rpSpace($key, $val, $val, 100px, 80px, $important);
	} @else if ($val == 100px) {
		@include rpSpace($key, $val, $val, 80px, 60px, $important);
	} @else if ($val == 80px) {
		@include rpSpace($key, $val, $val, 60px, 48px, $important);
	} @else if ($val == 64px) {
		@include rpSpace($key, $val, $val, 40px, 32px, $important);
	} @else if ($val == 60px) {
		@include rpSpace($key, $val, $val, 40px, 32px, $important);
	} @else if ($val == 40px) {
		@include rpSpace($key, $val, $val, 32px, 20px, $important);
	} @else if ($val == 20px) {
		@include rpSpace($key, $val, $val, 20px, 16px, $important);
	}
}
@mixin spaceSection($key, $val, $side: 0, $important: "") {
	@if ($val == 128px) {
		@include rpSpaceSection($key, $val, $val, 100px, 80px, $side, $important);
	} @else if ($val == 100px) {
		@include rpSpaceSection($key, $val, $val, 80px, 60px, $side, $important);
	} @else if ($val == 80px) {
		@include rpSpaceSection($key, $val, $val, 60px, 48px, $side, $important);
	} @else if ($val == 64px) {
		@include rpSpaceSection($key, $val, $val, 40px, 32px, $side, $important);
	} @else if ($val == 60px) {
		@include rpSpaceSection($key, $val, $val, 40px, 32px, $side, $important);
	} @else if ($val == 40px) {
		@include rpSpaceSection($key, $val, $val, 32px, 20px, $side, $important);
	} @else if ($val == 20px) {
		@include rpSpaceSection($key, $val, $val, 20px, 16px, $side, $important);
	}
}

@mixin spaceXL($name, $value: -1px) {
	@if ($value != -1px) {
		@include spaceSection($name, 128px, $value);

		// #{$name}: map-get($size, margin-block-x-large) $value;
		// @media (max-width: map-get($media, ps)) {
		// 	#{$name}: map-get($size, margin-block-large) $value;
		// }
		// @media (max-width: map-get($media, ml)) {
		// 	#{$name}: map-get($size, margin-block-medium) $value;
		// }
	} @else {
		@include space($name, 128px);

		// #{$name}: map-get($size, margin-block-x-large);
		// @media (max-width: map-get($media, ps)) {
		// 	#{$name}: map-get($size, margin-block-large);
		// }
		// @media (max-width: map-get($media, ml)) {
		// 	#{$name}: map-get($size, margin-block-medium);
		// }
	}
}

@mixin spaceL($name, $value: -1px) {
	@if ($value != -1px) {
		@include spaceSection($name, 64px);

		// #{$name}: map-get($size, margin-block-large) $value;
		// @media (max-width: map-get($media, ps)) {
		// 	#{$name}: map-get($size, margin-block-medium) $value;
		// }
		// @media (max-width: map-get($media, ml)) {
		// 	#{$name}: map-get($size, margin-block-small) $value;
		// }
	} @else {
		@include space($name, 64px);

		// #{$name}: map-get($size, margin-block-large);
		// @media (max-width: map-get($media, ps)) {
		// 	#{$name}: map-get($size, margin-block-medium);
		// }
		// @media (max-width: map-get($media, ml)) {
		// 	#{$name}: map-get($size, margin-block-small);
		// }
	}
}
@mixin spaceM($name, $value: -1px) {
	@if ($value != -1px) {
		#{$name}: map-get($size, margin-block-medium) $value;
		@media (max-width: map-get($media, ps)) {
			#{$name}: map-get($size, margin-block-small) $value;
		}
		@media (max-width: map-get($media, ml)) {
			#{$name}: 12px $value;
		}
	} @else {
		#{$name}: map-get($size, margin-block-medium);
		@media (max-width: map-get($media, ps)) {
			#{$name}: map-get($size, margin-block-small);
		}
		@media (max-width: map-get($media, ml)) {
			#{$name}: 12px;
		}
	}
}
@mixin spaceS($name, $value: -1px) {
	@if ($value != -1px) {
		#{$name}: map-get($size, margin-block-small) $value;
		@media (max-width: map-get($media, ps)) {
			#{$name}: 24px $value;
		}
		@media (max-width: map-get($media, ml)) {
			#{$name}: 16px $value;
		}
	} @else {
		#{$name}: map-get($size, margin-block-small);
		@media (max-width: map-get($media, ps)) {
			#{$name}: 24px;
		}
		@media (max-width: map-get($media, ml)) {
			#{$name}: 16px;
		}
	}
}

@mixin spaceButton($name, $value: -1px) {
	@if ($value != -1px) {
		#{$name}: 24px $value;
		@media (max-width: map-get($media, ps)) {
			#{$name}: 16px $value;
		}
		@media (max-width: map-get($media, ml)) {
			#{$name}: 12px $value;
		}
	} @else {
		#{$name}: 24px;
		@media (max-width: map-get($media, ps)) {
			#{$name}: 16px;
		}
		@media (max-width: map-get($media, ml)) {
			#{$name}: 12px;
		}
	}
}
@mixin spaceButtonS($name, $value: -1px) {
	@if ($value != -1px) {
		#{$name}: 20px $value;
		@media (max-width: map-get($media, ps)) {
			#{$name}: 12px $value;
		}
		@media (max-width: map-get($media, ml)) {
			#{$name}: 8px $value;
		}
	} @else {
		#{$name}: 20px;
		@media (max-width: map-get($media, ps)) {
			#{$name}: 12px;
		}
		@media (max-width: map-get($media, ml)) {
			#{$name}: 8px;
		}
	}
}

@mixin spaceContainer() {
	width: 96%;
	padding-right: 10px;
	padding-left: 10px;
	margin-right: auto;
	margin-left: auto;
	position: relative;

	@media (min-width: 576px) {
		max-width: 95%;
	}

	@media (min-width: 1025px) {
		max-width: 900px;
	}

	@media (min-width: 1280px) {
		max-width: 1200px;
		padding-right: 3%;
		padding-left: 3%;
	}

	@media (min-width: 1440px) {
		max-width: 1280px;
		padding-right: 3%;
		padding-left: 3%;
	}

	@media (min-width: 1600px) {
		max-width: 1366px;
		padding-right: 3%;
		padding-left: 3%;
	}
	@media (min-width: 1800px) {
		max-width: 1640px;
		padding-right: 5%;
		padding-left: 5%;
	}
}

@mixin font1 {
	font-family: "UTM-NEO-Sans-Intel", sans-serif;
}
@mixin font2 {
	font-family: "SVN-AvenirNext", sans-serif;
}

// font family for heading
@mixin font-heading($val) {
	// font-family: "UTM-NEO-Sans-Intel", sans-serif;
	// color: #000;
	// font-size: map-get($size, "font-h#{$val}");
	// // font-weight: 500;
	// font-weight: 700;

	@include font2;
	color: #000;
	font-size: map-get($size, "font-h#{$val}");
	font-weight: 500;

	@media (max-width: map-get($media, tl)) {
		font-size: map-get($size, "font-h#{$val}-tl");
	}
	@media (max-width: map-get($media, ml)) {
		font-size: map-get($size, "font-h#{$val}-ml");
	}
	// @media (max-width: map-get($media, mm)) {
	// 	font-size: map-get($size, "font-h#{$val}-mm");
	// }
}

@mixin font-p {
	color: map-get($mau, dark-gray);
	font-family: "UTM-NEO-Sans-Intel", sans-serif;
	font-size: map-get($size, "font-p");
	font-weight: 400;
	@media (max-width: map-get($media, tl)) {
		font-size: map-get($size, "font-p-tl");
	}
	@media (max-width: map-get($media, ml)) {
		font-size: map-get($size, "font-p-ml");
	}
}
@mixin font-p-medium {
	color: map-get($mau, dark-gray);
	font-family: "UTM-NEO-Sans-Intel", sans-serif;
	font-size: 18px;
	font-weight: 400;
	@media (max-width: map-get($media, tl)) {
		font-size: 17px;
	}
	@media (max-width: map-get($media, ml)) {
		font-size: 16px;
	}
}

@mixin font-p-small {
	color: map-get($mau, dark-gray);
	font-family: "UTM-NEO-Sans-Intel", sans-serif;
	font-size: map-get($size, "font-p-small");
	font-weight: 400;
	@media (max-width: map-get($media, tl)) {
		font-size: map-get($size, "font-p-small-tl");
	}
	@media (max-width: map-get($media, ml)) {
		font-size: map-get($size, "font-small-p-ml");
	}
}

@mixin p-line-clamp($val) {
	display: -webkit-box;
	-webkit-line-clamp: $val;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

@mixin text-nav-header {
	font-family: "SVN-AvenirNext", sans-serif;
	font-weight: 600;
	color: #000;
	font-size: 16px;
}
