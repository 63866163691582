.mattress-recommend__wrapper {
    // padding: 180px 0;
    @include spaceXL(padding, 0);

    .options-recommend {
        display: flex;
        align-items: center;
        // margin-bottom: 36px;
        @include spaceM(margin-bottom);
        &__link {
            padding: 5px 25px;
            &:first-child {
                border-radius: 5px 0px 0px 5px;
            }
            &:last-child {
                border-radius: 0px 5px 5px 0px;
            }
            font-weight: 700;
            cursor: pointer;
            background: map-get($mau, gray-2);
            color: map-get($mau, color-primary);
        }
        .active {
            background: map-get($mau, color-primary);
            color: map-get($mau, white-1);
            // border-radius: 6px !important;
        }
    }
    .mattress-recommend {
        @media (max-width: 1024px) {
            flex-direction: column;
            margin-right: 0;
            margin-left: 0;
        }
        .left-recommend {
            padding: 0;
            @include spaceL(padding-right);

            @media (max-width: 1024px) {
                padding-right: 0;

                max-width: 100%;
                flex: 0 0 100%;
            }
            .product-infor .product-infor__more {
                padding-top: 0;
                border-top: 0;
                span {
                    font-weight: 400;
                }
            }
            .more-wrapper {
                // padding-top: 24px;
                // @include spaceM(padding-top);

                .btn {
                    & > * {
                        border-radius: 5px;
                        border: 2px solid;
                        text-transform: uppercase;
                    }
                }
            }
        }
        .right-recommend {
            // margin-top: calc(-34px - 40px);

            @media (max-width: 1024px) {
                margin-top: 30px;
                max-width: 100%;
                flex: 0 0 100%;

                padding-right: 0;
                padding-left: 0;
            }
            .swiper-btn__nav {
                height: 100%;

                .slide {
                    height: 100%;

                    img {
                        height: 410px;
                        width: 100%;
                        object-fit: contain;
                        object-position: top;
                        @media (max-width: 425px) {
                            height: auto;
                        }
                    }
                }

                // .swiper-button-prev {
                //     left: -40px;
                //     padding: 7px;

                //     .slide{
                //         img{
                //             width: 100%;
                //         }
                //     }
                // }
                // .swiper-button-next {
                //     right: -40px;
                //     padding: 7px;
                // }
            }
        }
    }
}

// @media (max-width: map-get($media, ps)) {
//     .mattress-recommend__wrapper {
//         padding: 64px 0;
//     }
// }
// @media (max-width: map-get($media, tl)) {
//     .mattress-recommend__wrapper {
//         padding: 56px 0;
//     }
// }
@media (max-width: map-get($media, ts)) {
    // .mattress-recommend__wrapper {
    //     padding: 36px 0;
    // }
    // .mattress-recommend__wrapper .mattress-recommend .left-recommend {
    //     padding: 0;
    // }
    // .product-infor .product-infor__content {
    //     margin: 24px 0;
    // }
    // .mattress-recommend__wrapper .mattress-recommend{
    //     padding: 0 24px;
    // }
    .mattress-recommend__wrapper .mattress-recommend .left-recommend .more-wrapper {
        // padding-top: 24px;
        @include spaceM(padding, 0);
    }
}
