// @font-face {
//     font-family: HELVETICANEUELTSTD-BDOU ;
//     src: url('../fonts/HELVETICANEUELTSTD-BDOU.otf');
// }
// @font-face {
//     font-family: HELVETICANEUELTSTD-ROMAN ;
//     src: url('../fonts/HELVETICANEUELTSTD-ROMAN.otf');
// }
// @font-face {
//     font-family: SEGOEUI ;
//     src: url('../fonts/SEGOEUI.otf');
// }
// @font-face {
//     font-family: SEGOEUIB ;
//     src: url('../fonts/SEGOEUIB.otf');
// }
// @font-face {
//     font-family: SEGOEUII ;
//     src: url('../fonts/SEGOEUII.otf');
// }
// @font-face {
//     font-family:SEGOEUIL ;
//     src: url('../fonts/SEGOEUIL.otf');
// }
// @font-face {
//     font-family: SEGOEUISL ;
//     src: url('../fonts/SEGOEUISL.otf');
// }
// @font-face {
//     font-family: SEGOEUIZ ;
//     src: url('../fonts/SEGOEUIZ.otf');
// }
// @font-face {
//     font-family: SEGUIBL ;
//     src: url('../fonts/SEGUIBL.otf');
// }
// @font-face {
//     font-family:SEGUIBLI;
//     src: url('../fonts/SEGUIBLI.otf');
// }
// @font-face {
//     font-family:SEGUIEMJ;
//     src: url('../fonts/SEGUIEMJ.otf');
// }
// @font-face {
//     font-family:SEGUIHIS;
//     src: url('../fonts/SEGUIHIS.otf');
// }
// @font-face {
//     font-family:SEGUILI;
//     src: url('../fonts/SEGUILI.otf');
// }
// @font-face {
//     font-family:SEGUISB;
//     src: url('../fonts/SEGUISB.otf');
// }
// @font-face {
//     font-family:SEGUISBI;
//     src: url('../fonts/SEGUISBI.otf');
// }
// @font-face {
//     font-family:SEGUISLI;
//     src: url('../fonts/SEGUISLI.otf');

// }
// @font-face {
//     font-family:SEGUISYM;
//     src: url('../fonts/SEGUISYM.otf');
// }
// @font-face {
//     font-family:HELVETICA-NEUE;
//     src: url('../fonts/HELVETICANEUELTSTD-ROMAN.otf');
// }

@font-face {
    font-family: UTM-NEO-Sans-Intel;
    src: url("../fonts/00137-UTM-Neo-Sans-Intel.ttf") format("truetype");
    // src: url('../fonts/UTM Neo Sans IntelBold.ttf') format('truetype');
    // src: url('../fonts/UTM Neo Sans IntelBold_Italic.ttf') format('truetype');
    // src: url('../fonts/UTM Neo Sans Intel_Italic.ttf') format('truetype');
}
// @font-face {
//     font-family: UTM-NEO-Sans-Intel;
//     font-weight: 700;
// }
// @font-face {
//     font-family: UTM-NEO-Sans-Intel;
//     font-weight: 700;
//     font-style: italic;
// }
// @font-face {
//     font-family: UTM-NEO-Sans-Intel;
//     font-style: italic;
// }

// @font-face {
//     font-family: AvenirNextLTPro;

//     src: url('../fonts/AvenirNextLTPro-Bold.otf') format('opentype');
//     src: url('../fonts/AvenirNextLTPro-BoldIt.otf') format('opentype');

//     // src: url('../fonts/AvenirNextLTPro-BoldCn.otf') format('opentype');
//     // src: url('../fonts/AvenirNextLTPro-BoldCnIt.otf') format('opentype');
//     // src: url('../fonts/AvenirNextLTPro-Cn.otf') format('opentype');
//     // src: url('../fonts/AvenirNextLTPro-CnIt.otf') format('opentype');

//     src: url('../fonts/AvenirNextLTPro-Demi.otf') format('opentype');
//     src: url('../fonts/AvenirNextLTPro-DemiIt.otf') format('opentype');
//     // src: url('../fonts/AvenirNextLTPro-DemiCn.otf') format('opentype');
//     // src: url('../fonts/AvenirNextLTPro-DemiCnIt.otf') format('opentype');

//     src: url('../fonts/AvenirNextLTPro-Heavy.otf') format('opentype');
//     src: url('../fonts/AvenirNextLTPro-HeavyIt.otf') format('opentype');
//     // src: url('../fonts/AvenirNextLTPro-HeavyCn.otf') format('opentype');
//     // src: url('../fonts/AvenirNextLTPro-HeavyCnIt.otf') format('opentype');

//     src: url('../fonts/AvenirNextLTPro-It.otf') format('opentype');
//     src: url('../fonts/AvenirNextLTPro-Medium.otf') format('opentype');
//     src: url('../fonts/AvenirNextLTPro-MediumIt.otf') format('opentype');
//     // src: url('../fonts/AvenirNextLTPro-MediumCn.otf') format('opentype');
//     // src: url('../fonts/AvenirNextLTPro-MediumCnIt.otf') format('opentype');

//     src: url('../fonts/AvenirNextLTPro-Regular.otf') format('opentype');
//     src: url('../fonts/AvenirNextLTPro-UltLt.otf') format('opentype');
//     src: url('../fonts/AvenirNextLTPro-UltLtIt.otf') format('opentype');
//     // src: url('../fonts/AvenirNextLTPro-UltLtCn.otf') format('opentype');
//     // src: url('../fonts/AvenirNextLTPro-UltLtCnIt.otf') format('opentype');
// }

// @font-face {
//     font-family: AvenirNextLTPro;
//     src: url("../fonts/AvenirNextLTPro-Regular.otf") format("opentype");
//     font-weight: 400;
// }
// @font-face {
//     font-family: AvenirNextLTPro;
//     src: url("../fonts/AvenirNextLTPro-It.otf") format("opentype");
//     font-style: italic;
// }
// @font-face {
//     font-family: AvenirNextLTPro;
//     src: url("../fonts/AvenirNextLTPro-Medium.otf") format("opentype");
//     font-weight: 500;
// }
// @font-face {
//     font-family: AvenirNextLTPro;
//     src: url("../fonts/AvenirNextLTPro-MediumIt.otf") format("opentype");
//     font-weight: 500;
//     font-style: italic;
// }

// @font-face {
//     font-family: AvenirNextLTPro;
//     src: url("../fonts/AvenirNextLTPro-Bold.otf") format("opentype");
//     font-weight: 700;
// }
// @font-face {
//     font-family: AvenirNextLTPro;
//     src: url("../fonts/AvenirNextLTPro-BoldIt.otf") format("opentype");

//     font-weight: 700;
//     font-style: italic;
// }
// @font-face {
//     font-family: AvenirNextLTPro;
//     src: url("../fonts/AvenirNextLTPro-Demi.otf") format("opentype");
//     font-weight: 600;
// }
// @font-face {
//     font-family: AvenirNextLTPro;
//     src: url("../fonts/AvenirNextLTPro-DemiIt.otf") format("opentype");

//     font-weight: 600;
//     font-style: italic;
// }
// @font-face {
//     font-family: AvenirNextLTPro;
//     src: url("../fonts/AvenirNextLTPro-UltLt.otf") format("opentype");
//     font-weight: 800;
// }
// @font-face {
//     font-family: AvenirNextLTPro;
//     src: url("../fonts/AvenirNextLTPro-UltLtIt.otf") format("opentype");

//     font-weight: 800;
//     font-style: italic;
// }
// @font-face {
//     font-family: AvenirNextLTPro;
//     src: url("../fonts/AvenirNextLTPro-Heavy.otf") format("opentype");
//     font-weight: 900;
// }
// @font-face {
//     font-family: AvenirNextLTPro;
//     src: url("../fonts/AvenirNextLTPro-HeavyIt.otf") format("opentype");

//     font-weight: 900;
//     font-style: italic;
// }
// @font-face {
//     font-family: AvenirNextLTPro;
//     font-weight: 700;
// }
// @font-face {
//     font-family: AvenirNextLTPro;
//     font-style: italic;
// }

// Value	Common weight name
// 100	Thin (Hairline)
// 200	Extra Light (Ultra Light)
// 300	Light
// 400	Normal (Regular)
// 500	Medium
// 600	Semi Bold (Demi Bold)
// 700	Bold
// 800	Extra Bold (Ultra Bold)
// 900	Black (Heavy)
// 950	Extra Black (Ultra Black)

@font-face {
    font-family: SVN-AvenirNext;
    src:
        url("../fonts/SVN-AvenirNext/OTF/SVN-AvenirNext-Thin.otf") format("opentype"),
        url("../fonts/SVN-AvenirNext/TTF/SVN-AvenirNext-Thin.ttf") format("truetype");
    font-weight: 100;
}
@font-face {
    font-family: SVN-AvenirNext;
    src:
        url("../fonts/SVN-AvenirNext/OTF/SVN-AvenirNext-ThinItalic.otf") format("opentype"),
        url("../fonts/SVN-AvenirNext/TTF/SVN-AvenirNext-ThinItalic.ttf") format("truetype");
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: SVN-AvenirNext;
    src:
        url("../fonts/SVN-AvenirNext/OTF/SVN-AvenirNext-UltraLight.otf") format("opentype"),
        url("../fonts/SVN-AvenirNext/TTF/SVN-AvenirNext-UltraLight.ttf") format("truetype");
    font-weight: 200;
}
@font-face {
    font-family: SVN-AvenirNext;
    src:
        url("../fonts/SVN-AvenirNext/OTF/SVN-AvenirNext-UltraLightItalic.otf") format("opentype"),
        url("../fonts/SVN-AvenirNext/TTF/SVN-AvenirNext-UltraLightItalic.ttf") format("truetype");
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: SVN-AvenirNext;
    src:
        url("../fonts/SVN-AvenirNext/OTF/SVN-AvenirNext-Light.otf") format("opentype"),
        url("../fonts/SVN-AvenirNext/TTF/SVN-AvenirNext-Light.ttf") format("truetype");
    font-weight: 300;
}
@font-face {
    font-family: SVN-AvenirNext;
    src:
        url("../fonts/SVN-AvenirNext/OTF/SVN-AvenirNext-LightItalic.otf") format("opentype"),
        url("../fonts/SVN-AvenirNext/TTF/SVN-AvenirNext-LightItalic.ttf") format("truetype");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: SVN-AvenirNext;
    src:
        url("../fonts/SVN-AvenirNext/OTF/SVN-AvenirNext-Regular.otf") format("opentype"),
        url("../fonts/SVN-AvenirNext/TTF/SVN-AvenirNext-Regular.ttf") format("truetype");
    font-weight: 400;
}
@font-face {
    font-family: SVN-AvenirNext;
    src:
        url("../fonts/SVN-AvenirNext/OTF/SVN-AvenirNext-Italic.otf") format("opentype"),
        url("../fonts/SVN-AvenirNext/TTF/SVN-AvenirNext-Italic.ttf") format("truetype");
    font-style: italic;
}
@font-face {
    font-family: SVN-AvenirNext;
    src:
        url("../fonts/SVN-AvenirNext/OTF/SVN-AvenirNext-Medium.otf") format("opentype"),
        url("../fonts/SVN-AvenirNext/TTF/SVN-AvenirNext-Medium.ttf") format("truetype");
    font-weight: 500;
}
@font-face {
    font-family: SVN-AvenirNext;
    src:
        url("../fonts/SVN-AvenirNext/OTF/SVN-AvenirNext-MediumItalic.otf") format("opentype"),
        url("../fonts/SVN-AvenirNext/TTF/SVN-AvenirNext-MediumItalic.ttf") format("truetype");
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: SVN-AvenirNext;
    src:
        url("../fonts/SVN-AvenirNext/OTF/SVN-AvenirNext-Demi.otf") format("opentype"),
        url("../fonts/SVN-AvenirNext/TTF/SVN-AvenirNext-Demi.ttf") format("truetype");
    font-weight: 600;
}
@font-face {
    font-family: SVN-AvenirNext;
    src:
        url("../fonts/SVN-AvenirNext/OTF/SVN-AvenirNext-DemiItalic.otf") format("opentype"),
        url("../fonts/SVN-AvenirNext/TTF/SVN-AvenirNext-DemiItalic.ttf") format("truetype");

    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: SVN-AvenirNext;
    src:
        url("../fonts/SVN-AvenirNext/OTF/SVN-AvenirNext-Bold.otf") format("opentype"),
        url("../fonts/SVN-AvenirNext/TTF/SVN-AvenirNext-Bold.ttf") format("truetype");
    font-weight: 700;
}
@font-face {
    font-family: SVN-AvenirNext;
    src:
        url("../fonts/SVN-AvenirNext/OTF/SVN-AvenirNext-BoldItalic.otf") format("opentype"),
        url("../fonts/SVN-AvenirNext/TTF/SVN-AvenirNext-BoldItalic.ttf") format("truetype");

    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: SVN-AvenirNext;
    src:
        url("../fonts/SVN-AvenirNext/OTF/SVN-AvenirNext-ExtraBold.otf") format("opentype"),
        url("../fonts/SVN-AvenirNext/TTF/SVN-AvenirNext-ExtraBold.ttf") format("truetype");
    font-weight: 800;
}
@font-face {
    font-family: SVN-AvenirNext;
    src:
        url("../fonts/SVN-AvenirNext/OTF/SVN-AvenirNext-ExtraBoldItalic.otf") format("opentype"),
        url("../fonts/SVN-AvenirNext/TTF/SVN-AvenirNext-ExtraBoldItalic.ttf") format("truetype");

    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: SVN-AvenirNext;
    src:
        url("../fonts/SVN-AvenirNext/OTF/SVN-AvenirNext-Heavy.otf") format("opentype"),
        url("../fonts/SVN-AvenirNext/TTF/SVN-AvenirNext-Heavy.ttf") format("truetype");
    font-weight: 900;
}
@font-face {
    font-family: SVN-AvenirNext;
    src:
        url("../fonts/SVN-AvenirNext/OTF/SVN-AvenirNext-HeavyItalic.otf") format("opentype"),
        url("../fonts/SVN-AvenirNext/TTF/SVN-AvenirNext-HeavyItalic.ttf") format("truetype");

    font-weight: 900;
    font-style: italic;
}
