.wholesale--family {
    background-color: #c1bfbf;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    // padding: 50px 0px;
    @include spaceL(padding, 0);
    &__content {
        width: 686px;
        .title {
            @include spaceL(margin-bottom);
        }

        .content {
            text-transform: uppercase;
            // margin: 50px 0 20px 0;
            @include spaceS(margin-bottom);
            font-weight: 700;
            font-family: inherit;
        }
        .form-input {
            display: flex;
            flex-direction: column;
            .row-input {
                display: flex;
                justify-content: space-between;
                input {
                    padding-left: 10px;
                    width: 49%;
                    border-radius: 8px;
                    border: 1px;
                    height: 50px;
                    margin: 10px 0;
                    letter-spacing: 1px;
                    transition: 0.3s;
                }
                input:focus,
                textarea:focus {
                    border: 1px solid map-get($mau, color-primary);
                }
            }
            .colum-input {
                input {
                    padding-left: 10px;
                    border-radius: 8px;
                    border: 1px;
                    height: 50px;
                    margin: 10px 0;
                    width: 100%;
                    letter-spacing: 1px;
                    transition: 0.3s;
                }
                textarea {
                    width: 100%;
                    height: 150px;
                    border-radius: 8px;
                    outline: 0;
                    padding-left: 10px;
                    line-height: 40px;
                    resize: none;
                    letter-spacing: 1px;
                    outline: none;
                    transition: 0.3s;
                    border: 0;
                    margin: 10px 0;
                }
                input:focus,
                textarea:focus {
                    border: 1px solid map-get($mau, color-primary);
                }
            }
            .button-send input[type="submit"] {
                appearance: none;
                outline: none;
                border: none;
                background: none;
                border-radius: 0;

                display: inline-flex;
                white-space: nowrap;
                justify-content: center;
                align-items: center;
                text-align: center;

                padding: 15px;
                margin-top: 18px;
                color: map-get($mau, color-primary);
                border: 3px solid map-get($mau, color-primary);
                background-color: white;
                border-radius: 8px;
                @include size20px;
                font-weight: bold;
                text-transform: uppercase;

                transition: 0.16s all ease-in-out;
                &:hover {
                    background-color: map-get($mau, color-primary);
                    color: white;
                }
            }
        }
    }
}

@media (max-width: map-get($media, tm)) {
    .wholesale--family__content {
        width: 90%;
    }
}
@media (max-width: map-get($media, ml)) {
    .wholesale--family__content {
        width: 96%;
    }

    input {
        width: 100% !important;
    }

    .wholesale--family__content .form-input .row-input {
        flex-direction: column;
    }
}
