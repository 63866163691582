// .AS-media {
//     margin-top: 100px;
// }
.AS-container {
    margin-top: 80px;
    @media (max-width: 768px) {
        margin-top: 64px;
    }
    // background-color: #041e42;
    background-color: map-get($mau, color-primary);
    color: map-get($mau, gray-1);
}
.AS-header {
    padding-top: 70px;
    @media (max-width: 768px) {
        padding-top: 64px;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    & > * {
        @include font-heading(3);
        color: white;
    }
}

.AS-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    letter-spacing: 1px;

    padding-bottom: 88px;
    @media (max-width: 768px) {
        padding-bottom: 64px;
    }

    &-1 {
        @include spaceM(margin-top);
        display: flex;
        flex-direction: row;
        text-align: center;
        justify-content: center;

        padding: 0 12px;
        @media (max-width: 425px) {
            padding: 0;
        }

        & > * {
            font-weight: 600;
            @include font-heading(4);
            color: #c0c0c0;
            font-family: "Segoe UI", sans-serif;
        }

        .before,
        .after {
            height: fit-content;
            width: 64px;
            height: 43px;
            object-fit: contain;
            padding-right: 12px;
            // margin-top: -16px;

            @media (max-width: 1280px) {
                width: 48px;
            }
            @media (max-width: 425px) {
                width: 35px;
            }
        }
        .before {
            align-items: flex-start;
            margin-right: 32px;
            @media (max-width: 1280px) {
                margin-right: 8px;
            }
        }
        .after {
            transform: rotate(180deg) translateY(-16px);
            align-self: flex-end;
            margin-left: 32px;
            @media (max-width: 1280px) {
                margin-left: 8px;
            }
        }

        p {
            padding: 0 16px;
            @media (max-width: 768px) {
                padding: 0 8px;
            }
        }
    }
    &-2 {
        // @include font-p;
        // margin-top: 50px;
        @include spaceM(margin-top);

        p {
            @include font-heading(4);
            color: #c0c0c0;
            font-weight: 300;
            font-style: italic;
            font-family: "Segoe UI", sans-serif;
            letter-spacing: 2px;
        }
    }
    &-3 {
        @include spaceM(margin-top);
        a {
            text-transform: uppercase;
            background-color: transparent;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            border: 3px solid white;
            border-radius: 6px;
            padding: 16px 4px;
            min-width: 186px;
            color: white;
            font-weight: bold;

            &:hover {
                color: map-get($mau, color-primary);
                border: 3px solid map-get($mau, color-primary);
                background-color: map-get($mau, gray-1);
            }
        }
    }
}

.index-logo {
    .container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    // padding: 120px 0;

    // @media (max-width: 768px) {
    //     padding: 80px 0;
    // }
    // @media (max-width:425px) {
    //     padding: 40px 0;
    // }

    // @include spaceXL(padding-top);
}

.index-page {
    .footer {
        margin-top: 0px;
    }
}

// .AS-footer {
//     @include spaceL(padding, 0);
//     .AS-logo {
//         display: flex;
//         justify-content: space-evenly;
//         flex-wrap: wrap;
//         align-items: center;
//         width: 100%;

//         .AS-logo-slogan {
//             object-fit: contain;
//             object-position: center;
//             flex: 1;

//             width: 170px;
//             height: 61px;
//             // &::after{
//             //     position: absolute;
//             //     content: "";
//             //     top: 0;
//             //     left: 350%;
//             //     width: 1px;
//             //     height: 100%;
//             //     background: gray;
//             // }

//             &:not(:first-child) {
//                 border-left: 3px solid rgba(map-get($mau, gray-2), 0.3);
//             }
//         }
//     }
// }

// @media (max-width: map-get($media, tl)){
//     // .AS-media{
//     //     margin-top: 56px;
//     // }
//     .AS-content-1{
//         width: 95%;
//     }
//     .AS-content-1 .before,
//     .AS-content-1 .after{
//         width: 36px;
//         height: 36px;
//     }
// }
// @media (max-width: map-get($media, ml)){
//     // .AS-media{
//     //     margin-top: 56px;
//     // }
//     .AS-content-1{
//         width: 95%;
//     }
//     .AS-content-1 .before,
//     .AS-content-1 .after{
//         width: 26px;
//         height: 26px;
//     }
// }

// @media (max-width: map-get($media, ts)){
//     // .AS-media{
//     //     margin-top: 36px;
//     // }
//     .AS-footer .AS-logo .AS-logo-slogan{
//         &:not(:first-child) {
//             border-left: 0px solid rgba(map-get($mau, gray-2), 0.3);
//         }
//         margin: 24px;
//         flex: initial;
//     }
// }
