.form-info__popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 8888;
    // background: map-get($color,details-popup );
    background: rgba(#000, 0.8);

    display: flex;
    align-items: center;
    visibility: hidden;
    opacity: 0;
    transition: 0.3s ease-in-out;
    z-index: 10000;
    min-height: 100%;
    .contact-1__section--form {
        padding: 0;
        margin: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: 0.4s ease-in-out;
        min-height: 100%;
        z-index: 10001;
    }
    .btn-close {
        position: absolute;
        top: 0px;
        right: 0px;
        // padding: 5px;
        width: 42px;
        height: 42px;
        border-radius: 0 0 0 16px;
        // background: map-get($color, btn-hiden );
        // background: #041E42;
        background: #a32732;

        cursor: pointer;

        &::after {
            position: absolute;
            width: 100%;
            height: 100%;
            transform: scale(0);
            top: 0;
            left: 0;
            content: "";
            // background: map-get($color, button);
            background: white;
            border-radius: 0 0 0 16px;
            z-index: 2;
            transition: 0.3s ease-in-out;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        }
        &:hover {
            .line-1,
            .line-2 {
                // background: map-get($color, btn-hiden  );
                // background: #041E42;
                background: #a32732;
            }
            &::after {
                transform: scale(1);
            }
        }

        .line-1,
        .line-2 {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 3px;
            height: 20px;
            border-radius: 20px;
            // background: map-get($color, button );
            background: white;
            z-index: 3;
        }
        .line-1 {
            transform: translate(-50%, -50%) rotate(45deg);
        }
        .line-2 {
            transform: translate(-50%, -50%) rotate(135deg);
        }
    }
    .form-alert__popup--wrapper {
        // background: map-get($color, sub6 );
        background: #ffffff;
        max-width: 852px;

        width: 96%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: 0.4s ease-in-out;
        min-height: 100%;
        z-index: 10001;
        padding: 80px 45px;
        display: flex;
        flex-direction: column;
        &-alert {
            text-align: center;
            // color: map-get($color, sub1 );
            // @include spaceContact(s24, margin-bottom);
            margin-bottom: 38px;
            & > * {
                line-height: 32px;
                font-size: 32px;
                font-family: inherit;
                color: #000000;
                letter-spacing: 1.2px;
                font-weight: 400;
            }
        }
        &-btn {
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            .btn--link {
                width: 100%;
                max-width: 320px;

                @media (max-width: 640px) {
                    max-width: 100%;
                }

                padding: 16px 20px;
                display: flex;

                position: relative;
                align-items: center;
                justify-content: center;

                background-color: transparent;
                // border: 3px solid #041E42;
                border: 3px solid #a32732;
                border-radius: 6px;
                cursor: pointer;
                transition: 0.2s ease-in-out;

                & > * {
                    text-transform: uppercase;
                    // color: #041E42;
                    color: #a32732;
                    font-family: "Segoe UI";
                    font-weight: bold;
                    @include size20px;
                }

                &:hover {
                    // background-color: #041E42 !important;
                    background-color: #a32732 !important;
                    // opacity: .9;
                    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
                    & > * {
                        color: #fff !important;
                    }
                }
            }
        }
    }
}
.form-info__popup.active {
    visibility: visible;
    opacity: 1;
    .form-alert__popup--wrapper {
        transform: translate(-50%, -50%) scale(1);
    }
    // .contact-1__section--form,
    // .form-alert__popup--wrapper{
    // opacity: 0.5;
    .contact-1__section--form {
        transform: translate(-50%, -50%) scale(1);
    }
}
