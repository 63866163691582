.footer {
    @include spaceXL(margin-top);

    background-color: #a32732;
    color: #fff;
    a {
        color: inherit;
    }
    &-wrapper {
        padding: 48px 0 24px 0;
        display: flex;
        flex-wrap: wrap;

        &__logo {
            display: block;
            width: 100%;
            margin-right: 0px;
            margin-bottom: 40px;

            @include rpmin(ml) {
                width: 203px;
                margin-right: 100px;
            }
            & > * {
                width: 100%;
                height: 60px;
                object-fit: contain;
                object-position: left;
            }
        }

        &-nav {
            width: calc(100% + 40px);
            flex: 1;

            margin-left: -40px;
            margin-top: -40px;

            display: flex;
            flex-wrap: wrap;
            @include font2;
            justify-content: space-between;

            .nav-item {
                padding-top: 40px;
                padding-left: 40px;

                max-width: 50%;
                width: 50%;

                @include rpmin(xl) {
                    width: auto;
                    max-width: 30%;
                }

                & > ul {
                    margin-top: 16px;

                    display: flex;
                    flex-direction: column;

                    & > li {
                        & + li {
                            margin-top: 8px;
                        }
                        & > a {
                            @include font2;
                            white-space: wrap;
                            font-size: 15px;
                            font-weight: 500;
                        }
                    }
                }
                &--addr {
                    & > ul > li {
                        & + li {
                            margin-top: 22px;
                        }

                        & > a {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }

    &-copyright {
        padding: 24px;

        border-top: 2px solid #fff;

        @include font2;
        text-align: center;
        font-size: 15px;
        font-weight: 500;
    }
}
